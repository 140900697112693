/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { LinkText } from "../LinkText/LinkText";
import { NewWindowText } from "../NewWindowText/NewWindowText";

type InformationLinkTextItemProps = {
  label?: string;
  href: string;
  color?: string;
  showNewWindowIcon?: boolean;
};

export const InformationLinkText: React.FC<InformationLinkTextItemProps> = ({
  label,
  href,
  color,
  showNewWindowIcon = true,
}) => {
  return (
    <p
      css={css`
        ${mixin.changeTheme};
        color: ${color};
        text-align: left;
        display: flex;
        align-items: flex-end;
        column-gap: ${defaultTheme.spacing.xxxs};
        flex-wrap: wrap;
      `}
    >
      {label && (
        <span
          css={css`
            ${mixin.text.body};
            font-weight: normal;
            letter-spacing: ${defaultTheme.letterSpacing.xs};
            line-height: 1 !important;
          `}
        >
          {label}
        </span>
      )}
      <LinkText
        mode="anchor"
        item={{
          href,
          value: (
            <NewWindowText
              color={color}
              size={18}
              weight={20}
              hideIcon={!showNewWindowIcon}
              cssOverride={css`
                ${mixin.text.description};
              `}
            >
              {href}
            </NewWindowText>
          ),
        }}
        styleType="reverse"
      />
    </p>
  );
};
