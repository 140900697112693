/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SlideInOutOverlay } from "components/organisms/SlideInOutOverlay/SlideInOutOverlay";
import { AboutPage } from "components/pages/AboutPage/AboutPage";
import { ContactPage } from "components/pages/ContactPage/ContactPage";
import { DesignPage } from "components/pages/DesignPage/DesignPage";
import { GlobalLayout } from "components/pages/GlobalLayout/GlobalLayout";
import { HomePage } from "components/pages/HomePage/HomePage";
import { NotFoundPage } from "components/pages/NotFoundPage/NotFoundPage";
import { PrivacyPolicyPage } from "components/pages/PrivacyPolicyPage/PrivacyPolicyPage";
import { ProductFitScreenWindowPage } from "components/pages/ProductFitScreenWindowPage/ProductFitScreenWindowPage";
import { ProductKawaichiBlogPage } from "components/pages/ProductKawaichiBlogPage/ProductKawaichiBlogPage";
import { ProductPage } from "components/pages/ProductPage/ProductPage";
import { ProductPortfolioPage } from "components/pages/ProductPortfolioPage/ProductPortfolioPage";
import { ProductShotokuPage } from "components/pages/ProductShotokuPage/ProductShotokuPage";
import { ProductTalkStockPage } from "components/pages/ProductTalkStockPage/ProductTalkStockPage";
import { ProgrammingOtasukePage } from "components/pages/ProgrammingOtasukePage/ProgrammingOtasukePage";
import { SoftwarePage } from "components/pages/SoftwarePage/SoftwarePage";
import { SplashScreenPage } from "components/pages/SplashScreenPage/SplashScreenPage";
import { usePageTracking } from "hooks/usePageTracking";
import { usePageTransitionHandler } from "hooks/usePageTransition";
import { useRouter } from "hooks/useRouter";
import { Route, Routes } from "react-router-dom";
import { constants, GA_ID } from "utils/constants";

export const CustomRoutes = () => {
  usePageTracking({ gaId: GA_ID });
  const { location } = useRouter();
  const {
    currentPath,
    pageTransitionStatus,
    prevPathRef,
    showSplashScreen,
    handleAnimationComplete,
  } = usePageTransitionHandler();

  return (
    <>
      {showSplashScreen && (
        <SplashScreenPage onAnimationComplete={handleAnimationComplete} />
      )}

      <GlobalLayout>
        <SlideInOutOverlay
          show={pageTransitionStatus.pageTransition}
          direction="right-to-left"
        />
        <SlideInOutOverlay
          show={pageTransitionStatus.pageTransition2}
          direction="down-to-left"
        />

        {/* メインコンテンツ */}
        <div
          css={css`
            position: relative;
            z-index: 1;
          `}
        >
          <Routes
            location={{ ...location, pathname: currentPath }}
            key={prevPathRef.current}
          >
            <Route path={constants.pages.home.url} element={<HomePage />} />
            <Route path={constants.pages.about.url} element={<AboutPage />} />
            <Route
              path={constants.pages.product.url.index}
              element={<ProductPage />}
            />
            <Route
              path={constants.pages.product.url.talkstock}
              element={<ProductTalkStockPage />}
            />
            <Route
              path={constants.pages.product.url.portfolio}
              element={<ProductPortfolioPage />}
            />
            <Route
              path={constants.pages.product.url.kawaichiBlog}
              element={<ProductKawaichiBlogPage />}
            />
            <Route
              path={constants.pages.product.url.programmingOtasuke}
              element={<ProgrammingOtasukePage />}
            />
            <Route
              path={constants.pages.product.url.fitscreenwindow}
              element={<ProductFitScreenWindowPage />}
            />
            <Route
              path={constants.pages.product.url.shotoku}
              element={<ProductShotokuPage />}
            />
            <Route
              path={constants.pages.software.url}
              element={<SoftwarePage />}
            />
            <Route path={constants.pages.design.url} element={<DesignPage />} />
            <Route
              path={constants.pages.privacyPolicy.url}
              element={<PrivacyPolicyPage />}
            />
            <Route
              path={constants.pages.contact.url}
              element={<ContactPage />}
            />
            {/* *** No layout *** */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </GlobalLayout>
    </>
  );
};
