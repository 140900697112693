/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import { GradationDividerMediumTitle } from "components/atoms/GradationDividerMediumTitle/GradationDividerMediumTitle";
import { ImageFadeLoader } from "components/atoms/ImageFadeLoader/ImageFadeLoader";
import { InformationLinkText } from "components/atoms/InformationLinkText/InformationLinkText";
import { LargeBodyText } from "components/atoms/LargeBodyText/LargeBodyText";
import { LinkText } from "components/atoms/LinkText/LinkText";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { FitScreenWindowTechnicalStack } from "components/molecules/FitScreenWindowTechnicalStack/FitScreenWindowTechnicalStack";
import {
  ProductIntroductionTemplate,
  ProductIntroductionTemplateSlotProps,
} from "components/templates/ProductIntroductionTemplate/ProductIntroductionTemplate";
import {
  ProductFitScreenWindowPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { usePreloadImages } from "hooks/usePreloadImages";
import { useProduct } from "hooks/useProduct";
import { useTheme } from "hooks/useTheme";
import { ProductIntroductionSection } from "organisms/ProductIntroductionSection/ProductIntroductionSection";
import React, { memo, useMemo } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme, Theme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { constants } from "utils/constants";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { Columns2to3Wrapper } from "utils/Wrapper/Columns2to3Wrapper/Columns2to3Wrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";
import { MainLayout } from "../MainLayout/MainLayout";

type DetailProps = {
  handleImageCardClick: (src: string, alt: string) => void;
  theme: Theme;
};

const Detail01: React.FC<DetailProps> = memo(
  ({ handleImageCardClick, theme }) => {
    const detailRequireSource = [
      require("assets/images/product/fitscreenwindow/demonstration-moveresizewindow.gif"),
    ];
    const {
      preloadedSources: preloadedDetailSources,
      isPreloading: isDetailPreloading,
    } = usePreloadImages(detailRequireSource);
    const preloadedDetailRequireSource = preloadedDetailSources[0];

    return isDetailPreloading ? (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <ImageFadeLoader
          color="#AAAAAA"
          height={200}
          bgColor={defaultTheme.palette.common.white}
          cssOverride={css`
            border-radius: ${defaultTheme.borderRadius.base};
          `}
        />
      </FadeInOnScrollByComponent>
    ) : (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <button
          type="button"
          onClick={() =>
            handleImageCardClick(
              preloadedDetailRequireSource,
              "ウィンドウのリサイズ・配置機能"
            )
          }
        >
          <div
            css={css`
              ${mixin.boxShadow};
              ${mixin.hoverTransitionOpacityEffect};
              overflow: hidden;
            `}
          >
            <img
              src={preloadedDetailRequireSource}
              alt={"ウィンドウのリサイズ・配置機能"}
              css={css`
                border: ${defaultTheme.borderWidth.sm}px solid;
                border-color: ${theme.palette.gray.background};
                border-radius: ${defaultTheme.borderRadius.base};
                width: 100%;
                max-width: 439px;
                min-width: 320px;
              `}
            />
          </div>
        </button>
      </FadeInOnScrollByComponent>
    );
  }
);

const Detail02: React.FC<DetailProps> = memo(
  ({ handleImageCardClick, theme }) => {
    const detailRequireSource = [
      require("assets/images/product/fitscreenwindow/demonstration-settingmenu.gif"),
    ];
    const {
      preloadedSources: preloadedDetailSources,
      isPreloading: isDetailPreloading,
    } = usePreloadImages(detailRequireSource);
    const preloadedDetailRequireSource = preloadedDetailSources[0];

    return isDetailPreloading ? (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <ImageFadeLoader
          height={200}
          color="#AAAAAA"
          bgColor={defaultTheme.palette.common.white}
          cssOverride={css`
            width: 100%;
            max-width: 0px;
            border-radius: ${defaultTheme.borderRadius.base};
          `}
        />
      </FadeInOnScrollByComponent>
    ) : (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <button
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          type="button"
          onClick={() =>
            handleImageCardClick(
              preloadedDetailRequireSource,
              "設定ダッシュボード"
            )
          }
        >
          <img
            src={preloadedDetailRequireSource}
            alt={"設定ダッシュボード"}
            css={css`
              ${mixin.boxShadow};
              ${mixin.hoverTransitionOpacityEffect};
              border: ${defaultTheme.borderWidth.sm}px solid;
              border-color: ${theme.palette.gray.background};
              border-radius: ${defaultTheme.borderRadius.base};
              width: 100%;
              max-width: 439px;
              min-width: 320px;
            `}
          />
        </button>
      </FadeInOnScrollByComponent>
    );
  }
);

const ProductFitScreenWindowPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const {
    imageSources: { productFitScreenWindow },
  } = useImageSources();
  const { open, src, alt, handleImageCardClick, handleImageModalClose } =
    useProduct();

  const detail01RequireSource = [
    require("assets/images/product/fitscreenwindow/demonstration-settingmenu.gif"),
  ];
  const {
    preloadedSources: preloadedDetail01Sources,
    isPreloading: isDetail01Preloading,
  } = usePreloadImages(detail01RequireSource);
  const preloadeddetail01RequireSource = preloadedDetail01Sources[0];

  const detail02RequireSource = [
    require("assets/images/product/fitscreenwindow/demonstration-moveresizewindow.gif"),
  ];
  const {
    preloadedSources: preloadedDetail02Sources,
    isPreloading: isDetail02Preloading,
  } = usePreloadImages(detail02RequireSource);
  const preloadeddetail02RequireSource = preloadedDetail02Sources[0];

  const slotProps: ProductIntroductionTemplateSlotProps = useMemo(
    () => ({
      title: (
        <EngTitle
          cssOverride={css`
            margin-bottom: ${theme.spacing.xxs};
          `}
        >
          FitScreenWindow
        </EngTitle>
      ),
      subText: "WINDOWS APP",
      description: (
        <BodyText>
          選択しているアクティブなウィンドウを、キーボードショートカットで画面にフィットできるWindows向けネイティブアプリです
        </BodyText>
      ),
      information: (
        <>
          <InformationLinkText
            label="WEB: "
            href={constants.links.fitscreenwindow}
            color={theme.palette.text}
            showNewWindowIcon
          />
          <InformationLinkText
            label="GITHUB: "
            href="https://github.com/Kawaichi0228/FitScreenWindow/"
            color={theme.palette.text}
            showNewWindowIcon
          />
        </>
      ),
      technicalStack: {
        icons: (
          <FitScreenWindowTechnicalStack
            bgColor={defaultTheme.palette.contrastText}
          />
        ),
        table: {
          data: [
            ["アプリ", "Python 3.9"],
            ["GUI", "Qt for Python(PySide6)"],
            ["ウェブサイト", "Vue.js 2, SCSS, BEM, GitHub Pages"],
            [
              "主要パッケージ等",
              "pyWin32(画面情報取得), wxPython(ショートカットキー登録)",
            ],
            ["コンパイル", "Nuitka"],
            ["インストーラー", "NSIS"],
            ["コーディング規約", "Black & Flake8"],
            ["その他", "Qt Creator, venv, Illustrator"],
          ],
        },
      },
      images: {
        product: {
          src: productFitScreenWindow.brandBanner,
          alt: "FitScreenWindow",
        },
      },
      slot1: (
        <ProductIntroductionSection
          title="主な機能"
          theme={theme}
          bgColor={theme.palette.background}
          slot1={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    ウィンドウのリサイズ・配置機能
                  </GradationDividerMediumTitle>
                  <BodyText>
                    選択中のアクティブなウィンドウに対して、ショートカットキーを押すごとに、画面の端に合わせて自動的に配置
                    & サイズを拡大(縮小)します。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      text-align: right; // 右端に寄せる
                    }
                  `}
                >
                  <Detail01
                    handleImageCardClick={handleImageCardClick}
                    theme={theme}
                  />
                </div>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              leftComponent={
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      text-align: right; // 右端に寄せる
                    }
                  `}
                >
                  <Detail02
                    handleImageCardClick={handleImageCardClick}
                    theme={theme}
                  />
                </div>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    設定ダッシュボード
                  </GradationDividerMediumTitle>
                  <BodyText>
                    サイズやショートカットキーなどの各種設定は、タスクトレイからアプリを右クリックし、表示されたメニューの「設定」から変更可能です。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot2: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="開発背景・工夫した点"
          theme={theme}
          bgColor={theme.palette.contrastText}
          slot1={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    開発背景
                  </GradationDividerMediumTitle>
                  <BodyText>
                    私はよく、開いているブラウザを画面の左右どちらかに配置しつつ、もう片方の余白で別のアプリの作業をする、という使い方をしていました。
                  </BodyText>
                  <BodyText>
                    <br />
                    しかし、マウスでウィンドウを毎回ドラッグして画面の端に合わせてサイズ調整するのは面倒、といった問題が生じていました。
                    この問題を解決するため、Windows標準のショートカットキー(Winキー+
                    左/右矢印)<sup>(※1)</sup>を使っていましたが、このやり方では
                    <u>強制的に画面半分のサイズになってしまう</u>ため、
                    <strong>
                      サイズを拡大・縮小したいときにいちいちマウスに持ち替えないといけない...
                    </strong>
                    といった不満がありました。
                  </BodyText>
                  <BodyText>
                    <br />
                    普段パソコンで作業をしている私の知り合いや職場の方たちにも同様の悩みがあることを知り、それなら、これらの問題を解決したより使いやすいアプリケーションを自分で作ってしまおう、と思い開発
                    <sup>(※2)</sup>することにしました。
                  </BodyText>
                  <div
                    css={css`
                      margin-top: ${defaultTheme.spacing.xs};
                    `}
                  >
                    <p
                      css={css`
                        ${mixin.text.note};
                        color: ${theme.palette.gray.text};
                      `}
                    >
                      ※1:
                      フリーソフトもいくつか試してみましたが、なかなか理想の機能を持ったものは見当たりませんでした。
                    </p>
                    <p
                      css={css`
                        ${mixin.text.note};
                        color: ${theme.palette.gray.text};
                      `}
                    >
                      ※2:
                      当初のプロトタイプはPython3ではなくAutoHotKeyというプログラミング言語で開発しました。
                    </p>
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productFitScreenWindow.ingenuity1}
                      alt={"開発背景"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 439px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productFitScreenWindow.ingenuity2,
                          "工夫した点"
                        )
                      }
                    >
                      <img
                        src={productFitScreenWindow.ingenuity2}
                        alt={"工夫した点"}
                        css={css`
                          ${mixin.hoverTransitionOpacityEffect};
                          border-radius: ${defaultTheme.borderRadius.base};
                          width: 100%;
                          max-width: 439px;
                          min-width: 320px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    工夫した点
                  </GradationDividerMediumTitle>
                  <BodyText>
                    単純にあらかじめ設定した固定サイズで配置するだけでなく、連続してショートカットキーを押すごとに、設定した上限回数まで拡大・縮小(上限を超えると最初のサイズに戻る)できるようにしたり、別のウィンドウを選択した状態でショートカットキーを押すと、自動的に画面余白にフィットするように工夫しました。
                  </BodyText>
                  <BodyText>
                    <br />
                    ほかには、左配置と右配置それぞれで基準サイズ・逆方向にリサイズできるようにしたり、タスクバーのサイズを減算する・しないなどのオプションを選べるよう実装しました。
                  </BodyText>
                  <BodyText>
                    <br />
                    また、設定ダッシュボードはUI / UX向上のため、Qt
                    CreatorとPySide6<sup>(※1)</sup>
                    を使い、カッコイイ見た目に仕上げました。
                  </BodyText>
                  <div
                    css={css`
                      margin-top: ${defaultTheme.spacing.xs};
                    `}
                  >
                    <p
                      css={css`
                        ${mixin.text.note};
                        color: ${theme.palette.gray.text};
                      `}
                    >
                      ※1: 当初はQt DesignerとPySide2を使っていました(画像左側)。
                    </p>
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot3: (
        <section>
          <FullWidthOuter
            pt={defaultTheme.spacing.lg}
            pb={defaultTheme.spacing.lg}
            bgColor={theme.palette.background}
          >
            <BaseInner
              width="100%"
              cssOverride={css`
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing.lg};
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.text}>
                    その他
                  </GradationDividerMediumTitle>
                </FadeInOnScrollByComponent>
                <Columns2to3Wrapper
                  columnGap={{
                    sm: defaultTheme.spacing.sm,
                    md: defaultTheme.spacing.sm,
                    lg: defaultTheme.spacing.sm,
                  }}
                  firstComponent={
                    <FadeInOnScrollByComponent
                      delay={defaultTheme.delay.none}
                      direction="spot"
                    >
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        <LinkText
                          styleType="default"
                          mode="anchor"
                          item={{
                            value: <span>ウェブサイト(外部リンク)</span>,
                            href: constants.links.fitscreenwindow,
                          }}
                        />
                      </LargeBodyText>
                      <button
                        type="button"
                        onClick={() =>
                          handleImageCardClick(
                            productFitScreenWindow.other1,
                            "ウェブサイト"
                          )
                        }
                      >
                        <div
                          css={css`
                            ${mixin.boxShadow};
                            ${mixin.hoverTransitionOpacityEffect};
                            height: 254px;
                            overflow: hidden;
                          `}
                        >
                          <img
                            src={productFitScreenWindow.other1}
                            alt={"ウェブサイト"}
                            css={css`
                              border: ${defaultTheme.borderWidth.sm}px solid;
                              border-color: ${theme.palette.gray.background};
                              border-radius: ${defaultTheme.borderRadius.base};
                              width: 100%;
                              max-width: 100%;
                              @media screen and (min-width: ${defaultTheme
                                  .breakpoints.md}) {
                                max-width: 360px;
                              }
                            `}
                          />
                        </div>
                      </button>
                    </FadeInOnScrollByComponent>
                  }
                  secondComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 200 : 0}
                      direction="spot"
                    >
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        <LinkText
                          styleType="default"
                          mode="anchor"
                          item={{
                            value: (
                              <span
                                css={css`
                                  display: inline;
                                `}
                              >
                                LT資料(外部リンク: Docswell)
                              </span>
                            ),
                            href: "https://www.docswell.com/s/Kawaichi0228/51JE1L-2023-12-03-222909",
                          }}
                        />
                      </LargeBodyText>
                      <button
                        type="button"
                        onClick={() =>
                          handleImageCardClick(
                            productFitScreenWindow.other2,
                            "LT資料"
                          )
                        }
                      >
                        <img
                          src={productFitScreenWindow.other2}
                          alt={"LT資料"}
                          css={css`
                            ${mixin.boxShadow};
                            ${mixin.hoverTransitionOpacityEffect};
                            border: ${defaultTheme.borderWidth.sm}px solid;
                            border-color: ${theme.palette.gray.background};
                            border-radius: ${defaultTheme.borderRadius.base};
                            width: 100%;
                            max-width: 100%;
                            @media screen and (min-width: ${defaultTheme
                                .breakpoints.md}) {
                              max-width: 360px;
                            }
                          `}
                        />
                      </button>
                    </FadeInOnScrollByComponent>
                  }
                  thirdComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 400 : 0}
                      direction="spot"
                    >
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        インストーラ画面
                      </LargeBodyText>
                      <div
                        css={css`
                          text-align: center;
                          @media screen and (min-width: ${defaultTheme
                              .breakpoints.md}) {
                            text-align: left;
                          }
                        `}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            handleImageCardClick(
                              productFitScreenWindow.other3,
                              "インストーラ画面"
                            )
                          }
                        >
                          <div
                            css={css`
                              ${mixin.boxShadow};
                              ${mixin.hoverTransitionOpacityEffect};
                              overflow: hidden;
                              height: 254px;
                              background-color: ${defaultTheme.palette.common
                                .white};
                              border: ${defaultTheme.borderWidth.sm}px solid;
                              border-color: ${theme.palette.gray.background};
                              border-radius: ${defaultTheme.borderRadius.base};
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <img
                              src={productFitScreenWindow.other3}
                              alt={"インストーラ画面"}
                              css={css`
                                width: 100%;
                                max-width: 100%;
                                @media screen and (min-width: ${defaultTheme
                                    .breakpoints.md}) {
                                  max-width: 360px;
                                }
                              `}
                            />
                          </div>
                        </button>
                      </div>
                    </FadeInOnScrollByComponent>
                  }
                />
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>
      ),
      imageModal: {
        open,
        src,
        alt,
        onClose: handleImageModalClose,
      },
    }),
    [
      mode,
      theme,
      pc,
      open,
      src,
      alt,
      isDetail01Preloading,
      isDetail02Preloading,
    ]
  );

  return (
    <ProductIntroductionTemplate
      id={constants.pages.product.id}
      theme={theme}
      mediaQuery={mediaQuery}
      slotProps={slotProps}
    />
  );
};

const imageSources: ProductFitScreenWindowPageImageSourcesState = {
  productFitScreenWindow: {
    brandBanner: require("assets/images/product/fitscreenwindow.webp"),
    ingenuity1: require("assets/images/product/fitscreenwindow/ingenuity1.webp"),
    ingenuity2: require("assets/images/product/fitscreenwindow/ingenuity2.webp"),
    other1: require("assets/images/product/fitscreenwindow/website.webp"),
    other2: require("assets/images/product/fitscreenwindow/lt.webp"),
    other3: require("assets/images/product/fitscreenwindow/install.webp"),
  },
};
const requireSources = Object.values(imageSources.productFitScreenWindow);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <ProductFitScreenWindowPage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as ProductFitScreenWindowPage };
