/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { KawaichiBlogTextLogo } from "components/atoms/KawaichiBlogTextLogo/KawaichiBlogTextLogo";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { KawaichBlogTechnicalStack } from "components/molecules/KawaichBlogTechnicalStack/KawaichBlogTechnicalStack";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import React from "react";
import { defaultTheme, Theme } from "styles/theme";
import { CLIENT_BASEURL } from "utils/config";
import { constants } from "utils/constants";
import { ProductCard, ProductCardSlotProps } from "../ProductCard/ProductCard";
import { ProductCardBottomComponent } from "../ProductCardBottomComponent/ProductCardBottomComponent";

type Props = {
  src: string;
  slotProps?: ProductCardSlotProps & {
    button?: {
      color?: string;
    };
  };
  theme: Theme;
};

export const KawaichiBlogProductCard: React.FC<Props> = ({
  src,
  slotProps,
  theme,
}) => {
  return (
    <ProductCard
      title={
        <KawaichiBlogTextLogo
          darkColor={slotProps?.title?.color}
          lightColor={slotProps?.title?.color}
          cssOverride={slotProps?.title?.style}
        />
      }
      description="プログラミングに役立つ記事を投稿しているブログです"
      image={{
        src: src,
        alt: "kawaichiblog",
      }}
      href={`${CLIENT_BASEURL}${constants.pages.product.url.kawaichiBlog}`}
      slotProps={slotProps}
      bottomComponent={
        <ProductCardBottomComponent
          theme={theme}
          topComponent={
            <a
              href={`${CLIENT_BASEURL}${constants.pages.product.url.kawaichiBlog}`}
              target="_blank"
              rel="noreferrer"
            >
              <LongArrowButton
                textColor={slotProps?.button?.color}
                arrowColor={slotProps?.button?.color}
                cssOverride={css`
                  color: ${slotProps?.button?.color};
                `}
              >
                詳細を見る
              </LongArrowButton>
            </a>
          }
          technicalStack={
            <KawaichBlogTechnicalStack
              bgColor={defaultTheme.palette.background}
            />
          }
          other={
            <LabelRadiusTags bold={false} tags={[{ value: "Jamstack" }]} />
          }
        />
      }
    />
  );
};
