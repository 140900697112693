/** @jsxImportSource @emotion/react */
import { SerializedStyles, css, keyframes } from "@emotion/react";
import React from "react";

const fadeIn = keyframes`
  0% {
    transform: translateX(0px) translateY(26px);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
`;

type Props = {
  children: string;
  cssOverride?: SerializedStyles;
  initialDelay?: number;
  sequentialDelay?: number;
};

export const FadeInSequentialText: React.FC<Props> = ({
  children,
  cssOverride,
  initialDelay = 0,
  sequentialDelay = 100,
}) => {
  return (
    <div css={[cssOverride]}>
      {children.split("").map((char, index) => (
        <span
          key={index}
          css={css`
            opacity: 0;
            animation: ${fadeIn} 0.75s ease-in-out forwards;
            animation-delay: ${initialDelay + index * sequentialDelay}ms;
            display: inline-block;
          `}
        >
          {char}
        </span>
      ))}
    </div>
  );
};
