/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";
import { Button } from "../Button/Button";
import { LongArrowIcon } from "../LongArrowIcon/LongArrowIcon";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  textColor?: string;
  arrowColor?: string;
  borderHeight?: number;
  arrowSize?: number;
  direction?: "left" | "right";
  children?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const LongArrowButton: React.FC<Props> = ({
  className,
  cssOverride,
  textColor = "#444444",
  arrowColor = "#666666",
  borderHeight = 1.25,
  arrowSize = 36,
  direction = "right",
  children,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      cssOverride={css`
        ${cssOverride};
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        cursor: pointer;
      `}
      className={"" + (className ? ` ${className}` : "")}
    >
      {direction === "left" && (
        <LongArrowIcon
          size={arrowSize}
          borderHeight={borderHeight}
          color={arrowColor}
          cssOverride={css`
            display: flex;
            align-items: center;
          `}
          direction="left"
        />
      )}
      {children && (
        <div
          css={css`
            ${mixin.changeThemeColor};
            font-weight: 700;
            color: ${textColor};
          `}
        >
          {children}
        </div>
      )}
      {direction === "right" && (
        <LongArrowIcon
          size={arrowSize}
          borderHeight={borderHeight}
          color={arrowColor}
          cssOverride={css`
            display: flex;
            align-items: center;
          `}
          direction="right"
        />
      )}
    </Button>
  );
};
