/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";

type Props = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
  zIndex?: number;
};

export const PositionedWrapper: React.FC<Props> = ({
  children,
  cssOverride,
  top,
  right,
  bottom,
  left,
  zIndex,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          position: relative;
          z-index: ${zIndex ? zIndex : 0};
        `,
      ]}
    >
      <div
        css={css`
          position: absolute;
          top: ${top ? top : "auto"};
          right: ${right ? right : "auto"};
          bottom: ${bottom ? bottom : "auto"};
          left: ${left ? left : "auto"};
        `}
      >
        {children}
      </div>
    </div>
  );
};
