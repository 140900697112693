/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Copyright } from "components/atoms/Copyright/Copyright";
import { SelfIntroductionText } from "components/atoms/SelfIntroductionText/SelfIntroductionText";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

export type FixedFooterLayoutTemplateProps = {
  children: React.ReactNode;
};

export const FixedFooterLayoutTemplate: React.FC<FixedFooterLayoutTemplateProps> =
  ({ children }: FixedFooterLayoutTemplateProps) => {
    return (
      <>
        {children}
        <footer>
          <div>
            <div
              css={css`
                position: fixed;
                bottom: ${defaultTheme.spacing.header.top};
                left: ${defaultTheme.spacing.base.x.mobile};
                @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
                  left: ${defaultTheme.spacing.base.x.pc};
                }
              `}
            >
              <SelfIntroductionText
                cssOverride={css`
                  ${mixin.text.note};
                  @media screen and (min-width: ${defaultTheme.breakpoints
                      .sm}) {
                    ${mixin.text.description};
                  }
                  color: ${defaultTheme.palette.gray.text};
                  display: flex;
                  justify-content: center;
                  position: relative;
                `}
              />
            </div>
            <div
              css={css`
                position: fixed;
                bottom: ${defaultTheme.spacing.header.top};
                right: ${defaultTheme.spacing.base.x.mobile};
                @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
                  right: ${defaultTheme.spacing.base.x.pc};
                }
              `}
            >
              <Copyright
                cssOverride={css`
                  ${mixin.text.note};
                  @media screen and (min-width: ${defaultTheme.breakpoints
                      .sm}) {
                    ${mixin.text.description};
                  }
                  color: ${defaultTheme.palette.gray.text};
                `}
              />
            </div>
          </div>
        </footer>
      </>
    );
  };
