/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BorderRadiusButton } from "components/atoms/BorderRadiusButton/BorderRadiusButton";
import { FillRadiusButton } from "components/atoms/FillRadiusButton/FillRadiusButton";
import React, { MouseEventHandler } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { DialogModal } from "../DialogModal/DialogModal";

type ContactDialogModalProps = {
  open: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  error?: boolean;
  onOKButtonClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ContactDialogModal: React.FC<ContactDialogModalProps> = ({
  open,
  onClose,
  loading = false,
  error = false,
  onOKButtonClick,
}) => {
  return (
    <DialogModal
      loading={loading}
      open={open}
      onClose={onClose}
      message={
        <>
          <p
            css={[
              css`
                color: ${defaultTheme.palette.text};
                font-weight: 700;
                ${mixin.text.largeBody}
              `,
              error &&
                css`
                  margin-bottom: ${defaultTheme.spacing.xs};
                `,
            ]}
          >
            入力した内容で送信してよろしいですか？
          </p>
          {error && (
            <p
              css={css`
                color: ${defaultTheme.palette.error};
                font-weight: 700;
                ${mixin.text.largeBody}
                text-align: center;
              `}
            >
              エラー：送信に失敗しました。
            </p>
          )}
        </>
      }
      buttons={
        <>
          <BorderRadiusButton
            line={1.5}
            onClick={onClose}
            height={22}
            width={26}
            cssOverride={css`
              font-weight: 700;

              ${mixin.text.body}
              ${mixin.disableLineHeight}
              padding: 1.25rem 1rem;
              @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                ${mixin.text.largeBody}
                padding: 1.5rem 1.5rem;
              }
            `}
          >
            キャンセル
          </BorderRadiusButton>
          <FillRadiusButton
            onClick={onOKButtonClick}
            width={26}
            height={22}
            cssOverride={css`
              font-weight: 700;

              ${mixin.text.body}
              ${mixin.disableLineHeight}
              padding: 1.25rem 1.5rem;
              @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                ${mixin.text.largeBody}
                padding: 1.5rem 2rem;
              }
            `}
          >
            <div>送信する</div>
          </FillRadiusButton>
        </>
      }
    />
  );
};
