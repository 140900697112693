import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  usePageTransitionMutators,
  usePageTransitionState,
} from "stores/pageTransitionStore";
import { disableScroll, enableScroll } from "styles/functions";
import { ANIMATAION_DURATION_PAGE_TRANSITION } from "styles/mixin";
import { defaultZIndex } from "styles/theme";
import { constants } from "utils/constants";
import { useNavMenu } from "./useNavMenu";
import { useTheme } from "./useTheme";
import { useWindowScrollToTop } from "./useWindowScrollToTop";

/**
 * ページ遷移時に関するハンドラー
 */
export const usePageTransitionHandler = () => {
  const location = useLocation();
  const { scrollToWindowTop } = useWindowScrollToTop();
  const { setPageTransition } = usePageTransitionMutators();
  const { currentPath, pageTransitionStatus } = usePageTransitionState();
  const prevPathRef = useRef(location.pathname);
  const { mode, switchToDarkHeaderTheme, switchToLightHeaderTheme, setTheme } =
    useTheme();
  const {
    closeMenu,
    isItemClick: isNavItemClick,
    setIsItemClick,
    open: isNavMenuOpen,
  } = useNavMenu();

  useEffect(() => {
    if (prevPathRef.current !== location.pathname) {
      // メニューを閉じる
      closeMenu();

      // 開始 - ページ遷移アニメーション
      if (isNavItemClick || isNavMenuOpen) {
        setPageTransition({
          pageTransitionStatus: {
            ...pageTransitionStatus,
            pageTransition2: true,
          },
          currentPath: prevPathRef.current,
        });

        // ページ遷移中は一時的にheaderのZindexを最前面に出す
        setTheme((prev) => ({
          ...prev,
          theme: {
            ...prev.theme,
            zIndex: {
              ...prev.theme.zIndex,
              headerLogo: 1000,
              hambugerButton: 1000,
              changeThemeButton: 1000,
            },
          },
        }));
      } else {
        setPageTransition({
          pageTransitionStatus: {
            ...pageTransitionStatus,
            pageTransition: true,
          },
          currentPath: prevPathRef.current,
        });
      }

      // 開始 - スクロール禁止
      disableScroll();

      // 遷移中 - ページ遷移時(黒オーバーレイ表示されているとき)のみ、ヘッダーのテーマを変更
      const isLightMode = mode === "light";
      if (isLightMode) {
        switchToDarkHeaderTheme();
      }

      const pageTransitionTimer = setTimeout(() => {
        // 終了 - スクロール禁止解除
        enableScroll();

        // 終了 - アニメーション完了後の処理
        if (isNavItemClick || isNavMenuOpen) {
          setPageTransition({
            pageTransitionStatus: {
              ...pageTransitionStatus,
              pageTransition2: false,
            },
            currentPath: location.pathname,
          });

          setTheme((prev) => ({
            ...prev,
            theme: {
              ...prev.theme,
              zIndex: defaultZIndex,
            },
          }));
        } else {
          setPageTransition({
            pageTransitionStatus: {
              ...pageTransitionStatus,
              pageTransition: false,
            },
            currentPath: location.pathname,
          });
        }

        // 終了 - ページ遷移時(黒オーバーレイ表示されているとき)のみ、ヘッダーのテーマを戻す
        if (isLightMode) {
          switchToLightHeaderTheme();
        }

        // 終了 - メニューのisItemClickをfalseにする
        setIsItemClick(false);

        // 終了 - アニメーション完了後、スクロールトップまで戻す
        scrollToWindowTop();

        prevPathRef.current = location.pathname;
        return () => clearTimeout(pageTransitionTimer);
      }, ANIMATAION_DURATION_PAGE_TRANSITION);

      return () => clearTimeout(pageTransitionTimer);
    }
  }, [location.pathname]);

  // 初回ロード時のスプラッシュシーンの表示
  const [firstLoaded, setFirstLoaded] = useState(false);
  const handleAnimationComplete = useCallback(() => {
    setFirstLoaded(true);
  }, []);

  const showSplashScreen =
    !firstLoaded && currentPath === constants.pages.home.url;

  return {
    currentPath,
    pageTransitionStatus,
    prevPathRef,
    showSplashScreen,
    handleAnimationComplete,
  };
};
