import { FullScreenLoadingPage } from "components/pages/FullScreenLoadingPage/FullScreenLoadingPage";
import { useImageSources } from "hooks/useImageSources";
import { usePreloadImages } from "hooks/usePreloadImages";
import React, { useEffect, useState } from "react";

export const withLoader =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>,
    requireSources: string[],
    imageSources: any
  ) =>
  (props: P) => {
    const { setImageSources } = useImageSources();
    const [isLoading, setIsLoading] = useState(true);

    const { isPreloading } = usePreloadImages(requireSources);

    useEffect(() => {
      if (!isPreloading) {
        setImageSources((prev) => ({
          ...prev,
          ...imageSources,
        }));
        setIsLoading(false);
      }
    }, [isPreloading, setImageSources]);

    return isLoading ? (
      <FullScreenLoadingPage />
    ) : (
      <WrappedComponent {...(props as P)} />
    );
  };
