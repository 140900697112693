/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type FieldSetProps = {
  title: string;
  children: React.ReactNode;
  slotProps?: {
    fieldset?: {
      borderColor?: string;
      style?: SerializedStyles;
    };
    title?: {
      color?: string;
      style?: SerializedStyles;
    };
  };
};

export const FieldSet: React.FC<FieldSetProps> = ({
  title,
  children,
  slotProps = {},
}) => {
  const fieldsetStyle =
    slotProps.fieldset?.style ??
    css`
      padding: 20px 35px;
    `;
  const fieldsetBorderColor = slotProps.fieldset?.borderColor ?? "#d3d3d3";
  const titleStyle = slotProps.title?.style ?? css``;
  const titleColor = slotProps.title?.color ?? "#666666";

  return (
    <fieldset
      css={[
        fieldsetStyle,
        css`
          border: ${defaultTheme.borderWidth.sm}px solid ${fieldsetBorderColor};
          border-radius: ${defaultTheme.borderRadius.base};
          width: 100%;
          height: 100%;
        `,
      ]}
    >
      <legend
        css={[
          titleStyle,
          css`
            color: ${titleColor};
            text-align: center;
            text-transform: uppercase;
            letter-spacing: ${defaultTheme.letterSpacing.xs};
            padding: 0 20px;
          `,
        ]}
      >
        {title}
      </legend>
      {children}
    </fieldset>
  );
};
