/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { usePopover } from "hooks/usePopover";
import { ArrowTooltip } from "../ArrowTooltip/ArrowTooltip";

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
  bgColor?: string;
};

export const ArrowTooltipButtonWithRef = ({
  title,
  children,
  bgColor = "#666666",
}: Props) => {
  const { anchorRef, open, handleOpen, handleClose } =
    usePopover<HTMLButtonElement>();

  return (
    <ArrowTooltip open={open} title={title} bgColor={bgColor}>
      <button
        ref={anchorRef}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        css={css`
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
          cursor: inherit;
        `}
      >
        {children}
      </button>
    </ArrowTooltip>
  );
};
