/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  radius?: number;
  backgroundColor?: string;
  textColor?: string;
};

const styles = (props: Props) => {
  return css`
    border-radius: ${props.radius}px;
    display: block;
    width: fit-content;
    padding: calc(${props.height}px) calc(${props.width}px);
    white-space: nowrap;
    line-height: 0; // 文字をchildrenに渡したときに上下に余白ができてしまうのを防ぐ
    box-sizing: border-box;
    background-color: ${props.backgroundColor};
    color: ${props.textColor};
    text-decoration: none;
    text-align: center;
  `;
};

export const FillRadiusTag: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  width = 18,
  height = 12,
  radius = 10,
  backgroundColor = "#444444",
  textColor = "#ffffff",
}) => {
  return (
    <div
      css={[
        styles({
          width,
          height,
          radius,
          backgroundColor,
          textColor,
        }),
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      {children}
    </div>
  );
};
