/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BatchFileRenamerTextLogo } from "components/atoms/BatchFileRenamerTextLogo/BatchFileRenamerTextLogo";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { BatchFileRenamerTechnicalStack } from "components/molecules/BatchFileRenamerTechnicalStack/BatchFileRenamerTechnicalStack";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import React from "react";
import { defaultTheme, Theme } from "styles/theme";
import { constants } from "utils/constants";
import { ProductCard, ProductCardSlotProps } from "../ProductCard/ProductCard";
import { ProductCardBottomComponent } from "../ProductCardBottomComponent/ProductCardBottomComponent";

type Props = {
  src: string;
  slotProps?: ProductCardSlotProps & {
    button?: {
      color?: string;
    };
  };
  theme: Theme;
};

export const BatchFileRenamerProductCard: React.FC<Props> = ({
  src,
  slotProps,
  theme,
}) => {
  return (
    <ProductCard
      title={
        <BatchFileRenamerTextLogo
          darkColor={slotProps?.title?.color}
          lightColor={slotProps?.title?.color}
          cssOverride={slotProps?.title?.style}
        />
      }
      description="大量にあるファイルの名前を、ファイル名の変更前後を入力したデータから、まとめてリネームできるWindows/MacOS向けのCLIツールです"
      image={{
        src: src,
        alt: "batchfilerenamer",
      }}
      href={constants.links.batchfilerenamer}
      tag="開発中"
      slotProps={{
        title: {
          color: slotProps?.title?.color,
          style: css`
            letter-spacing: ${defaultTheme.letterSpacing.xs};
          `,
        },
        tag: slotProps?.tag,
        description: slotProps?.description,
      }}
      bottomComponent={
        <ProductCardBottomComponent
          theme={theme}
          topComponent={
            <a
              href={constants.links.batchfilerenamer}
              target="_blank"
              rel="noreferrer"
            >
              <LongArrowButton
                textColor={slotProps?.button?.color}
                arrowColor={slotProps?.button?.color}
                cssOverride={css`
                  color: ${slotProps?.button?.color};
                `}
              >
                公式サイトを見る
              </LongArrowButton>
            </a>
          }
          technicalStack={
            <BatchFileRenamerTechnicalStack
              bgColor={defaultTheme.palette.background}
            />
          }
          other={
            <LabelRadiusTags
              bold={false}
              tags={[{ value: "クロスコンパイル" }]}
            />
          }
        />
      }
    />
  );
};
