/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  FadeInUnderLineList,
  FadeInUnderLineListType,
} from "components/molecules/FadeInUnderLineList/FadeInUnderLineList";
import { SNSTextIconsType } from "components/molecules/SNSTextIcons/SNSTextIcons";
import React, { useEffect } from "react";
import {
  ANIMATAION_DURATION_MD,
  ANIMATAION_DURATION_NAVMENUITEM_FADEIN,
  ANIMATAION_DURATION_SM,
  ANIMATAION_DURATION_XS,
  convertDurationToSeconds,
  convertDurationToSecondsStr,
  mixin,
} from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { NavFooter } from "../NavFooter/NavFooter";
import { SlideOverlay } from "../SlideOverlay/SlideOverlay";

type Props = {
  show: boolean;
  slotProps: {
    logo: {
      onLogoClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
    overlay: {
      onOverlayClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
    navItems: {
      items: FadeInUnderLineListType[];
      onNavItemClick?: React.MouseEventHandler<HTMLButtonElement>;
      delayTime?: number;
      fadeInTime?: number;
    };
    footer: {
      iconProps: SNSTextIconsType;
      uiLibraryLink?: string;
    };
  };
};

export const NavMenuV3: React.FC<Props> = ({ show, slotProps }: Props) => {
  const { onLogoClick } = slotProps.logo;
  const { onOverlayClick } = slotProps.overlay;
  const { items, onNavItemClick } = slotProps.navItems;
  const { iconProps, uiLibraryLink = "" } = slotProps.footer;

  const [animateState, setAnimateState] = React.useState({
    listItems: false,
  });
  useEffect(() => {
    if (!show) {
      setAnimateState({
        listItems: false,
      });
      return;
    }

    setTimeout(() => {
      setAnimateState((prev) => ({
        ...prev,
        listItems: true,
      }));
    }, ANIMATAION_DURATION_SM);
  }, [show]);

  return (
    <SlideOverlay
      direction="top"
      opacityRange={{ from: 1, to: 1 }}
      cssOverride={css`
        z-index: ${defaultTheme.zIndex.navMenuOverlay};
      `}
      show={show}
      duration={convertDurationToSeconds(ANIMATAION_DURATION_MD)}
      onClick={onOverlayClick}
      color={defaultTheme.palette.common.overlay}
    >
      <BaseInner
        centered={false}
        width="100%"
        maxWidth="100%"
        cssOverride={css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow-y: ${show ? "auto" : "hidden"};
          overflow-x: ${show ? "hidden" : "auto"};

          padding-top: calc(
            ${defaultTheme.spacing.header.top} + 0.24rem
          ); // MEMO-9xkjadlkadfs: 縦方向の中央揃えのため調整
          color: ${defaultTheme.palette.common.white};

          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            margin: 0 auto;
            padding-left: 0;
          }
        `}
      >
        <div
          css={css`
            margin-top: ${defaultTheme.spacing.lg};
            padding: 0 ${defaultTheme.spacing.xs};
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              margin-top: ${defaultTheme.spacing.lg};
              padding: 0;
            }
          `}
        >
          {/* ナビリスト */}
          {animateState.listItems && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: ${defaultTheme.spacing.lg};
              `}
            >
              <FadeInUnderLineList
                items={items}
                onClick={onNavItemClick}
                delayTime={ANIMATAION_DURATION_XS}
                fadeInTime={convertDurationToSeconds(
                  ANIMATAION_DURATION_NAVMENUITEM_FADEIN
                )}
                slideInTime={convertDurationToSecondsStr(
                  ANIMATAION_DURATION_MD
                )}
                slotProps={{
                  container: {
                    style: css`
                      row-gap: ${defaultTheme.spacing.xs};
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        row-gap: ${defaultTheme.spacing.sm};
                      }
                    `,
                  },
                  item: {
                    text: {
                      style: css`
                        ${mixin.text.navMenuItem};
                      `,
                    },
                  },
                }}
              />
            </div>
          )}
          {/* フッター */}
          <NavFooter
            hiddenDivider
            hiddenComponentLibraryButton
            iconProps={iconProps}
            uiLibraryLink={uiLibraryLink}
            color={defaultTheme.palette.common.white}
            cssOverride={css`
              display: flex;
              flex-direction: column;
              padding-bottom: ${defaultTheme.spacing.footer.bottom};

              align-items: flex-start;
              @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                align-items: center;
              }
            `}
            animate={show}
          />
        </div>
      </BaseInner>
    </SlideOverlay>
  );
};
