/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { withLoader } from "components/atoms/withLoader/withLoader";
import {
  SoftwarePageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useTheme } from "hooks/useTheme";
import React, { useMemo } from "react";
import {
  BASE_COLOR_BATCHFILERENAMER,
  BUTTON_COLOR_BATCHFILERENAMER,
  BUTTON_COLOR_FITSCREENWINDOW,
  defaultTheme,
  TEXT_COLOR_BATCHFILERENAMER,
  TEXT_COLOR_FITSCREENWINDOW,
} from "styles/theme";
import { constants } from "utils/constants";
import { softWareIds, useSoftwarePage } from "../../../hooks/useSoftwarePage";
import {
  SoftwareTemplate,
  SoftwareTemplateSlotProps,
} from "../../templates/SoftwareTemplate/SoftwareTemplate";
import { MainLayout } from "../MainLayout/MainLayout";

const SoftwarePage: React.FC = () => {
  const { theme, customTheme } = useTheme();
  useSoftwarePage();
  const {
    imageSources: { fitScreenWindowImage, batchFileRenamerImage },
  } = useImageSources();

  const slotProps: SoftwareTemplateSlotProps = useMemo(
    () => ({
      productHero: {
        theme,
        subTitleColor: theme.palette.gray.text,
        arrowColor: theme.palette.contrastText,
        circleColor: theme.palette.gray.button[100],
        title: constants.pages.software.title,
        subTitle: "アプリのコンセプト",
        mainPhrase: {
          first: "「なんとなく」を",
          second: "シンプルにする",
        },
        description:
          "普段「なんとなく」している繰り返しの作業は、その回数が多くなれば、画面を見ている目や操作している手に気づかぬ疲れが溜まっていると感じます。プログラミングを通して、少しでもそれをシンプルにできたら、と思い開発しました。",
        scrollToID: constants.pages.software.scrollToID.fitscreenwindow,
      },
      productIntroduction1: {
        id: softWareIds.fitscreenwindow,
        direction: "left",
        title: "FitScreenWindow",
        description:
          "選択しているアクティブなウィンドウを、すばやく画面にフィットする",
        productColor: customTheme["fitScreenWindow"].color,
        textColor: TEXT_COLOR_FITSCREENWINDOW,
        buttonColor: BUTTON_COLOR_FITSCREENWINDOW,
        buttonLabel: "アプリのサイトへ",
        buttonUrl: constants.links.fitscreenwindow,
        firstTags: {
          label: "対応OS：",
          tags: [{ value: "Windows" }],
        },
        secondTags: {
          label: "開発言語：",
          tags: [{ value: "Python 3.9" }],
        },
        productImage: (
          <img
            css={css`
              width: 100%;
              max-width: 20rem;
              @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                max-width: 23rem;
                min-width: 20rem;
              }
            `}
            src={fitScreenWindowImage}
            alt="fitscreenwindowlogo"
          />
        ),
      },
      productIntroduction2: {
        id: softWareIds.batchfilerenamer,
        direction: "right",
        title: "BatchFileRenamer",
        description:
          "大量にあるファイルの名前を、まとめてデータからリネームする",
        productColor: BASE_COLOR_BATCHFILERENAMER,
        textColor: TEXT_COLOR_BATCHFILERENAMER,
        buttonColor: BUTTON_COLOR_BATCHFILERENAMER,
        buttonLabel: "アプリのサイトへ",
        buttonUrl: constants.links.batchfilerenamer,
        firstTags: {
          label: "対応OS：",
          tags: [{ value: "Windows" }, { value: "MacOS" }],
        },
        secondTags: {
          label: "開発言語：",
          tags: [{ value: "Go 1.19" }],
        },
        productImage: (
          <img
            css={css`
              width: 100%;
              max-width: 24rem;
              @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                max-width: 27rem;
                min-width: 24rem;
              }
            `}
            src={batchFileRenamerImage}
            alt="batchfilerenamerlogo"
          />
        ),
      },
    }),
    [customTheme["fitScreenWindow"].color]
  );

  return (
    <SoftwareTemplate id={constants.pages.software.id} slotProps={slotProps} />
  );
};

const imageSources: SoftwarePageImageSourcesState = {
  fitScreenWindowImage: require("assets/images/software/fitscreenwindow/logo.webp"),
  batchFileRenamerImage: require("assets/images/software/batchfilerenamer/logo.webp"),
};
const requireSources = Object.values(imageSources);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <SoftwarePage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as SoftwarePage };
