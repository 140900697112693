/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CloseButton } from "components/atoms/CloseButton/CloseButton";
import { Modal } from "components/organisms/Modal/Modal";
import { useElementScrollToTop } from "hooks/useElementScrollToTop";
import React, { useEffect } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { PositionedWrapper } from "utils/Wrapper/PositionedWrapper/PositionedWrapper";

export type ImageModalSlotProps = {
  image?: {
    bordered?: boolean;
  };
};

export type ImageModalProps = {
  open: boolean;
  src: string;
  alt: string;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  imageSize?: string | number;
  title?: string;
  description?: string;
  slotProps?: ImageModalSlotProps;
  buttons?: React.ReactNode;
  panelMaxWidth?: string;
  panelMaxHeight?: string;
  panelMinWidth?: string;
  isPanelScrollTop?: boolean;
  bottomComponent?: React.ReactNode;
};

export const ImageModal: React.FC<ImageModalProps> = ({
  open,
  src,
  alt,
  onClose,
  imageSize = "460px",
  title,
  description,
  slotProps,
  buttons,
  panelMaxWidth = "85vw",
  panelMaxHeight = "85vh",
  panelMinWidth,
  isPanelScrollTop = false,
  bottomComponent,
}) => {
  const { scrollToTopElementRef: panelRef, scrollToElementTop } =
    useElementScrollToTop<HTMLDivElement>();

  useEffect(() => {
    if (!isPanelScrollTop) {
      scrollToElementTop();
    }
  }, [isPanelScrollTop, scrollToElementTop]);

  return (
    <Modal onClose={onClose} open={open}>
      {/* モーダル内 - 閉じるボタン */}
      <PositionedWrapper top="20px" right="20px">
        <CloseButton
          size={10}
          onClick={onClose}
          backgroundColor={defaultTheme.palette.gray.button[200]}
        />
      </PositionedWrapper>
      {/* パネル */}
      <div
        css={css`
          border-radius: ${defaultTheme.borderRadius.base};
          background-color: ${defaultTheme.palette.common.white};
          padding: ${defaultTheme.spacing.md} ${defaultTheme.spacing.xs};
        `}
      >
        {/* パネルインナー(スクロールバー用) */}
        <div
          ref={panelRef}
          css={css`
            ${panelMinWidth && `min-width: ${panelMinWidth};`};
            max-width: ${panelMaxWidth};
            max-height: ${panelMaxHeight};
            overflow: auto;
            padding-left: ${defaultTheme.spacing.xs};
            padding-right: ${defaultTheme.spacing.xs};
          `}
        >
          {/* モーダル内 - 配置イメージ */}
          <div
            css={[
              slotProps?.image?.bordered &&
                css`
                  border: ${defaultTheme.borderWidth.sm}px solid;
                  border-color: ${defaultTheme.palette.gray.background};
                `,
              css`
                width: 100%;
                margin-bottom: ${defaultTheme.spacing.md};
              `,
            ]}
          >
            <img
              css={css`
                max-height: ${imageSize};
                max-width: ${imageSize};
              `}
              src={src}
              alt={alt}
            />
          </div>

          {description && (
            <p
              css={css`
                ${mixin.text.description};
                color: ${defaultTheme.palette.gray.text};
                margin-bottom: ${defaultTheme.spacing.md};
                text-align: center;
              `}
            >
              <span
                css={css`
                  ${mixin.text.description};
                  color: ${defaultTheme.palette.gray.text};
                  font-weight: normal;
                  letter-spacing: ${defaultTheme.letterSpacing.xs};
                `}
              >
                {`TYPE: `}
              </span>
              {description}
            </p>
          )}

          {title || description ? (
            <div
              css={css`
                margin-bottom: ${defaultTheme.spacing.lg};
              `}
            >
              {title && (
                <h3
                  css={[
                    css`
                      ${mixin.text.subtitle};
                      color: ${defaultTheme.palette.text};
                      text-align: center;
                      line-height: 1.75 !important;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        line-height: 1.75 !important;
                      }

                      margin-bottom: ${defaultTheme.spacing.xxxs};
                      font-weight: 700;
                    `,
                  ]}
                >
                  {title}
                </h3>
              )}
            </div>
          ) : null}
          <div
            css={[
              slotProps?.image?.bordered &&
                css`
                  border: ${defaultTheme.borderWidth.sm}px solid;
                  border-color: ${defaultTheme.palette.gray.background};
                `,
              css`
                margin-bottom: ${defaultTheme.spacing.md};
              `,
            ]}
          >
            {bottomComponent && bottomComponent}
          </div>
          {buttons && (
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              {buttons}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
