/** @jsxImportSource @emotion/react */
import { css } from "@storybook/theming";
import React from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { defaultTheme } from "styles/theme";

type CheckBoxProps = {
  id: string;
  register: UseFormRegisterReturn;
  errors?: FieldError;
  label: React.ReactNode;
};

export const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  register,
  errors,
  label,
}) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${defaultTheme.spacing.xs};
      `}
    >
      <input
        type="checkbox"
        {...register}
        id={id}
        css={css`
          //線の色を変える
          appearance: none;
          width: 20px;
          height: 20px;
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${!!errors
            ? defaultTheme.palette.error
            : defaultTheme.palette.gray.button[100]};
          border-radius: ${defaultTheme.borderRadius.base};
          outline: none;
          cursor: pointer;
          &:checked {
            background-color: ${defaultTheme.palette.primary};
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
          }
        `}
      />
      <div>{label}</div>
    </div>
  );
};
