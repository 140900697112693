/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as AwsAmplifyLogo } from "assets/images/product/aws-amplify.svg";
import { ReactComponent as AwsAppSyncLogo } from "assets/images/product/aws-appsync.svg";
import { ReactComponent as AwsDynamoDBLogo } from "assets/images/product/aws-dynamodb.svg";
import { ReactComponent as AwsEventBridgeLogo } from "assets/images/product/aws-eventbridge.svg";
import { ReactComponent as AwsLambda } from "assets/images/product/aws-lambda.svg";
import { ReactComponent as AwsLogo } from "assets/images/product/aws.svg";
import { ReactComponent as GraphQLLogo } from "assets/images/product/graphql.svg";
import { ReactComponent as MicroCmsLogo } from "assets/images/product/microcms.svg";
import { ReactComponent as NextJsLogo } from "assets/images/product/nextjs.svg";
import { ReactComponent as RecaptchaLogo } from "assets/images/product/recaptcha.svg";
import { ReactComponent as SendGridSmallLogo } from "assets/images/product/sendgrid-small.svg";
import { ReactComponent as TailwindLogo } from "assets/images/product/tailwind.svg";
import { ReactComponent as TerraformLogo } from "assets/images/product/terraform.svg";
import { ReactComponent as TypeScriptLogo } from "assets/images/product/typescript.svg";
import { ArrowTooltipButtonWithRef } from "components/atoms/ArrowTooltipButtonWithRef/ArrowTooltipButtonWithRef";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

import {
  TechnicalStackWrapper,
  TechnicalStackWrapperProps,
} from "utils/Wrapper/TechnicalStackWrapper/TechnicalStackWrapper";

type Props = TechnicalStackWrapperProps;

export const KawaichBlogTechnicalStack: React.FC<Props> = ({ ...rest }) => {
  return (
    <TechnicalStackWrapper {...rest}>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              開発フレームワーク
            </FillRadiusTag>
            <div>Next.js 13</div>
          </div>
        }
      >
        <NextJsLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              開発言語
            </FillRadiusTag>
            <div>TypeScript</div>
          </div>
        }
      >
        <TypeScriptLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              CSSフレームワーク
            </FillRadiusTag>
            <div>Tailwind</div>
          </div>
        }
      >
        <TailwindLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              バックエンド
            </FillRadiusTag>
            <div>GraphQL</div>
          </div>
        }
      >
        <GraphQLLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  インフラ
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  クラウド
                </FillRadiusTag>
              </div>
              <div>AWS</div>
            </div>
          </div>
        }
      >
        <AwsLogo
          width={30}
          height={30}
          css={css`
            position: relative;
            top: 2px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                デプロイ
              </FillRadiusTag>
              <div>AWS Amplify</div>
            </div>
          </div>
        }
      >
        <AwsAmplifyLogo
          width={26}
          height={26}
          css={css`
            position: relative;
            top: 1px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バックエンド
              </FillRadiusTag>
              <div>AWS AppSync</div>
            </div>
          </div>
        }
      >
        <AwsAppSyncLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                データベース
              </FillRadiusTag>
              <div>Amazon DynamoDB</div>
            </div>
          </div>
        }
      >
        <AwsDynamoDBLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バックエンド
              </FillRadiusTag>
              <div>AWS Lambda</div>
            </div>
          </div>
        }
      >
        <AwsLambda width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バッチ処理
              </FillRadiusTag>
              <div>Amazon EventBridge</div>
            </div>
          </div>
        }
      >
        <AwsEventBridgeLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                インフラ
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                IaC
              </FillRadiusTag>
            </div>
            <div>Terraform</div>
          </div>
        }
      >
        <TerraformLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                ヘッドレスCMS
              </FillRadiusTag>
            </div>
            <div>Micro CMS</div>
          </div>
        }
      >
        <MicroCmsLogo
          width={100}
          height={26}
          css={css`
            position: relative;
            top: 1px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                メール送信
              </FillRadiusTag>
            </div>
            <div>SendGrid</div>
          </div>
        }
      >
        <SendGridSmallLogo
          width={22}
          height={22}
          css={css`
            position: relative;
            top: 1px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                スパム対策
              </FillRadiusTag>
            </div>
            <div>reCAPTCHA v3</div>
          </div>
        }
      >
        <RecaptchaLogo
          width={26}
          height={26}
          css={css`
            position: relative;
            top: 4px;
            path {
              transform: scale(0.35);
            }
          `}
        />
      </ArrowTooltipButtonWithRef>
    </TechnicalStackWrapper>
  );
};
