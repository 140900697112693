/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as NsisLogo } from "assets/images/product/nsis.svg";
import { ReactComponent as PythonLogo } from "assets/images/product/python.svg";
import { ReactComponent as QtLogo } from "assets/images/product/qt.svg";
import { ReactComponent as ScssLogo } from "assets/images/product/sass.svg";
import { ReactComponent as VueLogo } from "assets/images/product/vue.svg";
import { ArrowTooltipButtonWithRef } from "components/atoms/ArrowTooltipButtonWithRef/ArrowTooltipButtonWithRef";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import {
  TechnicalStackWrapper,
  TechnicalStackWrapperProps,
} from "utils/Wrapper/TechnicalStackWrapper/TechnicalStackWrapper";

type Props = TechnicalStackWrapperProps;

export const FitScreenWindowTechnicalStack: React.FC<Props> = ({ ...rest }) => {
  return (
    <TechnicalStackWrapper {...rest}>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                アプリ
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                開発言語
              </FillRadiusTag>
            </div>
            <div>Python 3.9</div>
          </div>
        }
      >
        <PythonLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  アプリ
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  GUI
                </FillRadiusTag>
              </div>
            </div>
            <div>Qt for Python(PySide6)</div>
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                作成ツール
              </FillRadiusTag>
              <div>Qt Creator</div>
            </div>
          </div>
        }
      >
        <QtLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                アプリ
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                インストーラー作成
              </FillRadiusTag>
            </div>
            <div>NSIS</div>
          </div>
        }
      >
        <NsisLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                Webサイト
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                開発フレームワーク
              </FillRadiusTag>
            </div>
            <div>Vue.js 2</div>
          </div>
        }
      >
        <VueLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                Webサイト
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                スタイリング
              </FillRadiusTag>
            </div>
            <div>SCSS</div>
          </div>
        }
      >
        <ScssLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
    </TechnicalStackWrapper>
  );
};
