/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { ReactNode } from "react";
import { defaultTheme } from "styles/theme";
import { Overlay } from "../Overlay/Overlay";

type Props = {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  disableClose?: boolean;
  className?: string;
  cssOverride?: SerializedStyles;
  children?: ReactNode;
  open?: boolean;
  duration?: number; // sec
  backdropColor?: string; // モーダルの背景色
  zIndex?: number;
};

export const Modal: React.FC<Props> = ({
  onClose,
  disableClose = false,
  className,
  cssOverride,
  children,
  open = false,
  duration = 0.25,
  backdropColor = defaultTheme.palette.common.backdrop,
  zIndex = defaultTheme.zIndex.modal,
}: Props) => {
  return (
    /* backdrop */
    <Overlay
      show={open}
      color={backdropColor}
      duration={duration}
      cssOverride={css`
        ${cssOverride};
        z-index: ${zIndex};
      `}
      className={"" + (className ? ` ${className}` : "")}
      onClick={(e) => {
        if (!disableClose) {
          onClose(e);
        }
      }}
    >
      {/* content */}
      <button
        css={css`
          cursor: default;
          width: fit-content;

          /* 画面基準で上下左右中央に表示 */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
        // stopPropagationの設定(コンテンツの要素でClickイベントが伝搬しないようにする)
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </button>
    </Overlay>
  );
};
