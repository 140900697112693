/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type ListProps = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
};

export const List: React.FC<ListProps> = ({ children, cssOverride }) => {
  return (
    <ul
      css={[
        cssOverride,
        css`
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: ${defaultTheme.letterSpacing.xs};

          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            display: block;
            font-size: 20px;
          }
        `,
      ]}
    >
      {children}
    </ul>
  );
};
