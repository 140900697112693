/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import React, { memo } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_CLIENT_KEY } from "utils/constants";

type ReCaptchaProviderProps = {
  children: React.ReactNode;
};

export const ReCaptchaProvider: React.FC<ReCaptchaProviderProps> = ({
  children,
}) => {
  return (
    <GoogleReCaptchaProvider
      useEnterprise={true}
      reCaptchaKey={RECAPTCHA_CLIENT_KEY!}
      language="ja"
      container={{
        element: "recaptcha-widget",
        parameters: {
          badge: "inline",
          theme: "light",
        },
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

type RecaptchaWidgetProps = {
  cssOverride?: SerializedStyles;
};

export const RecaptchaWidget: React.FC<RecaptchaWidgetProps> = memo(
  ({ cssOverride }) => {
    return <div id="recaptcha-widget" css={[cssOverride]} />;
  }
);
