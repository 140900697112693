import { ImageClickEventHandler } from "components/templates/ProductIntroductionTemplate/ProductIntroductionTemplate";
import React, { useCallback, useRef } from "react";
import { ANIMATAION_DURATION_MD } from "styles/mixin";
import { sleep } from "utils/sleep";

type ImageCardState = {
  open: boolean;
  src: string;
  alt: string;
};

export const useProduct = () => {
  const [{ open, src, alt }, setImageCardState] =
    React.useState<ImageCardState>({
      open: false,
      src: "",
      alt: "",
    });

  const cancelCloseRef = useRef(false);

  const handleImageCardClick: ImageClickEventHandler = useCallback(
    (src, alt) => {
      cancelCloseRef.current = true;
      setImageCardState({ open: true, src, alt });
    },
    []
  );

  const handleImageModalClose = useCallback(async () => {
    cancelCloseRef.current = false;
    setImageCardState((prev) => ({ ...prev, open: false }));

    await sleep(ANIMATAION_DURATION_MD);
    if (!cancelCloseRef.current) {
      setImageCardState((prev) => ({
        ...prev,
        src: "",
        alt: "",
        title: "",
        description: "",
      }));
    }
  }, []);

  return {
    open,
    src,
    alt,
    handleImageCardClick,
    handleImageModalClose,
  };
};
