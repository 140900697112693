/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as AwsCognito } from "assets/images/product/aws-cognito.svg";
import { ReactComponent as AwsLogo } from "assets/images/product/aws.svg";
import DockerLogo from "assets/images/product/docker.webp";
import { ReactComponent as EchoLogo } from "assets/images/product/echo.svg";
import { ReactComponent as FlyIo } from "assets/images/product/flyio.svg";
import { ReactComponent as MicroCmsSmallLogo } from "assets/images/product/microcms-small.svg";
import { ReactComponent as MuiLogo } from "assets/images/product/mui.svg";
import { ReactComponent as NextJsLogo } from "assets/images/product/nextjs.svg";
import { ReactComponent as OpenAi } from "assets/images/product/openai.svg";
import { ReactComponent as ReduxLogo } from "assets/images/product/redux.svg";
import { ReactComponent as SendGridSmallLogo } from "assets/images/product/sendgrid-small.svg";
import { ReactComponent as StorybookSmallLogo } from "assets/images/product/storybook-small.svg";
import { ReactComponent as SwaggerLogo } from "assets/images/product/swagger.svg";
import { ReactComponent as TerraformLogo } from "assets/images/product/terraform.svg";
import { ReactComponent as TypeScriptLogo } from "assets/images/product/typescript.svg";
import { ReactComponent as VercelLogo } from "assets/images/product/vercel.svg";
import { ArrowTooltipButtonWithRef } from "components/atoms/ArrowTooltipButtonWithRef/ArrowTooltipButtonWithRef";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import {
  TechnicalStackWrapper,
  TechnicalStackWrapperProps,
} from "utils/Wrapper/TechnicalStackWrapper/TechnicalStackWrapper";

type Props = TechnicalStackWrapperProps;

export const TalkStockPersonalDevelopmentTechnicalStack: React.FC<Props> = ({
  ...rest
}) => {
  return (
    <TechnicalStackWrapper {...rest}>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                フロントエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                開発フレームワーク
              </FillRadiusTag>
            </div>
            <div>Next.js 13</div>
          </div>
        }
      >
        <NextJsLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                フロントエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                開発言語
              </FillRadiusTag>
            </div>
            <div>TypeScript</div>
          </div>
        }
      >
        <TypeScriptLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                フロントエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                スタイルライブラリ
              </FillRadiusTag>
            </div>
            <div>MUI</div>
          </div>
        }
      >
        <MuiLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                フロントエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                状態管理
              </FillRadiusTag>
            </div>
            <div>Redux</div>
          </div>
        }
      >
        <ReduxLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                フロントエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                コンポーネントライブラリ
              </FillRadiusTag>
            </div>
            <div>Storybook</div>
          </div>
        }
      >
        <StorybookSmallLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バックエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                開発フレームワーク
              </FillRadiusTag>
            </div>
            <div>Echo(Golang)</div>
          </div>
        }
      >
        <EchoLogo width={70} height={30} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バックエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                API仕様書
              </FillRadiusTag>
            </div>
            <div>Swagger</div>
          </div>
        }
      >
        <SwaggerLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  インフラ
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  クラウド
                </FillRadiusTag>
              </div>
              <div>AWS</div>
            </div>
          </div>
        }
      >
        <AwsLogo
          width={30}
          height={30}
          css={css`
            position: relative;
            top: 2px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バックエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                IDaaS
              </FillRadiusTag>
            </div>
            <div>Amazon Cognito</div>
          </div>
        }
      >
        <AwsCognito width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  フロントエンド
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  ホスティング
                </FillRadiusTag>
              </div>
              <div>Vercel</div>
            </div>
          </div>
        }
      >
        <VercelLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バックエンド
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                デプロイ
              </FillRadiusTag>
            </div>
            <div>Fly.io</div>
          </div>
        }
      >
        <FlyIo
          width={26}
          height={26}
          css={css`
            position: relative;
            top: 1px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                インフラ
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                IaC
              </FillRadiusTag>
            </div>
            <div>Terraform</div>
          </div>
        }
      >
        <TerraformLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                環境構築
              </FillRadiusTag>
            </div>
            <div>Docker</div>
          </div>
        }
      >
        <img src={DockerLogo} alt="docker" height={22} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                生成AI
              </FillRadiusTag>
            </div>
            <div>OpenAI API</div>
          </div>
        }
      >
        <OpenAi
          width={80}
          height={26}
          css={css`
            position: relative;
            top: 1px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                メール送信
              </FillRadiusTag>
            </div>
            <div>SendGrid</div>
          </div>
        }
      >
        <SendGridSmallLogo
          width={22}
          height={22}
          css={css`
            position: relative;
            top: 1px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                お知らせ管理
              </FillRadiusTag>
            </div>
            <div>Micro CMS</div>
          </div>
        }
      >
        <MicroCmsSmallLogo
          width={22}
          height={22}
          css={css`
            position: relative;
            top: 1px;
          `}
        />
      </ArrowTooltipButtonWithRef>
    </TechnicalStackWrapper>
  );
};
