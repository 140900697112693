/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import React, { ReactNode } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children: ReactNode;
};

export const GradationDividerSmallTitle: React.FC<Props> = ({
  className,
  cssOverride,
  children,
}) => {
  return (
    <div
      className={"" + (className ? ` ${className}` : "")}
      css={[
        css`
          width: fit-content;
        `,
        cssOverride,
      ]}
    >
      <div
        css={css`
          ${mixin.text.description};
          font-weight: 700;
        `}
      >
        {children}
      </div>
      <GradationDivider size={defaultTheme.borderWidth.sm} />
    </div>
  );
};
