/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EngTitleSubText } from "components/atoms/EngTitleSubText/EngTitleSubText";
import { FadeInSequentialText } from "components/atoms/FadeInSequentialText/FadeInSequentialText";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { SubTitleCard } from "components/organisms/SubTitleCard/SubTitleCard";
import { Theme, defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";

export type PrivacyPolicyTemplateSlotProps = {
  title: string;
  subText: string;
  bgColor: string;
  backButton: {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    color: string;
  };
  cards: {
    subTitleColor: string;
    personalInformation: {
      subTitle: React.ReactNode;
      text: React.ReactNode;
    };
    contactForm: {
      subTitle: React.ReactNode;
      text: React.ReactNode;
    };
    googleAnalytics: {
      subTitle: React.ReactNode;
      text: React.ReactNode;
    };
    googleReCaptcha: {
      subTitle: React.ReactNode;
      text: React.ReactNode;
    };
  };
};

type Props = {
  id: string;
  theme: Theme;
  slotProps: PrivacyPolicyTemplateSlotProps;
};

export const PrivacyPolicyTemplate: React.FC<Props> = ({
  id,
  theme,
  slotProps,
}) => {
  const { title, subText, bgColor, backButton, cards } = slotProps;

  return (
    <BaseWrapper id={id}>
      <section>
        <FullWidthOuter
          pt={defaultTheme.spacing.base.top}
          bgColor={bgColor}
          cssOverride={css``}
        >
          <BaseInner width="100%" pt="2.5rem" pb="2.5rem">
            <section>
              <FadeInOnScrollByComponent
                delay={defaultTheme.delay.none}
                direction="spot"
              >
                <div
                  css={css`
                    margin-bottom: ${defaultTheme.spacing.xxs};
                  `}
                >
                  <LongArrowButton
                    onClick={backButton.onClick}
                    direction="left"
                    textColor={backButton.color}
                    arrowColor={backButton.color}
                  >
                    <span>{backButton.text}</span>
                  </LongArrowButton>
                </div>
              </FadeInOnScrollByComponent>
              <FadeInOnScrollByComponent
                delay={defaultTheme.delay.short}
                direction="spot"
              >
                <div
                  css={css`
                    margin-bottom: ${defaultTheme.spacing.title.bottom};
                  `}
                >
                  <h1>
                    <EngTitleSubText
                      theme={theme}
                      title={
                        <FadeInSequentialText
                          initialDelay={defaultTheme.delay.none}
                        >
                          {title}
                        </FadeInSequentialText>
                      }
                      subText={
                        <FadeInOnScrollByComponent
                          delay={defaultTheme.delay.medium}
                          direction="spot"
                        >
                          {subText}
                        </FadeInOnScrollByComponent>
                      }
                      subTextColor={cards.subTitleColor}
                    />
                  </h1>
                </div>
              </FadeInOnScrollByComponent>

              <FadeInOnScrollByComponent
                delay={defaultTheme.delay.medium}
                direction="bottom"
              >
                <SubTitleCard
                  subTitle={cards.personalInformation.subTitle}
                  subTitleColor={cards.subTitleColor}
                  cssOverride={css`
                    text-align: left;
                    margin-bottom: ${defaultTheme.spacing.lg};
                    max-width: ${defaultTheme.maxWidth.md};
                  `}
                >
                  {cards.personalInformation.text}
                </SubTitleCard>
                <SubTitleCard
                  subTitle={cards.contactForm.subTitle}
                  subTitleColor={cards.subTitleColor}
                  cssOverride={css`
                    text-align: left;
                    margin-bottom: ${defaultTheme.spacing.lg};
                    max-width: ${defaultTheme.maxWidth.md};
                  `}
                >
                  {cards.contactForm.text}
                </SubTitleCard>
                <SubTitleCard
                  subTitle={cards.googleAnalytics.subTitle}
                  subTitleColor={cards.subTitleColor}
                  cssOverride={css`
                    text-align: left;
                    margin-bottom: ${defaultTheme.spacing.lg};
                    max-width: ${defaultTheme.maxWidth.md};
                  `}
                >
                  {cards.googleAnalytics.text}
                </SubTitleCard>
                <SubTitleCard
                  subTitle={cards.googleReCaptcha.subTitle}
                  subTitleColor={cards.subTitleColor}
                  cssOverride={css`
                    text-align: left;
                    max-width: ${defaultTheme.maxWidth.md};
                  `}
                >
                  {cards.googleReCaptcha.text}
                </SubTitleCard>
              </FadeInOnScrollByComponent>
            </section>
          </BaseInner>
        </FullWidthOuter>
      </section>
    </BaseWrapper>
  );
};
