/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { ArrowIcon } from "../ArrowIcon/ArrowIcon";
import { BorderRadiusButton } from "../BorderRadiusButton/BorderRadiusButton";
import { FillRadiusButton } from "../FillRadiusButton/FillRadiusButton";

const MARGIN = "0.5rem";
const WEIGHT = 2.5;
const SIZE = 3.5;

const Contents: React.FC<{
  children: React.ReactNode;
  direction: "left" | "right";
  color?: string;
}> = ({ children, direction, color }) => {
  return (
    <>
      {direction === "left" && (
        <>
          <ArrowIcon
            color={color}
            direction="left"
            weight={WEIGHT}
            size={SIZE}
          />
          <span
            css={css`
              margin-left: ${MARGIN};
            `}
          >
            {children}
          </span>
        </>
      )}
      {direction === "right" && (
        <>
          <span
            css={css`
              margin-right: ${MARGIN};
            `}
          >
            {children}
          </span>
          <ArrowIcon
            color={color}
            direction="right"
            weight={WEIGHT}
            size={SIZE}
          />
        </>
      )}
    </>
  );
};

const styles = css`
  font-weight: 700;
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  buttonColor?: string;
  hoverColor?: string;
  arrowColor?: string;
  direction?: "left" | "right";
  outlined?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const RadiusArrowButton: React.FC<Props> = ({
  children,
  className,
  buttonColor = "#444444",
  hoverColor = "#777777",
  arrowColor = "currentColor",
  direction = "right",
  outlined = false,
  ...rest
}) => {
  return outlined ? (
    <BorderRadiusButton
      {...rest}
      className={`${className ? className : ""}`}
      cssOverride={styles}
      color={buttonColor}
      line={2}
      height={22}
      width={25}
      hoverColor={hoverColor}
    >
      <Contents direction={direction} color={arrowColor}>
        {children}
      </Contents>
    </BorderRadiusButton>
  ) : (
    <FillRadiusButton
      {...rest}
      className={`${className ? className : ""}`}
      cssOverride={styles}
      backgroundColor={buttonColor}
      height={22}
      width={25}
    >
      <Contents direction={direction} color={arrowColor}>
        {children}
      </Contents>
    </FillRadiusButton>
  );
};
