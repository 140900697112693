/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { ZoomInImage } from "components/atoms/ZoomInImage/ZoomInImage";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

export type DesignCardSlotProps = {
  title?: {
    color?: string;
    style?: SerializedStyles;
  };
  description?: {
    color?: string;
  };
};

export type DesignCardClickEventHandler = (
  requireSrc: string,
  alt: string,
  title: string,
  description: string
) => void;

export type DesignCardProps = {
  title: string;
  description: string;
  image: {
    src: string;
    alt: string;
  };
  cssOverride?: SerializedStyles;
  tag?: string;
  slotProps?: DesignCardSlotProps;
  onClick: DesignCardClickEventHandler;
};

export const DesignCard: React.FC<DesignCardProps> = ({
  title,
  description,
  image,
  cssOverride,
  slotProps,
  onClick,
}) => {
  return (
    <section>
      <div
        css={[
          cssOverride,
          css`
            margin-bottom: ${defaultTheme.spacing.xs};
          `,
        ]}
      >
        <button
          type="button"
          onClick={() => onClick(image.src, image.alt, title, description)}
        >
          <div
            css={css`
              overflow: hidden; // MEMO: transform: scale()ではみだした部分を非表示にする
            `}
          >
            <ZoomInImage
              src={image.src}
              alt={image.alt}
              css={css`
                ${mixin.hoverTransitionOpacityEffect};
                max-width: 100%;
              `}
            />
          </div>
        </button>
      </div>

      <div
        css={css`
          text-align: left;
        `}
      >
        <h3
          css={[
            slotProps?.title?.style,
            css`
              ${mixin.text.body};
              line-height: 1.75 !important;
              @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                line-height: 1.75 !important;
              }

              margin-bottom: ${defaultTheme.spacing.xxxs};
              font-weight: 700;
              color: ${slotProps?.title?.color};
            `,
          ]}
        >
          {title}
        </h3>
        <p
          css={css`
            ${mixin.text.description};
            color: ${slotProps?.description?.color};
            /*margin-top: ${defaultTheme.spacing.xxxs};*/
          `}
        >
          {description}
        </p>
      </div>
    </section>
  );
};
