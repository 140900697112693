/** @jsxImportSource @emotion/react */
import { css, keyframes, SerializedStyles } from "@emotion/react";
import { useMemo } from "react";
import { defaultTheme } from "styles/theme";

type LineWithTextProps = {
  borderColor?: string;
  cssOverride?: SerializedStyles;
  children: React.ReactNode;
  size?: number;
  fromColor?: string;
  toColor?: string;
  duration?: number;
  disableAnimation?: boolean;
};

export const LineWithText: React.FC<LineWithTextProps> = ({
  children,
  cssOverride,
  borderColor = "#999999",
  size = 3,
  fromColor = "#888",
  toColor = "#888",
  duration = 1.5, // sec
  disableAnimation = false,
}) => {
  const styles = useMemo(() => {
    const slideIn = keyframes`
    0% {
      transform-origin: left top;
      transform: scale(0, 1);
    }
    100% {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  `;

    return {
      slideAnimation: css`
        /*** transition|animation ***/
        animation-name: ${slideIn};
        animation-duration: ${duration}s; /* アニメーションの長さ */
        animation-fill-mode: forwards; /* アニメーション終了時のスタイルをアニメーション終了後でも適用させる */
      `,
    };
  }, [size, fromColor, toColor, duration]);

  return (
    <div
      css={[
        cssOverride,
        css`
          display: flex;
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;
        `,
      ]}
    >
      <div
        css={css`
          margin-right: 1rem;
        `}
      >
        {children}
      </div>
      <span
        css={css`
          ${disableAnimation ? "animation: none;" : styles.slideAnimation}
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex: 1;
          &:after {
            content: "";
            flex: 1;
            border-bottom: ${defaultTheme.borderWidth.sm}px solid ${borderColor};
            border-image: linear-gradient(
              to right,
              ${fromColor} 0%,
              ${toColor} 100%
            );
            border-image-slice: 1;
          }
        `}
      />
    </div>
  );
};
