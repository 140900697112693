/** @jsxImportSource @emotion/react */
import { css } from "@emotion/css";
import { useCallback, useEffect, useMemo } from "react";
import {
  BASE_COLOR_BATCHFILERENAMER,
  BASE_COLOR_FITSCREENWINDOW,
} from "styles/theme";
import { position } from "utils/position";
import { useTheme } from "./useTheme";

export const softWareIds = {
  fitscreenwindow: "fitscreenwindow",
  batchfilerenamer: "batchfilerenamer",
};

export const useSoftwarePage = () => {
  const {
    updateFitScreenWindowTheme,
    mode,
    switchToLightHeaderTheme,
    switchToDarkHeaderTheme,
  } = useTheme();

  const bodyStyles = (backgroundColor: string) => {
    return css`
      background-color: ${backgroundColor};
    `;
  };

  // 各セクションのスタイル
  const fitScreenWindowStyles = useMemo(
    () => bodyStyles(BASE_COLOR_FITSCREENWINDOW),
    []
  );
  const batchFileRenamerStyles = useMemo(
    () => bodyStyles(BASE_COLOR_BATCHFILERENAMER),
    []
  );

  const updateBodyStyles = useCallback(
    (scrollY: number): void => {
      // 要素の座標を取得
      const fitScreenWindowY = position.getPositionYByElement(
        `#${softWareIds.fitscreenwindow}`
      );
      const batchFileRenamerY = position.getPositionYByElement(
        `#${softWareIds.batchfilerenamer}`
      );

      if (!fitScreenWindowY || !batchFileRenamerY) return;

      // 調整値
      const fitScreenWindowAdjust = -250;
      const batchFileRenamerStartAdjust = 450; // 開始位置
      const batchFileRenamerEndAdjust = 2400; // 終了位置

      const fitScreenWindowPosition = fitScreenWindowY + fitScreenWindowAdjust;
      const batchFileRenamerStartPosition =
        batchFileRenamerY + batchFileRenamerStartAdjust;
      const batchFileRenamerEndPosition =
        batchFileRenamerY + batchFileRenamerEndAdjust;

      // すべてのスタイルを一旦削除
      document.body.classList.remove(
        fitScreenWindowStyles,
        batchFileRenamerStyles
      );

      // スクロール位置に応じてスタイルを適用
      if (scrollY <= fitScreenWindowPosition) {
        updateFitScreenWindowTheme(BASE_COLOR_FITSCREENWINDOW);
        if (mode === "light") {
          switchToLightHeaderTheme();
        }
      } else if (scrollY <= batchFileRenamerStartPosition) {
        document.body.classList.add(fitScreenWindowStyles);
        updateFitScreenWindowTheme(BASE_COLOR_FITSCREENWINDOW);
        if (mode === "light") {
          switchToDarkHeaderTheme();
        }
      } else if (scrollY <= batchFileRenamerEndPosition) {
        document.body.classList.add(batchFileRenamerStyles);
        updateFitScreenWindowTheme(BASE_COLOR_BATCHFILERENAMER);
        if (mode === "light") {
          switchToDarkHeaderTheme();
        }
      } else {
        updateFitScreenWindowTheme(BASE_COLOR_FITSCREENWINDOW);
        if (mode === "light") {
          switchToLightHeaderTheme();
        }
      }
    },
    [
      mode,
      updateFitScreenWindowTheme,
      switchToLightHeaderTheme,
      switchToDarkHeaderTheme,
    ]
  );

  const handleScroll = useCallback(() => {
    updateBodyStyles(window.scrollY);
  }, [updateBodyStyles]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      // クリーンアップ時にスタイルをリセット
      document.body.classList.remove(
        fitScreenWindowStyles,
        batchFileRenamerStyles
      );
      updateFitScreenWindowTheme(BASE_COLOR_FITSCREENWINDOW);
    };
  }, []);
};
