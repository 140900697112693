/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { TalkStockTextLogo } from "components/atoms/TalkStockTextLogo/TalkStockTextLogo";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import { TalkStockAwsPortfolioTechnicalStack } from "components/molecules/TalkStockAwsPortfolioTechnicalStack/TalkStockAwsPortfolioTechnicalStack";
import React from "react";
import { defaultTheme, Theme } from "styles/theme";
import { CLIENT_BASEURL } from "utils/config";
import { constants } from "utils/constants";
import { ProductCard, ProductCardSlotProps } from "../ProductCard/ProductCard";
import { ProductCardBottomComponent } from "../ProductCardBottomComponent/ProductCardBottomComponent";

type Props = {
  src: string;
  slotProps?: ProductCardSlotProps & {
    button?: {
      color?: string;
    };
  };
  theme: Theme;
};

export const TalkStockProductCard: React.FC<Props> = ({
  src,
  slotProps,
  theme,
}) => {
  return (
    <ProductCard
      title={
        <TalkStockTextLogo
          darkColor={slotProps?.title?.color}
          lightColor={slotProps?.title?.color}
          cssOverride={slotProps?.title?.style}
        />
      }
      description="日常のあらゆるお困りごとを、AIに向けて質問し回答を得て、それらをほかの利用者が評価しあって、お困りごとを解決していく「トーク」の共有サービスです"
      image={{
        src: src,
        alt: "talkstock",
      }}
      href={`${CLIENT_BASEURL}${constants.pages.product.url.talkstock}`}
      slotProps={slotProps}
      bottomComponent={
        <ProductCardBottomComponent
          theme={theme}
          topComponent={
            <a
              href={`${CLIENT_BASEURL}${constants.pages.product.url.talkstock}`}
              target="_blank"
              rel="noreferrer"
            >
              <LongArrowButton
                textColor={slotProps?.button?.color}
                arrowColor={slotProps?.button?.color}
                cssOverride={css`
                  color: ${slotProps?.button?.color};
                `}
              >
                詳細を見る
              </LongArrowButton>
            </a>
          }
          technicalStack={
            <TalkStockAwsPortfolioTechnicalStack
              bgColor={defaultTheme.palette.background}
            />
          }
          other={
            <LabelRadiusTags
              bold={false}
              tags={[
                { value: "bulletproof-react" },
                //{ value: "ドメイン駆動開発(オニオン)" },
              ]}
            />
          }
        />
      }
    />
  );
};
