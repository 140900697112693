/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { defaultTheme } from "./theme";

const generateResponsiveMixin = ({
  pc,
  mobile,
  breakpoint = defaultTheme.breakpoints.md,
}: {
  pc: SerializedStyles;
  mobile: SerializedStyles;
  breakpoint?: string;
}) => {
  return css`
    ${mobile};
    @media screen and (min-width: ${breakpoint}) {
      ${pc};
    }
  `;
};

export const textMixin = {
  xxs: css`
    font-size: 0.625rem; /* 10px */
    line-height: 1rem; /* 16px */
  `,
  xs: css`
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
  `,
  sm: css`
    font-size: 0.875rem; /* 14px */
    line-height: 2rem; /* 32px */
  `,
  md: css`
    font-size: 1rem; /* 16px */
    line-height: 2rem; /* 32px */
  `,
  lg: css`
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
  `,
  xl: css`
    font-size: 1.65rem; /* 26px */
    line-height: 1.75rem; /* 28px */
  `,
  xxl: css`
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
  `,
  xxxl: css`
    font-size: 2rem /* 32px */;
    line-height: 2.5rem /* 40px */;
  `,
  xxxxl: css`
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
  `,
  xxxxxl: css`
    font-size: 3rem /* 48px */;
    line-height: 1;
  `,
};

export const ANIMATAION_DURATION_XS = 50;
export const ANIMATAION_DURATION_SM = 300;
export const ANIMATAION_DURATION_MD = 500;
export const ANIMATAION_DURATION_LG = 700;
export const ANIMATAION_DURATION_PAGE_TRANSITION = 900;
export const ANIMATAION_DURATION_NAVMENUITEM_FADEIN = 1800;
export const ANIMATAION_DURATION_SPLASH_SCREEN = 2500;
const ANIMATION_DURATION_CHANGE_THEME = 300;

export const convertDurationToSecondsStr = (duration: number) => {
  return `${duration / 1000}s`;
};
export const convertDurationToSeconds = (duration: number) => {
  return duration / 1000;
};

export const mixin = {
  // CSSアニメーション / エフェクト
  changeTheme: css`
    transition: background-color
        ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out,
      color ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)}
        ease-out,
      border-color
        ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out;
  `,
  changeThemeBgColor: css`
    transition: background-color
      ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out;
  `,
  changeThemeColor: css`
    transition: color
      ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out;
  `,
  changeThemeFill: css`
    transition: fill
      ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out;
  `,
  changeThemeStroke: css`
    transition: stroke
      ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out;
  `,
  changeThemeFontSize: css`
    transition: font-size
      ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out;
  `,
  changeThemeOpacity: css`
    transition: opacity
      ${convertDurationToSecondsStr(ANIMATION_DURATION_CHANGE_THEME)} ease-out;
  `,
  hoverTransitionOpacityEffect: css`
    transition-duration: 300ms;
    &:hover {
      opacity: 0.75;
      transition-duration: 300ms;
    }
  `,
  hoverOpacityEffect: css`
    &:hover {
      opacity: 0.75;
    }
  `,
  disableLineHeight: css`
    line-height: 0 !important;
    @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
      line-height: 0 !important;
    }
  `,
  boxShadow: css`
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  `,
  // フォントサイズと行間
  text: {
    jpTitle: generateResponsiveMixin({
      mobile: css`
        ${textMixin.xl};
        line-height: 2.5rem;
      `,
      pc: css`
        ${textMixin.xxxl};
        line-height: 3rem;
      `,
    }),
    smallJpTitle: generateResponsiveMixin({
      mobile: css`
        font-size: 1.45rem;
        line-height: 1.25rem;
      `,
      pc: css`
        ${textMixin.xl};
        line-height: 3rem;
      `,
    }),
    engTitle: generateResponsiveMixin({
      mobile: css`
        ${textMixin.xl};
        letter-spacing: ${defaultTheme.letterSpacing.md};
        line-height: 2.5rem;
      `,
      pc: css`
        ${textMixin.xxxl};
        letter-spacing: ${defaultTheme.letterSpacing.lg};
        line-height: 3rem;
      `,
    }),
    navMenuItem: generateResponsiveMixin({
      mobile: css`
        ${textMixin.xl};
        letter-spacing: ${defaultTheme.letterSpacing.md};
      `,
      pc: css`
        ${textMixin.xxl};
        letter-spacing: ${defaultTheme.letterSpacing.lg};
      `,
    }),
    siteMapItem: generateResponsiveMixin({
      mobile: css`
        ${textMixin.sm};
        letter-spacing: ${defaultTheme.letterSpacing.sm};
        white-space: nowrap;
      `,
      pc: css`
        ${textMixin.sm};
        letter-spacing: ${defaultTheme.letterSpacing.sm};
        white-space: nowrap;
      `,
    }),
    subtitle: generateResponsiveMixin({
      mobile: textMixin.md,
      pc: textMixin.lg,
    }),
    largeBody: generateResponsiveMixin({
      mobile: textMixin.md,
      pc: textMixin.md,
    }),
    body: generateResponsiveMixin({
      mobile: textMixin.sm,
      pc: textMixin.sm,
    }),
    description: generateResponsiveMixin({
      mobile: textMixin.xs,
      pc: textMixin.xs,
    }),
    note: generateResponsiveMixin({
      mobile: textMixin.xxs,
      pc: textMixin.xxs,
    }),
    fieldSetTitle: generateResponsiveMixin({
      mobile: textMixin.sm,
      pc: textMixin.lg,
    }),
    smallFieldSetTitle: generateResponsiveMixin({
      mobile: textMixin.sm,
      pc: textMixin.md,
    }),
    lineWithTextTitle: generateResponsiveMixin({
      mobile: textMixin.sm,
      pc: textMixin.lg,
    }),
  },
};
