/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  backgroundColor?: string;
  lineColor?: string;
  size?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/*** CSS Style ***/
const styles = (props: Props): SerializedStyles => {
  return css`
    display: block;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.5;
    background: ${props.backgroundColor};
    // 丸背景の大きさ
    width: calc(30px * (${props.size} / 10));
    height: calc(30px * (${props.size} / 10));

    ${mixin.hoverOpacityEffect};

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.5px; // 線の太さ
      height: calc(15px * ${props.size} / 10);
      background: ${props.lineColor};
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `;
};

export const CloseButton: React.FC<Props> = ({
  className,
  cssOverride,
  backgroundColor = "#666666",
  lineColor = "#ffffff",
  size = 10,
  ...rest
}: Props) => {
  return (
    <button
      {...rest}
      css={[styles({ backgroundColor, lineColor, size }), cssOverride]}
      className={"" + (className ? ` ${className}` : "")}
    />
  );
};
