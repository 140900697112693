/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import {
  DesignCard,
  DesignCardClickEventHandler,
  DesignCardSlotProps,
} from "components/organisms/DesignCard/DesignCard";
import React from "react";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { Columns2to3Wrapper } from "utils/Wrapper/Columns2to3Wrapper/Columns2to3Wrapper";

export type ImageGalleryItem = {
  title: string;
  description: string;
  src: string;
  alt: string;
};

export type ImageGallerySlotProps = {
  designCard: DesignCardSlotProps;
};

type ImageGalleryProps = {
  cssOverride?: SerializedStyles;
  items: ImageGalleryItem[];
  onClick: DesignCardClickEventHandler;
  slotProps?: ImageGallerySlotProps;
};

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  cssOverride,
  items,
  onClick,
  slotProps,
}: ImageGalleryProps) => {
  return (
    <Columns2to3Wrapper
      cssOverride={css`
        ${cssOverride};
      `}
    >
      {items.map((item, index) => (
        <div
          key={index}
          css={css`
            width: 100%;
          `}
        >
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.short}
            direction="spot"
          >
            <DesignCard
              onClick={onClick}
              title={item.title}
              description={item.description}
              image={{
                src: item.src,
                alt: item.alt,
              }}
              slotProps={slotProps?.designCard}
            />
          </FadeInOnScrollByComponent>
        </div>
      ))}
    </Columns2to3Wrapper>
  );
};
