/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  line?: number;
  radius?: number;
  color?: string;
  hoverColor?: string;
  type?: "button" | "submit" | "reset";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const styles = (props: Props) => {
  return css`
    ${mixin.disableLineHeight}
    border-radius: calc(${props.radius} * 10px);
    border: ${props.line}px solid;
    border-color: ${props.color};
    display: block;
    width: fit-content;
    padding: calc(${props.height}px) calc(${props.width}px);
    box-sizing: border-box;
    color: ${props.color};
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      color: ${props.hoverColor};
      border-color: ${props.hoverColor};
    }
  `;
};

export const BorderRadiusButton: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  width = 30,
  height = 18,
  line = 2,
  radius = 10,
  color = "#444444",
  hoverColor = "#777777",
  type = "button",
  ...rest
}) => {
  return (
    <button
      {...rest}
      type={type}
      css={[
        styles({
          width,
          height,
          radius,
          color,
          hoverColor,
          line,
        }),
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      {children}
    </button>
  );
};
