/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ProductHero } from "components/organisms/ProductHero/ProductHero";
import { SoftwareIntroduction } from "components/organisms/SoftwareIntroduction/SoftwareIntroduction";
import React from "react";
import { defaultTheme } from "styles/theme";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";

export type SoftwareTemplateSlotProps = {
  productHero: React.ComponentProps<typeof ProductHero>;
  productIntroduction1: React.ComponentProps<typeof SoftwareIntroduction>;
  productIntroduction2: React.ComponentProps<typeof SoftwareIntroduction>;
};

export type SoftwareTemplateProps = {
  id: string;
  slotProps: SoftwareTemplateSlotProps;
};

export const SoftwareTemplate: React.FC<SoftwareTemplateProps> = ({
  id,
  slotProps,
}) => {
  const { productHero, productIntroduction1, productIntroduction2 } = slotProps;

  return (
    <BaseWrapper id={id}>
      <div
        css={css`
          margin-bottom: ${defaultTheme.spacing.xl};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            margin-bottom: ${defaultTheme.spacing.xxl};
          }
        `}
      >
        <ProductHero {...productHero} />
      </div>
      <SoftwareIntroduction {...productIntroduction1} />
      <SoftwareIntroduction {...productIntroduction2} />
    </BaseWrapper>
  );
};
