/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GradationDividerJpTitle } from "components/atoms/GradationDividerJpTitle/GradationDividerJpTitle";
import { GradationDividerMediumTitle } from "components/atoms/GradationDividerMediumTitle/GradationDividerMediumTitle";
import { RadiusArrowButton } from "components/atoms/RadiusArrowButton/RadiusArrowButton";
import { Table } from "components/molecules/Table/Table";
import {
  ImageModal,
  ImageModalProps,
} from "components/organisms/ImageModal/ImageModal";
import { MediaQuery } from "hooks/useMediaQuery";
import { ReactNode } from "react";
import { defaultTheme, Theme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";

export type ImageClickEventHandler = (requireSrc: string, alt: string) => void;

type Image = {
  src: string;
  alt: string;
};

export type ProductIntroductionTemplateTechnicalStack = {
  icons: React.ReactNode;
  table: {
    data: ReactNode[][];
  };
};

export type ProductIntroductionTemplateSlot = ReactNode;

export type ProductIntroductionTemplateSlotProps = {
  title: React.ReactNode;
  subText: string;
  description: React.ReactNode;
  information: React.ReactNode;
  contentHeaderSlot?: ReactNode;
  technicalStack: ProductIntroductionTemplateTechnicalStack;
  images: {
    product: Image;
  };
  slot1?: ProductIntroductionTemplateSlot;
  slot2?: ProductIntroductionTemplateSlot;
  slot3?: ProductIntroductionTemplateSlot;
  slot4?: ProductIntroductionTemplateSlot;
  imageModal: ImageModalProps;
};

type Props = {
  id: string;
  theme: Theme;
  mediaQuery: MediaQuery;
  slotProps: ProductIntroductionTemplateSlotProps;
};

export const ProductIntroductionTemplate: React.FC<Props> = ({
  id,
  theme,
  mediaQuery,
  slotProps,
}) => {
  const { pc } = mediaQuery;
  const {
    title,
    subText,
    description,
    information,
    technicalStack,
    images,
    slot1,
    slot2,
    slot3,
    slot4,
    imageModal,
  } = slotProps;

  return (
    <>
      <BaseWrapper id={id}>
        <section>
          <FullWidthOuter
            pt={defaultTheme.spacing.base.top}
            bgColor={theme.palette.gray.background}
            cssOverride={css``}
          >
            <BaseInner
              width="100%"
              pt="2.5rem"
              pb="2.5rem"
              cssOverride={css`
                text-align: left;
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle
                    color={theme.palette.gray.text}
                    cssOverride={css`
                      text-transform: uppercase;
                      letter-spacing: ${defaultTheme.letterSpacing.xs};
                    `}
                  >
                    {subText}
                  </GradationDividerMediumTitle>
                </FadeInOnScrollByComponent>
                <Columns1to2Wrapper
                  leftSize={5}
                  rightSize={5}
                  leftComponent={
                    <div
                      css={css`
                        position: relative;
                        z-index: 1;

                        margin-bottom: ${defaultTheme.spacing.xs};
                        @media screen and (min-width: ${defaultTheme.breakpoints
                            .md}) {
                          margin-bottom: ${defaultTheme.spacing.xl};
                        }
                      `}
                    >
                      <FadeInOnScrollByComponent
                        delay={defaultTheme.delay.none}
                        direction="bottom"
                      >
                        <h1>{title}</h1>
                        <div
                          css={css`
                            margin-bottom: ${defaultTheme.spacing.md};
                          `}
                        >
                          {description}
                        </div>
                      </FadeInOnScrollByComponent>
                      <FadeInOnScrollByComponent
                        delay={defaultTheme.delay.none}
                        direction="bottom"
                      >
                        <div
                          css={css`
                            display: flex;
                            flex-direction: column;
                            gap: ${defaultTheme.spacing.xxs};
                          `}
                        >
                          {information}
                        </div>
                      </FadeInOnScrollByComponent>
                    </div>
                  }
                  rightComponent={
                    <div
                      css={css`
                        margin-left: auto; // 右端に寄せる
                        position: relative;
                        z-index: 1;
                      `}
                    >
                      <FadeInOnScrollByComponent
                        delay={defaultTheme.delay.none}
                        direction="bottom"
                      >
                        <figure>
                          <img
                            src={images.product.src}
                            alt={images.product.alt}
                            css={css`
                              border-radius: ${defaultTheme.borderRadius.base};
                              width: 100%;
                            `}
                          />
                        </figure>
                      </FadeInOnScrollByComponent>
                    </div>
                  }
                />
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>

        <section>
          <div
            css={css`
              position: relative;
              top: -140px;
            `}
          >
            <FullWidthOuter
              pt={`calc(${defaultTheme.spacing.base.top} + 40px)`}
              pb={defaultTheme.spacing.md}
              bgColor={theme.palette.contrastText}
              cssOverride={css``}
            >
              <BaseInner
                width="100%"
                pt="2.5rem"
                pb="2.5rem"
                cssOverride={css`
                  text-align: left;
                `}
              >
                <section>
                  {slotProps?.contentHeaderSlot && slotProps.contentHeaderSlot}
                  <Columns1to2Wrapper
                    leftSize={3}
                    rightSize={7}
                    leftComponent={
                      <FadeInOnScrollByComponent
                        delay={defaultTheme.delay.short}
                        direction="spot"
                      >
                        <GradationDividerJpTitle color={theme.palette.text}>
                          使用技術
                        </GradationDividerJpTitle>
                        {technicalStack.icons}
                      </FadeInOnScrollByComponent>
                    }
                    rightComponent={
                      <FadeInOnScrollByComponent
                        delay={defaultTheme.delay.medium}
                        direction="spot"
                      >
                        <div
                          css={css`
                            margin-left: auto; // 右端に寄せる
                          `}
                        >
                          <Table
                            hideOutlineBorder
                            firstColumnStyles={{
                              bold: true,
                              color: theme.palette.gray.text,
                            }}
                            columnWidths={[pc ? "140px" : "114px", "auto"]}
                            borderColor={defaultTheme.palette.gray.primary}
                            data={technicalStack.table.data}
                          />
                        </div>
                      </FadeInOnScrollByComponent>
                    }
                  />
                </section>
              </BaseInner>
            </FullWidthOuter>
          </div>
        </section>
        {slot1 && slot1}
        {slot2 && slot2}
        {slot3 && slot3}
        {slot4 && slot4}
      </BaseWrapper>
      <ImageModal
        src={imageModal.src}
        alt={imageModal.alt}
        open={imageModal.open}
        onClose={imageModal.onClose}
        imageSize={pc ? "80vh" : "80vw"}
        buttons={
          <RadiusArrowButton
            direction="left"
            buttonColor={defaultTheme.palette.primary}
            outlined
            onClick={imageModal.onClose}
          >
            ページに戻る
          </RadiusArrowButton>
        }
      />
    </>
  );
};
