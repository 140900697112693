import {
  useImageSourcesMutators,
  useImageSourcesState,
} from "stores/imageSourcesStore";

export type AboutPageImageSourcesState = {
  profilePhoto: string;
};

export type ProductPageImageSourcesState = {
  products: {
    batchfilerenamer: string;
    fitscreenwindow: string;
    kawaichiBlog: string;
    programmingOtasuke: string;
    kawaichiPortfolio: string;
    shotoku: string;
    talkstock: string;
  };
};

export type ProductTalkStockPageImageSourcesState = {
  productTalkStock: {
    brandBanner: string;
    ingenuity1_1: string;
    ingenuity1_2: string;
    ingenuity2: string;
  };
};

export type ProductPortfolioPageImageSourcesState = {
  productPortfolio: {
    brandBanner: string;
    diagram1: string;
    diagram2: string;
    ingenuity1: string;
    ingenuity2: string;
  };
};

export type ProductKawaichiBlogPageImageSourcesState = {
  productKawaichiBlog: {
    brandBanner: string;
    achievements1: string;
    diagram1: string;
    diagram2: string;
    ingenuity1: string;
    ingenuity2: string;
  };
};

export type ProductProgrammingOtasukePageImageSourcesState = {
  productProgrammingOtasuke: {
    brandBanner: string;
    diagram2: string;
    ingenuity1: string;
    ingenuity2: string;
    ingenuity3: string;
  };
};

export type ProductFitScreenWindowPageImageSourcesState = {
  productFitScreenWindow: {
    brandBanner: string;
    ingenuity1: string;
    ingenuity2: string;
    other1: string;
    other2: string;
    other3: string;
  };
};

export type ProductShotokuPageImageSourcesState = {
  productShotoku: {
    brandBanner: string;
    ingenuity1: string;
    ingenuity2: string;
    other1: string;
    other2: string;
  };
};

export type SoftwarePageImageSourcesState = {
  fitScreenWindowImage: string;
  batchFileRenamerImage: string;
};

export type DesignPageImageSourcesState = {
  designImages: {
    img01: string;
    img02: string;
    img03: string;
    img04: string;
    img05: string;
    img06: string;
    img07: string;
    img08: string;
    img09: string;
    img10: string;
    img11: string;
    img12: string;
    img13: string;
    img14: string;
    img15: string;
    img16: string;
    img17: string;
    img18: string;
  };
};

export type ImageSourcesState = AboutPageImageSourcesState &
  ProductPageImageSourcesState &
  ProductTalkStockPageImageSourcesState &
  ProductPortfolioPageImageSourcesState &
  ProductKawaichiBlogPageImageSourcesState &
  ProductProgrammingOtasukePageImageSourcesState &
  ProductFitScreenWindowPageImageSourcesState &
  ProductShotokuPageImageSourcesState &
  SoftwarePageImageSourcesState &
  DesignPageImageSourcesState;

export const useImageSources = () => {
  const imageSources = useImageSourcesState();
  const { setImageSources } = useImageSourcesMutators();

  return {
    imageSources,
    setImageSources,
  };
};
