/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { defaultTheme } from "styles/theme";
import { LineWithText } from "../LineWithText/LineWithText";
import { SubTitle } from "../SubTitle/SubTitle";

type Props = {
  title: string;
  slotProps?: {
    lineWithText?: {
      borderColor?: string;
    };
    subTitle?: {
      color?: string;
    };
  };
  cssOverride?: SerializedStyles;
};

export const LineWithTextSubTitle: React.FC<Props> = ({
  title,
  slotProps,
  cssOverride,
}) => {
  return (
    <LineWithText
      borderColor={slotProps?.lineWithText?.borderColor}
      fromColor={slotProps?.lineWithText?.borderColor}
      cssOverride={css`
        ${cssOverride};
        margin-bottom: ${defaultTheme.spacing.sm};
      `}
    >
      <h3>
        <SubTitle
          color={slotProps?.subTitle?.color}
          cssOverride={css`
            font-weight: normal;
            letter-spacing: ${defaultTheme.letterSpacing.xs};
            margin-bottom: 0;
          `}
        >
          {title}
        </SubTitle>
      </h3>
    </LineWithText>
  );
};
