/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { LinkText } from "components/atoms/LinkText/LinkText";
import {
  PrivacyPolicyTemplate,
  PrivacyPolicyTemplateSlotProps,
} from "components/templates/PrivacyPolicyTemplate/PrivacyPolicyTemplate";
import { useTheme } from "hooks/useTheme";
import React, { useMemo } from "react";
import { constants } from "utils/constants";
import { MainLayout } from "../MainLayout/MainLayout";

const PrivacyPolicyPage: React.FC = () => {
  const { theme, mode } = useTheme();

  const slotProps: PrivacyPolicyTemplateSlotProps = useMemo(
    () => ({
      title: "PRIVACY POLICY",
      subText: constants.pages.privacyPolicy.title,
      bgColor: "inherit",
      backButton: {
        text: "戻る",
        onClick: () => {
          window.history.back();
        },
        color: theme.palette.text,
      },
      cards: {
        subTitleColor: theme.palette.gray.text,
        personalInformation: {
          subTitle: "個人情報の利用目的",
          text: (
            <BodyText>
              当サイトでは、お問い合わせの際、名前やメールアドレスなどの個人情報を入力いただく場合がございます。取得した個人情報は、お問い合わせに対する回答や必要な情報をメールなどでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。
            </BodyText>
          ),
        },
        contactForm: {
          subTitle: "お問い合わせフォームについて",
          text: (
            <BodyText>
              当サイトでは、問い合わせを記録する際、IPアドレスを収集しています。これはサイトの標準機能としてサポートされている機能で、スパムや荒らしへの対応以外にこのIPアドレスを使用することはありません。
            </BodyText>
          ),
        },
        googleAnalytics: {
          subTitle: "アクセス解析ツールについて",
          text: (
            <BodyText>
              当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細は
              <LinkText
                cssOverride={css`
                  color: ${theme.palette.gray.text};
                `}
                item={{
                  href: "https://marketingplatform.google.com/about/analytics/terms/jp/",
                  value: "Googleアナリティクスサービス利用規約",
                }}
              />
              のページや
              <LinkText
                cssOverride={css`
                  color: ${theme.palette.gray.text};
                `}
                item={{
                  href: "https://policies.google.com/technologies/ads?hl=ja",
                  value: "Googleポリシーと規約ページ",
                }}
              />
              をご覧ください。
            </BodyText>
          ),
        },
        googleReCaptcha: {
          subTitle: "reCAPTCHAについて",
          text: (
            <BodyText>
              当サイトでは、「お問い合わせフォーム」に関して、Googleによるスパムなどからサイトを守るための「reCAPTCHAv3」を使用しています。reCAPTCHA
              APIは、デバイスやアプリケーションのデータなどハードウェアおよびソフトウェアの情報を収集し、それらのデータをGoogleに送信して分析することによって、ロボットによる悪質な行為かどうかを判断します。本サービスの利用に関連して収集された情報は、reCAPTCHAの向上および一般的なセキュリティ目的のために使用され、Googleによる広告には使用されません。
              この規約に関しての詳細は、Googleの
              <LinkText
                cssOverride={css`
                  color: ${theme.palette.gray.text};
                `}
                item={{
                  href: "https://policies.google.com/privacy?hl=ja",
                  value: "プライバシーポリシー",
                }}
              />
              と
              <LinkText
                cssOverride={css`
                  color: ${theme.palette.gray.text};
                `}
                item={{
                  href: "https://policies.google.com/terms?hl=ja",
                  value: "利用規約",
                }}
              />
              をご覧ください。
            </BodyText>
          ),
        },
      },
    }),
    [mode, theme]
  );

  return (
    <PrivacyPolicyTemplate
      id={constants.pages.privacyPolicy.id}
      theme={theme}
      slotProps={slotProps}
    />
  );
};

const LayoutPage = () => (
  <MainLayout showPageTop>
    <PrivacyPolicyPage />
  </MainLayout>
);
export { LayoutPage as PrivacyPolicyPage };
