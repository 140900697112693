import { useEffect, useState } from "react";

type ActiveMediaQuery = "mobile" | "tablet" | "pc";

type Options = {
  mobileBreakPoint?: string | number;
  tabletBreakPoint?: string | number;
};

export const useActiveMediaQuery = ({
  mobileBreakPoint = "767px",
  tabletBreakPoint = "1024px",
}: Options = {}) => {
  const [activeMediaQuery, setActiveMediaQuery] =
    useState<ActiveMediaQuery>("pc");

  useEffect(() => {
    const onResize = () => {
      setActiveMediaQuery(
        window.matchMedia(`screen and (max-width: ${mobileBreakPoint})`).matches
          ? "mobile"
          : window.matchMedia(
              `screen and (min-width: ${mobileBreakPoint}) and (max-width: ${tabletBreakPoint})`
            ).matches
          ? "tablet"
          : "pc"
      );
    };

    window.addEventListener("resize", onResize);
    window.addEventListener("load", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("load", onResize);
    };
  }, [mobileBreakPoint, tabletBreakPoint, activeMediaQuery]);

  return { activeMediaQuery };
};
