/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { Card } from "components/organisms/Card/Card";
import {
  SuccessTemplate,
  SuccessTemplateSlotProps,
} from "components/templates/SuccessTemplate/SuccessTemplate";
import { useTheme } from "hooks/useTheme";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";

export const ContactSuccessPage: React.FC = () => {
  const { theme, mode } = useTheme();

  // トップに戻るボタン
  const navigate = useNavigate();
  const handleTopButtonClick = useCallback(() => {
    navigate(constants.pages.home.url);
  }, []);

  // テンプレートに注入するprops
  const successTemplateSlotProps: SuccessTemplateSlotProps = useMemo(
    () => ({
      title: "お問い合わせありがとうございます",
      titleColor: theme.palette.gray.text,
      topButton: {
        text: "トップに戻る",
        onClick: handleTopButtonClick,
        color: theme.palette.text,
      },
      description: (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${defaultTheme.spacing.md};
          `}
        >
          <div>
            <BodyText>
              フォームの内容でお問い合わせ受付を完了いたしました。
            </BodyText>
            <BodyText>
              受付確認の自動返信メールをお送りいたしましたので、ご確認ください。
            </BodyText>
          </div>
          <Card bgColor={theme.palette.gray.background}>
            <BodyText>
              ※自動返信メールが届かない場合は、正常に送信できていない可能性もございますので、
              お手数ですが再度ご送信いただきますようお願いいたします。
            </BodyText>
          </Card>
        </div>
      ),
    }),
    [mode, theme]
  );

  return (
    <SuccessTemplate
      id="contact-success"
      slotProps={successTemplateSlotProps}
    />
  );
};
