import { useCallback } from "react";

export const useWindowScrollToTop = (): {
  scrollToWindowTop: () => void;
} => {
  // スクロール位置をページ最上部まで戻す
  const scrollToWindowTop = useCallback((): void => {
    window.scrollTo(0, 0);
  }, []);

  return {
    scrollToWindowTop,
  };
};
