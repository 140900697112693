/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type Columns1to2WrapperProps = {
  leftSize?: number;
  rightSize?: number;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  reverse?: boolean;
  cssOverride?: SerializedStyles;
  gap?: {
    columns1?: string | number;
    columns2?: string | number;
  };
  mt?: string | number;
};

export const Columns1to2Wrapper: React.FC<Columns1to2WrapperProps> = ({
  leftSize = 5,
  rightSize = 5,
  leftComponent,
  rightComponent,
  reverse = false,
  cssOverride,
  gap = {
    columns1: defaultTheme.spacing.sm,
    columns2: defaultTheme.spacing.md,
  },
  mt = 0,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: ${gap.columns1};
          margin-top: ${mt};
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            grid-template-columns: ${leftSize}fr ${rightSize}fr;
            gap: ${gap.columns2};
          }
        `,
      ]}
    >
      <div>{reverse ? rightComponent : leftComponent}</div>
      <div>{reverse ? leftComponent : rightComponent}</div>
    </div>
  );
};
