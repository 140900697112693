/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Footer, FooterSlotProps } from "components/organisms/Footer/Footer";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { defaultTheme } from "styles/theme";

export type FooterLayoutSlotProps = {
  footer: FooterSlotProps;
};

export type FooterLayoutTemplateProps = {
  slotProps: FooterLayoutSlotProps;
  children: React.ReactNode;
  mediaQuery: MediaQuery;
};

export const FooterLayoutTemplate: React.FC<FooterLayoutTemplateProps> = ({
  slotProps,
  children,
  mediaQuery,
}: FooterLayoutTemplateProps) => {
  return (
    <>
      {children}
      <footer>
        <Footer
          id="footer"
          slotProps={{
            ...slotProps.footer,
            pageTop: {
              show: slotProps.footer?.pageTop?.show,
              bgColor: slotProps.footer?.pageTop?.bgColor,
              color: slotProps.footer?.pageTop?.color,
              cssOverride: slotProps.footer?.pageTop?.cssOverride,
              styleType: slotProps.footer?.pageTop?.styleType,
            },
            wrapper: {
              bgColor: slotProps.footer.wrapper.bgColor,
              textColor: slotProps.footer.wrapper.textColor,
              style: css`
                padding-top: ${defaultTheme.spacing.footer.top};
                padding-bottom: ${defaultTheme.spacing.footer.bottom};
              `,
            },
          }}
          mediaQuery={mediaQuery}
        />
      </footer>
    </>
  );
};
