import { useEffect, useState } from "react";

// 画像のURL(require関数で返したモノ)の配列を受け取り、全ての画像がプリロードされるのを待つカスタムフック
export const usePreloadImages = (requireImageSources: string[]) => {
  const [preloadedSources, setPreloadedSources] = useState<string[]>([]);
  const [isPreloading, setIsPreloading] = useState(true);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    const preloadImage = (src: string) =>
      new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = (err) => reject(err);
      });

    const preloadImages = async () => {
      try {
        await Promise.all(requireImageSources.map(preloadImage));
        setPreloadedSources(requireImageSources);
      } catch (error) {
        console.error("Failed to preload images", error);
        setError(error);
      } finally {
        setIsPreloading(false);
      }
    };

    preloadImages();
  }, [requireImageSources, setIsPreloading]);

  return {
    preloadedSources,
    isPreloading,
    error,
  };
};
