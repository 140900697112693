/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const FitScreenWindowTextLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 18,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 825 81"
        height={size}
        width={"100%"}
      >
        <path d="M2.09,8.62h41v9.21H13V39H38.56V48.1H13v29.8H2.09Z" />
        <path d="M53,10c0-3.85,2.73-6.39,6.67-6.39S66.48,6.17,66.48,10s-2.82,6.49-6.77,6.49S53,14,53,10Zm1.32,16.17H65.16v51.7H54.35Z" />
        <path d="M83,61V34.84H75.5V26.76l8.08-.56,1.22-14.29h9V26.19h13.44v8.65H93.83V61.07c0,6.2,2.16,9.4,7.52,9.4a15.41,15.41,0,0,0,5.55-1.22l2,8a33.06,33.06,0,0,1-10.15,1.88C87.06,79.11,83,71.88,83,61Z" />
        <path d="M135.76,69l6.39-7.52c5,5,11.75,8.18,18.42,8.18,8.37,0,13.07-3.95,13.07-9.78,0-6.3-4.7-8.27-11.09-11.09L153,44.62c-6.67-2.82-14.1-8.08-14.1-18.14,0-10.9,9.59-19.08,22.75-19.08a29.63,29.63,0,0,1,21,8.74l-5.73,7c-4.32-3.85-9.12-6.2-15.23-6.2-7,0-11.66,3.38-11.66,8.83,0,6,5.55,8.27,11.19,10.53l9.49,4c8.18,3.48,14.1,8.55,14.1,18.71,0,11.09-9.21,20.11-24.34,20.11A34.52,34.52,0,0,1,135.76,69Z" />
        <path d="M193.19,52c0-17.2,11.94-27.16,25.57-27.16a22.19,22.19,0,0,1,15.13,5.92l-5.36,7c-2.82-2.54-5.73-4-9.21-4-8.74,0-14.95,7.33-14.95,18.23s6,18.24,14.57,18.24c4.32,0,8.18-2.07,11.28-4.7l4.51,7.14a25.6,25.6,0,0,1-16.82,6.39C203.9,79.11,193.19,69.24,193.19,52Z" />
        <path d="M245.45,26.19h8.83l.85,9.31h.28c3.67-6.67,9.12-10.62,14.66-10.62A12.86,12.86,0,0,1,275.91,26l-2,9.4a15.31,15.31,0,0,0-5.17-.85c-4.23,0-9.31,2.91-12.5,11.09V77.89H245.45Z" />
        <path d="M280.23,52c0-16.73,11.56-27.16,23.59-27.16,13.72,0,21.15,9.87,21.15,24.44a28.09,28.09,0,0,1-.56,5.55H290.85c.85,9.87,7,15.79,16,15.79a22,22,0,0,0,12.31-3.85l3.76,6.86a31.37,31.37,0,0,1-17.48,5.45C291.42,79.11,280.23,69.15,280.23,52Zm35.34-4.7c0-8.84-4-14-11.56-14-6.49,0-12.22,5-13.25,14Z" />
        <path d="M333.34,52c0-16.73,11.56-27.16,23.59-27.16,13.72,0,21.15,9.87,21.15,24.44a28,28,0,0,1-.56,5.55H344c.85,9.87,7,15.79,16,15.79a22,22,0,0,0,12.31-3.85L376,73.66a31.36,31.36,0,0,1-17.48,5.45C344.53,79.11,333.34,69.15,333.34,52Zm35.34-4.7c0-8.84-4-14-11.56-14-6.49,0-12.22,5-13.25,14Z" />
        <path d="M390.11,26.19h8.83l.85,7.14h.28c4.79-4.61,10.06-8.46,17.11-8.46,11,0,15.88,7.33,15.88,20.49V77.89H422.26V46.69c0-8.93-2.63-12.5-8.74-12.5-4.79,0-8.08,2.44-12.6,7V77.89H390.11Z" />
        <path d="M463.9,8.62h11.19l6.49,36.09C482.8,52,484,59.47,485.24,66.89h.38c1.5-7.43,3.1-14.85,4.7-22.18l8.93-36.09h9.68l8.93,36.09c1.6,7.24,3.1,14.66,4.7,22.18H523c1.13-7.52,2.35-14.95,3.48-22.18l6.58-36.09h10.43L529.89,77.89H516.45l-9.31-38.44c-1.22-5.45-2.26-10.62-3.2-15.88h-.38c-1,5.26-2.16,10.43-3.29,15.88l-9.12,38.44H478Z" />
        <path d="M552.64,10c0-3.85,2.73-6.39,6.67-6.39s6.77,2.54,6.77,6.39-2.82,6.49-6.77,6.49S552.64,14,552.64,10ZM554,26.19h10.81v51.7H554Z" />
        <path d="M580.93,26.19h8.83l.85,7.14h.28c4.79-4.61,10.06-8.46,17.11-8.46,11,0,15.88,7.33,15.88,20.49V77.89H613.08V46.69c0-8.93-2.63-12.5-8.74-12.5-4.79,0-8.08,2.44-12.59,7V77.89H580.93Z" />
        <path d="M636.11,52c0-16.73,10.62-27.16,22.18-27.16,5.92,0,9.68,2.26,13.91,5.83l-.47-8.55V3h10.81V77.89h-8.84l-.85-5.83h-.38c-3.85,3.95-9.21,7-15,7C644.47,79.11,636.11,69.24,636.11,52Zm35.62,11.84V38.6c-3.85-3.48-7.52-4.7-11.37-4.7-7.24,0-13.07,6.86-13.07,18,0,11.75,4.61,18.24,12.69,18.24C664.31,70.18,668,68.21,671.73,63.89Z" />
        <path d="M695.14,52c0-17.2,11.56-27.16,24.34-27.16s24.34,10,24.34,27.16-11.56,27.07-24.34,27.07S695.14,69.24,695.14,52Zm37.6,0c0-10.9-5.08-18.23-13.25-18.23S706.33,41.14,706.33,52s5.08,18.24,13.16,18.24S732.74,63,732.74,52Z" />
        <path d="M750.88,26.19h10.9l6.86,27.92c1,5.08,1.88,9.78,2.82,14.76h.38c1.13-5,2.16-9.78,3.38-14.76l7.33-27.92h10l7.43,27.92c1.22,5.08,2.26,9.78,3.48,14.76h.38c1-5,1.88-9.68,3-14.76l6.67-27.92h10.15l-13.35,51.7h-13l-6.58-25.57c-1.22-4.89-2.16-9.59-3.29-14.95H787c-1,5.36-2,10.15-3.29,15l-6.3,25.47h-12.5Z" />
      </svg>
    </div>
  );
};
