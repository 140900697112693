import { useCallback, useEffect } from "react";
import { useNavMenuMutators, useNavMenuState } from "stores/navMenuStore";
import { disableScroll, enableScroll } from "styles/functions";

export const useNavMenu = () => {
  const { open, isItemClick } = useNavMenuState();
  const { setNavMenu } = useNavMenuMutators();

  // 表示の切り替え
  const toggleOpen = useCallback(() => {
    setNavMenu((prev) => ({ ...prev, open: !prev.open }));
  }, [setNavMenu]);

  const closeMenu = useCallback(() => {
    setNavMenu((prev) => ({ ...prev, open: false }));
  }, [setNavMenu]);

  // スクロール禁止をトグル
  useEffect(() => {
    if (open) {
      disableScroll();
      return;
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [open]);

  const setIsItemClick = useCallback(
    (isItemClick: boolean) => {
      setNavMenu((prev) => ({ ...prev, isItemClick }));
    },
    [setNavMenu]
  );

  return {
    open,
    isItemClick,
    toggleOpen,
    closeMenu,
    setIsItemClick,
  };
};
