import React, { useRef } from "react";

export const useElementScrollToTop = <T extends HTMLElement = HTMLElement>(): {
  scrollToTopElementRef: React.RefObject<T>;
  scrollToElementTop: () => void;
} => {
  const scrollToTopElementRef = useRef<T>(null);
  const scrollToElementTop = React.useCallback((): void => {
    if (scrollToTopElementRef.current) {
      scrollToTopElementRef.current.scrollTo(0, 0);
    }
  }, []);

  return {
    scrollToTopElementRef,
    scrollToElementTop,
  };
};
