/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as AwsApiGateway } from "assets/images/product/aws-apigateway.svg";
import { ReactComponent as AwsLambda } from "assets/images/product/aws-lambda.svg";
import { ReactComponent as AwsLogo } from "assets/images/product/aws.svg";
import EmotionLogo from "assets/images/product/emotion.webp";
import { ReactComponent as JestLogo } from "assets/images/product/jest.svg";
import { ReactComponent as ReactLogo } from "assets/images/product/react.svg";
import { ReactComponent as RecaptchaLogo } from "assets/images/product/recaptcha.svg";
import SendGridLogo from "assets/images/product/sendgrid.webp";
import { ReactComponent as StorybookLogo } from "assets/images/product/storybook.svg";
import { ReactComponent as TerraformLogo } from "assets/images/product/terraform.svg";
import { ReactComponent as TypeScriptLogo } from "assets/images/product/typescript.svg";
import { ArrowTooltipButtonWithRef } from "components/atoms/ArrowTooltipButtonWithRef/ArrowTooltipButtonWithRef";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import {
  TechnicalStackWrapper,
  TechnicalStackWrapperProps,
} from "utils/Wrapper/TechnicalStackWrapper/TechnicalStackWrapper";

type Props = TechnicalStackWrapperProps;

export const KawaichiPortfolioTechnicalStack: React.FC<Props> = ({
  ...rest
}) => {
  return (
    <TechnicalStackWrapper {...rest}>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              開発ライブラリ
            </FillRadiusTag>
            <div>React 18</div>
          </div>
        }
      >
        <ReactLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              開発言語
            </FillRadiusTag>
            <div>TypeScript</div>
          </div>
        }
      >
        <TypeScriptLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              スタイルライブラリ
            </FillRadiusTag>
            <div>Emotion(@emotion/react)</div>
          </div>
        }
      >
        <img src={EmotionLogo} alt="emotion" height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                テストフレームワーク
              </FillRadiusTag>
              <div>Jest</div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                テストライブラリ
              </FillRadiusTag>
              <div>React Testing Library</div>
            </div>
          </div>
        }
      >
        <JestLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              コンポーネントライブラリ
            </FillRadiusTag>
            <div>Storybook</div>
          </div>
        }
      >
        <StorybookLogo width={100} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  インフラ
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  クラウド
                </FillRadiusTag>
              </div>
              <div>AWS</div>
            </div>
          </div>
        }
      >
        <AwsLogo
          width={30}
          height={30}
          css={css`
            position: relative;
            top: 2px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                デプロイ
              </FillRadiusTag>
              <div>Amazon API Gateway</div>
            </div>
          </div>
        }
      >
        <AwsApiGateway width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                バックエンド
              </FillRadiusTag>
              <div>AWS Lambda</div>
            </div>
          </div>
        }
      >
        <AwsLambda width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                インフラ
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                IaC
              </FillRadiusTag>
            </div>
            <div>Terraform</div>
          </div>
        }
      >
        <TerraformLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                メール送信
              </FillRadiusTag>
            </div>
            <div>SendGrid</div>
          </div>
        }
      >
        <img src={SendGridLogo} alt="sendgrid" height={20} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                外部API
              </FillRadiusTag>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                スパム対策
              </FillRadiusTag>
            </div>
            <div>reCAPTCHA v3</div>
          </div>
        }
      >
        <RecaptchaLogo
          width={26}
          height={26}
          css={css`
            position: relative;
            top: 4px;
            path {
              transform: scale(0.35);
            }
          `}
        />
      </ArrowTooltipButtonWithRef>
    </TechnicalStackWrapper>
  );
};
