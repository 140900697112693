/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  line?: number;
  radius?: number;
  color?: string;
};

const styles = (props: Props) => {
  return css`
    border-radius: calc(${props.radius} * 10px);
    border: solid ${props.line}px;
    border-color: ${props.color};
    display: block;
    width: fit-content;
    padding: calc(${props.height}px) calc(${props.width}px);
    line-height: 0; // 文字をchildrenに渡したときに上下に余白ができてしまうのを防ぐ
    box-sizing: border-box;
    color: ${props.color};
    text-decoration: none;
    text-align: center;
  `;
};

export const BorderRadiusTag: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  width = 18,
  height = 12,
  line = 2,
  radius = 10,
  color = "#444444",
}) => {
  return (
    <div
      css={[
        styles({
          width,
          height,
          line,
          radius,
          color,
        }),
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      {children}
    </div>
  );
};
