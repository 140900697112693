import { ContactState } from "hooks/useContact";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

const defaultValues: ContactState = {
  isSendSuccess: false,
};

const contactState = atom<ContactState>({
  key: "contactState",
  default: defaultValues,
});

export const useContactState = () => {
  const [state, setState] = useRecoilState(contactState);
  return {
    ...state,
  };
};

export const useContactMutators = () => {
  const setState = useSetRecoilState(contactState);

  return {
    setContact: setState,
  };
};
