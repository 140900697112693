/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { defaultTheme } from "styles/theme";

const styles = {
  lineWithText: css`
    position: block;
    @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
      // MEMO: テキストラインを視覚上に揃えるため調整
      position: relative;
      top: 10px;
    }
  `,
};

type Props = {
  children: React.ReactNode;
  useFor: "LineWithText";
};

export const AdjustPositionWrapper: React.FC<Props> = ({
  children,
  useFor,
}) => {
  const adjustStyles = useFor === "LineWithText" ? styles.lineWithText : null;

  return <div css={adjustStyles}>{children}</div>;
};
