/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FitScreenWindowTextLogo } from "components/atoms/FitScreenWindowTextLogo/FitScreenWindowTextLogo";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { FitScreenWindowTechnicalStack } from "components/molecules/FitScreenWindowTechnicalStack/FitScreenWindowTechnicalStack";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import React from "react";
import { defaultTheme, Theme } from "styles/theme";
import { CLIENT_BASEURL } from "utils/config";
import { constants } from "utils/constants";
import { ProductCard, ProductCardSlotProps } from "../ProductCard/ProductCard";
import { ProductCardBottomComponent } from "../ProductCardBottomComponent/ProductCardBottomComponent";

type Props = {
  src: string;
  slotProps?: ProductCardSlotProps & {
    button?: {
      color?: string;
    };
  };
  theme: Theme;
};

export const FitScreenWindowProductCard: React.FC<Props> = ({
  src,
  slotProps,
  theme,
}) => {
  return (
    <ProductCard
      title={
        <FitScreenWindowTextLogo
          darkColor={slotProps?.title?.color}
          lightColor={slotProps?.title?.color}
          cssOverride={slotProps?.title?.style}
        />
      }
      description="選択しているアクティブなウィンドウを、キーボードショートカットで画面にフィットできるWindows向けネイティブアプリです"
      image={{
        src: src,
        alt: "fitscreenwindow",
      }}
      href={`${CLIENT_BASEURL}${constants.pages.product.url.fitscreenwindow}`}
      slotProps={{
        title: {
          color: slotProps?.title?.color,
          style: css`
            letter-spacing: ${defaultTheme.letterSpacing.xs};
          `,
        },
        tag: slotProps?.tag,
        description: slotProps?.description,
      }}
      bottomComponent={
        <ProductCardBottomComponent
          theme={theme}
          topComponent={
            <a
              href={`${CLIENT_BASEURL}${constants.pages.product.url.fitscreenwindow}`}
              target="_blank"
              rel="noreferrer"
            >
              <LongArrowButton
                textColor={slotProps?.button?.color}
                arrowColor={slotProps?.button?.color}
                cssOverride={css`
                  color: ${slotProps?.button?.color};
                `}
              >
                詳細を見る
              </LongArrowButton>
            </a>
          }
          technicalStack={
            <FitScreenWindowTechnicalStack
              bgColor={defaultTheme.palette.background}
            />
          }
          other={
            <LabelRadiusTags
              bold={false}
              tags={[{ value: "OSS(MIT)" }, { value: "インストーラー形式" }]}
            />
          }
        />
      }
    />
  );
};
