/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { defaultTheme } from "styles/theme";

type TextAreaProps = {
  id: string;
  register: UseFormRegisterReturn;
  errors?: FieldError;
  size?: number;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea: React.FC<TextAreaProps> = ({
  id,
  register,
  errors,
  size = 150,
  ...args
}) => {
  return (
    <textarea
      {...args}
      {...register}
      id={id}
      css={css`
        resize: none;
        width: 100%;
        border: ${defaultTheme.borderWidth.sm}px solid;
        border-color: ${!!errors
          ? defaultTheme.palette.error
          : defaultTheme.palette.gray.button[100]};
        border-radius: ${defaultTheme.borderRadius.base};
        padding: 0.75rem 0.5rem;
        line-height: 1.25;
        min-height: ${size}px;
      `}
    />
  );
};
