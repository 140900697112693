/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type Props = {
  title: React.ReactNode;
  open: boolean;
  children: React.ReactNode;
  bgColor?: string;
};

export const ArrowTooltip = ({
  title,
  open,
  children,
  bgColor = "#666666",
}: Props) => {
  return (
    <div
      css={css`
        position: relative;
        z-index: ${defaultTheme.zIndex.tooltip};
      `}
    >
      {children}
      {open && (
        <div
          css={css`
            position: absolute;
            min-width: 200px;
            bottom: 170%; // 子要素の下端から表示
            left: 10%; // 中央揃え
            transform: translateX(-10%); // 中央揃えの微調整
            background-color: ${bgColor};
            color: white;
            padding: 0.5em 1em;
            border-radius: 5px;

            // 三角矢印
            &::before {
              content: "";
              position: absolute;
              bottom: -8px;
              left: 15%;
              transform: translateX(-50%); // 中央揃えの微調整

              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 10px 0 10px; // 下向きの矢印に変更
              border-color: ${bgColor} transparent transparent transparent;
            }
          `}
        >
          {title}
        </div>
      )}
    </div>
  );
};
