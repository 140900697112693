/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as AwsCdkLogo } from "assets/images/product/aws-cdk.svg";
import { ReactComponent as AwsCloudFrontLogo } from "assets/images/product/aws-cloudfront.svg";
import { ReactComponent as AwsS3Logo } from "assets/images/product/aws-s3.svg";
import { ReactComponent as AwsLogo } from "assets/images/product/aws.svg";
import { ReactComponent as BoltNew } from "assets/images/product/boltnew.svg";
import { ReactComponent as HeadlessUILogo } from "assets/images/product/headlessui.svg";
import { ReactComponent as NuxtJsLogo } from "assets/images/product/nuxtjs.svg";
import { ReactComponent as StorybookSmallLogo } from "assets/images/product/storybook-small.svg";
import { ReactComponent as TailwindLogo } from "assets/images/product/tailwind.svg";
import { ReactComponent as TypeScriptLogo } from "assets/images/product/typescript.svg";
import { ReactComponent as VitestLogo } from "assets/images/product/vitest.svg";
import { ArrowTooltipButtonWithRef } from "components/atoms/ArrowTooltipButtonWithRef/ArrowTooltipButtonWithRef";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";

import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

import {
  TechnicalStackWrapper,
  TechnicalStackWrapperProps,
} from "utils/Wrapper/TechnicalStackWrapper/TechnicalStackWrapper";

type Props = TechnicalStackWrapperProps;

export const ProgrammingOtasukeTechnicalStack: React.FC<Props> = ({
  ...rest
}) => {
  return (
    <TechnicalStackWrapper {...rest}>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              開発フレームワーク
            </FillRadiusTag>
            <div>Nuxt.js 3</div>
          </div>
        }
      >
        <NuxtJsLogo width={32} height={32} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              開発言語
            </FillRadiusTag>
            <div>TypeScript</div>
          </div>
        }
      >
        <TypeScriptLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              CSSフレームワーク
            </FillRadiusTag>
            <div>Tailwind</div>
          </div>
        }
      >
        <TailwindLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              スタイルライブラリ
            </FillRadiusTag>
            <div>Headless UI</div>
          </div>
        }
      >
        <HeadlessUILogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                テストフレームワーク
              </FillRadiusTag>
              <div>Vitest</div>
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                テストライブラリ
              </FillRadiusTag>
              <div>Vue Testing Library</div>
            </div>
          </div>
        }
      >
        <VitestLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                コンポーネントライブラリ
              </FillRadiusTag>
            </div>
            <div>Storybook</div>
          </div>
        }
      >
        <StorybookSmallLogo width={24} height={24} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 0.5rem;
              `}
            >
              <FillRadiusTag
                cssOverride={css`
                  ${mixin.text.description}
                  font-weight: 700;
                `}
                backgroundColor={defaultTheme.palette.common.white}
                textColor={defaultTheme.palette.gray.button[200]}
                width={10}
                height={2}
              >
                開発環境
              </FillRadiusTag>
            </div>
            <div>bolt.new</div>
          </div>
        }
      >
        <BoltNew width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  インフラ
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  クラウド
                </FillRadiusTag>
              </div>
              <div>AWS</div>
            </div>
          </div>
        }
      >
        <AwsLogo
          width={30}
          height={30}
          css={css`
            position: relative;
            top: 2px;
          `}
        />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  ホスティング
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  CDN
                </FillRadiusTag>
              </div>
              <div>Amazon CloudFront</div>
            </div>
          </div>
        }
      >
        <AwsCloudFrontLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  ホスティング
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  ストレージ
                </FillRadiusTag>
              </div>
              <div>Amazon S3</div>
            </div>
          </div>
        }
      >
        <AwsS3Logo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <div>
              <div
                css={css`
                  display: flex;
                  gap: 0.5rem;
                `}
              >
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  インフラ
                </FillRadiusTag>
                <FillRadiusTag
                  cssOverride={css`
                    ${mixin.text.description}
                    font-weight: 700;
                  `}
                  backgroundColor={defaultTheme.palette.common.white}
                  textColor={defaultTheme.palette.gray.button[200]}
                  width={10}
                  height={2}
                >
                  IaC
                </FillRadiusTag>
              </div>
              <div>AWS CDK v2</div>
            </div>
          </div>
        }
      >
        <AwsCdkLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
    </TechnicalStackWrapper>
  );
};
