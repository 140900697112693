/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EngTitleSubText } from "components/atoms/EngTitleSubText/EngTitleSubText";
import { FadeInSequentialText } from "components/atoms/FadeInSequentialText/FadeInSequentialText";
import { Theme, defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";

export type ProductTemplateSlotProps = {
  title: string;
  subText: string;
  subTitleColor: string;
  bgColor: string;
  description: React.ReactNode;
  product: {
    service: React.ReactNode;
    software: React.ReactNode;
    blog: React.ReactNode;
    website: React.ReactNode;
  };
};

type Props = {
  id: string;
  theme: Theme;
  slotProps: ProductTemplateSlotProps;
};

export const ProductTemplate: React.FC<Props> = ({ id, theme, slotProps }) => {
  const { title, subText, subTitleColor, bgColor, description, product } =
    slotProps;

  return (
    <>
      <BaseWrapper id={id}>
        <section>
          <FullWidthOuter
            pt={defaultTheme.spacing.base.top}
            bgColor={bgColor}
            cssOverride={css``}
          >
            <BaseInner
              width="100%"
              pt="2.5rem"
              pb="2.5rem"
              cssOverride={css`
                text-align: left;
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-bottom: ${defaultTheme.spacing.title.bottom};
                    `}
                  >
                    <h1>
                      <EngTitleSubText
                        theme={theme}
                        title={
                          <FadeInSequentialText
                            initialDelay={defaultTheme.delay.none}
                          >
                            {title}
                          </FadeInSequentialText>
                        }
                        subText={
                          <FadeInOnScrollByComponent
                            delay={defaultTheme.delay.medium}
                            direction="spot"
                          >
                            {subText}
                          </FadeInOnScrollByComponent>
                        }
                        subTextColor={subTitleColor}
                      />
                    </h1>
                  </div>
                </FadeInOnScrollByComponent>

                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="bottom"
                >
                  <div
                    css={css`
                      margin-bottom: ${defaultTheme.spacing.sm};
                    `}
                  >
                    {description}
                  </div>
                </FadeInOnScrollByComponent>

                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: ${defaultTheme.spacing.lg};
                  `}
                >
                  {product.service}
                  {product.website}
                  {product.blog}
                  {product.software}
                </div>
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>
      </BaseWrapper>
    </>
  );
};
