import { ComponentMeta, ComponentStory } from "@storybook/react";
import { withDesign } from "storybook-addon-designs";
import { ImageGallery } from "./ImageGallery";

export default {
  title: "Molecules/Gallery/ImageGallery",
  component: ImageGallery,
  decorators: [withDesign],
} as ComponentMeta<typeof ImageGallery>;

/*** Component ***/
// MEMO: Component名と同一 && 小文字だと、サイドメニューのコンポーネント配下にストーリーが作成されない。
export const imageGallery: ComponentStory<typeof ImageGallery> = (args) => (
  <ImageGallery {...args} />
);

/*** Stories ***/
imageGallery.args = {
  items: [
    {
      title: "Title",
      description: "Description",
      src: "https://place-hold.it/150x200",
      alt: "alt",
    },
    {
      title: "Title",
      description: "Description",
      src: "https://place-hold.it/150x200",
      alt: "alt",
    },
    {
      title: "Title",
      description: "Description",
      src: "https://place-hold.it/150x200",
      alt: "alt",
    },
  ],
  onClick: () => {
    console.log("onClick");
  },
};
