import { SNSTextIconsType } from "components/molecules/SNSTextIcons/SNSTextIcons";

export const GA_ID = process.env.REACT_APP_GA_ID || "";
export const RECAPTCHA_CLIENT_KEY =
  process.env.REACT_APP_RECAPTCHA_CLIENT_KEY || "";

export const constants = {
  pages: {
    home: {
      id: "home",
      url: "/",
      scrollToID: "header",
    },
    about: {
      id: "about",
      title: "ABOUT",
      url: "/about",
      scrollToID: "about",
    },
    product: {
      id: "product",
      title: "PRODUCT",
      url: {
        index: "/product",
        talkstock: "/product/talkstock",
        fitscreenwindow: "/product/fitscreenwindow",
        shotoku: "/product/shotoku",
        kawaichiBlog: "/product/kawaichiblog",
        portfolio: "/product/portfolio",
        programmingOtasuke: "/product/programming-otasuke",
      },
      scrollToID: "product",
    },
    software: {
      id: "software",
      title: "SOFTWARE",
      url: "/software",
      scrollToID: {
        index: "software",
        fitscreenwindow: "fitscreenwindow",
      },
    },
    design: {
      id: "design",
      title: "DESIGN",
      url: "/design",
      scrollToID: "design",
    },
    privacyPolicy: {
      id: "privacy-policy",
      title: "プライバシーポリシー",
      url: "/privacy",
    },
    contact: {
      id: "contact",
      title: "CONTACT",
      url: "/contact",
      scrollToID: "contact",
    },
  },
  copyRight: { text: "© 2022-2025 KAWAICHI" },
  links: {
    portfolio: "https://kawaichi0228.com/",
    portfolioStorybook: "https://ui.kawaichi0228.com/",
    kawaichiBlog: "https://kawaichiblog.com/",
    programmingOtasuke: "https://tools.kawaichiblog.com/",
    fitscreenwindow: "https://fitscreenwindow.com/",
    batchfilerenamer: "https://batchfilerenamer.netlify.app/",
    kawaichidrums: "https://drums.kawaichi0228.com/",
  },
};

export const footerSns: SNSTextIconsType = {
  github: {
    href: "https://github.com/Kawaichi0228",
  },
};
