/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { defaultTheme } from "styles/theme";

export type TechnicalStackWrapperProps = {
  cssOverride?: SerializedStyles;
  bgColor?: string;
  children?: React.ReactNode;
};

export const TechnicalStackWrapper: React.FC<TechnicalStackWrapperProps> = ({
  cssOverride,
  bgColor = "inherit",
  children,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          background-color: ${bgColor};
          padding: ${defaultTheme.spacing.xs};
          border-radius: ${defaultTheme.borderRadius.base};
          display: flex;
          align-items: center;
          gap: 1rem;
          flex-wrap: wrap;
        `,
      ]}
    >
      {children}
    </div>
  );
};
