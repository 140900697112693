/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { GradationDividerMediumTitle } from "components/atoms/GradationDividerMediumTitle/GradationDividerMediumTitle";
import { ImageFadeLoader } from "components/atoms/ImageFadeLoader/ImageFadeLoader";
import { InformationLinkText } from "components/atoms/InformationLinkText/InformationLinkText";
import { JpTitle } from "components/atoms/JpTitle/JpTitle";
import { LargeBodyText } from "components/atoms/LargeBodyText/LargeBodyText";
import { LinkText } from "components/atoms/LinkText/LinkText";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { ShotokuTechnicalStack } from "components/molecules/ShotokuTechnicalStack/ShotokuTechnicalStack";
import {
  ProductIntroductionTemplate,
  ProductIntroductionTemplateSlotProps,
} from "components/templates/ProductIntroductionTemplate/ProductIntroductionTemplate";
import {
  ProductShotokuPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { usePreloadImages } from "hooks/usePreloadImages";
import { useProduct } from "hooks/useProduct";
import { useTheme } from "hooks/useTheme";
import { ProductIntroductionSection } from "organisms/ProductIntroductionSection/ProductIntroductionSection";
import React, { useMemo } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme, Theme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { constants } from "utils/constants";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { Columns1to2to3Wrapper } from "utils/Wrapper/Columns1to2to3Wrapper/Columns1to2to3Wrapper";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { Columns2to3Wrapper } from "utils/Wrapper/Columns2to3Wrapper/Columns2to3Wrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";
import { MainLayout } from "../MainLayout/MainLayout";

type DetailProps = {
  handleImageCardClick: (src: string, alt: string) => void;
  theme: Theme;
};

const Detail01: React.FC<DetailProps> = ({ handleImageCardClick, theme }) => {
  const detail01RequireSource = [
    require("assets/images/product/shotoku/main-features-1.gif"),
  ];
  const {
    preloadedSources: preloadedDetail01Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail01RequireSource);
  const preloadedDetailRequireSource = preloadedDetail01Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "税務書類の出力機能(自動計算)"
        )
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"税務書類の出力機能(自動計算)"}
          css={css`
            border: ${defaultTheme.borderWidth.sm}px solid;
            border-color: ${theme.palette.gray.background};
            border-radius: ${defaultTheme.borderRadius.base};
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 360px;
            }
          `}
        />
      </div>
    </button>
  );
};

const Detail02: React.FC<DetailProps> = ({ handleImageCardClick, theme }) => {
  const detail02RequireSource = [
    require("assets/images/product/shotoku/main-features-2.gif"),
  ];
  const {
    preloadedSources: preloadedDetail02Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail02RequireSource);
  const preloadedDetailRequireSource = preloadedDetail02Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "明細データを印刷・CSV・PDFの3種類の中から選択し、出力できる機能"
        )
      }
    >
      <img
        src={preloadedDetailRequireSource}
        alt={"明細データを印刷・CSV・PDFの3種類の中から選択し、出力できる機能"}
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
          width: 100%;
          max-width: 100%;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            max-width: 360px;
          }
        `}
      />
    </button>
  );
};

const Detail03: React.FC<DetailProps> = ({ handleImageCardClick, theme }) => {
  const detail03RequireSource = [
    require("assets/images/product/shotoku/main-features-3.gif"),
  ];
  const {
    preloadedSources: preloadedDetail03Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail03RequireSource);
  const preloadedDetailRequireSource = preloadedDetail03Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "データベース機能(MySQL)"
        )
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
          background-color: ${defaultTheme.palette.common.black};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"データベース機能(MySQL)"}
          css={css`
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 360px;
            }
          `}
        />
      </div>
    </button>
  );
};

const ProductShotokuPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const {
    imageSources: { productShotoku },
  } = useImageSources();
  const { open, src, alt, handleImageCardClick, handleImageModalClose } =
    useProduct();

  const slotProps: ProductIntroductionTemplateSlotProps = useMemo(
    () => ({
      title: (
        <JpTitle
          cssOverride={css`
            margin-bottom: ${theme.spacing.xxs};
          `}
        >
          所得拡大促進税制エクセルソフト
        </JpTitle>
      ),
      subText: "VBA MACRO",
      description: (
        <>
          <BodyText>
            法人税を税額控除できる制度としてメジャーである 「所得拡大促進税制」
            の計算を、簡単に・スピーディーに行うことができる本格的なエクセルマクロです
          </BodyText>
          <BodyText>※ 現在、公開停止中</BodyText>
        </>
      ),
      information: (
        <>
          <InformationLinkText
            label="GITHUB: "
            href="https://github.com/Kawaichi0228/shotokukakudaiexcel-main/"
            color={theme.palette.text}
            showNewWindowIcon
          />
        </>
      ),
      technicalStack: {
        icons: (
          <ShotokuTechnicalStack bgColor={defaultTheme.palette.contrastText} />
        ),
        table: {
          data: [
            ["アプリ", "Excel VBA"],
            ["データベース", "MySQL"],
            ["コーディング規約", "自作"],
            [
              "その他",
              "フルスクラッチ開発(実ステップ数: 5,680), デザインツールとしてIllustratorを使用",
            ],
          ],
        },
      },
      images: {
        product: {
          src: productShotoku.brandBanner,
          alt: "所得拡大促進税制エクセルソフト",
        },
      },
      slot1: (
        <section>
          <FullWidthOuter
            pt={"0"}
            pb={defaultTheme.spacing.lg}
            bgColor={theme.palette.background}
          >
            <BaseInner
              width="100%"
              cssOverride={css`
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing.lg};
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-bottom: ${theme.spacing.lg};
                    `}
                  >
                    <JpTitle>主な機能</JpTitle>
                  </div>
                </FadeInOnScrollByComponent>
                <Columns1to2to3Wrapper
                  firstComponent={
                    <FadeInOnScrollByComponent
                      delay={defaultTheme.delay.none}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <Detail01
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        税務書類の出力機能(自動計算)
                      </LargeBodyText>
                      <BodyText>
                        複雑な税金の計算を、各シートに定められたフィールドへ入力することで、簡単に自動計算できるようにしました。また、計算した結果を税務書類様式へ反映させ、それを印刷できるように実装しました。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                  secondComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 200 : 0}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <Detail02
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        明細データを印刷・CSV・PDFの3種類の中から選択し、出力できる機能
                      </LargeBodyText>
                      <BodyText>
                        ユーザが入力した各従業員の給与等データを明細として出力できる機能を実装しました。出力タイプは印刷、CSV、PDF
                        の 3 種類から選ぶことができます。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                  thirdComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 400 : 0}
                      direction="spot"
                    >
                      <div
                        css={css`
                          text-align: left;
                        `}
                      >
                        <div
                          css={css`
                            margin-bottom: ${defaultTheme.spacing.xs};
                          `}
                        >
                          <Detail03
                            handleImageCardClick={handleImageCardClick}
                            theme={theme}
                          />
                        </div>
                        <LargeBodyText
                          cssOverride={css`
                            font-weight: 700;
                            color: ${theme.palette.gray.text};
                          `}
                        >
                          データベース機能(MySQL)
                        </LargeBodyText>
                        <BodyText>
                          MySQL へ接続(ADODB 使用)し、ExcelVBA
                          からデータベースを操作できるライブラリを自作しました。また、データベース上に登録されたデータを表示し、選択した事業者のデータを各入力フィールドへインポートできる機能を実装しました。
                        </BodyText>
                      </div>
                    </FadeInOnScrollByComponent>
                  }
                />
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>
      ),
      slot2: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="開発背景・工夫した点"
          theme={theme}
          bgColor={theme.palette.contrastText}
          slot1={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    開発背景
                  </GradationDividerMediumTitle>
                  <BodyText>
                    経理業務をしていたときに、先輩や顧客企業様の経理の方から、
                    「制度が適用できる条件に該当するか、従業員さん全員分のデータを確認していくんだけど、人数が多くて毎回時間かかって大変なんだよね...なんとかできないかな？」
                    と相談を受けました。
                  </BodyText>
                  <BodyText>
                    <br />
                    そこで、プロトタイプとなる計算用のマクロを作り使っていただいたところ、
                    「これは便利だね、ラクに一瞬で計算できる」と感謝の言葉をいただきました
                    <sup>(※1)</sup>。
                  </BodyText>
                  <BodyText>
                    <br />
                    私は「もっと多くの方に使っていただきたい」と思い、より使いやすく本格的なアプリケーション化をするため、あらためてプライベートにて個人開発に着手しました。
                  </BodyText>
                  <div
                    css={css`
                      margin-top: ${defaultTheme.spacing.xs};
                    `}
                  >
                    <p
                      css={css`
                        ${mixin.text.note};
                        color: ${theme.palette.gray.text};
                      `}
                    >
                      ※1:
                      のちに、私が「プログラミングを仕事にしよう」とエンジニアを目指す大きなキッカケのひとつとなりました。
                    </p>
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productShotoku.ingenuity1}
                      alt={"開発背景"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 439px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productShotoku.ingenuity2}
                      alt={"工夫した点"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 439px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    工夫した点
                  </GradationDividerMediumTitle>
                  <BodyText>
                    ユーザーの手間を少なくするため、給与計算ソフト(弥生給与など)で出力したCSVデータから、ほとんど加工をせずにコピー&ペーストするだけで計算をできるようにしたり、利便性を上げるため、計算結果データを印刷・CSV・PDFの中から選んで出力できるようにしたり工夫をしました。
                  </BodyText>
                  <BodyText>
                    <br />
                    また、UI /
                    UX向上として、ユーザーの入力ミスを減らすため、行ごとに色を分ける・入力箇所のみに色を付けたり、入力しやすいように年度ごとに表示を切り替えできるボタンを用意したりしました。
                  </BodyText>
                  <BodyText>
                    <br />
                    ほかには、入力漏れを防ぐためのバリデーションを自前で実装するなど、可能な限りユーザーへ負担を減らし、使い方が分かりやすようにシンプルさをかなり意識して開発しました。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot3: (
        <section>
          <FullWidthOuter
            pt={defaultTheme.spacing.lg}
            pb={defaultTheme.spacing.lg}
            bgColor={theme.palette.background}
          >
            <BaseInner
              width="100%"
              cssOverride={css`
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing.lg};
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.text}>
                    その他
                  </GradationDividerMediumTitle>
                </FadeInOnScrollByComponent>
                <Columns2to3Wrapper
                  columnGap={{
                    sm: defaultTheme.spacing.sm,
                    md: defaultTheme.spacing.sm,
                    lg: defaultTheme.spacing.sm,
                  }}
                  firstComponent={
                    <FadeInOnScrollByComponent
                      delay={defaultTheme.delay.none}
                      direction="spot"
                    >
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        ウェブサイト
                      </LargeBodyText>
                      <button
                        type="button"
                        onClick={() =>
                          handleImageCardClick(
                            productShotoku.other1,
                            "ウェブサイト"
                          )
                        }
                      >
                        <div
                          css={css`
                            ${mixin.boxShadow};
                            ${mixin.hoverTransitionOpacityEffect};
                            height: 254px;
                            overflow: hidden;
                          `}
                        >
                          <img
                            src={productShotoku.other1}
                            alt={"ウェブサイト"}
                            css={css`
                              border: ${defaultTheme.borderWidth.sm}px solid;
                              border-color: ${theme.palette.gray.background};
                              border-radius: ${defaultTheme.borderRadius.base};
                              width: 100%;
                              max-width: 100%;
                              @media screen and (min-width: ${defaultTheme
                                  .breakpoints.md}) {
                                max-width: 360px;
                              }
                            `}
                          />
                        </div>
                      </button>
                    </FadeInOnScrollByComponent>
                  }
                  secondComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 200 : 0}
                      direction="spot"
                    >
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        <LinkText
                          styleType="default"
                          mode="anchor"
                          item={{
                            value: (
                              <span
                                css={css`
                                  display: inline;
                                `}
                              >
                                コーディング規約(外部リンク: GitHub)
                              </span>
                            ),
                            href: "https://github.com/Kawaichi0228/shotokukakudaiexcel-main/blob/master/styleguide/README.md",
                          }}
                        />
                      </LargeBodyText>
                      <button
                        type="button"
                        onClick={() =>
                          handleImageCardClick(productShotoku.other2, "LT資料")
                        }
                      >
                        <img
                          src={productShotoku.other2}
                          alt={"LT資料"}
                          css={css`
                            ${mixin.boxShadow};
                            ${mixin.hoverTransitionOpacityEffect};
                            border: ${defaultTheme.borderWidth.sm}px solid;
                            border-color: ${theme.palette.gray.background};
                            border-radius: ${defaultTheme.borderRadius.base};
                            width: 100%;
                            max-width: 100%;
                            @media screen and (min-width: ${defaultTheme
                                .breakpoints.md}) {
                              max-width: 360px;
                            }
                          `}
                        />
                      </button>
                    </FadeInOnScrollByComponent>
                  }
                  thirdComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 400 : 0}
                      direction="spot"
                    >
                      <span />
                    </FadeInOnScrollByComponent>
                  }
                />
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>
      ),
      imageModal: {
        open,
        src,
        alt,
        onClose: handleImageModalClose,
      },
    }),
    [mode, theme, pc, open, src, alt]
  );

  return (
    <ProductIntroductionTemplate
      id={constants.pages.product.id}
      theme={theme}
      mediaQuery={mediaQuery}
      slotProps={slotProps}
    />
  );
};

const imageSources: ProductShotokuPageImageSourcesState = {
  productShotoku: {
    brandBanner: require("assets/images/product/shotoku.webp"),
    ingenuity1: require("assets/images/product/shotoku/ingenuity1.webp"),
    ingenuity2: require("assets/images/product/shotoku/ingenuity2.webp"),
    other1: require("assets/images/product/shotoku/website.webp"),
    other2: require("assets/images/product/shotoku/styleguides.webp"),
  },
};
const requireSources = Object.values(imageSources.productShotoku);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <ProductShotokuPage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as ProductShotokuPage };
