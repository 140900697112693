/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { defaultTheme } from "styles/theme";

export type CardProps = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
  bgColor?: string;
};

export const Card: React.FC<CardProps> = ({
  children,
  cssOverride,
  bgColor = defaultTheme.palette.background,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          background-color: ${bgColor};
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-left: 1rem;
          padding-right: 1rem;
        `,
      ]}
    >
      <div css={[css``]}>{children}</div>
    </div>
  );
};
