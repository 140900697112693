/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const KawaichiBlogTextLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 18,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1066 79.33"
        height={size}
        width={"100%"}
      >
        <g id="front">
          <path d="M10.43,74c1.42-9,3.35-25.71,4.36-33.08-3.94.84-7,1.93-9.8,2.76L3.57,32.33a121.09,121.09,0,0,1,12.56-5.53l1.76-15.58,12.9.25C30,16.25,29.69,20.86,29.19,25c4.86.08,8.79-.08,10.89.42A17.05,17.05,0,0,1,49,28.73,31.21,31.21,0,0,1,52.8,36.6a24.59,24.59,0,0,1,.84,9.13,89.23,89.23,0,0,1-2.51,14.32c-1.17,4.77-2.35,9.8-3.94,14.24L35.47,71.52c1.26-4,3.1-9.71,3.94-13.73a68,68,0,0,0,1.68-13c-.17-2.51-.92-3.77-1.68-4.52-2-2.68-10-2.85-11.56-1.34C27.26,45,24.5,67.42,23,75.12ZM60.26,54.61c-1.59-5.95-3-13.15-4.61-20.1-1.68-7.45-3.1-14.57-5-20.68L63.27,9.05c2.26,6.87,3.52,13.4,5.28,19.76,1.67,6.7,3.27,14.57,4.61,21.44Z" />
          <path d="M95.93,73.62l.17-10.47-7.2,5.61-8-8.71c2.6-4.1,12.39-15.83,15.91-19.43l.08-3.27c-4.27.42-10.8,1.26-13.73,1.51l.33-13.73,12.9-1.42L96.35,11l14-.59.34,18.92c6.53-2.93,9.71-4.35,13.23-4.86a52,52,0,0,1,11.47-.33,17.83,17.83,0,0,1,12,5.19,21.86,21.86,0,0,1,3.94,7A23.82,23.82,0,0,1,153.13,45c0,4.52-2.09,9.88-3.52,14.15-2.18,7-3.6,9.88-4.94,14l-13.9-2.6c1.09-3.43,2.43-7,4.35-14a28.35,28.35,0,0,0,2.26-11.3,7.57,7.57,0,0,0-2.6-4.86,11.94,11.94,0,0,0-7.12-1.17,22.36,22.36,0,0,0-8.12,3.52c-3.18,1.42-5.61,3.68-8.88,5.19l-.25,25.88Z" />
          <path d="M168.87,62.31a204.41,204.41,0,0,1-6.78-42.71l12.64-3.68c.59,3.43,1.93,16.16,2.76,21.1a98.25,98.25,0,0,0,4,17.67,111,111,0,0,1,10.63-7L197.26,59c-2.6,3-10.22,9.21-13.06,10.55a13.83,13.83,0,0,1-9.46,1.93C172.22,69.93,170.29,66.08,168.87,62.31Zm46.48-2.93c-.84-5.78-2.26-12.9-3.77-19.09S208.39,27.56,206,18.77L219,13.49c3,5.78,4.94,12.73,6.28,20.27,1.51,7.29,2.76,15.16,3.85,21.52Z" />
          <path d="M278.23,74.45c1.84-3.18,4.19-7.2,6.11-10.3A22.07,22.07,0,0,0,287.7,53.1a4.66,4.66,0,0,0-4-1.51c-3.69,0-6.87.84-11.89,2.26a33.3,33.3,0,0,0-8.71,5.69l-2.26,3C257.13,61.64,253,59.3,250.6,58c2.76-10.13,5.78-20.1,7.45-25.79l-11.3.75V20.19l13.65-1.51a80.68,80.68,0,0,0,3.77-12.23l14.4.25c-.5,3.35-2.09,8.37-2.51,11.3,7.45-.42,16.66-1.42,24.37-1.34l.84,12c-10.21,1.09-18.34,1.76-28.39,2.76-1.17,3.18-2.43,7.37-3.6,10.8a50.65,50.65,0,0,1,12.14-2.51c9.38-1,14.49,1.34,17.75,4.35a14.45,14.45,0,0,1,2.76,9.71,31.38,31.38,0,0,1-2.51,10.63,54,54,0,0,1-9.21,13.4Z" />
          <path d="M327.08,64.81a12.3,12.3,0,0,1-5.65-5.16c-2-3.21-3.14-8-2.16-13.68a18.65,18.65,0,0,1,2.79-8.37A35,35,0,0,1,327.78,30,36.12,36.12,0,0,1,340,21.69c4.54-1.88,9.56-2.3,15.28-1.67a41.15,41.15,0,0,1,6.7,2.51,22.52,22.52,0,0,1,5.93,4.75,22,22,0,0,1,4,7.26,30.73,30.73,0,0,1,1.67,8.65,46.64,46.64,0,0,1-1.39,12.07,37,37,0,0,1-5.79,12.28l-9.7-3.84a66.58,66.58,0,0,0,4-8.93,38.43,38.43,0,0,0,2.09-9.21,42.4,42.4,0,0,0-.84-8.09c-1.47-3.63-3.35-5.3-5.58-5.37-.77,3.84-1.4,9.14-2.79,13.19a27,27,0,0,1-3.63,8.3,27.82,27.82,0,0,1-11.3,10.89A13.39,13.39,0,0,1,333.78,66C330.92,65.79,329.11,65.72,327.08,64.81Zm14.38-18c.91-2.09,1.68-4.54,2.44-6.63,1-3.56,1.54-6.84,2.3-9.21l-3.21.42c-2.72.84-5.58,3.14-8.16,5.93A16.64,16.64,0,0,0,331.27,43a21.11,21.11,0,0,0-1.46,8.23,3.49,3.49,0,0,0,3.14,3.14l2.16-.77A15.07,15.07,0,0,0,341.46,46.81Z" />
          <path d="M397.6,52.24c6.95-.42,18.51-.92,24.37-.84l.17-25c-6.53,0-13.48.08-20.94.17l.67-14.65c17.84-.75,36.26-.5,54.68.33L456,26c-6.11.08-12.31.17-19.51.17l-.08,25.12c8.88.33,16,0,22.27.25l.17,14c-20.35.17-40.87.25-61.38,0Z" />
          <path d="M474.89,23.94l2.6-14.65c9.21.75,18,2,27.38,3l-2,14.57C493.4,25.78,483.51,25.2,474.89,23.94Zm1.76,36.34c7.37-3.94,13.23-6.45,17.75-9.38s11.64-8.21,16-11.72c9.55-7,18.26-15.49,22.61-19.51l10.21,12.06c-6.2,6.28-15,13.57-21.94,20.1a124.74,124.74,0,0,1-28.05,18.84,78.38,78.38,0,0,1-10.38,4.52Z" />
          <path d="M554.53,37.93l1.76-11.47c6.2,0,23.7,2.18,26.63,3.27l-1.84,11.22C576.88,40.94,558.71,39.1,554.53,37.93Zm2.6-19.43,2.43-12.06c5.61-.42,22.36,1.42,27,3.52L584.67,21.6C578.22,21.26,562.23,19.08,557.12,18.5Zm8,40.53c8-5.28,15.57-9.38,24.54-15.66,8.29-5.95,15.24-11.56,22.53-17.67L621,38.51A136.92,136.92,0,0,1,597.74,57c-8,5.44-16.08,11.05-24.2,16.33ZM603.85,11.8l6.28-2.93c1.17,3.27,3.52,11.3,3.94,14.91l-6.28,2A93.1,93.1,0,0,0,603.85,11.8ZM613,8.87l6.45-2.76a52.83,52.83,0,0,1,3.85,14.15l-6,2.26A56.16,56.16,0,0,0,613,8.87Z" />
          <path d="M631.73,49.31c19.68-.84,43.71-.59,65.48-1.09l-.08,16.33c-22.11.59-44,.75-65,.34ZM642.87,26.2V12c15.24-1.51,30.23-1.51,45-1.42l.08,14.4C672.85,25.28,656.86,25.78,642.87,26.2Z" />
          <path d="M745.37,35.83a137.29,137.29,0,0,0,11.22-15.66c-8.29-.08-29.31,2.09-44.38,4l-.5-14c7.37-1.09,20.18-2.76,29.06-3.35,18.17-1.26,28.81-1,33.75-.5l.34,11.47c-2.51,5.44-10.3,19-19.18,28.64ZM722.68,70A125.2,125.2,0,0,0,728,52.41c1.09-5.36,1.67-13.15,1.84-23.87l15.58-.75a189.25,189.25,0,0,1-3,23.78A98.87,98.87,0,0,1,736.24,73Z" />
          <path d="M786.07,60c.25-4.86,1.59-9.88,2.34-13.57a31.07,31.07,0,0,1,4.19-9.71L806,39.94c-2.43,5.11-6.62,19.26-6.7,22.86ZM803,66.31l6.87-10.63a20,20,0,0,0,9.8,3.68c2.09.17,3-.33,3.6-1.42s.5-3.18-.08-5.53a42.84,42.84,0,0,0-4.77-10.13,85.37,85.37,0,0,1-6-9.29l10.72-6.2c2.18,2.76,4.69,5.11,6.53,7.54a66.71,66.71,0,0,1,4.77,8,38.88,38.88,0,0,1,2.34,7.29,24.1,24.1,0,0,1,.59,7.87c-.33,3.52-1,7.45-3.6,10-2.93,3.35-7.29,4.94-11.81,5.53h-4.27C813.62,72.68,809.68,71.67,803,66.31Zm4.19-52.5,7.2-11c7.79,4.52,16,9,22.61,13.9l-5.7,10.64C828.19,25.62,812.61,16.82,807.17,13.81Zm31.4,1.34,6.28-2.93c1.17,3.27,3.52,11.3,3.94,14.91l-6.28,2A93.09,93.09,0,0,0,838.57,15.15Zm6.37,47.4c-.84-10.72-2.43-17.5-3.35-23.2l12.06-2.43c2.34,5.61,4.19,16.58,3.94,22.27Zm2.76-50.33,6.45-2.76A52.68,52.68,0,0,1,858,23.61l-6,2.26A56.09,56.09,0,0,0,847.7,12.22Z" />
          <path d="M894.76,64.05a59.07,59.07,0,0,1,8-5c4.35-3,7-4.86,10-8.62,2.09-2.26,1.93-4.61.67-5.95C912,42.28,909.08,42.2,905,42.7c-6.11.67-11.64,2.93-16.91,5.19-3,1.59-8.21,4.27-13.57,6.62l-3.18-10.22c2.43-3,8.88-8.79,12.64-12.39,3.52-3.35,11.39-11.56,14-14.4l-20.1,1.67L877.6,6.94c5.44-.75,12.81-1.26,21.52-1.76,10.13-.59,19.09-1,23.36-.67l.42,10.13L905.56,30.31a28.43,28.43,0,0,1,11.05-1c3.94.67,6.87,1.34,9.8,4.69a12.92,12.92,0,0,1,2.68,5.61c.84,2.34.75,5.53.67,8.88a20.08,20.08,0,0,1-4.44,9.63,78.85,78.85,0,0,1-10.22,8.54,81.49,81.49,0,0,1-12.23,6.7Z" />
          <path d="M973.65,61.46a196.24,196.24,0,0,1-15.91-11.89L954.64,46c-1.26-.59-1.93-3.1-1.09-4.61,1.42-2.43,3.77-6.7,6.45-9.63,4.1-5.78,10.38-12.39,27.38-29.14L998.18,11c-3.77,4.36-22.86,24.54-26.71,29.81,5.7,5.11,25,17.17,29.73,20.26l-7.79,12.14C987.71,69.83,979,65.06,973.65,61.46Zm18.67-36.09,6.28-2.93c1.17,3.27,3.52,11.3,3.94,14.91l-6.28,2A93.07,93.07,0,0,0,992.32,25.36Zm8.79-2.93,6.45-2.76a52.69,52.69,0,0,1,3.85,14.15l-6,2.26A56.1,56.1,0,0,0,1001.11,22.43Z" />
          <path d="M1045.66,6a87,87,0,0,1,18.09-.08c-1.42,16.83-2.6,31.57-4.27,46.06h-10.55C1047,35.66,1045.91,16.49,1045.66,6Zm3.94,65.74a7.73,7.73,0,0,1-2.43-6.11,10.28,10.28,0,0,1,3.43-6.2,10.36,10.36,0,0,1,5-1,6.93,6.93,0,0,1,5.11,4.27,9.93,9.93,0,0,1,0,5.19,7.72,7.72,0,0,1-3,4.61,7.8,7.8,0,0,1-3.77.5A10.78,10.78,0,0,1,1049.6,71.76Z" />
        </g>
      </svg>
    </div>
  );
};
