/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  FooterLayoutSlotProps,
  FooterLayoutTemplate,
} from "components/templates/FooterLayoutTemplate/FooterLayoutTemplate";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useRouter } from "hooks/useRouter";
import { useTheme } from "hooks/useTheme";
import React from "react";
import { defaultTheme } from "styles/theme";
import { constants, footerSns } from "utils/constants";

export type MainLayoutProps = {
  children: React.ReactNode;
  showPageTop: boolean;
};

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  showPageTop,
}: MainLayoutProps) => {
  const { theme } = useTheme();
  const { location } = useRouter();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });

  const slotProps: FooterLayoutSlotProps = {
    footer: {
      pageTop: {
        show: showPageTop,
        bgColor: theme.palette.primary,
        color: theme.palette.contrastText,
        cssOverride:
          location.pathname === constants.pages.software.url ||
          location.pathname === constants.pages.product.url.programmingOtasuke
            ? undefined
            : css`
                margin-top: ${defaultTheme.spacing.lg};
              `,
        styleType: "icon",
      },
      wrapper: {
        bgColor: theme.palette.background,
        textColor: theme.palette.gray.text,
      },
      snsIcons: {
        color: theme.palette.gray.text,
        iconProps: footerSns,
      },
    },
  };
  return (
    <FooterLayoutTemplate slotProps={slotProps} mediaQuery={mediaQuery}>
      {children}
    </FooterLayoutTemplate>
  );
};
