import { useCallback } from "react";
import { useContactMutators, useContactState } from "stores/contactStore";
import { API_BASEURL } from "utils/config";

export type SendMailRequest = {
  recaptchaToken: string;
  company?: string;
  email: string;
  name: string;
  message: string;
};

type SendMailSuccessResponse = {
  result: {
    message: string;
    inquiry_number: string;
  };
};

type SendMailErrorResponse = {
  result: {
    code: number;
    message: string;
  };
};

export type ContactState = {
  isSendSuccess: boolean;
};

export const useContact = () => {
  const contact = useContactState();
  const { setContact } = useContactMutators();

  const sendMail = useCallback(
    async ({ request }: { request: SendMailRequest }) => {
      const sendMailResponse = await fetch(`${API_BASEURL}/send-mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: {
            recaptchaToken: request.recaptchaToken,
            company: request.company,
            email: request.email,
            name: request.name,
            message: request.message,
          },
        }),
      });

      if (sendMailResponse.status !== 200) {
        const sendMailResponseJson: SendMailErrorResponse =
          await sendMailResponse.json();
        return {
          error: sendMailResponseJson,
        };
      }

      const sendMailResponseJson: SendMailSuccessResponse =
        await sendMailResponse.json();

      return {
        data: sendMailResponseJson,
      };
    },
    []
  );

  return {
    contact,
    setContact,
    sendMail,
  };
};
