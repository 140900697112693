/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const ProgrammingOtasukeTextLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 18,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 846.64 47.86"
        height={size}
        width={"100%"}
      >
        <g id="73e7f5e7-df13-4592-bb4e-4296c5b8fdc1" data-name="front">
          <path d="M9.33,40.83c2-2.69,4.06-5.47,6.15-8.44a68.54,68.54,0,0,0,5.83-9.73c-8,.16-15,.48-21.31.72l.08-7.16c5.19-.52,10.58-.64,15.72-.76,3.7-.12,7.36-.12,11-.12a5,5,0,0,1-.2-1.29,3.54,3.54,0,0,1,2-3.46A6.87,6.87,0,0,1,31,9.86a4.77,4.77,0,0,1,3.46,1.09,4.79,4.79,0,0,1,.93,4.18,4.5,4.5,0,0,1-1.33,2.45,6,6,0,0,1-3.58.84,21.91,21.91,0,0,1-.24,4.26A110,110,0,0,1,24,35a107.06,107.06,0,0,1-8.69,11.46Zm22-28.19a3.55,3.55,0,0,0-1.21.4,3,3,0,0,0-.44,1.77c.2.72,1.33,1,2.41.56C32.77,14,32.49,13.08,31.33,12.64Z" />
          <path d="M53.16,42.84c-5-.08-10.33-.2-13.71-.52-.16-4.91-.16-9.53-.16-13.71,0-4.34-.12-9.81.2-13.23,3.34-.28,8.12-.4,12.95-.6a156.78,156.78,0,0,1,16.09,0,34.65,34.65,0,0,1-.08,6.07c-.16,2.13-.52,8-.84,11.54a93.52,93.52,0,0,1-1.25,10.13C63.3,42.64,58.19,42.88,53.16,42.84Zm7.48-22c-4.95.12-9.57.2-14.4.56-.2,5.11.12,9.89.36,14.44,4.71.28,8.85.28,13.27.16C60.4,31.5,60.52,26.19,60.64,20.8Z" />
          <path d="M89.52,42.48A84.41,84.41,0,0,0,93.3,32.7,48.71,48.71,0,0,0,96,22.57a51.27,51.27,0,0,0-8.36.6c-.84,2.13-3,8.4-3.66,9.61l-6.76-1.53c1-3.58,2.05-6.63,3-9.61.92-2.81,2-5.75,3.1-8.73l7.2.24c-.16.56-.36,1.37-.68,2.45L103,15.29a25.66,25.66,0,0,0-.92-2.81l3-1.41A46.81,46.81,0,0,1,107,18.23l-2.29.72a28.1,28.1,0,0,1-.56,3.3c-.84,3-1.49,5.79-2.85,9.69-1.53,4.34-2.69,9.17-5.11,13.07ZM106,11.07l3.1-1.33a25.33,25.33,0,0,1,1.85,6.8L108,17.62A26.93,26.93,0,0,0,106,11.07Z" />
          <path d="M127.12,41.71c1.77-2.29,3.66-4.91,4.54-6.27,1.25-2.05,2.78-4,4.18-6.31-6.43,0-14.52.52-20.55,1.13L115,23.74c9.49-1,18.5-1.37,28.83-1.61a25,25,0,0,1,.44,6.55A72.72,72.72,0,0,1,140.51,36a103.32,103.32,0,0,1-6.35,9.53Zm-8.93-22.24L118,13.12c7.52-.4,15-.48,22.52-.4l.24,6.55C133,19.07,125.51,19.19,118.19,19.47Z" />
          <path d="M163.84,43.68c-3.82-1.09-8.08-2.73-10.94-3.86l3.5-6.51c3.46,1.13,7.12,2.73,10.7,3.58a69.45,69.45,0,0,0,11.42,1.61l.12,6.19C171.08,45,167.14,44.53,163.84,43.68ZM158,29.53l2.29-6.07c2.69.6,5.23,1.21,8.69,2.29a47.63,47.63,0,0,1,8.4,3l-1.81,5.63C172.12,33.67,161.51,30.65,158,29.53Zm1.81-11.38,3-6.39c3,1,16.13,4.74,18.94,6.11l-1.85,6.27A181.62,181.62,0,0,1,159.82,18.15Z" />
          <path d="M189.7,21.77l1.25-7c4.42.36,8.65,1,13.15,1.45l-1,7C198.59,22.65,193.84,22.37,189.7,21.77Zm.84,17.45c3.54-1.89,6.35-3.1,8.53-4.5s5.59-3.94,7.68-5.63a119,119,0,0,0,10.86-9.37l4.91,5.79c-3,3-7.2,6.51-10.54,9.65a59.85,59.85,0,0,1-13.47,9,37.46,37.46,0,0,1-5,2.17Z" />
          <path d="M239.93,42.48a84.41,84.41,0,0,0,3.78-9.77,48.71,48.71,0,0,0,2.73-10.13,51.27,51.27,0,0,0-8.36.6c-.84,2.13-3,8.4-3.66,9.61l-6.76-1.53c1-3.58,2.05-6.63,3-9.61.92-2.81,2-5.75,3.1-8.73l7.2.24c-.16.56-.36,1.37-.68,2.45l13.11-.32a25.66,25.66,0,0,0-.92-2.81l3-1.41a46.81,46.81,0,0,1,1.89,7.16l-2.29.72a28.1,28.1,0,0,1-.56,3.3c-.84,3-1.49,5.79-2.85,9.69-1.53,4.34-2.69,9.17-5.11,13.07Zm16.45-31.41,3.1-1.33a25.33,25.33,0,0,1,1.85,6.8l-2.9,1.09A26.93,26.93,0,0,0,256.37,11.07Z" />
          <path d="M271.34,41.55a7.08,7.08,0,0,1-3.26-3,11.22,11.22,0,0,1-1.25-7.88,10.76,10.76,0,0,1,1.61-4.82,20.21,20.21,0,0,1,3.3-4.38,20.81,20.81,0,0,1,7-4.79,17.54,17.54,0,0,1,8.81-1,23.6,23.6,0,0,1,3.86,1.45,13,13,0,0,1,3.42,2.73,12.66,12.66,0,0,1,2.33,4.18,17.71,17.71,0,0,1,1,5,26.82,26.82,0,0,1-.8,7A21.34,21.34,0,0,1,294,43.12l-5.59-2.21a38.3,38.3,0,0,0,2.29-5.15,22.17,22.17,0,0,0,1.21-5.31,24.56,24.56,0,0,0-.48-4.66c-.84-2.09-1.93-3.06-3.22-3.1a61.28,61.28,0,0,1-1.61,7.6,15.55,15.55,0,0,1-2.09,4.78A16,16,0,0,1,278,41.35a7.69,7.69,0,0,1-2.81.88A10.65,10.65,0,0,1,271.34,41.55Zm8.28-10.37c.52-1.21,1-2.61,1.41-3.82.56-2,.88-3.94,1.33-5.31l-1.85.24a11.89,11.89,0,0,0-4.71,3.42A9.6,9.6,0,0,0,273.75,29a12.17,12.17,0,0,0-.84,4.75,2,2,0,0,0,1.81,1.81l1.25-.44A8.68,8.68,0,0,0,279.62,31.18Z" />
          <path d="M309.58,33.23c4.51-.27,12-.6,15.83-.54l.11-16.26c-4.24,0-8.76.05-13.6.11L312.35,7c11.58-.49,23.55-.33,35.51.22l-.33,8.92c-4,.05-8,.11-12.67.11l-.05,16.31c5.76.22,10.39,0,14.47.16l.11,9.08c-13.21.11-26.54.16-39.86,0Z" />
          <path d="M377.39,41.82c2.39-3.1,4.95-6.63,6.15-8.48,1.69-2.77,3.75-5.38,5.66-8.54-8.7-.05-19.63.71-27.79,1.52L361,17.51c12.83-1.36,25-1.85,39-2.18a33.78,33.78,0,0,1,.6,8.86,98,98,0,0,1-5.11,9.9A139.72,139.72,0,0,1,386.91,47ZM365.32,11.75l-.22-8.59c10.17-.54,20.34-.65,30.45-.54l.33,8.86C385.33,11.2,375.22,11.37,365.32,11.75Z" />
          <path d="M411.12,19.85a164,164,0,0,1,20.72-2.12c6.91-.16,13.81-.38,21-.33l-.33,9.41c-6.85.38-14.58.82-21.26,1.14s-14,.76-20.45,1Z" />
          <path d="M463.92,43.07a9.6,9.6,0,0,1-2.28-5.55,13.73,13.73,0,0,1,1.41-4.68,13.25,13.25,0,0,1,2.67-4,13.58,13.58,0,0,1,4-3,39.37,39.37,0,0,1,5.33-1.9L475,18.54l-11.53.54v-8l11.42-.82-.11-8.92,7.88.11.27,8.38,8.86.11-.05,7.78-8.54.6.16,4.35a56.74,56.74,0,0,1,8.81,1.14,17.13,17.13,0,0,1,6.74,3.92c2.28,2,3.1,4.24,3.21,7.78a19.07,19.07,0,0,1-1.63,5.6,19.79,19.79,0,0,1-2.72,4.95l-8.21-2.83a24.68,24.68,0,0,0,3-7.72c.11-1.58-.43-2.88-1.47-3.32-2-1.09-4.95-2-7.29-1.14l-.33,6.63c-.38,3.64-1.31,6.31-3.21,7.29a16.93,16.93,0,0,1-8.37,1.3C469.09,45.74,465.88,45.14,463.92,43.07Zm11.26-10.5A4.73,4.73,0,0,0,472,33.83a4.52,4.52,0,0,0-1,3.7c.43.76,2.67,1.25,3.7.43C475.4,37.36,475.29,35.08,475.18,32.58ZM496.5,9.79l8.32-1.3c.38,2.34,1.41,10.06,1.58,14.3l-8,1.63A135.83,135.83,0,0,0,496.5,9.79Z" />
          <path d="M516.35,45.63c.6-4.35,1.47-10.5,2.28-14.85s1.41-9.35,2-13.7l-7.94.76.05-7.94c2.5-.33,5.55-1,8.43-1.3.6-2.77.82-5.17,1.58-7.78l8.86.71-1,6.36a135.87,135.87,0,0,1,14.08-.54l-.05,8c-5.76.49-11.53.44-15,.92,0,0-.92,5.49-2.61,16.37-.54,3.26-1,9.84-2,13.05Zm21.92-.71a31.62,31.62,0,0,1-8.65-4.08l4-6.63c4,2.5,6.63,3.37,10.71,3.64a63.94,63.94,0,0,0,11-.22v7.61A50.69,50.69,0,0,1,538.27,44.92Zm-4.19-17-.16-7.5a197.16,197.16,0,0,1,20.23-.54l.22,7.72C550.72,27.79,539.63,28.22,534.08,28Z" />
          <path d="M579.93,45.36a28.68,28.68,0,0,0,3.48-7.56l-4.73.16a7.65,7.65,0,0,1-4.46-2.45,10.54,10.54,0,0,1-2.07-7.5,11.53,11.53,0,0,1,2.61-6.53A14.38,14.38,0,0,1,579,18.82a10.45,10.45,0,0,1,4.84-.38l1.3.22.44-2c-2.28,0-11.53.22-20.39.33V8.59c6.69-.38,12.94-.38,20.12-.65a76.94,76.94,0,0,0,0-7.94h9.35c.16,2.83,0,5.28,0,7.94h10.55v8.65c-3.59.11-7.12.16-10.66.27-.27,2.56-.38,4.89-1.41,6.91l.05,1-.22,1a12.6,12.6,0,0,1,1.14,5.6c-.33,2.83-.92,5.44-1.47,8a33.44,33.44,0,0,1-4,8.43Zm5.27-18.22a3.73,3.73,0,0,0-2.45-1.58c-1.52.27-2.12.54-2.61,1.36a5.28,5.28,0,0,0-.49,3.59l.27.82,2.83.54C584.61,31.11,584.93,28.82,585.2,27.14Z" />
          <path d="M616.53,43.4c-.71-1.52-1.2-11.69-1.36-17.51s.27-13.05.49-16.7l8.27-.33c-.16,6.36-.33,12.67-.38,17.89-.11,5.49.54,11.09.71,15.66Zm20.39.87a66.31,66.31,0,0,0,2.72-11.86A110.65,110.65,0,0,0,641,20.34c-2.83-.16-6.09.05-10.12.11l.05-8c1.36-.05,7.72-.22,10.39-.27a70.59,70.59,0,0,0,0-9.63L650.41,2c.22,2.77.22,6.14.27,9.84l6.91-.05-.11,8.54-7.07.22a110.94,110.94,0,0,1-1.63,13.16,73.36,73.36,0,0,1-3.15,12.13Z" />
          <path d="M668.15,24.64C667.39,21,664.78,10.55,664.56,8l9.14-2.28c.71,3.75,2.83,12.89,3.53,17ZM678.59,6l9.35-2c.38,2.88,2.56,10.28,3.53,15.34l-9,2.28C681.63,17.84,680,12.07,678.59,6Zm6.2,36.22c3.26-6.42,6.42-12.72,9-18.49A184.67,184.67,0,0,0,701.1,5l9.14,2.83c-2.12,7.61-4.84,13.32-7.45,19.47s-6.14,13.16-9,19Z" />
          <path d="M717.86,19.85a164.07,164.07,0,0,1,20.72-2.12c6.91-.16,13.81-.38,21-.33l-.33,9.41c-6.85.38-14.58.82-21.26,1.14s-14,.76-20.45,1Z" />
          <path d="M767.29,44.7a194.65,194.65,0,0,0,3.81-21.54c.76-6.31,1-12.07,1.79-19.47l9.73.05c-.33,6.85-.71,13.92-1.3,20.94a106.81,106.81,0,0,1-3.92,20.83Zm22.08.22c-.71-7.23-.33-16.1-.38-22.95,0-6.47.16-12.83.33-19.14l10-.49c-.38,6-.6,11-.54,16.37.05,4.57,0,10.66-.05,14.68,2.5-1.36,9.52-5,12.24-6.47l2.77,8.92c-1.85,1.41-9.52,7-14.41,9.35C796.17,45.3,791.87,45.46,789.37,44.92Z" />
          <path d="M834.9,3.21a56.46,56.46,0,0,1,11.75-.05c-.92,10.93-1.69,20.5-2.77,29.91H837C835.77,22.46,835.06,10,834.9,3.21Zm2.56,42.69a5,5,0,0,1-1.58-4,6.69,6.69,0,0,1,2.23-4,6.73,6.73,0,0,1,3.26-.65A4.5,4.5,0,0,1,844.68,40a6.44,6.44,0,0,1,0,3.37,5,5,0,0,1-2,3,5.07,5.07,0,0,1-2.45.33A7,7,0,0,1,837.45,45.9Z" />
        </g>
      </svg>
    </div>
  );
};
