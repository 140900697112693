/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RadiusArrowButton } from "components/atoms/RadiusArrowButton/RadiusArrowButton";
import { EngTitleDescription } from "components/molecules/EngTitleDescription/EngTitleDescription";
import {
  LabelRadiusTags,
  Tag,
} from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import React from "react";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";

type Props = {
  id: string;
  title: React.ReactNode;
  description: React.ReactNode;
  buttonLabel: React.ReactNode;
  buttonUrl: string;
  firstTags: {
    label: React.ReactNode;
    tags: Tag[];
  };
  secondTags: {
    label: React.ReactNode;
    tags: Tag[];
  };
  productImage: React.ReactNode;
  className?: string;
  productColor?: string;
  textColor?: string;
  buttonColor?: string;
  direction?: "left" | "right";
};

export const SoftwareIntroduction: React.FC<Props> = ({
  id,
  title,
  description,
  buttonLabel,
  buttonUrl,
  firstTags,
  secondTags,
  productImage,
  className,
  productColor,
  textColor,
  buttonColor,
  direction = "left",
}: Props) => {
  return (
    <section id={id} className={"" + (className ? ` ${className}` : "")}>
      <FullWidthOuter
        bgColor={productColor}
        cssOverride={css`
          padding-top: 3rem;
          overflow-x: hidden; // MEMO: スクロールアニメーション対策(表示前のアニメーション開始位置でoverflowしてしまうため)
          overflow-y: hidden; // MEMO: スクロールアニメーション対策(表示前のアニメーション開始位置でoverflowしてしまうため)
        `}
      >
        <BaseInner
          cssOverride={css`
            color: ${textColor};
          `}
        >
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.none}
            threshold={0.5}
            direction="bottom"
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                column-gap: 30px;
                flex-wrap: wrap;
                @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                  flex-wrap: nowrap;
                }
              `}
            >
              <div
                css={css`
                  align-self: flex-end;
                `}
              >
                {/* イメージ */}
                {direction === "right" && (
                  <div
                    css={css`
                      display: none;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        display: block;
                      }
                    `}
                  >
                    {productImage}
                  </div>
                )}
              </div>

              <div
                css={css`
                  padding: 50px 0;
                `}
              >
                {/* タイトルと説明 */}
                <EngTitleDescription
                  title={title}
                  description={description}
                  cssOverride={css`
                    margin-bottom: 1.25rem;
                  `}
                />
                {/* サービスサイトへのリンクボタン */}
                <div
                  css={css`
                    margin-bottom: 1.5rem;
                  `}
                >
                  <a
                    css={css`
                      display: block;
                      width: fit-content;
                    `}
                    href={buttonUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RadiusArrowButton
                      buttonColor={buttonColor}
                      color={textColor}
                    >
                      {buttonLabel}
                    </RadiusArrowButton>
                  </a>
                </div>
                {/* タグ1行目 */}
                <div
                  css={css`
                    margin-bottom: 0.75rem;
                  `}
                >
                  <LabelRadiusTags
                    label={firstTags?.label}
                    tags={firstTags?.tags}
                    backgroundColor={textColor}
                    textColor={productColor}
                  />
                </div>
                <div css={css``}>
                  {/* タグ2行目 */}
                  <LabelRadiusTags
                    label={secondTags?.label}
                    tags={secondTags?.tags}
                    backgroundColor={textColor}
                    textColor={productColor}
                  />
                </div>
              </div>
              {/* イメージ */}
              {direction === "left" && (
                <div
                  css={css`
                    align-self: flex-end;
                  `}
                >
                  {productImage}
                </div>
              )}
              {direction === "right" && (
                <div
                  css={css`
                    display: block;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      display: none;
                    }
                  `}
                >
                  {productImage}
                </div>
              )}
            </div>
          </FadeInOnScrollByComponent>
        </BaseInner>
      </FullWidthOuter>
    </section>
  );
};
