/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CircleArrowButton } from "components/atoms/CircleArrowButton/CircleArrowButton";
import { FadeInSequentialText } from "components/atoms/FadeInSequentialText/FadeInSequentialText";
import { GradationDividerTitle } from "components/atoms/GradationDividerTitle/GradationDividerTitle";
import { SubTitle } from "components/atoms/SubTitle/SubTitle";
import React from "react";
import { mixin } from "styles/mixin";
import { Theme, defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { CenteredOuter } from "utils/Wrapper/CenteredOuter/CenteredOuter";
import { CustomScroll } from "../../../utils/Wrapper/Scroll/CustomScroll";

type Props = {
  subTitleColor?: string;
  arrowColor?: string;
  circleColor?: string;
  title: string;
  subTitle: React.ReactNode;
  mainPhrase: {
    first: React.ReactNode;
    second: React.ReactNode;
  };
  description: React.ReactNode;
  scrollToID?: string;
  theme: Theme;
};
export const ProductHero: React.FC<Props> = ({
  subTitleColor = "#666666",
  arrowColor = "#ffffff",
  circleColor = "#666666",
  title,
  subTitle,
  mainPhrase,
  description,
  scrollToID = "",
  theme,
}: Props) => {
  return (
    <section>
      <CenteredOuter
        cssOverride={css`
          text-align: center;
        `}
      >
        <h1>
          <GradationDividerTitle
            cssOverride={css`
              margin-left: auto;
              margin-right: auto;
              margin-bottom: ${defaultTheme.spacing.title.bottom};
            `}
          >
            <FadeInSequentialText>{title}</FadeInSequentialText>
          </GradationDividerTitle>
        </h1>
        <div>
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.short}
            direction="spot"
          >
            <h2>
              <SubTitle
                cssOverride={css`
                  margin-bottom: ${theme.spacing.xs};
                `}
                color={subTitleColor}
              >
                {subTitle}
              </SubTitle>
            </h2>
            <p
              css={css`
                ${mixin.text.jpTitle};
                display: inline-block;
                font-weight: 700;
              `}
            >
              {mainPhrase.first}
            </p>
            <p
              css={css`
                ${mixin.text.jpTitle};
                display: inline-block;
                font-weight: 700;
                margin-bottom: 2.5rem;
              `}
            >
              {mainPhrase.second}
            </p>
          </FadeInOnScrollByComponent>
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.medium}
            direction="bottom"
          >
            <p
              css={css`
                display: inline-block;
                ${mixin.text.body};
                text-align: left;
                margin-bottom: 2.5rem;
              `}
            >
              {description}
            </p>
            <CustomScroll
              cssOverride={css`
                margin: 0 auto;
              `}
              toId={scrollToID}
            >
              <CircleArrowButton
                direction="down"
                circleColor={circleColor}
                arrowColor={arrowColor}
                circleSize={16}
                arrowSize={4}
              />
            </CustomScroll>
          </FadeInOnScrollByComponent>
        </div>
      </CenteredOuter>
    </section>
  );
};
