/** @jsxImportSource @emotion/react */
import { BodyText } from "components/atoms/BodyText/BodyText";
import { ContactDialogModal } from "components/organisms/ContactDialogModal/ContactDialogModal";
import { ContactFormData } from "components/organisms/ContactForm/ContactForm";
import {
  ContactTemplate,
  ContactTemplateSlotProps,
} from "components/templates/ContactTemplate/ContactTemplate";
import { useContact } from "hooks/useContact";
import { useTheme } from "hooks/useTheme";
import React, { useCallback, useMemo } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { retryAsyncFunction } from "utils/api";
import { constants } from "utils/constants";

export const ContactFormPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const { setContact, sendMail } = useContact();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // 戻るボタン
  const handleBackButtonClick = useCallback(() => {
    window.history.back();
  }, []);

  // お問い合わせフォーム
  const [{ open, isSending, isSendError }, setFormState] = React.useState<{
    open: boolean;
    isSending: boolean;
    isSendError?: boolean;
  }>({
    open: false,
    isSending: false,
    isSendError: false,
  });
  const formValues = React.useRef<ContactFormData>();

  const handleClose = useCallback(() => {
    formValues.current = undefined;
    setFormState((prevState) => ({
      open: false,
      isSending: false,
      isSendError: false,
    }));
  }, []);

  const handleSubmit = useCallback((data: ContactFormData) => {
    formValues.current = data;
    setFormState((prevState) => ({
      ...prevState,
      open: true,
      isSending: false,
      isSendError: false,
    }));
  }, []);

  const handleOKButtonClick = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    const _sendMail = async (attempts = 3) => {
      setFormState((prevState) => ({
        ...prevState,
        isSending: true,
        isSendError: false,
      }));

      const actionToken = await executeRecaptcha("contact"); // MEMO: フロントからトークン発行する際のアクション名。名称は自由だが、受け取るバックエンド側で一致させる。
      if (actionToken) {
        if (formValues.current === undefined) {
          throw new Error("formValues is undefined");
        }

        // メール送信
        const { data, error } = await sendMail({
          request: {
            recaptchaToken: actionToken,
            company: formValues.current.companyName,
            email: formValues.current.email,
            name: formValues.current.name,
            message: formValues.current.inquiry,
          },
        });
        if (error) {
          throw new Error(error.result.message);
        }

        formValues.current = undefined;
        setFormState((prevState) => ({
          ...prevState,
          open: false,
          isSending: false,
        }));
        setContact({ isSendSuccess: true });
      }
    };

    try {
      await retryAsyncFunction({
        asyncFunction: _sendMail,
      });
    } catch (e) {
      setFormState((prevState) => ({
        ...prevState,
        isSending: false,
        isSendError: true,
      }));
      setContact({ isSendSuccess: false });
    }
  }, [executeRecaptcha]);

  const contactTemplateSlotProps: ContactTemplateSlotProps = useMemo(
    () => ({
      title: constants.pages.contact.title,
      subText: "お問い合わせ",
      bgColor: "inherit",
      backButton: {
        text: "戻る",
        onClick: handleBackButtonClick,
        color: theme.palette.text,
      },
      subTitleColor: theme.palette.gray.text,
      description: (
        <BodyText>
          KAWAICHI(カワイチ)のWebサイトにご訪問いただき、ありがとうございます。
          <br />
          お問い合わせは、下記フォームより必要項目をご記入ください。
          内容に間違いがなければ、ページ最下部の送信ボタンを押してください。
          <br />
          なお、大変恐れ入りますが、返信の有無は内容により判断させていただきます。ご了承ください。
        </BodyText>
      ),
      form: {
        onSubmit: handleSubmit,
      },
      dialog: (
        <ContactDialogModal
          open={open}
          onClose={handleClose}
          loading={isSending}
          error={isSendError}
          onOKButtonClick={handleOKButtonClick}
        />
      ),
    }),
    [mode, theme, open, isSending, isSendError]
  );

  return (
    <ContactTemplate
      id={constants.pages.contact.id}
      theme={theme}
      slotProps={contactTemplateSlotProps}
    />
  );
};
