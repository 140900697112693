import { RETRY_ATTEMPTS, RETRY_INTERVAL } from "./config";

export const retryAsyncFunction = async ({
  asyncFunction,
  attempts = RETRY_ATTEMPTS,
  interval = RETRY_INTERVAL,
}: {
  asyncFunction: () => Promise<any>;
  attempts?: number;
  interval?: number;
}) => {
  for (let i = 0; i < attempts; i++) {
    try {
      return await asyncFunction();
    } catch (e) {
      if (i < attempts - 1) {
        console.error(
          `Attempt ${i + 1} failed. Retrying in ${interval} ms...`,
          e
        );
        await new Promise((resolve) => setTimeout(resolve, interval));
      } else {
        console.error("All attempts failed. Please try again later.", e);
        throw e;
      }
    }
  }
};
