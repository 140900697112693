/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EngTitleSubText } from "components/atoms/EngTitleSubText/EngTitleSubText";
import { FadeInSequentialText } from "components/atoms/FadeInSequentialText/FadeInSequentialText";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import {
  ContactForm,
  ContactFormProps,
} from "components/organisms/ContactForm/ContactForm";
import { Theme, defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";

export type ContactTemplateSlotProps = {
  title: string;
  subText: string;
  subTitleColor: string;
  bgColor: string;
  backButton: {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    color: string;
  };
  description: React.ReactNode;
  form: ContactFormProps;
  dialog: React.ReactNode;
};

type Props = {
  id: string;
  theme: Theme;
  slotProps: ContactTemplateSlotProps;
};

export const ContactTemplate: React.FC<Props> = ({ id, theme, slotProps }) => {
  const {
    title,
    subText,
    subTitleColor,
    bgColor,
    backButton,
    description,
    form,
    dialog,
  } = slotProps;

  return (
    <>
      <BaseWrapper id={id}>
        <section>
          <FullWidthOuter
            pt={defaultTheme.spacing.base.top}
            bgColor={bgColor}
            cssOverride={css``}
          >
            <BaseInner
              width="100%"
              pt="2.5rem"
              pb="2.5rem"
              cssOverride={css`
                text-align: left;
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-bottom: ${defaultTheme.spacing.xxs};
                    `}
                  >
                    <LongArrowButton
                      onClick={backButton.onClick}
                      direction="left"
                      textColor={backButton.color}
                      arrowColor={backButton.color}
                    >
                      <span>{backButton.text}</span>
                    </LongArrowButton>
                  </div>
                </FadeInOnScrollByComponent>

                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-bottom: ${defaultTheme.spacing.title.bottom};
                    `}
                  >
                    <h1>
                      <EngTitleSubText
                        theme={theme}
                        title={
                          <FadeInSequentialText
                            initialDelay={defaultTheme.delay.none}
                          >
                            {title}
                          </FadeInSequentialText>
                        }
                        subText={
                          <FadeInOnScrollByComponent
                            delay={defaultTheme.delay.medium}
                            direction="spot"
                          >
                            {subText}
                          </FadeInOnScrollByComponent>
                        }
                        subTextColor={subTitleColor}
                      />
                    </h1>
                  </div>
                </FadeInOnScrollByComponent>

                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="bottom"
                >
                  <div
                    css={css`
                      margin-bottom: ${defaultTheme.spacing.sm};
                    `}
                  >
                    {description}
                  </div>
                </FadeInOnScrollByComponent>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <ContactForm onSubmit={form.onSubmit} />
                </FadeInOnScrollByComponent>
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>
      </BaseWrapper>
      {dialog}
    </>
  );
};
