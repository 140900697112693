/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { FillRadiusButton } from "components/atoms/FillRadiusButton/FillRadiusButton";
import { GradationDividerMediumTitle } from "components/atoms/GradationDividerMediumTitle/GradationDividerMediumTitle";
import { ImageFadeLoader } from "components/atoms/ImageFadeLoader/ImageFadeLoader";
import { InformationLinkText } from "components/atoms/InformationLinkText/InformationLinkText";
import { JpTitle } from "components/atoms/JpTitle/JpTitle";
import { LargeBodyText } from "components/atoms/LargeBodyText/LargeBodyText";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { TalkStockAwsPortfolioTechnicalStack } from "components/molecules/TalkStockAwsPortfolioTechnicalStack/TalkStockAwsPortfolioTechnicalStack";
import { TalkStockPersonalDevelopmentTechnicalStack } from "components/molecules/TalkStockPersonalDevelopmentTechnicalStack/TalkStockPersonalDevelopmentTechnicalStack";
import {
  ProductIntroductionTemplate,
  ProductIntroductionTemplateSlotProps,
  ProductIntroductionTemplateTechnicalStack,
} from "components/templates/ProductIntroductionTemplate/ProductIntroductionTemplate";
import {
  ProductTalkStockPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { usePreloadImages } from "hooks/usePreloadImages";
import { useProduct } from "hooks/useProduct";
import { useTheme } from "hooks/useTheme";
import { ProductIntroductionSection } from "organisms/ProductIntroductionSection/ProductIntroductionSection";
import React, { memo, useCallback, useMemo, useState } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme, Theme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { constants } from "utils/constants";
import { Columns1to2to3Wrapper } from "utils/Wrapper/Columns1to2to3Wrapper/Columns1to2to3Wrapper";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { MainLayout } from "../MainLayout/MainLayout";

type Tabs = "AWSPortfolio" | "PersonalDevelopment";

type ImageProps = {
  handleImageCardClick: (src: string, alt: string) => void;
  theme: Theme;
};

type DetailProps = {
  handleImageCardClick: (src: string, alt: string) => void;
  theme: Theme;
};

const Detail01: React.FC<DetailProps> = memo(
  ({ handleImageCardClick, theme }) => {
    const detailRequireSource = [
      require("assets/images/product/talkstock/main-features-1.gif"),
    ];
    const {
      preloadedSources: preloadedDetailSources,
      isPreloading: isDetailPreloading,
    } = usePreloadImages(detailRequireSource);
    const preloadedDetailRequireSource = preloadedDetailSources[0];

    return isDetailPreloading ? (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <ImageFadeLoader
          color="#AAAAAA"
          height={200}
          bgColor={defaultTheme.palette.common.white}
          cssOverride={css`
            border-radius: ${defaultTheme.borderRadius.base};
          `}
        />
      </FadeInOnScrollByComponent>
    ) : (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <button
          type="button"
          onClick={() =>
            handleImageCardClick(
              preloadedDetailRequireSource,
              "生成AIによる質問への3つの回答"
            )
          }
        >
          <div
            css={css`
              ${mixin.boxShadow};
              ${mixin.hoverTransitionOpacityEffect};
              overflow: hidden;
            `}
          >
            <img
              src={preloadedDetailRequireSource}
              alt={"生成AIによる質問への3つの回答"}
              css={css`
                border: ${defaultTheme.borderWidth.sm}px solid;
                border-color: ${theme.palette.gray.background};
                border-radius: ${defaultTheme.borderRadius.base};
                width: 100%;
                max-width: 439px;
                min-width: 320px;
              `}
            />
          </div>
        </button>
      </FadeInOnScrollByComponent>
    );
  }
);

const Detail02: React.FC<DetailProps> = memo(
  ({ handleImageCardClick, theme }) => {
    const detailRequireSource = [
      require("assets/images/product/talkstock/main-features-2.webp"),
    ];
    const {
      preloadedSources: preloadedDetailSources,
      isPreloading: isDetailPreloading,
    } = usePreloadImages(detailRequireSource);
    const preloadedDetailRequireSource = preloadedDetailSources[0];

    return isDetailPreloading ? (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <ImageFadeLoader
          height={200}
          color="#AAAAAA"
          bgColor={defaultTheme.palette.common.white}
          cssOverride={css`
            width: 100%;
            max-width: 0px;
            border-radius: ${defaultTheme.borderRadius.base};
          `}
        />
      </FadeInOnScrollByComponent>
    ) : (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <button
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          type="button"
          onClick={() =>
            handleImageCardClick(
              preloadedDetailRequireSource,
              "投稿された質問へのコメント機能"
            )
          }
        >
          <img
            src={preloadedDetailRequireSource}
            alt={"投稿された質問へのコメント機能"}
            css={css`
              ${mixin.boxShadow};
              ${mixin.hoverTransitionOpacityEffect};
              border: ${defaultTheme.borderWidth.sm}px solid;
              border-color: ${theme.palette.gray.background};
              border-radius: ${defaultTheme.borderRadius.base};
              width: 100%;
              max-width: 439px;
              min-width: 320px;
            `}
          />
        </button>
      </FadeInOnScrollByComponent>
    );
  }
);

const AWSPortfolioInfra: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail01RequireSource = [
    require("assets/images/product/talkstock/aws-infra-app.webp"),
  ];
  const {
    preloadedSources: preloadedDetail01Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail01RequireSource);
  const preloadedDetailRequireSource = preloadedDetail01Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(preloadedDetailRequireSource, "インフラ構成図")
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"インフラ構成図"}
          css={css`
            border: ${defaultTheme.borderWidth.sm}px solid;
            border-color: ${theme.palette.gray.background};
            border-radius: ${defaultTheme.borderRadius.base};
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 520px;
            }
          `}
        />
      </div>
    </button>
  );
};

const PersonalDevelopmentInfra: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail01RequireSource = [
    require("assets/images/product/talkstock/personal-infra-app.webp"),
  ];
  const {
    preloadedSources: preloadedDetail01Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail01RequireSource);
  const preloadedDetailRequireSource = preloadedDetail01Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(preloadedDetailRequireSource, "インフラ構成図")
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"インフラ構成図"}
          css={css`
            border: ${defaultTheme.borderWidth.sm}px solid;
            border-color: ${theme.palette.gray.background};
            border-radius: ${defaultTheme.borderRadius.base};
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 520px;
            }
          `}
        />
      </div>
    </button>
  );
};

const AWSPortfolioWorkflow01: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail02RequireSource = [
    require("assets/images/product/talkstock/aws-workflow-front.webp"),
  ];
  const {
    preloadedSources: preloadedDetail02Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail02RequireSource);
  const preloadedDetailRequireSource = preloadedDetail02Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "ワークフロー図 フロントエンド"
        )
      }
    >
      <img
        src={preloadedDetailRequireSource}
        alt={"ワークフロー図 フロントエンド"}
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
          width: 100%;
          max-width: 100%;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            max-width: 360px;
          }
        `}
      />
    </button>
  );
};

const AWSPortfolioWorkflow02: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail03RequireSource = [
    require("assets/images/product/talkstock/aws-workflow-back.webp"),
  ];
  const {
    preloadedSources: preloadedDetail03Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail03RequireSource);
  const preloadedDetailRequireSource = preloadedDetail03Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "ワークフロー図 バックエンド"
        )
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
          background-color: ${defaultTheme.palette.common.black};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"ワークフロー図 バックエンド"}
          css={css`
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 360px;
            }
          `}
        />
      </div>
    </button>
  );
};

const AWSPortfolioWorkflow03: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail04RequireSource = [
    require("assets/images/product/talkstock/aws-workflow-iac.webp"),
  ];
  const {
    preloadedSources: preloadedDetail04Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail04RequireSource);
  const preloadedDetailRequireSource = preloadedDetail04Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(preloadedDetailRequireSource, "ワークフロー図 IaC")
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
          background-color: ${defaultTheme.palette.common.black};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"ワークフロー図 IaC"}
          css={css`
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 360px;
            }
          `}
        />
      </div>
    </button>
  );
};

const PersonalDevelopmentWorkflow01: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail02RequireSource = [
    require("assets/images/product/talkstock/personal-workflow-front.webp"),
  ];
  const {
    preloadedSources: preloadedDetail02Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail02RequireSource);
  const preloadedDetailRequireSource = preloadedDetail02Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "ワークフロー図 フロントエンド"
        )
      }
    >
      <img
        src={preloadedDetailRequireSource}
        alt={"ワークフロー図 フロントエンド"}
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
          width: 100%;
          max-width: 100%;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            max-width: 360px;
          }
        `}
      />
    </button>
  );
};

const PersonalDevelopmentWorkflow02: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail03RequireSource = [
    require("assets/images/product/talkstock/personal-workflow-back.webp"),
  ];
  const {
    preloadedSources: preloadedDetail03Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail03RequireSource);
  const preloadedDetailRequireSource = preloadedDetail03Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "ワークフロー図 バックエンド"
        )
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
          background-color: ${defaultTheme.palette.common.black};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"ワークフロー図 バックエンド"}
          css={css`
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 360px;
            }
          `}
        />
      </div>
    </button>
  );
};

const PersonalDevelopmentWorkflow03: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail03RequireSource = [
    require("assets/images/product/talkstock/personal-workflow-iac.webp"),
  ];
  const {
    preloadedSources: preloadedDetail03Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail03RequireSource);
  const preloadedDetailRequireSource = preloadedDetail03Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(preloadedDetailRequireSource, "ワークフロー図 IaC")
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
          background-color: ${defaultTheme.palette.common.black};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"ワークフロー図 バックエンド"}
          css={css`
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 360px;
            }
          `}
        />
      </div>
    </button>
  );
};

const ProductTalkStockPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const {
    imageSources: { productTalkStock },
  } = useImageSources();
  const { open, src, alt, handleImageCardClick, handleImageModalClose } =
    useProduct();

  const [tabs, setTabs] = useState<Tabs>("AWSPortfolio");

  const handleAWSPortfolioButtonClick = useCallback(() => {
    setTabs("AWSPortfolio");
  }, []);

  const handlePersonalDevelopmentButtonClick = useCallback(() => {
    setTabs("PersonalDevelopment");
  }, []);

  const awsPortfolioTechnicalStack: ProductIntroductionTemplateTechnicalStack =
    useMemo(
      () => ({
        icons: (
          <TalkStockAwsPortfolioTechnicalStack
            bgColor={defaultTheme.palette.contrastText}
          />
        ),
        table: {
          data: [
            [
              "フロントエンド",
              "Next.js 13, TypeScript, bulletproof-react(一部カスタマイズ)",
            ],
            [
              "バックエンド",
              "Echo(Golang), REST API, MySQL, Amazon Cognito, OpenAI API(エンドポイント: Chat Completions API, モデル: GPT-4o mini), microCMS(お知らせ・リリースノート管理), SendGrid(メール送信)",
            ],
            [
              "インフラ",
              "AWS(ECS Fargate / Aurora RDS / S3 / CloudFront / Lambda@Edge / Route53 / ACM 等), Docker",
            ],
            ["プロビジョニング", "Terraform"],
            ["CI/CD", "GitHub Actions"],
            [
              "主要パッケージ等",
              <>
                <span>
                  【フロント】Redux, MUI, Axios, SWR, React Hook Form, Zod,
                  react-content-loader(スケルトンスクリーン)
                </span>
                <br />
                <span>
                  【バック】Gorm, golang-migrate, go-playground/validator,
                  crypto, logrus
                </span>
              </>,
            ],
            [
              "品質保証",
              "Jest, React Testing Library, Storybook, Chromatic, Swagger(コードファーストアプローチ)",
            ],
            ["ビルド", "SSG"],
            ["コーディング規約", "ESLint & Prettier, Husky & lint-staged"],
            [
              "その他",
              "GoogleTagManager, GoogleAnalytics(GA4), GoogleSearchConsole, Illustrator",
            ],
          ],
        },
      }),
      []
    );

  const personalDevelopmentTechnicalStack: ProductIntroductionTemplateTechnicalStack =
    useMemo(
      () => ({
        icons: (
          <TalkStockPersonalDevelopmentTechnicalStack
            bgColor={defaultTheme.palette.contrastText}
          />
        ),
        table: {
          data: [
            [
              "フロントエンド",
              "Next.js 13, TypeScript, bulletproof-react(一部カスタマイズ)",
            ],
            [
              "バックエンド",
              "Echo(Golang), REST API, MySQL, Amazon Cognito, OpenAI API(エンドポイント: Chat Completions API, モデル: GPT-4o mini), microCMS(お知らせ・リリースノート管理), SendGrid(メール送信)",
            ],
            ["インフラ", "Vercel, fly.io, Docker"],
            ["プロビジョニング", "Terraform"],
            ["CI/CD", "GitHub Actions"],
            [
              "主要パッケージ等",
              <>
                <span>
                  【フロント】Redux, MUI, Axios, SWR, React Hook Form, Zod,
                  react-content-loader(スケルトンスクリーン)
                </span>
                <br />
                <span>
                  【バック】Gorm, golang-migrate, go-playground/validator,
                  crypto, logrus
                </span>
              </>,
            ],
            [
              "品質保証",
              "Jest, React Testing Library, Storybook, Swagger(コードファーストアプローチ)",
            ],
            ["ビルド", "SSG"],
            ["コーディング規約", "ESLint & Prettier, Husky & lint-staged"],
            [
              "その他",
              "GoogleTagManager, GoogleAnalytics(GA4), GoogleSearchConsole, Illustrator",
            ],
          ],
        },
      }),
      []
    );

  const slotProps: ProductIntroductionTemplateSlotProps = useMemo(
    () => ({
      images: {
        product: {
          src: productTalkStock.brandBanner,
          alt: "トークストック",
        },
      },
      title: (
        <JpTitle
          cssOverride={css`
            margin-bottom: ${theme.spacing.xxs};
          `}
        >
          トークストック
        </JpTitle>
      ),
      subText: "WEB SERVICE",
      description: (
        <BodyText>
          日常のあらゆるお困りごとを、AIに向けて質問し回答を得て、それらをほかの利用者が評価しあって、お困りごとを解決していく「トーク」の共有サービスです
        </BodyText>
      ),
      information: (
        <InformationLinkText
          label="WEB: "
          href="https://www.talkstock.jp/"
          color={theme.palette.text}
          showNewWindowIcon
        />
      ),
      contentHeaderSlot: (
        <div>
          <div
            css={css`
              ${mixin.text.description};
              font-weight: 700;
              margin-bottom: ${defaultTheme.spacing.xs};
            `}
          >
            表示しているページ(クリック又はタッチで切り替え)
          </div>
          <div
            css={css`
              display: flex;
              column-gap: ${defaultTheme.spacing.xs};
              margin-bottom: ${defaultTheme.spacing.lg};
            `}
          >
            <FillRadiusButton
              onClick={handleAWSPortfolioButtonClick}
              width={26}
              height={22}
              backgroundColor={
                tabs === "AWSPortfolio"
                  ? theme.palette.primary
                  : theme.palette.gray.button[100]
              }
              textColor={theme.palette.contrastText}
              cssOverride={css`
                font-weight: 700;
                ${mixin.text.body};
                ${mixin.disableLineHeight};
                padding: 1.25rem 1.5rem;
                @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                  ${mixin.text.body};
                  padding: 1.5rem 2rem;
                }
                transition: 0.2s;
                ${tabs === "AWSPortfolio"
                  ? `cursor: default;`
                  : "cursor: pointer;"}
                ${tabs === "PersonalDevelopment"
                  ? `&:hover {
                      transition: none;
                      background-color: ${theme.palette.primary};
                    }`
                  : `&:hover {
                      transition: 0.2s;
                      background-color: ${theme.palette.primary};
                    }`}
              `}
            >
              <div>AWSポートフォリオ用</div>
            </FillRadiusButton>
            <FillRadiusButton
              onClick={handlePersonalDevelopmentButtonClick}
              width={26}
              height={22}
              backgroundColor={
                tabs === "PersonalDevelopment"
                  ? theme.palette.primary
                  : theme.palette.gray.button[100]
              }
              textColor={theme.palette.contrastText}
              cssOverride={css`
                font-weight: 700;
                ${mixin.text.body};
                ${mixin.disableLineHeight};
                padding: 1.25rem 1.5rem;
                @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
                  ${mixin.text.body};
                  padding: 1.5rem 2rem;
                }
                transition: 0.2s;
                ${tabs === "PersonalDevelopment"
                  ? `cursor: default;`
                  : "cursor: pointer;"}
                ${tabs === "PersonalDevelopment"
                  ? `&:hover {
                      transition: none;
                      background-color: ${theme.palette.primary};
                    }`
                  : `&:hover {
                      transition: 0.2s;
                      background-color: ${theme.palette.primary};
                    }`}
              `}
            >
              <div>個人開発用</div>
            </FillRadiusButton>
          </div>
        </div>
      ),
      technicalStack:
        tabs === "AWSPortfolio"
          ? awsPortfolioTechnicalStack
          : personalDevelopmentTechnicalStack,
      slot1: (
        <ProductIntroductionSection
          title="主な機能"
          theme={theme}
          bgColor={theme.palette.background}
          slot1={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    生成AIによる質問への3つの回答
                  </GradationDividerMediumTitle>
                  <BodyText>
                    「カテゴリー」や「トークの相手」をボタンで選び、入力フォームに質問内容を入力し「聞いてみる」ボタンを押すことで、トークアシスタント(生成AI)から3つの選択肢をもらうことができます。
                    <br />
                    その中から「いいね」と感じた回答を選ぶことで質問が公開され、ユーザーからのコメント・反応を待つことができます。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      text-align: right;
                    }
                  `}
                >
                  <Detail01
                    handleImageCardClick={handleImageCardClick}
                    theme={theme}
                  />
                </div>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              leftComponent={
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      text-align: right;
                    }
                  `}
                >
                  <Detail02
                    handleImageCardClick={handleImageCardClick}
                    theme={theme}
                  />
                </div>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    投稿された質問へのコメント機能
                  </GradationDividerMediumTitle>
                  <BodyText>
                    サービスを利用する全てのユーザーは、ログイン不要で投稿されたトークに対して自由にコメントをすることができます。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot2: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="開発背景・工夫した点"
          theme={theme}
          bgColor={theme.palette.contrastText}
          slot1={
            <Columns1to2Wrapper
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    開発背景
                  </GradationDividerMediumTitle>
                  <BodyText>
                    私たちが日常を過ごすなかで、
                    コミュニケーションにまつわる悩みは日々誰しも抱えていると思います。
                    たとえば、
                    「色んな人と交流したいけど、どんな話題を話すのがいいだろうか」
                    「あのときの私の言い方、相手を不快にさせてしまわなかっただろうか」
                    など考えたことはありませんか？
                  </BodyText>
                  <BodyText>
                    <br />
                    AIが勢いよく発展している近年ですが、そんなAIであっても解決の難しい課題があります。
                    <br />
                    それは、これらの「トーク」です。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right;
                      }
                    `}
                  >
                    <img
                      src={productTalkStock.ingenuity1_1}
                      alt={"開発背景-1"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 600px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right;
                      }
                    `}
                  >
                    <img
                      src={productTalkStock.ingenuity1_2}
                      alt={"開発背景-2"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 600px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <BodyText>
                    従来はQ&AサイトやSNS、質問箱など、ユーザー同士で直接解決しあっていました。
                    <br />
                    しかし、Q&Aサイトは文章を考えて投稿するのがやや面倒だったり、
                    SNSはフォロワーの評価やいいねの反応が気になってしまったり、
                    といったデメリットがあります。
                    <br />
                  </BodyText>
                  <BodyText>
                    <br />
                    そこで、それを解消すべく、ユーザーとのあいだにAIを挟むことで気軽に質問し、それを人が見て感じて評価できるサービスのアイデアが浮かびました。
                    <br />
                    AI相手なら気軽につぶやける。まずは"AI"からすぐに、いくつかのざっくりとした回答をもらえる。
                    <br />
                    そして、あとからそのつぶやいた質問を見た"ヒト"が具体的なコメントや反応をくれる。
                    <br />
                    私はアイデアを実現するため、AIとヒトが共存できるサービスの開発をすることにしました。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot3={
            <Columns1to2Wrapper
              mt={defaultTheme.spacing.lg}
              leftSize={6}
              rightSize={4}
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productTalkStock.ingenuity2}
                      alt={"工夫した点"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 600px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    工夫した点
                  </GradationDividerMediumTitle>
                  <BodyText>
                    UI/UX向上のため、ボタンで質問のカテゴリーとトークの相手を選択できるようにし、ユーザーが入力する文字数や負担をなるべく減らすようにしています。
                  </BodyText>
                  <BodyText>
                    <br />
                    また、いいね機能はあえて実装せず、一覧からクリックした時点で"そのユーザーは一定以上の興味がある"と判断し、「閲覧数」を表示するようにしています。
                    <br />
                    理由として、Q&Aサイトの「役に立った」などのボタンは、意外と押すまでのハードルがあるように感じ、
                    また、そもそも押すにはログインが必要であるサービスが多く、あまり使われない機能だろうなと考えたためです。
                  </BodyText>
                  <BodyText>
                    <br />
                    お知らせ・リリースノートはmicroCMSを採用し、画面上から簡単に更新できるようにしています。
                    <br />
                    開発言語には、できる限りバグを減らすため、静的型付け言語のTypeScriptとGolangを使用しています。
                  </BodyText>
                  {/*<BodyText>
                    <br />
                    より多くの人にアプリを使ってもらうため、ユーザーあたりの1日の質問回数を制限しています(OpenAI
                    APIのコスト削減目的)
                  </BodyText>*/}
                  {/*<BodyText>
                    <br />
                    セキュリティ向上のため、管理画面はVPN接続をするように実装しています。
                  </BodyText>*/}
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot3:
        tabs === "AWSPortfolio" ? (
          <ProductIntroductionSection
            pt={defaultTheme.spacing.xl}
            title="各種図面"
            theme={theme}
            bgColor={theme.palette.background}
            slot1={
              <Columns1to2Wrapper
                leftSize={5}
                rightSize={5}
                leftComponent={
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.short}
                    direction="spot"
                  >
                    <GradationDividerMediumTitle
                      color={theme.palette.gray.text}
                    >
                      インフラ構成図
                    </GradationDividerMediumTitle>
                    <BodyText>
                      クラウドサービスにAWSを採用しています。
                      フロントエンドはAWS S3 &
                      CloudFrontでホスティングし、APIはECS
                      Fargateでデプロイ、データベースはAurora
                      RDSで構築しています。
                      インフラは、IaCであるTerraformを用いてコード化しています。
                    </BodyText>
                    <BodyText>
                      <br />
                      また、AWSを使ったWebサービスのポートフォリオ用として構築したため、コスト削減の理由から、
                      EventBridge &
                      Lambdaを用いて、指定した時間(平日の日中など)のみバックエンドを稼働させるバッチ処理を実装しています。
                    </BodyText>
                  </FadeInOnScrollByComponent>
                }
                rightComponent={
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.medium}
                    direction="spot"
                  >
                    <div
                      css={css`
                        margin-left: auto; // 右端に寄せる
                      `}
                    >
                      <AWSPortfolioInfra
                        handleImageCardClick={handleImageCardClick}
                        theme={theme}
                      />
                    </div>
                  </FadeInOnScrollByComponent>
                }
              />
            }
            slot2={
              <div>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    ワークフロー図
                  </GradationDividerMediumTitle>
                </FadeInOnScrollByComponent>
                <Columns1to2to3Wrapper
                  firstComponent={
                    <FadeInOnScrollByComponent
                      delay={defaultTheme.delay.none}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <AWSPortfolioWorkflow01
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        フロントエンド
                      </LargeBodyText>
                      <BodyText>
                        リポジトリへのプッシュをトリガーに、GitHubActionsのCI/CDを実行し、S3
                        & CloudFrontへアップロードしています。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                  secondComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 200 : 0}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <AWSPortfolioWorkflow02
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        バックエンド
                      </LargeBodyText>
                      <BodyText>
                        リポジトリへのプッシュをトリガーに、GitHubActionsのCI/CDを実行し、ECRへイメージをプッシュおよびECSのタスク更新を行っています。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                  thirdComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 400 : 0}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <AWSPortfolioWorkflow03
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        IaC
                      </LargeBodyText>
                      <BodyText>
                        リポジトリへのプッシュをトリガーに、GitHubActionsのCIのみを実行しています。
                        安全性を考慮し、デプロイはTerraformのコマンド操作によって手動で行っています。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                />
              </div>
            }
          />
        ) : (
          <ProductIntroductionSection
            pt={defaultTheme.spacing.xl}
            title="各種図面"
            theme={theme}
            bgColor={theme.palette.background}
            slot1={
              <Columns1to2Wrapper
                leftSize={5}
                rightSize={5}
                leftComponent={
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.short}
                    direction="spot"
                  >
                    <GradationDividerMediumTitle
                      color={theme.palette.gray.text}
                    >
                      インフラ構成図
                    </GradationDividerMediumTitle>
                    <BodyText>
                      クラウドサービスにVercelとfly.ioを採用しています。
                      フロントエンドはVercelでホスティングし、APIおよびデータベースはfly.ioでデプロイしています。
                    </BodyText>
                    <BodyText>
                      また、お知らせ・リリースノート管理にmicroCMSを採用しています。
                    </BodyText>
                  </FadeInOnScrollByComponent>
                }
                rightComponent={
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.medium}
                    direction="spot"
                  >
                    <div
                      css={css`
                        margin-left: auto; // 右端に寄せる
                      `}
                    >
                      <PersonalDevelopmentInfra
                        handleImageCardClick={handleImageCardClick}
                        theme={theme}
                      />
                    </div>
                  </FadeInOnScrollByComponent>
                }
              />
            }
            slot2={
              <div>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    ワークフロー図
                  </GradationDividerMediumTitle>
                </FadeInOnScrollByComponent>
                <Columns1to2to3Wrapper
                  firstComponent={
                    <FadeInOnScrollByComponent
                      delay={defaultTheme.delay.none}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <PersonalDevelopmentWorkflow01
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        フロントエンド
                      </LargeBodyText>
                      <BodyText>
                        ブランチは開発と本番で分け、リポジトリへのプッシュをトリガーに、Vercelにアップロードしています。
                        また、Storybookは開発環境のみのデプロイとしています。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                  secondComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 200 : 0}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <PersonalDevelopmentWorkflow02
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        バックエンド
                      </LargeBodyText>
                      <BodyText>
                        リポジトリへのプッシュをトリガーに、GitHubActionsのCI/CDを実行し、fly.ioへデプロイしています。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                  thirdComponent={
                    <FadeInOnScrollByComponent
                      delay={pc ? 400 : 0}
                      direction="spot"
                    >
                      <div
                        css={css`
                          margin-bottom: ${defaultTheme.spacing.xs};
                        `}
                      >
                        <PersonalDevelopmentWorkflow03
                          handleImageCardClick={handleImageCardClick}
                          theme={theme}
                        />
                      </div>
                      <LargeBodyText
                        cssOverride={css`
                          font-weight: 700;
                          color: ${theme.palette.gray.text};
                        `}
                      >
                        IaC
                      </LargeBodyText>
                      <BodyText>
                        リポジトリへのプッシュをトリガーに、GitHubActionsのCIのみを実行しています。
                        安全性を考慮し、デプロイはTerraformのコマンド操作によって手動で行っています。
                      </BodyText>
                    </FadeInOnScrollByComponent>
                  }
                />
              </div>
            }
          />
        ),
      imageModal: {
        open,
        src,
        alt,
        onClose: handleImageModalClose,
      },
    }),
    [mode, theme, pc, open, src, alt, tabs]
  );

  return (
    <ProductIntroductionTemplate
      id={constants.pages.product.id}
      theme={theme}
      mediaQuery={mediaQuery}
      slotProps={slotProps}
    />
  );
};

const imageSources: ProductTalkStockPageImageSourcesState = {
  productTalkStock: {
    brandBanner: require("assets/images/product/talkstock.webp"),
    ingenuity1_1: require("assets/images/product/talkstock/ingenuity1-1.webp"),
    ingenuity1_2: require("assets/images/product/talkstock/ingenuity1-2.webp"),
    ingenuity2: require("assets/images/product/talkstock/ingenuity2.webp"),
  },
};
const requireSources = Object.values(imageSources.productTalkStock);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <ProductTalkStockPage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as ProductTalkStockPage };
