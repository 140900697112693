/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const TalkStockTextLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 16,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 495.44 70.12"
        height={size}
        width={"100%"}
      >
        <path d="M18.11,3.15H2.71V66.4h15.4V34.23C25.88,37.54,37.54,43.95,43.94,49l7.7-14.9C41.64,28,27.75,22.43,18.11,19.34Z" />
        <rect x="63.08" y="27.32" width="63.18" height="14.54" />
        <path d="M178.71,3.29l-15-1.44c-6.69,17.77-19.21,24.68-23.32,26.91l8.85,10.36a59.63,59.63,0,0,0,17.34-15h19.5c-6.76,25.19-25.69,28.57-41.23,30.37l5.61,13.17C171.45,64.1,181,58,187.13,51.43c12.23-13.17,15.33-31.66,16.33-40.37H175.26A67.84,67.84,0,0,0,178.71,3.29Z" />
        <path d="M250.46,47.83A264.59,264.59,0,0,1,270.1,68.13l12-11.66a150.86,150.86,0,0,0-23-19.29c8.85-13.31,11-23.24,12.31-31.38H219.8V19.12h32.81c-1.8,4.53-4.61,11.59-13.82,20.94-11.23,11.3-20,14.32-23.53,15.54L223,67.48C236.21,61.87,245.27,53.3,250.46,47.83Z" />
        <path d="M316,3.15h-15.4V66.4H316V34.23c7.77,3.31,19.43,9.71,25.83,14.75l7.7-14.9C339.54,28,325.65,22.43,316,19.34Z" />
        <path d="M378.82,38.91c-3-12.45-4.82-16-6.55-19.21L361.34,22.5a87.18,87.18,0,0,1,5.83,19.14Z" />
        <path d="M395.16,34.74a69.7,69.7,0,0,0-5.61-18.49l-10.79,2A102.33,102.33,0,0,1,384,37.18Z" />
        <path d="M367.17,57.19l6.91,11.08c34.61-8.13,40.51-34.4,43.75-48.86l-14-2.81C399,44.67,390.12,51.29,367.17,57.19Z" />
        <path d="M464.52,11.06A67.84,67.84,0,0,0,468,3.29L453,1.85c-6.69,17.77-19.21,24.68-23.32,26.91l8.85,10.36a59.63,59.63,0,0,0,17.34-15h19.5c-6.76,25.19-25.69,28.57-41.23,30.37l5.61,13.17C460.71,64.1,470.28,58,476.4,51.43c12.23-13.17,15.33-31.66,16.33-40.37Z" />
      </svg>
    </div>
  );
};
