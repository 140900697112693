/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ProgrammingOtasukeTextLogo } from "components/atoms/AKawaichiBlogTextLogo/ProgrammingOtasukeTextLogo";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import { ProgrammingOtasukeTechnicalStack } from "components/molecules/ProgrammingOtasukeTechnicalStack/ProgrammingOtasukeTechnicalStack";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { defaultTheme, Theme } from "styles/theme";
import { CLIENT_BASEURL } from "utils/config";
import { constants } from "utils/constants";
import { ProductCard, ProductCardSlotProps } from "../ProductCard/ProductCard";
import { ProductCardBottomComponent } from "../ProductCardBottomComponent/ProductCardBottomComponent";

type Props = {
  src: string;
  slotProps?: ProductCardSlotProps & {
    button?: {
      color?: string;
    };
  };
  theme: Theme;
  mediaQuery: MediaQuery;
};

export const ProgrammingOtasukeProductCard: React.FC<Props> = ({
  src,
  slotProps,
  theme,
  mediaQuery: { pc },
}) => {
  return (
    <ProductCard
      title={
        <ProgrammingOtasukeTextLogo
          darkColor={slotProps?.title?.color}
          lightColor={slotProps?.title?.color}
          cssOverride={slotProps?.title?.style}
        />
      }
      description={
        "質問に答えてプログラミングのエラーを解決していく、初心者Webエンジニアの方へ向けたウェブツールです"
      }
      image={{
        src: src,
        alt: "programming-otasuke",
      }}
      href={`${CLIENT_BASEURL}${constants.pages.product.url.programmingOtasuke}`}
      slotProps={slotProps}
      bottomComponent={
        <ProductCardBottomComponent
          theme={theme}
          topComponent={
            <a
              href={`${CLIENT_BASEURL}${constants.pages.product.url.programmingOtasuke}`}
              target="_blank"
              rel="noreferrer"
            >
              <LongArrowButton
                textColor={slotProps?.button?.color}
                arrowColor={slotProps?.button?.color}
                cssOverride={css`
                  color: ${slotProps?.button?.color};
                `}
              >
                詳細を見る
              </LongArrowButton>
            </a>
          }
          technicalStack={
            <ProgrammingOtasukeTechnicalStack
              bgColor={defaultTheme.palette.background}
            />
          }
          other={
            <LabelRadiusTags
              bold={false}
              tags={[
                { value: "Full-Stack TypeScript" },
                { value: "Feature-Sliced Design" },
                { value: "AI駆動開発" },
              ]}
            />
          }
        />
      }
    />
  );
};
