/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SlashLineSeparator } from "components/atoms/SlashSeparator/SlashLineSeparator";
import { FullScreenCenteredHeroProps } from "components/organisms/FullScreenCenteredHero/FullScreenCenteredHero";
import { HomeLayout } from "components/pages/HomeLayout/HomeLayout";
import { HomeTemplate } from "components/templates/HomeTemplate/HomeTemplate";
import { useCallback, useState } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";

const Separator: React.FC = () => (
  <SlashLineSeparator mx={defaultTheme.spacing.xxs} size={0.75} />
);

const PanelTextItem: React.FC<{
  text: React.ReactNode;
  hideSeparator?: boolean;
}> = ({ text, hideSeparator = false }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <span
      css={css`
        ${mixin.text.note};
        @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
          ${mixin.text.description};
        }

        letter-spacing: 0.2em;
        white-space: nowrap;
        text-transform: uppercase;
      `}
    >
      {text}
    </span>
    {!hideSeparator && <Separator />}
  </div>
);

const imageSources = {
  products: {
    kawaichiPortfolio: require("assets/images/home/homepage-products-01.webp"),
    talkstock: require("assets/images/home/homepage-products-02.webp"),
    kawaichiBlog: require("assets/images/home/homepage-products-03.webp"),
    programmingOtasuke: require("assets/images/home/homepage-products-08.webp"),
    fitscreenwindow: require("assets/images/home/homepage-products-04.webp"),
    batchfilerenamer: require("assets/images/home/homepage-products-05.webp"),
    shotoku: require("assets/images/home/homepage-products-06.webp"),
    dtpdesign: require("assets/images/home/homepage-products-07.webp"),
  },
};

const HomePage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const screenComponentItems: FullScreenCenteredHeroProps[] = [
    {
      title: "PORTFOLIO SITE",
      requireSrc: imageSources.products.kawaichiPortfolio,
      alt: "KAWAICHI PORTFOLIO",
      href: constants.pages.product.url.portfolio,
      panels: [
        { value: <PanelTextItem text="React" /> },
        { value: <PanelTextItem text="TypeScript" /> },
        { value: <PanelTextItem text="Storybook" /> },
        { value: <PanelTextItem text="AWS" /> },
        { value: <PanelTextItem text="Terraform" hideSeparator /> },
      ],
    },
    {
      title: "WEB SERVICE",
      requireSrc: imageSources.products.talkstock,
      alt: "トークストック",
      href: constants.pages.product.url.talkstock,
      panels: [
        { value: <PanelTextItem text="Next.js" /> },
        { value: <PanelTextItem text="TypeScript" /> },
        { value: <PanelTextItem text="Redux" /> },
        { value: <PanelTextItem text="Echo" /> },
        { value: <PanelTextItem text="Go" /> },
        { value: <PanelTextItem text="AWS" /> },
        { value: <PanelTextItem text="Terraform" hideSeparator /> },
      ],
    },
    {
      title: "ENGINEER BLOG",
      requireSrc: imageSources.products.kawaichiBlog,
      alt: "かわいちのエンジニアぶろぐ！",
      href: constants.pages.product.url.kawaichiBlog,
      panels: [
        { value: <PanelTextItem text="Next.js" /> },
        { value: <PanelTextItem text="TypeScript" /> },
        { value: <PanelTextItem text="GraphQL" /> },
        { value: <PanelTextItem text="AWS" /> },
        { value: <PanelTextItem text="Terraform" hideSeparator /> },
      ],
    },
    {
      title: "WEB TOOL",
      requireSrc: imageSources.products.programmingOtasuke,
      alt: "プログラミングのエラーおたすけツール！",
      href: constants.pages.product.url.programmingOtasuke,
      panels: [
        { value: <PanelTextItem text="Nuxt.js" /> },
        { value: <PanelTextItem text="TypeScript" /> },
        { value: <PanelTextItem text="AWS" /> },
        { value: <PanelTextItem text="AWS CDK" /> },
        { value: <PanelTextItem text="bolt.new" hideSeparator /> },
      ],
    },
    {
      title: "WINDOWS APP",
      requireSrc: imageSources.products.fitscreenwindow,
      alt: "FitScreenWindow",
      href: constants.pages.product.url.fitscreenwindow,
      panels: [
        { value: <PanelTextItem text="Python" /> },
        { value: <PanelTextItem text="Qt" /> },
        { value: <PanelTextItem text="NSIS" /> },
        { value: <PanelTextItem text="Vue.js" hideSeparator /> },
      ],
    },
    {
      title: "CLI TOOL",
      requireSrc: imageSources.products.batchfilerenamer,
      alt: "BatchFileRenamer",
      href: constants.links.batchfilerenamer,
      isAnchorLink: true,
      panels: [
        { value: <PanelTextItem text="GO" /> },
        { value: <PanelTextItem text="Vue.js" hideSeparator /> },
      ],
    },
    {
      title: "VBA MACRO",
      requireSrc: imageSources.products.shotoku,
      alt: "所得拡大促進税制エクセルソフト",
      href: constants.pages.product.url.shotoku,
      panels: [
        { value: <PanelTextItem text="Excel VBA" /> },
        { value: <PanelTextItem text="MySQL" hideSeparator /> },
      ],
    },
    {
      title: "DTP DESIGN",
      requireSrc: imageSources.products.dtpdesign,
      alt: "DTPデザイン",
      href: constants.pages.design.url,
      panels: [
        { value: <PanelTextItem text="Illustrator" /> },
        { value: <PanelTextItem text="Photoshop" hideSeparator /> },
      ],
    },
  ];

  const pagesNumbers = screenComponentItems.map((_, index) => index);
  const [animate, setAnimate] = useState(
    screenComponentItems.map((_, index) => index === 0)
  );

  const handleBeforePageChange = useCallback((number: number) => {
    setCurrentPage(number);

    // アニメーションのリセット
    setAnimate(screenComponentItems.map((_, index) => index === number));
  }, []);

  const handleAfterPageChange = useCallback((number: number) => {}, []);

  const handleScrollUnavailable = useCallback(() => {
    if (currentPage === 0) {
      setCurrentPage(pagesNumbers.length - 1);

      return;
    }
    if (currentPage === pagesNumbers.length - 1) {
      setCurrentPage(0);
      return;
    }
  }, [currentPage]);

  return (
    <HomeTemplate
      currentPage={currentPage}
      pagesNumbers={pagesNumbers}
      handleBeforePageChange={handleBeforePageChange}
      handleAfterPageChange={handleAfterPageChange}
      handleScrollUnavailable={handleScrollUnavailable}
      screenComponentItems={screenComponentItems}
      animate={animate}
    />
  );
};

const LayoutPage = () => {
  return (
    <HomeLayout>
      <HomePage />
    </HomeLayout>
  );
};

export { LayoutPage as HomePage };
