/** @jsxImportSource @emotion/react */
import { useContact } from "hooks/useContact";
import { useRouter } from "hooks/useRouter";
import React, { useEffect } from "react";
import { ReCaptchaProvider } from "utils/GoogleReCaptcha";
import { ContactFormPage } from "../ContactFormPage/ContactFormPage";
import { ContactSuccessPage } from "../ContactSuccessPage/ContactSuccessPage";
import { MainLayout } from "../MainLayout/MainLayout";

const ContactPage: React.FC = () => {
  const { contact, setContact } = useContact();
  const { isSendSuccess } = contact;

  // ページが遷移したときに状態管理Stateを初期化する
  const { location } = useRouter();
  useEffect(() => {
    setContact((prev) => ({ ...prev, isSendSuccess: false }));
  }, [location]);

  useEffect(() => {
    if (isSendSuccess) {
      window.scrollTo(0, 0);
    }
  }, [isSendSuccess]);

  return isSendSuccess ? <ContactSuccessPage /> : <ContactFormPage />;
};

const LayoutPage = () => (
  <ReCaptchaProvider>
    <MainLayout showPageTop>
      <ContactPage />
    </MainLayout>
  </ReCaptchaProvider>
);
export { LayoutPage as ContactPage };
