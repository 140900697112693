/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import { ImageFadeLoader } from "components/atoms/ImageFadeLoader/ImageFadeLoader";
import { usePreloadImages } from "hooks/usePreloadImages";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";

export const DesignImage04Detail = () => {
  const detail01RequireSource = [
    require("assets/images/design/detail01-img-04.webp"),
  ];
  const { preloadedSources, isPreloading } = usePreloadImages(
    detail01RequireSource
  );
  const preloadeddetail01RequireSource = preloadedSources[0];

  return (
    <div
      css={css`
        margin-top: ${defaultTheme.spacing.lg};
        margin-bottom: ${defaultTheme.spacing.lg};
      `}
    >
      <GradationDivider
        disableAnimation
        size={defaultTheme.borderWidth.sm}
        cssOverride={css`
          margin-bottom: ${defaultTheme.spacing.lg};
        `}
      />

      {isPreloading ? (
        <ImageFadeLoader
          color="#AAAAAA"
          height={300}
          bgColor={defaultTheme.palette.common.white}
        />
      ) : (
        <FadeInOnScrollByComponent
          delay={defaultTheme.delay.none}
          direction="bottom"
        >
          <div
            css={css`
              max-width: 560px;
              margin: 0 auto;
            `}
          >
            <img
              css={css`
                width: 100%;
              `}
              src={preloadeddetail01RequireSource}
              alt={"detail01-img-04"}
            />
          </div>
        </FadeInOnScrollByComponent>
      )}
    </div>
  );
};
