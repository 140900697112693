/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { defaultTheme } from "styles/theme";
import { BaseInner } from "../BaseInner/BaseInner";
import { BaseSmallInner } from "../BaseSmallInner/BaseSmallInner";

type Props = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
};

export const CenteredOuter: React.FC<Props> = ({ children, cssOverride }) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          margin-left: auto;
          margin-right: auto;
          padding: 0 ${defaultTheme.spacing.base.x.mobile};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            padding: 0 ${defaultTheme.spacing.base.x.pc};
          }
        `,
      ]}
    >
      <BaseInner px="0" pt={defaultTheme.spacing.base.top}>
        <BaseSmallInner>{children}</BaseSmallInner>
      </BaseInner>
    </div>
  );
};
