/** @jsxImportSource @emotion/react */
import React from "react";
import { FixedFooterLayoutTemplate } from "../../templates/FixedFooterLayoutTemplate/FixedFooterLayoutTemplate";

export type HomeLayoutProps = {
  children: React.ReactNode;
};

export const HomeLayout: React.FC<HomeLayoutProps> = ({
  children,
}: HomeLayoutProps) => {
  return <FixedFooterLayoutTemplate>{children}</FixedFooterLayoutTemplate>;
};
