export const BASE_COLOR_FITSCREENWINDOW = "#ff5f5f";
export const BUTTON_COLOR_FITSCREENWINDOW = "#444444";
export const TEXT_COLOR_FITSCREENWINDOW = "#ffffff";

export const BASE_COLOR_BATCHFILERENAMER = "#3399ff";
export const BUTTON_COLOR_BATCHFILERENAMER = "#444444";
export const TEXT_COLOR_BATCHFILERENAMER = "#ffffff";

export const BASE_COLOR_DEFAULT = "#ffffff";

type Spacing = {
  spacing: {
    base: {
      x: {
        pc: string;
        mobile: string;
      };
      top: string;
    };
    xxxs: string;
    xxs: string;
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
    title: {
      bottom: string;
    };
    header: {
      top: string;
    };
    footer: {
      top: string;
      bottom: string;
    };
  };
};

export const mobileSpacing: Spacing = {
  spacing: {
    base: {
      x: {
        pc: "2rem",
        mobile: "1rem",
      },
      top: "3rem",
    },
    xxxs: "0.25rem",
    xxs: "0.25rem",
    xs: "0.5rem",
    sm: "1.5rem",
    md: "2rem",
    lg: "4rem",
    xl: "8rem",
    xxl: "10rem",
    title: {
      bottom: "3rem",
    },
    header: {
      top: "1rem",
    },
    footer: {
      top: "3rem",
      bottom: "1rem",
    },
  },
};

export const pcSpacing: Spacing = {
  spacing: {
    base: {
      x: {
        pc: "4rem",
        mobile: "1.5rem",
      },
      top: "5rem",
    },
    xxxs: "0.25rem",
    xxs: "0.5rem",
    xs: "1rem",
    sm: "1.5rem",
    md: "2rem",
    lg: "4rem",
    xl: "10rem",
    xxl: "12rem",
    title: {
      bottom: "5rem",
    },
    header: {
      top: "2rem",
    },
    footer: {
      top: "6rem",
      bottom: "2rem",
    },
  },
};

const defaultSpacing: Spacing["spacing"] = pcSpacing.spacing;

export const defaultZIndex = {
  slideInOutOverlay: 100,
  tooltip: 200,
  headerLogo: 200,
  pageNumber: 200,
  changeThemeButton: 200,
  navMenuOverlay: 300,
  hambugerButton: 400,
  modal: 500,
  splashScreen: 1000,
};

export type Mode = "light" | "dark";
export type Theme = {
  palette: {
    background: string;
    text: string;
    contrastText: string;
    primary: string;
    secondary: string;
    //accent: string;
    common: {
      white: string;
      black: string;
      overlay: string;
      backdrop: string;
    };
    gray: {
      button: {
        100: string;
        200: string;
      };
      text: string;
      primary: string;
      background: string;
    };
    error: string;
  };
  zIndex: {
    navMenuOverlay: number;
    slideInOutOverlay: number;
    headerLogo: number;
    pageNumber: number;
    hambugerButton: number;
    changeThemeButton: number;
    modal: number;
    tooltip: number;
    splashScreen: number;
  };
  breakpoints: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    //xl: string,
    //xxl: string,
  };
  spacing: Spacing["spacing"];
  maxWidth: {
    base: string;
    sm: string;
    md: string;
  };
  letterSpacing: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
  };
  borderWidth: {
    sm: number;
    md: number;
  };
  borderRadius: {
    base: string;
  };
  delay: {
    none: number;
    short: number;
    medium: number;
  };
};

const commonTheme = {
  palette: {
    common: {
      white: "#ffffff",
      black: "#000000",
      overlay: "#222222",
      backdrop: "#22222266",
    },
  },
  // Global stack level
  // MEMO: z-indexは100単位
  zIndex: defaultZIndex,
  breakpoints: {
    xs: "375px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    //xl: "1280px",
    //xxl: "1440px",
  },
  spacing: defaultSpacing,
  maxWidth: {
    base: "1280px",
    sm: "640px",
    md: "768px",
  },
  letterSpacing: {
    xs: "0.05rem",
    sm: "0.16rem",
    md: "0.28rem",
    lg: "0.32rem",
  },
  borderWidth: {
    sm: 1,
    md: 1.5,
  },
  borderRadius: {
    base: "0px",
  },
  delay: {
    none: 0,
    short: 200,
    medium: 400,
  },
};

export const lightTheme: Theme = {
  // MEMO: `<color>xxx` の数値は高いほど暗い、低いほど明るい
  palette: {
    common: { ...commonTheme.palette.common },
    background: "#ecece7",
    text: "#444444",
    contrastText: "#ffffff",
    primary: "#444444",
    secondary: "#eeeeee",
    gray: {
      button: {
        100: "#999999",
        200: "#666666",
      },
      text: "#666666",
      primary: "#cccccc",
      background: "#e0e0d9",
    },
    error: "#D16E74",
  },
  zIndex: { ...commonTheme.zIndex },
  breakpoints: { ...commonTheme.breakpoints },
  spacing: { ...commonTheme.spacing },
  maxWidth: { ...commonTheme.maxWidth },
  letterSpacing: { ...commonTheme.letterSpacing },
  borderWidth: { ...commonTheme.borderWidth },
  borderRadius: { ...commonTheme.borderRadius },
  delay: { ...commonTheme.delay },
};

export const darkTheme: Theme = {
  palette: {
    common: { ...commonTheme.palette.common },
    background: "#222222",
    text: "#ffffff",
    contrastText: "#222222",
    primary: "#ffffff",
    secondary: "#eeeeee",
    gray: {
      button: {
        100: "#666666",
        200: "#444444",
      },
      primary: "#cccccc",
      text: "#999999",
      background: "#1c1c1c",
    },
    error: "#D16E74",
  },
  zIndex: { ...commonTheme.zIndex },
  breakpoints: { ...commonTheme.breakpoints },
  spacing: { ...commonTheme.spacing },
  maxWidth: { ...commonTheme.maxWidth },
  letterSpacing: { ...commonTheme.letterSpacing },
  borderWidth: { ...commonTheme.borderWidth },
  borderRadius: { ...commonTheme.borderRadius },
  delay: { ...commonTheme.delay },
};

export const defaultMode: Mode = "light";
export const defaultTheme: Theme = {
  ...lightTheme,
};
