/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import React, { ReactNode } from "react";

const styles = {
  container: css`
    width: fit-content;
  `,
};

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: ReactNode;
};

export const GradationDividerTitle: React.FC<Props> = ({
  className,
  cssOverride,
  children = "TITLE",
}) => {
  return (
    <div
      className={"" + (className ? ` ${className}` : "")}
      css={[styles.container, cssOverride]}
    >
      <EngTitle>{children}</EngTitle>
      <GradationDivider />
    </div>
  );
};
