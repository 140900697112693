/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ReactNode } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

const RequiredIcon: React.FC = () => (
  <span
    style={{
      color: defaultTheme.palette.error,
      marginLeft: "0.1rem",
      display: "inline-block",
      position: "relative",
      top: "-0.4rem",
      fontSize: "0.5rem",
      fontWeight: "bold",
    }}
  >
    ＊
  </span>
);

const RequiredText: React.FC = () => (
  <span
    style={{
      color: defaultTheme.palette.error,
      display: "inline-block",
      fontWeight: "normal",
      whiteSpace: "nowrap",
    }}
  >
    必須
  </span>
);

type MarkerTextProps = {
  children: React.ReactNode;
  bgColor?: string;
};

const MarkerText: React.FC<MarkerTextProps> = ({ children, bgColor }) => (
  <span
    css={css`
      ${mixin.text.body}
      color: #FFFFFF;
      background-color: ${bgColor};
      border-radius: ${defaultTheme.borderRadius.base};
      padding: 0.2rem 0.45rem;
      font-weight: normal;
      white-space: nowrap;
      position: relative;
      top: -0.1rem;
    `}
  >
    {children}
  </span>
);

const OptionalMarkerText: React.FC = () => (
  <MarkerText bgColor={defaultTheme.palette.gray.button[100]}>任意</MarkerText>
);

const RequiredMarkerText: React.FC = () => (
  <MarkerText bgColor={defaultTheme.palette.error}>必須</MarkerText>
);

type LabelProps = {
  required?: boolean;
  optional?: boolean;
  cssOverride?: SerializedStyles;
  requiredStyle?: "icon" | "text" | "markerText";
  optionalStyle?: "text";
  children: ReactNode;
  bold?: boolean;
  htmlFor?: string;
};

export const Label: React.FC<LabelProps> = ({
  children,
  optional = false,
  required = false,
  cssOverride,
  requiredStyle = "markerText",
  optionalStyle = "text",
  bold = true,
  htmlFor,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `,
      ]}
    >
      <label
        htmlFor={htmlFor}
        css={css`
          cursor: default !important;
          white-space: "nowrap";
          min-width: "100px";
          font-weight: ${bold ? "bold" : "normal"};
        `}
      >
        {children}
      </label>
      {required ? (
        <div
          css={[
            cssOverride,
            css`
              margin-left: ${defaultTheme.spacing.xs};
            `,
          ]}
        >
          {requiredStyle === "icon" ? (
            <RequiredIcon />
          ) : requiredStyle === "text" ? (
            <RequiredText />
          ) : requiredStyle === "markerText" ? (
            <RequiredMarkerText />
          ) : null}
        </div>
      ) : null}
      {optional ? (
        <div
          css={[
            cssOverride,
            css`
              margin-left: ${defaultTheme.spacing.xs};
            `,
          ]}
        >
          {optionalStyle === "text" ? <OptionalMarkerText /> : null}
        </div>
      ) : null}
    </div>
  );
};
