/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: React.ReactNode;
  outlined?: boolean;
  size?: number;
  color?: string;
  textColor?: string;
};

const styles = (props: Props) => {
  const colorStyle = props.outlined
    ? css`
        border: solid 3px;
        border-color: ${props.color};
      `
    : css`
        background: ${props.color};
      `;
  return css`
    ${colorStyle}
    ${mixin.disableLineHeight}
    border-radius: 50%;
    display: block;
    width: fit-content;
    padding: calc(${props.size}px) calc(${props.size}px);
    box-sizing: border-box;
    color: ${props.textColor};
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      filter: brightness(120%);
    }
  `;
};

export const CircleButton: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  outlined = false,
  size = 30,
  color = "#444444",
  textColor = "#ffffff",
}) => {
  return (
    <div
      css={[styles({ outlined, size, color, textColor }), cssOverride]}
      className={`${className ? className : ""}`}
    >
      {children}
    </div>
  );
};
