/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type Columns2to3WrapperProps = {
  firstComponent?: React.ReactNode;
  secondComponent?: React.ReactNode;
  thirdComponent?: React.ReactNode;
  children?: React.ReactNode;
  cssOverride?: SerializedStyles;
  columnGap?: {
    sm?: string | number;
    md?: string | number;
    lg?: string | number;
  };
};

export const Columns2to3Wrapper: React.FC<Columns2to3WrapperProps> = ({
  firstComponent,
  secondComponent,
  thirdComponent,
  children,
  cssOverride,
  columnGap = {
    sm: defaultTheme.spacing.sm,
    md: defaultTheme.spacing.md,
    lg: defaultTheme.spacing.lg,
  },
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          display: grid;

          grid-template-columns: repeat(2, 1fr);
          row-gap: ${defaultTheme.spacing.md};
          column-gap: ${columnGap.sm};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: ${defaultTheme.spacing.md};
            column-gap: ${columnGap.md};
          }
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            grid-template-columns: repeat(3, 1fr);
            row-gap: ${defaultTheme.spacing.lg};
            column-gap: ${columnGap.lg};
          }

          // MEMO: どのウィンドウサイズのときでも、各divが均等に縮小されるようにする
          & > div {
            min-width: 0;
          }
        `,
      ]}
    >
      {children ? (
        <>{children}</>
      ) : (
        <>
          <div>{firstComponent}</div>
          <div>{secondComponent}</div>
          <div>{thirdComponent}</div>
        </>
      )}
    </div>
  );
};
