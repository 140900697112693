/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

export type TableProps = {
  headers?: string[];
  data: Array<Array<string | number | ReactNode>>;
  columnWidths?: string[];
  borderWeight?: number;
  borderColor?: string;
  hideOutlineBorder?: boolean;
  headerBgColor?: string;
  headerColor?: string;
  paddingX?: string;
  paddingY?: string;
  firstColumnStyles?: {
    bold?: boolean;
    color?: string;
  };
  cssOverride?: SerializedStyles;
};

export const Table: React.FC<TableProps> = ({
  headers,
  data,
  columnWidths = [],
  borderWeight = defaultTheme.borderWidth.sm,
  borderColor = defaultTheme.palette.secondary,
  hideOutlineBorder = false,
  headerBgColor = defaultTheme.palette.secondary,
  headerColor = defaultTheme.palette.text,
  paddingX = defaultTheme.spacing.xxs,
  paddingY = defaultTheme.spacing.xxxs,
  firstColumnStyles = {
    bold: false,
    color: defaultTheme.palette.gray.text,
  },
  cssOverride,
}) => {
  const _firstColumnStyles = css`
    color: ${firstColumnStyles.color};
    font-weight: ${firstColumnStyles.bold ? "bold" : "normal"};

    ${mixin.text.description};
    line-height: 1rem !important;
    @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
      ${mixin.text.body};
      line-height: 1rem !important;
    }
  `;

  return (
    <div
      css={css`
        ${!hideOutlineBorder &&
        `border: ${borderWeight}px solid ${borderColor};`}
        border-radius: ${defaultTheme.borderRadius.base};
        overflow: hidden;
      `}
    >
      <table
        css={[
          cssOverride,
          css`
            width: 100%;
            border-collapse: collapse;
            line-height: 1.5;
          `,
        ]}
      >
        <thead
          css={css`
            background-color: ${headerBgColor};
            color: ${headerColor};
          `}
        >
          <tr>
            {headers &&
              headers.map((header, index) => (
                <th
                  key={index}
                  css={css`
                    ${mixin.text.body};
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      ${mixin.text.largeBody};
                    }
                    padding-left: ${paddingX};
                    padding-right: ${paddingX};
                    padding-top: ${paddingY};
                    padding-bottom: ${paddingY};
                    text-align: left;
                    border-bottom: ${borderWeight}px solid ${borderColor};
                    width: ${columnWidths[index] || "auto"}; // 列幅を指定
                  `}
                >
                  {header}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  css={css`
                    ${mixin.text.body};
                    line-height: 1.5rem !important;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      ${mixin.text.body};
                      line-height: 1.75rem !important;
                    }
                    // 最後の行以外に下ボーダーを追加
                    ${rowIndex !== data.length - 1 &&
                    `border-bottom: ${borderWeight}px solid ${borderColor};`}

                    padding-left: ${paddingX};
                    padding-right: ${paddingX};
                    padding-top: ${paddingY};
                    padding-bottom: ${paddingY};
                    width: ${columnWidths[cellIndex] || "auto"}; // 列幅を指定

                    word-break: break-all;
                  `}
                >
                  {React.isValidElement(cell) &&
                  firstColumnStyles &&
                  cellIndex === 0 ? (
                    React.cloneElement(cell, {
                      //@ts-ignore
                      css: _firstColumnStyles,
                    })
                  ) : firstColumnStyles && cellIndex === 0 ? (
                    <span css={_firstColumnStyles}>{cell}</span>
                  ) : (
                    cell
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
