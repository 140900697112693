/** @jsxImportSource @emotion/react */
import { css } from "@storybook/theming";
import { RadiusArrowButton } from "components/atoms/RadiusArrowButton/RadiusArrowButton";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { ImageGalleryItem } from "components/molecules/ImageGallery/ImageGallery";
import { imageGallery } from "components/molecules/ImageGallery/ImageGallery.stories";
import { DesignCardClickEventHandler } from "components/organisms/DesignCard/DesignCard";
import { DesignImage01Detail } from "components/organisms/DesignImage01Detail/DesignImage01Detail";
import { DesignImage03Detail } from "components/organisms/DesignImage03Detail/DesignImage03Detail";
import { DesignImage04Detail } from "components/organisms/DesignImage04Detail/DesignImage04Detail";
import {
  DesignTemplate,
  DesignTemplateSlotProps,
} from "components/templates/DesignTemplate/DesignTemplate";
import {
  DesignPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useTheme } from "hooks/useTheme";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ANIMATAION_DURATION_MD, mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";
import { sleep } from "utils/sleep";
import { MainLayout } from "../MainLayout/MainLayout";

type ImageCardState = {
  open: boolean;
  src: string;
  alt: string;
  title: string;
  description: string;
};

const DesignPage: React.FC = () => {
  const {
    imageSources: { designImages },
  } = useImageSources();
  const { theme } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;

  const [{ open, src, alt, title, description }, setImageCardState] =
    React.useState<ImageCardState>({
      open: false,
      src: "",
      alt: "",
      title: "",
      description: "",
    });

  const imageGalleryItems: ImageGalleryItem[] = [
    {
      title: "牧場のカフェオレ缶",
      description: "飲料 - 缶コーヒー",
      src: designImages.img01,
      alt: "牧場のカフェオレ缶",
    },
    {
      title: "牧場オリジナル プレミアムハンバーグ",
      description: "ポップ (展示場所: 観光地の売店)",
      src: designImages.img02,
      alt: "牧場オリジナル プレミアムハンバーグ",
    },
    {
      title: "レストハウスの焼肉と軽食メニュー",
      description: "立て掛けメニュー - A1サイズ",
      src: designImages.img03,
      alt: "レストハウスの焼肉と軽食メニュー",
    },
    {
      title: "焼肉ハウスの建物内ご案内看板",
      description: "看板 (展示場所: 観光地レストハウス内)",
      src: designImages.img04,
      alt: "焼肉ハウスの建物内ご案内看板",
    },
    {
      title: "焼肉ハウスのメニュー",
      description: "立て掛けメニュー - A1サイズ",
      src: designImages.img05,
      alt: "焼肉ハウスのメニュー",
    },
    {
      title: "観光牧場の紹介ポスター",
      description: "ポスター - A1サイズ",
      src: designImages.img06,
      alt: "観光牧場の紹介ポスター",
    },
    {
      title: "観光牧場の紹介ポスター 2",
      description: "ポスター - A1サイズ",
      src: designImages.img07,
      alt: "観光牧場の紹介ポスター 2",
    },
    {
      title: "牧場アイスクリームの販促ポップ",
      description: "ポップ (展示場所: ホテル・旅館等)",
      src: designImages.img08,
      alt: "牧場アイスクリームの販促ポップ",
    },
    {
      title: "酪農のお祭りイベント",
      description: "チラシ・抽選券・商品券等",
      src: designImages.img17,
      alt: "酪農のお祭りイベント",
    },
    {
      title: "牛乳の販促ポップ",
      description: "ポップ (展示場所: 各販売スーパーマーケット)",
      src: designImages.img10,
      alt: "牛乳の販促ポップ",
    },
    {
      title: "乳飲料の販促ポップ",
      description: "ポップ (展示場所: 各販売スーパーマーケット)",
      src: designImages.img12,
      alt: "乳飲料の販促ポップ",
    },
    {
      title: "観光地そと売店のポップ",
      description: "ポップ (展示場所: 観光地の外売店)",
      src: designImages.img09,
      alt: "観光地そと売店のポップ",
    },
    {
      title: "酪農家をめざすセミナーのPR用チラシ",
      description: "チラシ - A4サイズ",
      src: designImages.img11,
      alt: "酪農家をめざすセミナーの宣伝チラシ",
    },
    {
      title: "贅沢なロールケーキとタルトの販促ポップ",
      description: "ポップ (展示場所: 各販売百貨店・スーパー等)",
      src: designImages.img14,
      alt: "贅沢なロールケーキとタルトの販促ポップ",
    },
    {
      title: "パン屋さんのロゴ",
      description: "ロゴ",
      src: designImages.img13,
      alt: "焼きたてパン工房",
    },
    {
      title: "牛乳飲みくらべイベントのPR用のぼり旗",
      description: "のぼり旗 (展示場所: 各イベント会場)",
      src: designImages.img15,
      alt: "牛乳飲みくらべイベントのPR用のぼり旗",
    },
    {
      title: "秋のまつたけ祭 PR用のぼり旗",
      description: "のぼり旗 (展示場所: 各イベント会場)",
      src: designImages.img16,
      alt: "秋のまつたけ祭 PR用のぼり旗",
    },

    {
      title: "売店コーナー 建物内看板(オモテ/ウラ)",
      description: "看板 (展示場所: 観光地レストハウス内)",
      src: designImages.img18,
      alt: "売店コーナー 建物内看板(オモテ/ウラ)",
    },
  ];

  const cancelCloseRef = useRef(false);
  const isPanelScrollTopRef = useRef(false);

  const handleImageCardClick: DesignCardClickEventHandler = useCallback(
    (src, alt, title, description) => {
      cancelCloseRef.current = true;
      isPanelScrollTopRef.current = true;
      setImageCardState({ open: true, src, alt, title, description });
    },
    []
  );

  const handleImageModalClose = useCallback(async () => {
    cancelCloseRef.current = false;
    isPanelScrollTopRef.current = false;

    setImageCardState((prev) => ({ ...prev, open: false }));

    await sleep(ANIMATAION_DURATION_MD);

    if (!cancelCloseRef.current) {
      setImageCardState((prev) => ({
        ...prev,
        src: "",
        alt: "",
        title: "",
        description: "",
      }));
    }
  }, []);

  const [imageModalBottomComponent, setImageModalBottomComponent] =
    React.useState<React.ReactNode>(<></>);
  useEffect(() => {
    if (open) {
      if (src.match(/img-01/)) {
        setImageModalBottomComponent(<DesignImage01Detail />);
      } else if (src.match(/img-03/)) {
        setImageModalBottomComponent(<DesignImage03Detail />);
      } else if (src.match(/img-04/)) {
        setImageModalBottomComponent(<DesignImage04Detail />);
      } else {
        setImageModalBottomComponent(<></>);
      }
    }
  }, [open]);

  const slotProps: DesignTemplateSlotProps = useMemo(
    () => ({
      title: constants.pages.design.title,
      subTitle: "広告デザイン",
      subTitleColor: theme.palette.gray.text,
      description:
        "これまでのお仕事で制作した、広告デザイン作品集(DTP)の一部です。",
      descriptionTags: {
        label: "使用ツール：",
        labelColor: theme.palette.gray.text,
        tags: [{ value: "Adobe Illustrator" }, { value: "Adobe Photoshop" }],
        backgroundColor: theme.palette.gray.button[200],
        textColor: theme.palette.common.white,
      },
      imageGallery: {
        open,
        src,
        alt,
        title,
        description,
        items: imageGalleryItems,
        onImageCardClick: handleImageCardClick,
        onImageModalClose: handleImageModalClose,
        imageSize: pc ? "560px" : "75vw",
        designCard: {
          title: {
            color: theme.palette.text,
            style: css`
              ${mixin.changeTheme}
            `,
          },
          description: {
            color: theme.palette.gray.text,
          },
        },
        imageModal: {
          image: {
            bordered: false,
          },
          buttons: (
            <RadiusArrowButton
              direction="left"
              buttonColor={defaultTheme.palette.primary}
              outlined
              onClick={handleImageModalClose}
            >
              一覧に戻る
            </RadiusArrowButton>
          ),
          isPanelScrollTop: isPanelScrollTopRef.current,
          bottomComponent: imageModalBottomComponent,
        },
      },
      imageGalleryFooter: {
        text: "実績ほか： クラウドワークス、某飲食店様看板 など",
        color: theme.palette.gray.text,
      },
    }),
    [
      open,
      src,
      title,
      description,
      handleImageCardClick,
      handleImageModalClose,
      theme,
      imageGallery,
      pc,
      isPanelScrollTopRef,
      imageModalBottomComponent,
    ]
  );

  return (
    <DesignTemplate
      id={constants.pages.design.id}
      slotProps={slotProps}
      mediaQuery={mediaQuery}
    />
  );
};

const imageSources: DesignPageImageSourcesState = {
  designImages: {
    img01: require("assets/images/design/img-01.webp"),
    img02: require("assets/images/design/img-02.webp"),
    img03: require("assets/images/design/img-03.webp"),
    img04: require("assets/images/design/img-04.webp"),
    img05: require("assets/images/design/img-05.webp"),
    img06: require("assets/images/design/img-06.webp"),
    img07: require("assets/images/design/img-07.webp"),
    img08: require("assets/images/design/img-08.webp"),
    img09: require("assets/images/design/img-09.webp"),
    img10: require("assets/images/design/img-10.webp"),
    img11: require("assets/images/design/img-11.webp"),
    img12: require("assets/images/design/img-12.webp"),
    img13: require("assets/images/design/img-13.webp"),
    img14: require("assets/images/design/img-14.webp"),
    img15: require("assets/images/design/img-15.webp"),
    img16: require("assets/images/design/img-16.webp"),
    img17: require("assets/images/design/img-17.webp"),
    img18: require("assets/images/design/img-18.webp"),
  },
};
const requireSources = Object.values(imageSources.designImages);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <DesignPage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as DesignPage };
