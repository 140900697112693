/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { FadeLoader } from "react-spinners";

type Props = {
  color?: string;
  bgColor?: string;
  height?: number;
  cssOverride?: SerializedStyles;
};

export const ImageFadeLoader: React.FC<Props> = ({
  color = "#AAAAAA",
  bgColor = "#EEEEEE",
  height = 280,
  cssOverride,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          height: ${height}px;
          background-color: ${bgColor};
        `,
      ]}
    >
      <FadeLoader color={color} />
    </div>
  );
};
