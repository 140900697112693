/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { NewWindowIcon } from "components/atoms/NewWindowIcon/NewWindowIcon";
import {
  OneColumnLayoutTemplate,
  OneColumnLayoutTemplateSlotProps,
} from "components/templates/OneColumnLayoutTemplate/OneColumnLayoutTemplate";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useNavMenu } from "hooks/useNavMenu";
import { usePageTransitionHandler } from "hooks/usePageTransition";
import { useRouter } from "hooks/useRouter";
import { useTheme } from "hooks/useTheme";
import React, { useCallback, useMemo } from "react";
import { ANIMATAION_DURATION_MD, convertDurationToSeconds } from "styles/mixin";
import { constants, footerSns } from "utils/constants";

type Props = {
  children: React.ReactNode;
};

export const GlobalLayout: React.FC<Props> = ({ children }: Props) => {
  const { open, toggleOpen, setIsItemClick } = useNavMenu();
  const {
    toggleTheme,
    mode,
    theme,
    customTheme,
    switchToDarkHeaderTheme,
    switchToLightHeaderTheme,
    isHeaderDarkMode,
  } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const { currentPath } = usePageTransitionHandler();
  const { location } = useRouter();

  const handleHeaderThemeChange = useCallback(() => {
    if (!open && mode === "light") {
      // Case ライトモード時にひらいた
      switchToDarkHeaderTheme();
    } else if (open && mode === "light") {
      // Case ライトモード時に閉じた
      switchToLightHeaderTheme();
    }
  }, [
    mode,
    open,
    isHeaderDarkMode,
    switchToDarkHeaderTheme,
    switchToLightHeaderTheme,
  ]);

  const handleHamburgerButtonClick = useCallback(() => {
    toggleOpen();
    handleHeaderThemeChange();
  }, [toggleOpen, handleHeaderThemeChange]);

  const handleChangeThemeButtonClick = useCallback(() => {
    toggleTheme();
  }, [toggleTheme]);

  const handleLogoButtonClick = useCallback(() => {
    toggleOpen();
  }, [toggleOpen]);

  const handleOverlayClick = useCallback(() => {}, []);

  const handleNavItemClick = useCallback(() => {
    toggleOpen();
    handleHeaderThemeChange();

    if (currentPath === location.pathname) {
      setIsItemClick(true);
    }
  }, [toggleOpen, handleHeaderThemeChange, currentPath, location]);

  const duration = useMemo(
    () => convertDurationToSeconds(ANIMATAION_DURATION_MD),
    []
  );

  const slotProps: OneColumnLayoutTemplateSlotProps = useMemo(
    () => ({
      header: {
        logo: {
          dark: customTheme["header"].logo.dark,
        },
        hamburgerButton: {
          onClick: handleHamburgerButtonClick,
          open,
          color: customTheme["header"].hamburgerButton.color,
          openColor: customTheme["header"].hamburgerButton.openColor,
        },
        changeThemeButton: {
          dark: mode === "dark",
          styleProps: {
            light: {
              color: customTheme["header"].changeThemeButton.colors.light,
            },
            dark: {
              color: customTheme["header"].changeThemeButton.colors.dark,
            },
          },
          onClick: handleChangeThemeButtonClick,
        },
      },
      navMenu: {
        show: open,
        logo: {
          onLogoClick: handleLogoButtonClick,
        },
        overlay: {
          onOverlayClick: handleOverlayClick,
        },
        navItems: {
          items: [
            {
              path: constants.pages.about.url,
              scrollToID: constants.pages.about.scrollToID,
              children: constants.pages.about.title,
            },
            {
              path: constants.pages.product.url.index,
              scrollToID: constants.pages.product.scrollToID,
              children: constants.pages.product.title,
            },
            {
              path: constants.pages.software.url,
              scrollToID: constants.pages.software.scrollToID.index,
              children: constants.pages.software.title,
            },
            {
              path: constants.pages.design.url,
              scrollToID: constants.pages.design.scrollToID,
              children: constants.pages.design.title,
            },
            {
              path: constants.pages.contact.url,
              scrollToID: constants.pages.contact.scrollToID,
              children: constants.pages.contact.title,
            },
            {
              path: constants.links.kawaichiBlog,
              children: (
                <span
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span>BLOG</span>
                  <NewWindowIcon
                    size={pc ? 32 : 28}
                    color={theme.palette.common.white}
                  />
                </span>
              ),
            },
          ],
          onNavItemClick: handleNavItemClick,
        },
        footer: {
          iconProps: footerSns,
          uiLibraryLink: constants.links.portfolioStorybook,
        },
      },
    }),
    [
      theme,
      open,
      handleChangeThemeButtonClick,
      handleHamburgerButtonClick,
      handleLogoButtonClick,
      handleNavItemClick,
      handleOverlayClick,
      footerSns,
      pc,
      customTheme,
    ]
  );

  return (
    <OneColumnLayoutTemplate
      duration={duration}
      slotProps={slotProps}
      mediaQuery={mediaQuery}
      theme={theme}
    >
      {children}
    </OneColumnLayoutTemplate>
  );
};
