/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as StorybookIcon } from "assets/images/product/portfolio/icon-storybook.svg";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import { GradationDividerMediumTitle } from "components/atoms/GradationDividerMediumTitle/GradationDividerMediumTitle";
import { InformationLinkText } from "components/atoms/InformationLinkText/InformationLinkText";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { KawaichiPortfolioTechnicalStack } from "components/molecules/KawaichiPortfolioTechnicalStack/KawaichiPortfolioTechnicalStack";

import { LinkText } from "components/atoms/LinkText/LinkText";
import { DiscList } from "components/molecules/DiscList/DiscList";
import { Table } from "components/molecules/Table/Table";
import {
  ProductIntroductionTemplate,
  ProductIntroductionTemplateSlotProps,
} from "components/templates/ProductIntroductionTemplate/ProductIntroductionTemplate";
import {
  ProductPortfolioPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useProduct } from "hooks/useProduct";
import { useTheme } from "hooks/useTheme";
import { ProductIntroductionSection } from "organisms/ProductIntroductionSection/ProductIntroductionSection";
import React, { useMemo } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { constants } from "utils/constants";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";
import { MainLayout } from "../MainLayout/MainLayout";

const ProductPortfolioPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const {
    imageSources: { productPortfolio },
  } = useImageSources();
  const { open, src, alt, handleImageCardClick, handleImageModalClose } =
    useProduct();

  const slotProps: ProductIntroductionTemplateSlotProps = useMemo(
    () => ({
      title: (
        <EngTitle
          cssOverride={css`
            margin-bottom: ${theme.spacing.xxs};
            word-break: normal !important;
          `}
        >
          KAWAICHI PORTFOLIO
        </EngTitle>
      ),
      subText: "PORTFOLIO SITE",
      description: <BodyText>私のポートフォリオサイトです</BodyText>,
      information: (
        <>
          <InformationLinkText
            label="WEB: "
            href={constants.links.portfolio}
            color={theme.palette.text}
            showNewWindowIcon
          />
          <InformationLinkText
            label="STORYBOOK: "
            href={constants.links.portfolioStorybook}
            color={theme.palette.text}
            showNewWindowIcon
          />
        </>
      ),
      technicalStack: {
        icons: (
          <KawaichiPortfolioTechnicalStack
            bgColor={defaultTheme.palette.contrastText}
          />
        ),
        table: {
          data: [
            ["フロントエンド", "React 18, TypeScript, SCSS, Atomic Design"],
            ["バックエンド", "Node.js, REST API, SendGrid"],
            ["インフラ", "AWS(API Gateway / Lambda / SSM  等), Netlify"],
            ["モニタリング", "CloudWatch, SNS, Chatbot"],
            ["プロビジョニング", "Terraform"],
            [
              "主要パッケージ等",
              "Recoil, @emotion/react, React Hook Form, Zod, destyle.css(リセットCSS)",
            ],
            [
              "品質保証",
              "Jest, React Testing Library, Storybook, Chromatic, Hygen(コードジェネレーター)",
            ],
            ["ビルド", " CSR(SPA)"],
            ["コーディング規約", " ESLint & Prettier"],
            [
              "その他",
              "reCAPTCHA(v3), GoogleAnalytics(GA4), GoogleSearchConsole, Illustrator",
            ],
          ],
        },
      },
      images: {
        product: {
          src: productPortfolio.brandBanner,
          alt: "KAWAICHI PORTFOLIO",
        },
      },
      slot1: (
        <ProductIntroductionSection
          title="各種図面"
          theme={theme}
          bgColor={theme.palette.background}
          slot1={
            <Columns1to2Wrapper
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    インフラ構成図
                  </GradationDividerMediumTitle>
                  <BodyText>
                    フロントエンドはNetlifyでホスティングし、バックエンド(お問い合わせフォーム)はAWS
                    API
                    GatewayとLambdaでデプロイしています。また、IaCであるTerraformを用いてインフラをコード化しています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-left: auto; // 右端に寄せる
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productPortfolio.diagram1,
                          "インフラ構成図"
                        )
                      }
                    >
                      <img
                        src={productPortfolio.diagram1}
                        alt={"インフラ構成図"}
                        css={css`
                          ${mixin.boxShadow};
                          ${mixin.hoverTransitionOpacityEffect};
                          border: ${defaultTheme.borderWidth.sm}px solid;
                          border-color: ${theme.palette.gray.background};
                          border-radius: ${defaultTheme.borderRadius.base};
                          max-width: 100%;
                          min-width: 300px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-left: auto; // 右端に寄せる
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productPortfolio.diagram2,
                          "ワークフロー図"
                        )
                      }
                    >
                      <img
                        src={productPortfolio.diagram2}
                        alt={"ワークフロー図"}
                        css={css`
                          ${mixin.boxShadow};
                          ${mixin.hoverTransitionOpacityEffect};
                          border: ${defaultTheme.borderWidth.sm}px solid;
                          border-color: ${theme.palette.gray.background};
                          border-radius: ${defaultTheme.borderRadius.base};
                          max-width: 100%;
                          min-width: 300px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    ワークフロー図
                  </GradationDividerMediumTitle>
                  <BodyText>
                    ブランチは開発と本番で分け、リポジトリへのプッシュをトリガーに、それぞれでWebサイトおよびStorybookをデプロイしています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot2: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="工夫した点"
          theme={theme}
          bgColor={theme.palette.contrastText}
          slot1={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productPortfolio.ingenuity1}
                      alt={
                        "Atomic Designによる再利用性を高めたコンポーネント設計"
                      }
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 439px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    Atomic Designによる再利用性を高めたコンポーネント設計
                  </GradationDividerMediumTitle>
                  <BodyText>
                    コンポーネントの再利用性向上のため、コンポーネント設計およびディレクトリ構成として「Atomic
                    Design<sup>(※1)</sup>」を採用しています。
                  </BodyText>
                  <BodyText>
                    <br />
                    UIの構造をatoms・molecules・organisms・templates・pagesという5段階に分割するという考え方で、これにより、デザインの一貫性を保つとともに、コンポーネントの再利用性・疎結合性が高まることによる、コンテンツの追加やデザインの変更などの開発スピードを向上させています。
                  </BodyText>
                  <BodyText>
                    <br />
                    よく議論される問題点として、「バケツリレー、データフェッチ問題」「開発メンバー間のコンポーネント分割単位の認識ズレ」「エンジニア・デザイナー間のコミュニケーション齟齬」がありますが、バケツリレーはCompositionパターンで解消でき、かつデータフェッチのない静的サイトであるということ、コーディング・デザインを一人で担当する個人開発であるということを踏まえて、これらの問題点をカバーできると考え採用しました。
                  </BodyText>
                  <div
                    css={css`
                      margin-top: ${defaultTheme.spacing.xs};
                    `}
                  >
                    <p
                      css={css`
                        ${mixin.text.note};
                        color: ${theme.palette.gray.text};
                      `}
                    >
                      {`※1: 出典: `}
                      <LinkText
                        item={{
                          href: "https://bradfrost.com/blog/post/atomic-web-design/",
                          value: "Atomic Design",
                        }}
                      />
                    </p>
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    <StorybookIcon
                      width="28"
                      height="28"
                      css={css`
                        vertical-align: middle;
                        margin-right: ${defaultTheme.spacing.xxxs};
                        position: relative;
                        top: -2px;
                      `}
                    />
                    <span>Storybookを導入・公開</span>
                  </GradationDividerMediumTitle>
                  <BodyText>
                    サイトの品質向上のため、想定したレイアウトや色のズレがないか、適切なスタイルが当てられており再利用できるコンポーネントとなっているか、などをすばやくブラウザでチェックできるツールである「Storybook」を導入しています。
                  </BodyText>
                  <BodyText>
                    <br />
                    また、Atomic
                    Designによって作成されたコンポーネントは全てストーリー化し、コンポーネントライブラリとしてStorybookを
                    <LinkText
                      cssOverride={css`
                        color: ${theme.palette.gray.text};
                      `}
                      item={{
                        href: constants.links.portfolioStorybook,
                        value: "Web上で公開",
                      }}
                    />
                    しています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productPortfolio.ingenuity2,
                          "Storybookを導入・公開"
                        )
                      }
                    >
                      <img
                        src={productPortfolio.ingenuity2}
                        alt={"Storybookを導入・公開"}
                        css={css`
                          ${mixin.boxShadow};
                          ${mixin.hoverTransitionOpacityEffect};
                          border-radius: ${defaultTheme.borderRadius.base};
                          width: 100%;
                          max-width: 439px;
                          min-width: 320px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot3={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    そのほか工夫した点
                  </GradationDividerMediumTitle>
                  <DiscList
                    cssOverride={css`
                      flex-direction: column;
                      align-items: flex-start;
                    `}
                    slotProps={{
                      item: {
                        style: css`
                          ${mixin.text.body};
                          margin-bottom: ${defaultTheme.spacing.xxxs};
                          @media screen and (min-width: ${defaultTheme
                              .breakpoints.md}) {
                            margin-bottom: ${defaultTheme.spacing.xxs};
                          }
                        `,
                      },
                    }}
                    items={[
                      "Illustratorを用いたロゴの作成",
                      "AWS、Terraform、SendGridを使ったお問い合わせフォームの実装",
                      "reCAPTCHA(v3)によるスパム対策",
                      "GoogleAnalytics(GA4)を導入、内部トラフィックは「Google アナリティクス オプトアウト アドオン」によって除外",
                      "EmotionによるCSSのスクラッチ",
                      "ダークテーマ/ライトテーマを切り替えできるボタンの実装",
                      "SVG形式およびWebP形式による画像サイズ最適化",
                      "画像のプリロードによるUX向上",
                      "コードジェネレーター HygenによるJestおよびStorybookテンプレートの自動生成",
                      "OGP対応",
                    ]}
                  />
                  <BodyText
                    cssOverride={css`
                      text-align: right;
                    `}
                  >
                    など...
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot3: (
        <section>
          <FullWidthOuter
            pt={defaultTheme.spacing.lg}
            pb={defaultTheme.spacing.lg}
            bgColor={theme.palette.background}
          >
            <BaseInner
              width="100%"
              cssOverride={css`
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing.lg};
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.text}>
                    開発期間および主なアップデート履歴
                  </GradationDividerMediumTitle>
                </FadeInOnScrollByComponent>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <Table
                    borderColor={defaultTheme.palette.gray.button[100]}
                    headers={["年月", "内容"]}
                    data={[
                      [
                        "〜現在",
                        "現在も継続してアップデート中(デザイン変更やコンテンツ追加など)",
                      ],
                      [
                        "2024年5月",
                        "お問い合わせフォームをAWS & Terraformで実装",
                      ],
                      [
                        "2024年3月",
                        "状態管理をReact Context API から Recoil へ変更",
                      ],
                      ["2022年8月", "React & emotion へリプレイス"],
                      ["2022年7月", "Vue.js 3 & Tailwind にて初回リリース"],
                    ]}
                  />
                </FadeInOnScrollByComponent>
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>
      ),
      imageModal: {
        open,
        src,
        alt,
        onClose: handleImageModalClose,
      },
    }),
    [mode, theme, pc, open, src, alt]
  );

  return (
    <ProductIntroductionTemplate
      id={constants.pages.product.id}
      theme={theme}
      mediaQuery={mediaQuery}
      slotProps={slotProps}
    />
  );
};

const imageSources: ProductPortfolioPageImageSourcesState = {
  productPortfolio: {
    brandBanner: require("assets/images/product/kawaichi-portfolio.webp"),
    diagram1: require("assets/images/product/portfolio/infra.webp"),
    diagram2: require("assets/images/product/portfolio/workflow.webp"),
    ingenuity1: require("assets/images/product/portfolio/atomic-design.webp"),
    ingenuity2: require("assets/images/product/portfolio/storybook-screenshot.webp"),
  },
};
const requireSources = Object.values(imageSources.productPortfolio);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <ProductPortfolioPage />
  </MainLayout>
);

const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as ProductPortfolioPage };
