import { atom, useRecoilState, useSetRecoilState } from "recoil";

type PageTransitionState = {
  pageTransitionStatus: {
    pageTransition: boolean;
    pageTransition2: boolean;
  };
  currentPath: string;
};

const defaultValues: PageTransitionState = {
  pageTransitionStatus: {
    pageTransition: false,
    pageTransition2: false,
  },
  currentPath: window.location.pathname,
};

const pageTransitionState = atom<PageTransitionState>({
  key: "pageTransitionState",
  default: defaultValues,
});

export const usePageTransitionState = () => {
  const [state, setState] = useRecoilState(pageTransitionState);
  return {
    ...state,
  };
};

export const usePageTransitionMutators = () => {
  const setState = useSetRecoilState(pageTransitionState);

  return {
    setPageTransition: setState,
  };
};
