/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { defaultTheme } from "styles/theme";

type InputProps = {
  id: string;
  register?: UseFormRegisterReturn;
  errors?: FieldError;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input: React.FC<InputProps> = ({
  id,
  errors,
  register,
  ...args
}) => {
  return (
    <input
      {...args}
      {...register}
      id={id}
      css={css`
        width: 100%;
        border: ${defaultTheme.borderWidth.sm}px solid;
        border-color: ${!!errors
          ? defaultTheme.palette.error
          : defaultTheme.palette.gray.button[100]};
        border-radius: ${defaultTheme.borderRadius.base};
        padding: 0 0.5rem;
        line-height: 2.5;
      `}
    />
  );
};
