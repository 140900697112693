/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { GradationDividerSmallTitle } from "components/atoms/GradationDividerSmallTitle/GradationDividerSmallTitle";
import { Theme, defaultTheme } from "styles/theme";

type Props = {
  topComponent?: React.ReactNode;
  technicalStack: React.ReactNode;
  other: React.ReactNode;
  theme: Theme;
};

export const ProductCardBottomComponent: React.FC<Props> = ({
  topComponent,
  technicalStack,
  other,
  theme,
}) => {
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      {topComponent && (
        <div
          css={css`
            width: fit-content;
            margin-bottom: 1.25rem;
          `}
        >
          {topComponent}
        </div>
      )}
      <div
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: ${defaultTheme.spacing.xs};
          `}
        >
          <GradationDividerSmallTitle
            cssOverride={css`
              width: 100%;
              color: ${theme.palette.gray.text};
              margin-bottom: 0.75rem;
            `}
          >
            主な使用技術(ホバー又はタッチで詳細表示)
          </GradationDividerSmallTitle>
          {technicalStack}
        </div>
        <div>
          <GradationDividerSmallTitle
            cssOverride={css`
              width: 100%;
              color: ${theme.palette.gray.text};
              margin-bottom: 0.75rem;
            `}
          >
            その他
          </GradationDividerSmallTitle>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            {other}
          </div>
        </div>
      </div>
    </div>
  );
};
