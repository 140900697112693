/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { css } from "@storybook/theming";
import React from "react";
import { mixin } from "styles/mixin";

type BodyTextProps = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
};

export const BodyText: React.FC<BodyTextProps> = ({
  children,
  cssOverride,
}) => {
  return (
    <p
      css={[
        cssOverride,
        css`
          ${mixin.text.body};
        `,
      ]}
    >
      {children}
    </p>
  );
};
