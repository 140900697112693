/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { TextLogoV2 } from "components/atoms/TextLogoV2/TextLogoV2";
import { KawaichiPortfolioTechnicalStack } from "components/molecules/KawaichiPortfolioTechnicalStack/KawaichiPortfolioTechnicalStack";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import React from "react";
import { defaultTheme, Theme } from "styles/theme";
import { CLIENT_BASEURL } from "utils/config";
import { constants } from "utils/constants";
import { ProductCard, ProductCardSlotProps } from "../ProductCard/ProductCard";
import { ProductCardBottomComponent } from "../ProductCardBottomComponent/ProductCardBottomComponent";

type Props = {
  src: string;
  slotProps?: ProductCardSlotProps & {
    button?: {
      color?: string;
    };
  };
  theme: Theme;
};

export const KawaichiPortfolioProductCard: React.FC<Props> = ({
  src,
  slotProps,
  theme,
}) => {
  return (
    <ProductCard
      title={
        <TextLogoV2
          darkColor={slotProps?.title?.color}
          lightColor={slotProps?.title?.color}
          size={14}
          cssOverride={css`
            ${slotProps?.title?.style};
          `}
        />
      }
      description="このサイトであり、私のポートフォリオサイトです"
      image={{
        src: src,
        alt: "kawaichiportfolio",
      }}
      href={`${CLIENT_BASEURL}${constants.pages.product.url.portfolio}`}
      slotProps={{
        title: {
          color: slotProps?.title?.color,
          style: css`
            letter-spacing: ${defaultTheme.letterSpacing.xs};
          `,
        },
        image: {
          style: css`
            border-style: solid;
            border-width: ${defaultTheme.borderWidth.sm}px;
            border-color: ${defaultTheme.palette.gray.button[100]};
          `,
        },
        tag: slotProps?.tag,
        description: slotProps?.description,
      }}
      bottomComponent={
        <ProductCardBottomComponent
          theme={theme}
          topComponent={
            <a
              href={`${CLIENT_BASEURL}${constants.pages.product.url.portfolio}`}
              target="_blank"
              rel="noreferrer"
            >
              <LongArrowButton
                textColor={slotProps?.button?.color}
                arrowColor={slotProps?.button?.color}
                cssOverride={css`
                  color: ${slotProps?.button?.color};
                `}
              >
                詳細を見る
              </LongArrowButton>
            </a>
          }
          technicalStack={
            <KawaichiPortfolioTechnicalStack
              bgColor={defaultTheme.palette.background}
            />
          }
          other={
            <LabelRadiusTags
              bold={false}
              tags={[
                { value: "Atomic Design" },
                { value: "コンポーネント駆動開発" },
              ]}
            />
          }
        />
      }
    />
  );
};
