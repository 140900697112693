/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as DrumLogo } from "assets/images/logo/drum.svg";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { NewWindowIcon } from "components/atoms/NewWindowIcon/NewWindowIcon";
import { withLoader } from "components/atoms/withLoader/withLoader";
import {
  AboutTemplate,
  AboutTemplateSlotProps,
} from "components/templates/AboutTemplate/AboutTemplate";
import {
  AboutPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useTheme } from "hooks/useTheme";
import React, { useMemo } from "react";
import { mixin } from "styles/mixin";
import { constants } from "utils/constants";
import { MainLayout } from "../MainLayout/MainLayout";

const AboutPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const {
    imageSources: { profilePhoto: profilePhotoSrc },
  } = useImageSources();

  const slotProps: AboutTemplateSlotProps = useMemo(
    () => ({
      title: constants.pages.about.title,
      profile: {
        title: "PROFILE",
        dark: mode === "dark",
        mobilePhoto: (
          <img
            src={profilePhotoSrc}
            alt="profilephoto"
            css={css`
              max-width: 320px;
            `}
          />
        ),
        pcPhoto: (
          <img
            src={profilePhotoSrc}
            alt="profilephoto"
            css={css`
              width: 100%;
              min-width: 320px;
              max-width: 375px; // MEMO: 元の画像サイズ ÷ 2のpxを指定してサイズを最適化する(Retinaディスプレイ用: 750px = 375px * 2)
            `}
          />
        ),
        name: "カワイチ",
        smallName: "KAWAICHI",
        work: "WEBエンジニア",
        logoColor: theme.palette.text,
        textColor: theme.palette.gray.text,
        logoSize: {
          pc: 44,
          mobile: 38,
        },
      },
      cards: {
        subTitleColor: theme.palette.gray.text,
        career: {
          subTitle: "",
          text: (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing.sm};
              `}
            >
              <BodyText>
                1993年生まれ。モノづくり好き。
                <br />
                趣味は邦楽ロック鑑賞、楽器演奏(ドラム)。
                <br />
                最近、ノリと勢いで叩いてみた動画を投稿しはじめる。
                {/* drumアイコン */}
                <a
                  href={constants.links.kawaichidrums}
                  target="_blank"
                  rel="noreferrer"
                  css={css`
                    ${mixin.hoverOpacityEffect};
                    display: flex;
                    align-items: center;
                    color: ${theme.palette.gray.text};
                    /*color: ${theme.palette.text};*/
                    text-decoration: none;
                    margin-top: ${theme.spacing.xxs};
                    width: fit-content;
                  `}
                >
                  <DrumLogo
                    css={css`
                      ${mixin.hoverOpacityEffect};
                      position: relative;
                      top: -2px;
                      display: flex;
                      align-items: center;
                      width: 22px;
                      height: auto;
                      fill: ${theme.palette.gray.text};
                      margin-right: ${theme.spacing.xxs};
                    `}
                  />
                  <span
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    叩いてみた
                  </span>
                  <NewWindowIcon
                    color={theme.palette.gray.text}
                    size={18}
                    weight={26}
                    cssOverride={css`
                      ${mixin.changeThemeStroke};
                      display: inline-block;
                      // MEMO: テキストラインを視覚上に揃えるため調整
                      position: relative;
                      top: 5px;
                    `}
                  />
                </a>
              </BodyText>
              <BodyText>
                2022年より受託開発のWebエンジニアとして、Next.js(TypeScript)・Laravel(PHP)・AWS等を用いた複数の新規開発案件に従事。
                <br />
                現在はアドテク系SaaS企業にて、主にTypeScriptとAWSで開発している。
              </BodyText>
              <BodyText>
                デザイン性とユーザーへの使いやすさを意識したWebアプリケーションの開発が得意。
                <br />
                「効率化して、空いた時間で、より良いサイクルをつくる」をモットーに、アイデアをプロダクトにすばやく実現できるよう心掛けている。
              </BodyText>
            </div>
          ),
          circleArrowButton: {
            arrowColor: theme.palette.contrastText,
            circleColor: theme.palette.gray.button[100],
          },
        },
        skillset: {
          subTitle: "保有スキル",
          bgColor: theme.palette.gray.background,
          fieldset: {
            borderColor: theme.palette.gray.button[100],
            titleColor: theme.palette.gray.button[100],
          },
          frontEnd: {
            title: "FRONT END",
            items: [
              "React(Next.js)",
              "Vue.js(Nuxt.js)",
              "TypeScript",
              "Redux",
              "Storybook",
              "Jest / React Testing Library",
              "MUI",
              "Tailwind CSS",
              "Emotion / SCSS",
            ],
          },
          backEnd: {
            title: "BACK END",
            items: [
              "Express(TypeScript / Node.js)",
              "Echo(Go)",
              "Laravel(PHP)",
              "REST API",
              "GraphQL",
              "MySQL",
              "Amazon Cognito",
            ],
          },
          infra: {
            title: "INFRASTRUCTURE",
            items: [
              {
                level1: ["AWS"],
                level2: [
                  "ECS / Fargate",
                  "RDS / AURORA",
                  "API Gateway",
                  "AppSync",
                  "DynamoDB",
                  "Lambda",
                  "S3 / CloudFront",
                  "Route53 / ACM",
                  "Amplify",
                ],
              },
              {
                level1: ["AWS CDK"],
              },
              {
                level1: ["Terraform"],
              },
              {
                level1: ["Docker"],
              },
            ],
          },
          others: {
            title: "OTHERS",
            items: [
              "Illustrator",
              "GitHub Actions",
              "Git",
              "Python",
              "Notion",
              "Figma",
            ],
          },
        },
      },
    }),
    [mode, theme]
  );

  return (
    <AboutTemplate
      id={constants.pages.about.id}
      slotProps={slotProps}
      theme={theme}
    />
  );
};

const imageSources: AboutPageImageSourcesState = {
  profilePhoto: require("assets/images/about/profile-photo.webp"),
};
const requireSources = Object.values(imageSources);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <AboutPage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as AboutPage };
