/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";
import { ZoomInImage } from "components/atoms/ZoomInImage/ZoomInImage";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { PositionedWrapper } from "utils/Wrapper/PositionedWrapper/PositionedWrapper";

export type ProductCardSlotProps = {
  title?: {
    color?: string;
    style?: SerializedStyles;
  };
  image?: {
    style?: SerializedStyles;
  };
  tag?: {
    color?: string;
  };
  description?: {
    color?: string;
  };
};

export type ProductCardProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  image: {
    src: string;
    alt: string;
  };
  href?: string;
  cssOverride?: SerializedStyles;
  tag?: string;
  slotProps?: ProductCardSlotProps;
  bottomComponent?: React.ReactNode;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  title,
  description,
  image,
  href,
  cssOverride,
  tag,
  slotProps,
  bottomComponent,
}) => {
  return (
    <section>
      <div
        css={[
          cssOverride,
          css`
            margin-bottom: ${defaultTheme.spacing.sm};
          `,
        ]}
      >
        <PositionedWrapper
          top="0.75rem"
          right="0.75rem"
          zIndex={1}
          cssOverride={css`
            width: "100%";
            display: flex;
            justify-content: flex-start;
          `}
        >
          {tag && (
            <FillRadiusTag
              cssOverride={css`
                font-size: 0.875rem; /* 14px */
              `}
              textColor={defaultTheme.palette.contrastText}
              backgroundColor={`${defaultTheme.palette.gray.button[200]}cc`}
              width={10}
              height={14}
              radius={6}
            >
              {tag}
            </FillRadiusTag>
          )}
        </PositionedWrapper>
        <div>
          {href ? (
            <a
              css={css`
                display: block;
                width: fit-content;
              `}
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              <div
                css={css`
                  ${slotProps?.image?.style};
                  overflow: hidden; // MEMO: transform: scale()ではみだした部分を非表示にする
                  border-radius: ${defaultTheme.borderRadius.base};
                `}
              >
                <ZoomInImage
                  src={image.src}
                  alt={image.alt}
                  css={css`
                    ${mixin.hoverTransitionOpacityEffect};
                    max-width: 100%;
                  `}
                />
              </div>
            </a>
          ) : (
            <div
              css={css`
                ${slotProps?.image?.style};
                overflow: hidden; // MEMO: transform: scale()ではみだした部分を非表示にする
                border-radius: ${defaultTheme.borderRadius.base};
              `}
            >
              <ZoomInImage
                src={image.src}
                alt={image.alt}
                css={css`
                  ${mixin.hoverTransitionOpacityEffect};
                  max-width: 100%;
                `}
              />
            </div>
          )}
        </div>
      </div>

      <div
        css={css`
          margin-bottom: 0.75rem;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.75rem;
          `}
        >
          <h3
            css={[
              slotProps?.title?.style,
              css`
                ${mixin.text.subtitle};
                font-weight: 700;
                color: ${slotProps?.title?.color};
              `,
            ]}
          >
            {title}
          </h3>
        </div>
        <p
          css={css`
            margin-top: ${defaultTheme.spacing.xxs};
            ${mixin.text.body};
            line-height: 1.75rem;

            color: ${slotProps?.description?.color};
          `}
        >
          {description}
        </p>
      </div>

      {bottomComponent && (
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
          `}
        >
          {bottomComponent}
        </div>
      )}
    </section>
  );
};
