/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { mixin } from "styles/mixin";
import { SubTitle } from "../../atoms/SubTitle/SubTitle";

type Props = {
  cssOverride?: SerializedStyles;
  subTitleColor?: string;
  subTitle: React.ReactNode;
  children: React.ReactNode;
};
export const SubTitleCard: React.FC<Props> = ({
  cssOverride,
  subTitleColor = "#666666",
  subTitle,
  children,
}: Props) => {
  return (
    <div css={[cssOverride]}>
      <h2>
        <SubTitle color={subTitleColor}>{subTitle}</SubTitle>
      </h2>
      <div
        css={css`
          ${mixin.text.body}
          text-align: left;
        `}
      >
        {children}
      </div>
    </div>
  );
};
