/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: ReactNode;
  color?: string;
};

export const SubTitle: React.FC<Props> = ({
  className,
  cssOverride,
  children = "SUBTITLE",
  color = "#444444",
}) => {
  return (
    <div
      className={"" + (className ? ` ${className}` : "")}
      css={[
        css`
          ${mixin.text.subtitle};
          font-weight: 700;
          margin-bottom: 1.25rem;
          color: ${color};
        `,
        cssOverride,
      ]}
    >
      {children}
    </div>
  );
};
