/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Direction,
  SlideOverlay,
} from "components/organisms/SlideOverlay/SlideOverlay";
import {
  ANIMATAION_DURATION_MD,
  convertDurationToSeconds,
  convertDurationToSecondsStr,
} from "styles/mixin";
import { defaultTheme } from "styles/theme";

type Props = {
  show: boolean;
  direction?: Direction;
};

export const SlideInOutOverlay = ({
  show,
  direction = "right-to-left",
}: Props) => {
  return (
    <SlideOverlay
      direction={direction}
      opacityRange={{ from: 1, to: 1 }}
      cssOverride={css`
        z-index: ${defaultTheme.zIndex.slideInOutOverlay};
        pointer-events: none;
      `}
      show={show}
      duration={convertDurationToSeconds(ANIMATAION_DURATION_MD)}
      color={defaultTheme.palette.common.overlay}
    >
      <div
        data-testid="slide-overlay"
        css={css`
          transition: transform
            ${convertDurationToSecondsStr(ANIMATAION_DURATION_MD)} ease;
          transform: translateY(${show ? "-100%" : "0"});
        `}
      />
    </SlideOverlay>
  );
};
