/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { css } from "@storybook/theming";
import React, { useMemo } from "react";

type HyphenTextProps = {
  children: React.ReactNode;
  size?: number;
  hyphenColor?: string;
  cssOverride?: SerializedStyles;
};

export const HyphenText: React.FC<HyphenTextProps> = ({
  children,
  size = 10,
  hyphenColor = "#666666",
  cssOverride,
}) => {
  const width = useMemo(() => 8 * (size / 10), [size]);
  const height = useMemo(() => 2.5 * (size / 10), [size]);
  const paddingLeft = useMemo(() => 20 * (size / 10), [size]);

  return (
    <div
      css={[
        cssOverride,
        css`
          position: relative;
          padding-left: ${paddingLeft}px;

          &::before {
            // ハイフンをCSSで描画
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: ${width}px;
            height: ${height}px;
            background-color: ${hyphenColor};
            transform: translateY(-50%);
          }
        `,
      ]}
    >
      {children}
    </div>
  );
};
