/** @jsxImportSource @emotion/react */
import { ThemeProvider } from "@emotion/react";
import { CursorIndicatorContainer } from "components/atoms/CursorIndicator/CursorIndicatorContainer";
import { CustomRoutes } from "CustomRoutes";
import { RootProvider } from "provider/RootProvider";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "styles/GlobalStyles";
import { defaultTheme, Theme } from "styles/theme";

/*** Main Render ***/
export const App: React.FC = () => {
  const [theme] = useState<Theme>(defaultTheme);

  return (
    <RootProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <CursorIndicatorContainer />
          <CustomRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </RootProvider>
  );
};
