/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { LongArrowButton } from "components/atoms/LongArrowButton/LongArrowButton";
import { ShotokuTextLogo } from "components/atoms/ShotokuTextLogo/ShotokuTextLogo";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import { ShotokuTechnicalStack } from "components/molecules/ShotokuTechnicalStack/ShotokuTechnicalStack";
import React from "react";
import { defaultTheme, Theme } from "styles/theme";
import { CLIENT_BASEURL } from "utils/config";
import { constants } from "utils/constants";
import { ProductCard, ProductCardSlotProps } from "../ProductCard/ProductCard";
import { ProductCardBottomComponent } from "../ProductCardBottomComponent/ProductCardBottomComponent";

type Props = {
  src: string;
  slotProps?: ProductCardSlotProps & {
    button?: {
      color?: string;
    };
  };
  theme: Theme;
};

export const ShotokuProductCard: React.FC<Props> = ({
  src,
  slotProps,
  theme,
}) => {
  return (
    <ProductCard
      title={
        <ShotokuTextLogo
          darkColor={slotProps?.title?.color}
          lightColor={slotProps?.title?.color}
          cssOverride={slotProps?.title?.style}
        />
      }
      description="法人税を税額控除できる制度としてメジャーである 「所得拡大促進税制」 の計算を、簡単に・スピーディーに行うことができる本格的なエクセルマクロです"
      image={{
        src: src,
        alt: "shotoku",
      }}
      href={`${CLIENT_BASEURL}${constants.pages.product.url.shotoku}`}
      slotProps={slotProps}
      bottomComponent={
        <ProductCardBottomComponent
          theme={theme}
          topComponent={
            <a
              href={`${CLIENT_BASEURL}${constants.pages.product.url.shotoku}`}
              target="_blank"
              rel="noreferrer"
            >
              <LongArrowButton
                textColor={slotProps?.button?.color}
                arrowColor={slotProps?.button?.color}
                cssOverride={css`
                  color: ${slotProps?.button?.color};
                `}
              >
                詳細を見る
              </LongArrowButton>
            </a>
          }
          technicalStack={
            <ShotokuTechnicalStack bgColor={defaultTheme.palette.background} />
          }
          other={
            <LabelRadiusTags
              bold={false}
              tags={[{ value: "フルスクラッチ開発" }]}
            />
          }
        />
      }
    />
  );
};
