/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RadiusArrowButton } from "components/atoms/RadiusArrowButton/RadiusArrowButton";
import { SubTitle } from "components/atoms/SubTitle/SubTitle";
import { defaultTheme } from "styles/theme";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { CenteredOuter } from "utils/Wrapper/CenteredOuter/CenteredOuter";

export type SuccessTemplateSlotProps = {
  title: string;
  titleColor: string;
  topButton: {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    color: string;
  };
  description: React.ReactNode;
};

type Props = {
  id: string;
  slotProps: SuccessTemplateSlotProps;
};

export const SuccessTemplate: React.FC<Props> = ({ id, slotProps }) => {
  const { title, titleColor, topButton, description } = slotProps;

  return (
    <BaseWrapper id={id}>
      <section>
        <CenteredOuter
          cssOverride={css`
            max-width: ${defaultTheme.maxWidth.md};
            text-align: center;
          `}
        >
          <h1>
            <SubTitle color={titleColor}>{title}</SubTitle>
          </h1>
          <p
            css={css`
              display: inline-block;
              text-align: center;
              margin-bottom: ${defaultTheme.spacing.md};
            `}
          >
            {description}
          </p>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <RadiusArrowButton
              direction="left"
              buttonColor={topButton.color}
              outlined
              onClick={topButton.onClick}
            >
              {topButton.text}
            </RadiusArrowButton>
          </div>
        </CenteredOuter>
      </section>
    </BaseWrapper>
  );
};
