/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { LineWithTextSubTitle } from "components/atoms/LineWithTextSubTitle/LineWithTextSubTitle";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { BatchFileRenamerProductCard } from "components/organisms/BatchFileRenamerProductCard/BatchFileRenamerProductCard";
import { FitScreenWindowProductCard } from "components/organisms/FitScreenWindowProductCard/FitScreenWindowProductCard";
import { KawaichiBlogProductCard } from "components/organisms/KawaichiBlogProductCard/KawaichiBlogProductCard";
import { KawaichiPortfolioProductCard } from "components/organisms/KawaichiPortfolioProductCard/KawaichiPortfolioProductCard";
import { ProgrammingOtasukeProductCard } from "components/organisms/ProgrammingOtasukeProductCard/ProgrammingOtasukeProductCard";
import { ShotokuProductCard } from "components/organisms/ShotokuProductCard/ShotokuProductCard";
import { TalkStockProductCard } from "components/organisms/TalkStockProductCard/TalkStockProductCard";
import {
  ProductTemplate,
  ProductTemplateSlotProps,
} from "components/templates/ProductTemplate/ProductTemplate";
import { useActiveMediaQuery } from "hooks/useActiveMediaQuery";
import {
  ProductPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useTheme } from "hooks/useTheme";
import React, { useMemo } from "react";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { constants } from "utils/constants";
import { AdjustPositionWrapper } from "utils/Wrapper/AdjustPositionWrapper/AdjustPositionWrapper";
import { Columns1to2to3Wrapper } from "utils/Wrapper/Columns1to2to3Wrapper/Columns1to2to3Wrapper";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { MainLayout } from "../MainLayout/MainLayout";

const ProductPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const { activeMediaQuery } = useActiveMediaQuery({
    mobileBreakPoint: theme.breakpoints.md,
    tabletBreakPoint: theme.breakpoints.lg,
  });
  const { pc, mobile } = useMediaQuery({
    pcBreakPoint: theme.breakpoints.md,
  });
  const {
    imageSources: { products: productsSrc },
  } = useImageSources();

  const getProductWrapperSize = () => {
    if (activeMediaQuery === "pc") {
      return { left: 6, right: 4 };
    } else if (activeMediaQuery === "tablet") {
      return { left: 4, right: 6 };
    } else {
      return { left: 5, right: 5 };
    }
  };

  const productCardSlotProps = useMemo(
    () => ({
      title: {
        color: theme.palette.text,
        style: css``,
      },
      tag: {
        color: theme.palette.gray.text,
      },
      description: {
        color: theme.palette.gray.text,
      },
      button: {
        color: theme.palette.text,
      },
    }),
    [theme]
  );

  const lineWithTextBorderColor = theme.palette.gray.button[100];
  const categoryTitleColor = theme.palette.gray.button[100];

  const productTemplateSlotProps: ProductTemplateSlotProps = useMemo(
    () => ({
      title: constants.pages.product.title,
      subText: "個人開発プロダクト",
      bgColor: "inherit",

      subTitleColor: theme.palette.gray.text,
      description: (
        <BodyText>これまでの、私が個人開発したプロダクトです。</BodyText>
      ),
      product: {
        service: (
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.none}
            direction="spot"
          >
            <LineWithTextSubTitle
              title="SERVICE"
              slotProps={{
                lineWithText: {
                  borderColor: lineWithTextBorderColor,
                },
                subTitle: {
                  color: categoryTitleColor,
                },
              }}
            />
            <Columns1to2Wrapper
              gap={{
                columns1: 0,
                columns2: defaultTheme.spacing.md,
              }}
              leftSize={4}
              rightSize={4}
              leftComponent={
                <div
                  css={css`
                    ${mobile
                      ? `margin-bottom: ${defaultTheme.spacing.lg};`
                      : ""}
                  `}
                >
                  <TalkStockProductCard
                    theme={theme}
                    src={productsSrc.talkstock}
                    slotProps={productCardSlotProps}
                  />
                </div>
              }
              rightComponent={
                <ProgrammingOtasukeProductCard
                  theme={theme}
                  src={productsSrc.programmingOtasuke}
                  slotProps={productCardSlotProps}
                  mediaQuery={{ pc, mobile }}
                />
              }
            />
          </FadeInOnScrollByComponent>
        ),
        website: (
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.none}
            direction="spot"
          >
            <Columns1to2Wrapper
              gap={{
                columns1: 0,
                columns2: defaultTheme.spacing.md,
              }}
              leftSize={getProductWrapperSize().left}
              rightSize={getProductWrapperSize().right}
              leftComponent={
                <LineWithTextSubTitle
                  title="WEBSITE"
                  slotProps={{
                    lineWithText: {
                      borderColor: lineWithTextBorderColor,
                    },
                    subTitle: {
                      color: categoryTitleColor,
                    },
                  }}
                />
              }
              rightComponent={
                <AdjustPositionWrapper useFor="LineWithText">
                  <KawaichiPortfolioProductCard
                    theme={theme}
                    src={productsSrc.kawaichiPortfolio}
                    slotProps={productCardSlotProps}
                  />
                </AdjustPositionWrapper>
              }
            />
          </FadeInOnScrollByComponent>
        ),
        blog: (
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.none}
            direction="spot"
          >
            <Columns1to2Wrapper
              gap={{
                columns1: 0,
                columns2: defaultTheme.spacing.md,
              }}
              leftSize={getProductWrapperSize().left}
              rightSize={getProductWrapperSize().right}
              leftComponent={
                <LineWithTextSubTitle
                  title="BLOG"
                  slotProps={{
                    lineWithText: {
                      borderColor: lineWithTextBorderColor,
                    },
                    subTitle: {
                      color: categoryTitleColor,
                    },
                  }}
                />
              }
              rightComponent={
                <AdjustPositionWrapper useFor="LineWithText">
                  <KawaichiBlogProductCard
                    theme={theme}
                    src={productsSrc.kawaichiBlog}
                    slotProps={productCardSlotProps}
                  />
                </AdjustPositionWrapper>
              }
            />
          </FadeInOnScrollByComponent>
        ),
        software: (
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.none}
            direction="spot"
          >
            <LineWithTextSubTitle
              title="SOFTWARE"
              slotProps={{
                lineWithText: {
                  borderColor: lineWithTextBorderColor,
                },
                subTitle: {
                  color: categoryTitleColor,
                },
              }}
            />
            <Columns1to2to3Wrapper
              firstComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <FitScreenWindowProductCard
                    theme={theme}
                    src={productsSrc.fitscreenwindow}
                    slotProps={productCardSlotProps}
                  />
                </FadeInOnScrollByComponent>
              }
              secondComponent={
                <FadeInOnScrollByComponent
                  delay={pc ? 200 : 0}
                  direction="spot"
                >
                  <BatchFileRenamerProductCard
                    theme={theme}
                    src={productsSrc.batchfilerenamer}
                    slotProps={productCardSlotProps}
                  />
                </FadeInOnScrollByComponent>
              }
              thirdComponent={
                <FadeInOnScrollByComponent
                  delay={pc ? 400 : 0}
                  direction="spot"
                >
                  <ShotokuProductCard
                    theme={theme}
                    src={productsSrc.shotoku}
                    slotProps={productCardSlotProps}
                  />
                </FadeInOnScrollByComponent>
              }
            />
          </FadeInOnScrollByComponent>
        ),
      },
    }),
    [mode, theme, pc, activeMediaQuery]
  );

  return (
    <ProductTemplate
      id={constants.pages.product.id}
      theme={theme}
      slotProps={productTemplateSlotProps}
    />
  );
};

const imageSources: ProductPageImageSourcesState = {
  products: {
    batchfilerenamer: require("assets/images/product/batchfilerenamer.webp"),
    fitscreenwindow: require("assets/images/product/fitscreenwindow.webp"),
    kawaichiBlog: require("assets/images/product/kawaichi-blog.webp"),
    programmingOtasuke: require("assets/images/product/programming-otasuke.webp"),
    kawaichiPortfolio: require("assets/images/product/kawaichi-portfolio.webp"),
    shotoku: require("assets/images/product/shotoku.webp"),
    talkstock: require("assets/images/product/talkstock.webp"),
  },
};
const requireSources = Object.values(imageSources.products);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <ProductPage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as ProductPage };
