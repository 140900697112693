/** @jsxImportSource @emotion/react */
import { FadeInUnderLineListType } from "components/molecules/FadeInUnderLineList/FadeInUnderLineList";
import { SNSTextIconsType } from "components/molecules/SNSTextIcons/SNSTextIcons";
import { Header } from "components/organisms/Header/Header";
import { NavMenuV3 } from "components/organisms/NavMenuV3/NavMenuV3";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { Theme } from "styles/theme";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";

export type OneColumnLayoutTemplateSlotProps = {
  header: {
    logo: {
      dark: boolean;
    };
    hamburgerButton: {
      onClick: React.MouseEventHandler<HTMLButtonElement>;
      open: boolean;
      color: string;
      openColor: string;
    };
    changeThemeButton: {
      dark?: boolean;
      styleProps?: {
        light?: {
          color?: string;
        };
        dark?: {
          color?: string;
        };
      };
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
  };
  navMenu: {
    show: boolean;
    logo: {
      onLogoClick: () => void;
    };
    overlay: {
      onOverlayClick: () => void;
    };
    navItems: {
      items: FadeInUnderLineListType[];
      onNavItemClick: () => void;
    };
    footer: {
      iconProps: SNSTextIconsType;
      uiLibraryLink: string;
    };
  };
};

export type OneColumnLayoutTemplateProps = {
  slotProps: OneColumnLayoutTemplateSlotProps;
  children: React.ReactNode;
  duration?: number;
  mediaQuery: MediaQuery;
  theme: Theme;
};

export const OneColumnLayoutTemplate: React.FC<OneColumnLayoutTemplateProps> =
  ({
    slotProps,
    children,
    mediaQuery,
    theme,
  }: OneColumnLayoutTemplateProps) => {
    return (
      <BaseWrapper id="layout">
        <header>
          <Header
            id="header"
            slotProps={slotProps.header}
            mediaQuery={mediaQuery}
            theme={theme}
          />
          <nav>
            <NavMenuV3
              show={slotProps.navMenu.show}
              slotProps={slotProps.navMenu}
            />
          </nav>
        </header>
        <main>{children}</main>
      </BaseWrapper>
    );
  };
