/** @jsxImportSource @emotion/react */
import { MoonLoader as ReactSpinnersMoonLoader } from "react-spinners";

type Props = {
  color?: string;
};

export const MoonLoader: React.FC<Props> = ({ color = "#444444" }) => {
  return <ReactSpinnersMoonLoader color={color} size={40} />;
};
