/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DiscText } from "components/atoms/DiscText/DiscText";
import { FadeInSequentialText } from "components/atoms/FadeInSequentialText/FadeInSequentialText";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import { GradationDividerTitle } from "components/atoms/GradationDividerTitle/GradationDividerTitle";
import { HyphenText } from "components/atoms/HyphenText/HyphenText";
import { LineWithText } from "components/atoms/LineWithText/LineWithText";
import { SubTitle } from "components/atoms/SubTitle/SubTitle";
import { DiscList } from "components/molecules/DiscList/DiscList";
import { List } from "components/molecules/List/List";
import { Profile } from "components/molecules/Profile/Profile";
import { FieldSet } from "components/organisms/FieldSet/FieldSet";
import { SubTitleCard } from "components/organisms/SubTitleCard/SubTitleCard";
import { mixin } from "styles/mixin";
import { Theme, defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { AdjustPositionWrapper } from "utils/Wrapper/AdjustPositionWrapper/AdjustPositionWrapper";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { Columns1to2to4Wrapper } from "utils/Wrapper/Columns1to2to4Wrapper/Columns1to2to4Wrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";

const styles = {
  fieldSetWrapper: css`
    font-weight: 700;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
  `,
  fieldSetTitle: css`
    ${mixin.text.fieldSetTitle}
    font-weight: normal;
    white-space: nowrap;
  `,
  fieldSetContent: css`
    max-width: 85%;
    padding-top: 0;
    @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
      max-width: 60%;
      padding-top: 26px;
    }
  `,
  fieldSetContentList: css`
    text-transform: uppercase;
    letter-spacing: ${defaultTheme.letterSpacing.xs};
  `,
  fieldSetContentListItem: css`
    ${mixin.text.body};
    margin-bottom: ${defaultTheme.spacing.xs};
    @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
      margin-bottom: ${defaultTheme.spacing.sm};
    }
  `,
  lineWithTextTitle: css`
    ${mixin.text.lineWithTextTitle}
  `,
};

export type AboutTemplateSlotProps = {
  title: string;
  profile: {
    title: string;
    dark: boolean;
    mobilePhoto: React.ReactNode;
    pcPhoto: React.ReactNode;
    name: React.ReactNode;
    smallName: React.ReactNode;
    work: React.ReactNode;
    age?: React.ReactNode;
    address?: React.ReactNode;
    logoColor: string;
    textColor: string;
    logoSize: {
      pc: number;
      mobile: number;
    };
  };
  cards: {
    subTitleColor: string;
    career: {
      subTitle: React.ReactNode;
      text: React.ReactNode;
      circleArrowButton: {
        circleColor: string;
        arrowColor: string;
      };
    };
    skillset: {
      subTitle: React.ReactNode;
      bgColor: string;
      fieldset: {
        borderColor: string;
        titleColor: string;
      };
      frontEnd: {
        title: string;
        items: React.ReactNode[];
      };
      backEnd: {
        title: string;
        items: React.ReactNode[];
      };
      infra: {
        title: string;
        items: {
          level1: React.ReactNode[];
          level2?: React.ReactNode[];
        }[];
      };
      others: {
        title: string;
        items: React.ReactNode[];
      };
    };
  };
};

type Props = {
  id: string;
  theme: Theme;
  slotProps: AboutTemplateSlotProps;
};

export const AboutTemplate: React.FC<Props> = ({ id, slotProps, theme }) => {
  const { title, profile, cards } = slotProps;

  return (
    <BaseWrapper id={id}>
      <section>
        <FullWidthOuter bgColor="inherit">
          <BaseInner
            width="100%"
            pt={defaultTheme.spacing.base.top}
            pb={defaultTheme.spacing.title.bottom}
          >
            <h1>
              <GradationDividerTitle
                cssOverride={css`
                  margin-bottom: ${theme.spacing.title.bottom};
                `}
              >
                <FadeInSequentialText>{title}</FadeInSequentialText>
              </GradationDividerTitle>
            </h1>
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <div>
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.short}
                    direction="spot"
                  >
                    <LineWithText
                      borderColor={cards.subTitleColor}
                      cssOverride={css`
                        margin-bottom: ${defaultTheme.spacing.sm};
                      `}
                    >
                      <h2>
                        <SubTitle
                          color={cards.subTitleColor}
                          cssOverride={css`
                            ${styles.lineWithTextTitle}
                            font-weight: normal;
                            letter-spacing: ${defaultTheme.letterSpacing.xs};
                            margin-bottom: 0;
                          `}
                        >
                          {profile.title}
                        </SubTitle>
                      </h2>
                    </LineWithText>
                  </FadeInOnScrollByComponent>
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.medium}
                    direction="spot"
                  >
                    <div
                      css={css`
                        display: block;
                        margin-bottom: ${defaultTheme.spacing.sm};
                        text-align: left; // 左端に寄せる
                        @media screen and (min-width: ${defaultTheme.breakpoints
                            .md}) {
                          display: none;
                        }
                      `}
                    >
                      {profile.mobilePhoto}
                    </div>
                  </FadeInOnScrollByComponent>
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.medium}
                    direction="spot"
                  >
                    <Profile
                      color={profile.textColor}
                      name={profile.name}
                      smallName={profile.smallName}
                      work={
                        <div
                          css={css`
                            display: flex;
                            justify-content: flex-start;
                            margin-bottom: 0.5rem;
                          `}
                        >
                          {profile.work}
                        </div>
                      }
                      age={profile.age}
                      address={profile.address}
                      cssOverride={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-bottom: 2.5rem;
                      `}
                    />
                  </FadeInOnScrollByComponent>
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.medium}
                    direction="bottom"
                  >
                    <SubTitleCard
                      subTitle={cards.career.subTitle}
                      subTitleColor={cards.subTitleColor}
                      cssOverride={css`
                        text-align: left;
                        max-width: ${defaultTheme.breakpoints.md};
                      `}
                    >
                      {cards.career.text}
                    </SubTitleCard>
                  </FadeInOnScrollByComponent>
                </div>
              }
              rightComponent={
                <div>
                  <FadeInOnScrollByComponent
                    delay={defaultTheme.delay.medium}
                    direction="spot"
                  >
                    <AdjustPositionWrapper useFor="LineWithText">
                      <div
                        css={css`
                          display: none;
                          @media screen and (min-width: ${defaultTheme
                              .breakpoints.md}) {
                            display: block;
                            margin-left: auto; // 右端に寄せる
                          }
                        `}
                      >
                        {profile.pcPhoto}
                      </div>
                    </AdjustPositionWrapper>
                  </FadeInOnScrollByComponent>
                </div>
              }
            />
          </BaseInner>
        </FullWidthOuter>
      </section>

      <section>
        <BaseInner>
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.none}
            direction="spot"
          >
            <GradationDivider
              size={defaultTheme.borderWidth.sm}
              cssOverride={css`
                margin-bottom: ${defaultTheme.spacing.title.bottom};
              `}
            />
          </FadeInOnScrollByComponent>
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.none}
            direction="spot"
          >
            <SubTitleCard
              subTitle={cards.skillset.subTitle}
              subTitleColor={cards.subTitleColor}
              cssOverride={css`
                margin-bottom: 6rem;
              `}
            >
              <Columns1to2to4Wrapper
                firstComponent={
                  <FieldSet
                    title={cards.skillset.frontEnd.title}
                    slotProps={{
                      fieldset: {
                        borderColor: cards.skillset.fieldset.borderColor,
                        style: styles.fieldSetWrapper,
                      },
                      title: {
                        color: cards.skillset.fieldset.titleColor,
                        style: styles.fieldSetTitle,
                      },
                    }}
                  >
                    <div css={styles.fieldSetContent}>
                      <DiscList
                        items={cards.skillset.frontEnd.items}
                        slotProps={{
                          item: {
                            style: styles.fieldSetContentListItem,
                          },
                        }}
                        cssOverride={styles.fieldSetContentList}
                      />
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                        `}
                      >
                        ETC
                      </div>
                    </div>
                  </FieldSet>
                }
                secondComponent={
                  <FieldSet
                    title={cards.skillset.backEnd.title}
                    slotProps={{
                      fieldset: {
                        borderColor: cards.skillset.fieldset.borderColor,
                        style: styles.fieldSetWrapper,
                      },
                      title: {
                        color: cards.skillset.fieldset.titleColor,
                        style: styles.fieldSetTitle,
                      },
                    }}
                  >
                    <div css={styles.fieldSetContent}>
                      <DiscList
                        slotProps={{
                          item: {
                            style: styles.fieldSetContentListItem,
                          },
                        }}
                        items={cards.skillset.backEnd.items}
                        cssOverride={styles.fieldSetContentList}
                      />
                    </div>
                  </FieldSet>
                }
                thirdComponent={
                  <FieldSet
                    title={cards.skillset.infra.title}
                    slotProps={{
                      fieldset: {
                        borderColor: cards.skillset.fieldset.borderColor,
                        style: styles.fieldSetWrapper,
                      },
                      title: {
                        color: cards.skillset.fieldset.titleColor,
                        style: css`
                          ${mixin.text.smallFieldSetTitle}
                          white-space: nowrap;
                          font-weight: normal;
                        `,
                      },
                    }}
                  >
                    <div css={styles.fieldSetContent}>
                      {cards.skillset.infra.items.map((item1, index) => (
                        <>
                          <List>
                            {item1.level1.map((item2, index) => (
                              <li
                                key={index}
                                css={css`
                                  display: flex;
                                `}
                              >
                                <DiscText
                                  cssOverride={css`
                                    ${mixin.text.body};
                                    margin-bottom: ${item1.level2
                                      ? "0"
                                      : "15px"};
                                    padding-right: 20px;
                                    width: auto;

                                    @media screen and (min-width: ${defaultTheme
                                        .breakpoints.md}) {
                                      width: 100%;
                                      padding-right: 0;
                                      margin-bottom: ${item1.level2
                                        ? "0"
                                        : "20px"};
                                    }
                                  `}
                                >
                                  {item2}
                                </DiscText>
                              </li>
                            ))}
                          </List>
                          <List
                            cssOverride={css`
                              margin-top: 0;
                              @media screen and (min-width: ${defaultTheme
                                  .breakpoints.xs}) {
                                margin-top: 10px;
                              }
                              @media screen and (min-width: ${defaultTheme
                                  .breakpoints.sm}) {
                                margin-top: 0;
                              }
                            `}
                          >
                            {item1.level2 &&
                              item1.level2.map((item2, index) => (
                                <li
                                  key={index}
                                  css={css`
                                    ${mixin.text.description}
                                    padding-left: 10px;

                                    margin-bottom: ${item1.level2 &&
                                    index === item1.level2.length - 1
                                      ? "15px"
                                      : "20px"};

                                    @media screen and (min-width: ${defaultTheme
                                        .breakpoints.sm}) {
                                      margin-top: ${item1.level2 &&
                                      index === 0 &&
                                      "10px"};

                                      margin-bottom: ${item1.level2 &&
                                      index === item1.level2.length - 1
                                        ? "20px"
                                        : "12px"};
                                    }
                                  `}
                                >
                                  <HyphenText>{item2}</HyphenText>
                                </li>
                              ))}
                          </List>
                        </>
                      ))}
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                        `}
                      >
                        ETC
                      </div>
                    </div>
                  </FieldSet>
                }
                fourthComponent={
                  <FieldSet
                    title={cards.skillset.others.title}
                    slotProps={{
                      fieldset: {
                        borderColor: cards.skillset.fieldset.borderColor,
                        style: styles.fieldSetWrapper,
                      },
                      title: {
                        color: cards.skillset.fieldset.titleColor,
                        style: styles.fieldSetTitle,
                      },
                    }}
                  >
                    <div css={styles.fieldSetContent}>
                      <DiscList
                        slotProps={{
                          item: {
                            style: styles.fieldSetContentListItem,
                          },
                        }}
                        items={cards.skillset.others.items}
                        cssOverride={styles.fieldSetContentList}
                      />
                    </div>
                  </FieldSet>
                }
              />
            </SubTitleCard>
          </FadeInOnScrollByComponent>
        </BaseInner>
      </section>
    </BaseWrapper>
  );
};
