/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

const styles = css``;

type Props = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
  size?: "small" | "medium";
};
export const JpTitle: React.FC<Props> = ({
  children,
  cssOverride,
  size = "medium",
}: Props) => {
  return (
    <span
      css={[
        size === "small"
          ? css`
              ${mixin.text.smallJpTitle};
              font-weight: 700;
            `
          : css`
              ${mixin.text.jpTitle};
              font-weight: 700;
            `,
        cssOverride,
      ]}
    >
      {children}
    </span>
  );
};
