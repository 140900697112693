/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

type HelperTextProps = {
  children: React.ReactNode;
};

export const HelperText: React.FC<HelperTextProps> = ({ children }) => (
  <p
    css={css`
      ${mixin.text.body};
      color: ${defaultTheme.palette.error};
    `}
  >
    {children}
  </p>
);
