/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import React, { ReactNode } from "react";
import { mixin } from "styles/mixin";
import { Theme } from "styles/theme";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  title?: ReactNode;
  subText?: ReactNode;
  subTextColor?: string;
  theme: Theme;
};

export const EngTitleSubText: React.FC<Props> = ({
  className,
  cssOverride,
  title = "TITLE",
  subText = "SUBTITLE",
  subTextColor = "#444444",
  theme,
}) => {
  return (
    <div
      className={"" + (className ? ` ${className}` : "")}
      css={[
        css`
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
        `,
        cssOverride,
      ]}
    >
      <EngTitle
        cssOverride={css`
          margin-bottom: ${theme.spacing.xxs};
        `}
      >
        {title}
      </EngTitle>
      <div
        css={css`
          ${mixin.text.subtitle};
          font-weight: 700;
          color: ${subTextColor};
        `}
      >
        {subText}
      </div>
    </div>
  );
};
