import { ImageSourcesState } from "hooks/useImageSources";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

const defaultValues: ImageSourcesState = {
  fitScreenWindowImage: "",
  batchFileRenamerImage: "",
  products: {
    batchfilerenamer: "",
    fitscreenwindow: "",
    kawaichiBlog: "",
    programmingOtasuke: "",
    kawaichiPortfolio: "",
    shotoku: "",
    talkstock: "",
  },
  productTalkStock: {
    brandBanner: "",
    ingenuity1_1: "",
    ingenuity1_2: "",
    ingenuity2: "",
  },
  productPortfolio: {
    brandBanner: "",
    diagram1: "",
    diagram2: "",
    ingenuity1: "",
    ingenuity2: "",
  },
  productKawaichiBlog: {
    brandBanner: "",
    achievements1: "",
    diagram1: "",
    diagram2: "",
    ingenuity1: "",
    ingenuity2: "",
  },
  productProgrammingOtasuke: {
    brandBanner: "",
    diagram2: "",
    ingenuity1: "",
    ingenuity2: "",
    ingenuity3: "",
  },
  productFitScreenWindow: {
    brandBanner: "",
    ingenuity1: "",
    ingenuity2: "",
    other1: "",
    other2: "",
    other3: "",
  },
  productShotoku: {
    brandBanner: "",
    ingenuity1: "",
    ingenuity2: "",
    other1: "",
    other2: "",
  },
  designImages: {
    img01: "",
    img02: "",
    img03: "",
    img04: "",
    img05: "",
    img06: "",
    img07: "",
    img08: "",
    img09: "",
    img10: "",
    img11: "",
    img12: "",
    img13: "",
    img14: "",
    img15: "",
    img16: "",
    img17: "",
    img18: "",
  },
  profilePhoto: "",
};

const imageSourcesState = atom<ImageSourcesState>({
  key: "imageSourcesState",
  default: defaultValues,
});

export const useImageSourcesState = () => {
  const [state, setState] = useRecoilState(imageSourcesState);
  return {
    ...state,
  };
};

export const useImageSourcesMutators = () => {
  const setState = useSetRecoilState(imageSourcesState);

  return {
    setImageSources: setState,
  };
};
