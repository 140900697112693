/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type Columns1to2to4WrapperProps = {
  firstComponent?: React.ReactNode;
  secondComponent?: React.ReactNode;
  thirdComponent?: React.ReactNode;
  cssOverride?: SerializedStyles;
};

export const Columns1to2to3Wrapper: React.FC<Columns1to2to4WrapperProps> = ({
  firstComponent,
  secondComponent,
  thirdComponent,
  cssOverride,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          display: grid;

          grid-template-columns: repeat(1, 1fr);
          gap: ${defaultTheme.spacing.lg};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: ${defaultTheme.spacing.lg};
            column-gap: ${defaultTheme.spacing.sm};
          }
          @media screen and (min-width: ${defaultTheme.breakpoints.lg}) {
            grid-template-columns: repeat(3, 1fr);
            gap: ${defaultTheme.spacing.sm};
          }

          // MEMO: どのウィンドウサイズのときでも、各divが均等に縮小されるようにする
          & > div {
            min-width: 0;
          }
        `,
      ]}
    >
      <div>{firstComponent}</div>
      <div>{secondComponent}</div>
      <div>{thirdComponent}</div>
    </div>
  );
};
