/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  ...rest
}) => {
  return (
    <button
      {...rest}
      css={[
        css`
          ${mixin.hoverOpacityEffect};
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          width: fit-content;

          cursor: pointer;
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      {children}
    </button>
  );
};
