/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { VerticalLineSeparator } from "components/atoms/VerticalLineSeparator/VerticalLineSeparator";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

type Props = {
  name: React.ReactNode;
  smallName: React.ReactNode;
  work: React.ReactNode;
  age?: React.ReactNode;
  address?: React.ReactNode;
  cssOverride?: SerializedStyles;
  color?: string;
};

export const Profile: React.FC<Props> = ({
  cssOverride,
  name,
  smallName,
  work,
  age,
  address,
  color = "#666666",
}: Props) => {
  return (
    <div css={[cssOverride]}>
      <div
        css={css`
          display: flex;
          align-items: flex-end;
          gap: ${defaultTheme.spacing.xxs};
          flex-wrap: wrap;
          margin-bottom: ${defaultTheme.spacing.xxs};
        `}
      >
        <div
          css={css`
            ${mixin.text.jpTitle};
            font-weight: 700;
          `}
        >
          {name}
        </div>
        <div
          css={css`
            ${mixin.text.body};
            color: ${color};
            text-transform: uppercase;
            letter-spacing: ${defaultTheme.letterSpacing.xs};
          `}
        >
          {smallName}
        </div>
      </div>
      <div
        css={css`
          ${mixin.text.largeBody};
          font-weight: 700;
          display: flex;
          justify-content: flex-start;
        `}
      >
        {work}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {age && (
          <>
            <div
              css={css`
                ${mixin.text.body};

                text-transform: uppercase;
                letter-spacing: ${defaultTheme.letterSpacing.xs};
                color: ${color};
              `}
            >
              {age}
            </div>
            <VerticalLineSeparator color={color} />
          </>
        )}
        {address && (
          <div
            css={css`
              ${mixin.text.body};

              text-transform: uppercase;
              letter-spacing: ${defaultTheme.letterSpacing.xs};
              color: ${color};
            `}
          >
            {address}
          </div>
        )}
      </div>
    </div>
  );
};
