/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as ExcelVbaLogo } from "assets/images/product/excelvba.svg";
import { ReactComponent as MySqlLogo } from "assets/images/product/mysql.svg";
import { ArrowTooltipButtonWithRef } from "components/atoms/ArrowTooltipButtonWithRef/ArrowTooltipButtonWithRef";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import {
  TechnicalStackWrapper,
  TechnicalStackWrapperProps,
} from "utils/Wrapper/TechnicalStackWrapper/TechnicalStackWrapper";

type Props = TechnicalStackWrapperProps;

export const ShotokuTechnicalStack: React.FC<Props> = ({ ...rest }) => {
  return (
    <TechnicalStackWrapper {...rest}>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              開発言語
            </FillRadiusTag>
            <div>ExcelVBA</div>
          </div>
        }
      >
        <ExcelVbaLogo width={26} height={26} />
      </ArrowTooltipButtonWithRef>
      <ArrowTooltipButtonWithRef
        bgColor={defaultTheme.palette.gray.button[200]}
        title={
          <div
            css={css`
              ${mixin.text.body}
            `}
          >
            <FillRadiusTag
              cssOverride={css`
                ${mixin.text.description}
                font-weight: 700;
              `}
              backgroundColor={defaultTheme.palette.common.white}
              textColor={defaultTheme.palette.gray.button[200]}
              width={10}
              height={2}
            >
              データベース
            </FillRadiusTag>
            <div>MySQL</div>
          </div>
        }
      >
        <MySqlLogo width={40} height={26} />
      </ArrowTooltipButtonWithRef>
    </TechnicalStackWrapper>
  );
};
