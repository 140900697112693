/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { ArrowIcon } from "../ArrowIcon/ArrowIcon";
import { CircleButton } from "../CircleButton/CircleButton";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  circleColor?: string;
  arrowColor?: string;
  direction?: "down" | "up" | "left" | "right";
  circleSize?: number;
  arrowSize?: number;
  weight?: number;
};

export const CircleArrowButton: React.FC<Props> = ({
  className,
  cssOverride,
  circleColor = "#444444",
  arrowColor = "#ffffff",
  direction = "down",
  circleSize = 16,
  arrowSize = 4,
  weight = 2.5,
}) => {
  const styles = (props: Props) => {
    const common = css`
      position: relative;
    `;

    const directionStyles =
      props.direction === "down"
        ? css`
            top: -2.5px;
          `
        : props.direction === "up"
        ? css`
            bottom: -2.5px;
          `
        : props.direction === "left"
        ? css`
            right: -2.5px;
          `
        : props.direction === "right"
        ? css`
            left: -2.5px;
          `
        : {};

    return css`
      ${common}
      ${directionStyles}
    `;
  };
  return (
    <CircleButton
      className={`${className ? className : ""}`}
      cssOverride={cssOverride}
      size={circleSize}
      color={circleColor}
    >
      <ArrowIcon
        direction={direction}
        size={arrowSize}
        weight={weight}
        color={arrowColor}
        cssOverride={styles({ direction })}
      />
    </CircleButton>
  );
};
