/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type FormRowProps = {
  children?: React.ReactNode;
  mb?: string;
  maxWidth?: string;
};

export const FormRow: React.FC<FormRowProps> = ({
  children,
  mb = defaultTheme.spacing.sm,
  maxWidth = "600px",
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      margin-bottom: ${mb ? mb : 0};
      max-width: ${maxWidth};
    `}
  >
    {children}
  </div>
);
