/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CloseButton } from "components/atoms/CloseButton/CloseButton";
import { MoonLoader } from "components/atoms/MoonLoader/MoonLoader";
import { Modal } from "components/organisms/Modal/Modal";
import React from "react";
import { defaultTheme } from "styles/theme";
import { PositionedWrapper } from "utils/Wrapper/PositionedWrapper/PositionedWrapper";

type DialogModalProps = {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  message: React.ReactNode;
  buttons: React.ReactNode;
  loading?: boolean;
};

export const DialogModal: React.FC<DialogModalProps> = ({
  open,
  onClose,
  message,
  buttons,
  loading = false,
}) => {
  return (
    <Modal onClose={onClose} open={open} disableClose={loading}>
      {!loading && (
        <PositionedWrapper top="20px" right="20px">
          <CloseButton
            size={10}
            onClick={onClose}
            backgroundColor={defaultTheme.palette.gray.button[200]}
          />
        </PositionedWrapper>
      )}
      <div
        css={css`
          background-color: ${defaultTheme.palette.common.white};
          padding-left: 2rem;
          padding-right: 2rem;
          padding-top: 4rem;
          padding-bottom: 2rem;
          border-radius: ${defaultTheme.borderRadius.base};
          display: flex;
          flex-direction: column;
          min-width: 300px;
          min-height: 200px;
        `}
      >
        {loading ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              top: 8px;
            `}
          >
            <MoonLoader />
          </div>
        ) : (
          <>
            <div
              css={css`
                margin-bottom: 1rem;
              `}
            >
              {message}
            </div>
            <div
              css={css`
                display: flex;
                justify-content: space-around;
                gap: 1rem;
              `}
            >
              {buttons}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
