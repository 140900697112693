/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { EngTitle } from "../../atoms/EngTitle/EngTitle";

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
  className?: string;
  cssOverride?: SerializedStyles;
};

export const EngTitleDescription: React.FC<Props> = ({
  title,
  description,
  className,
  cssOverride,
}: Props) => {
  return (
    <div className={`${className ? className : ""}`} css={cssOverride}>
      <div
        css={css`
          margin-bottom: 1rem;
          text-align: left;
        `}
      >
        <h2>
          <EngTitle>{title}</EngTitle>
        </h2>
      </div>
      <p
        css={css`
          text-align: left;
          line-height: 2rem;
        `}
      >
        {description}
      </p>
    </div>
  );
};
