/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { BorderRadiusTag } from "components/atoms/BorderRadiusTag/BorderRadiusTag";
import { FillRadiusTag } from "components/atoms/FillRadiusTag/FillRadiusTag";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

export type Tag = {
  value: React.ReactNode;
};

export type LabelRadiusTagsProps = {
  tags: Tag[];
  label?: React.ReactNode;
  labelColor?: string;
  backgroundColor?: string;
  textColor?: string;
  cssOverride?: SerializedStyles;
  outlined?: boolean;
  bold?: boolean;
};
export const LabelRadiusTags: React.FC<LabelRadiusTagsProps> = ({
  tags,
  label = "",
  labelColor = "inherit",
  backgroundColor = "#666666",
  textColor = "#ffffff",
  cssOverride,
  outlined = false,
  bold = true,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          ${mixin.text.description};
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 0.5rem;
        `,
      ]}
    >
      {label && (
        <div
          css={[
            css`
              color: ${labelColor};
            `,
          ]}
        >
          {label}
        </div>
      )}
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {tags.map((tag, index) =>
          outlined ? (
            <BorderRadiusTag
              key={index}
              cssOverride={css`
                font-weight: ${bold ? 600 : "normal"};
              `}
              color={labelColor}
              line={defaultTheme.borderWidth.sm}
              width={10}
              height={10}
            >
              {tag.value}
            </BorderRadiusTag>
          ) : (
            <FillRadiusTag
              key={index}
              cssOverride={css`
                font-weight: ${bold ? 600 : "normal"};
              `}
              backgroundColor={backgroundColor}
              textColor={textColor}
              width={10}
              height={10}
            >
              {tag.value}
            </FillRadiusTag>
          )
        )}
      </div>
    </div>
  );
};
