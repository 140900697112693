import { useCallback, useEffect, useMemo } from "react";
import { useThemeMutators, useThemeState } from "stores/themeStore";
import {
  darkTheme,
  lightTheme,
  mobileSpacing,
  Mode,
  pcSpacing,
  Theme,
} from "styles/theme";
import { useMediaQuery } from "./useMediaQuery";

export type CustomHeaderTheme = {
  logo: {
    dark: boolean;
  };
  hamburgerButton: {
    color: string;
    openColor: string;
  };
  changeThemeButton: {
    dark: boolean;
    colors: {
      light: string;
      dark: string;
    };
  };
};

export type CustomFitScreenWindowTheme = {
  color: string;
};

export type CustomTheme = {
  header: CustomHeaderTheme;
  fitScreenWindow: CustomFitScreenWindowTheme;
};

export type ThemeState = {
  theme: Theme;
  customTheme: CustomTheme;
  mode: Mode;
  active: boolean;
};

export const useTheme = () => {
  const { active, mode, theme, customTheme } = useThemeState();
  const { setTheme } = useThemeMutators();

  const { pc } = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  useEffect(() => {
    if (pc) {
      setTheme((prev) => ({
        ...prev,
        theme: { ...prev.theme, ...pcSpacing },
      }));
    } else {
      setTheme((prev) => ({
        ...prev,
        theme: { ...prev.theme, ...mobileSpacing },
      }));
    }
  }, [pc]);

  // ------------------------------------------------------
  // ヘッダーのテーマオプション
  // ------------------------------------------------------
  const switchToLightHeaderTheme = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      customTheme: {
        ...prev.customTheme,
        header: {
          ...prev.customTheme["header"],
          logo: { dark: false },
          hamburgerButton: {
            color: theme.palette.common.black,
            openColor: theme.palette.common.black,
          },
          changeThemeButton: {
            dark: false,
            colors: {
              light: theme.palette.common.black,
              dark: theme.palette.common.black,
            },
          },
        },
      },
    }));
  }, []);

  const switchToDarkHeaderTheme = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      customTheme: {
        ...prev.customTheme,
        header: {
          ...prev.customTheme["header"],
          logo: { dark: true },
          hamburgerButton: {
            color: theme.palette.common.white,
            openColor: theme.palette.common.white,
          },
          changeThemeButton: {
            dark: true,
            colors: {
              light: theme.palette.common.white,
              dark: theme.palette.common.white,
            },
          },
        },
      },
    }));
  }, []);

  const isHeaderDarkMode = useMemo(() => {
    return customTheme["header"].logo.dark;
  }, [customTheme["header"].logo.dark]);

  const toggleHeaderTheme = useCallback(() => {
    if (isHeaderDarkMode) {
      switchToLightHeaderTheme();
    } else {
      switchToDarkHeaderTheme();
    }
  }, [isHeaderDarkMode, switchToLightHeaderTheme, switchToDarkHeaderTheme]);

  // ------------------------------------------------------
  // テーマの切り替え
  // ------------------------------------------------------
  const switchToLightTheme = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      mode: "light",
      theme: lightTheme,
    }));
    switchToLightHeaderTheme();
  }, [setTheme, switchToLightHeaderTheme]);

  const switchToDarkTheme = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      mode: "dark",
      theme: darkTheme,
    }));
    switchToDarkHeaderTheme();
  }, [setTheme, switchToDarkHeaderTheme]);

  const toggleTheme = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      active: !prev.active,
    }));

    if (active) {
      switchToLightTheme();
    } else {
      switchToDarkTheme();
    }
  }, [active, switchToLightTheme, switchToDarkTheme]);

  // ------------------------------------------------------
  // FitScreenWindowのテーマ
  // ------------------------------------------------------
  const updateFitScreenWindowTheme = useCallback(
    (color: string) => {
      setTheme((prev) => ({
        ...prev,
        customTheme: {
          ...prev.customTheme,
          fitScreenWindow: {
            ...prev.customTheme.fitScreenWindow,
            color: color,
          },
        },
      }));
    },
    [setTheme]
  );

  return {
    theme,
    customTheme,
    mode,
    active,
    switchToLightHeaderTheme,
    switchToDarkHeaderTheme,
    toggleHeaderTheme,
    switchToLightTheme,
    switchToDarkTheme,
    toggleTheme,
    updateFitScreenWindowTheme,
    setTheme,
    isHeaderDarkMode,
  };
};
