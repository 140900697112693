import { atom, useRecoilState, useSetRecoilState } from "recoil";

type NavMenuState = {
  open: boolean;
  isItemClick: boolean;
};

const defaultValues: NavMenuState = {
  open: false,
  isItemClick: false,
};

const navMenuState = atom<NavMenuState>({
  key: "navMenuState",
  default: defaultValues,
});

export const useNavMenuState = () => {
  const [state, setState] = useRecoilState(navMenuState);
  return {
    ...state,
  };
};

export const useNavMenuMutators = () => {
  const setState = useSetRecoilState(navMenuState);

  return {
    setNavMenu: setState,
  };
};
