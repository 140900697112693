/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as MedalLogo } from "assets/images/product/medal.svg";
import { ReactComponent as TailwindLogo } from "assets/images/product/tailwind.svg";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { GradationDividerMediumTitle } from "components/atoms/GradationDividerMediumTitle/GradationDividerMediumTitle";
import { InformationLinkText } from "components/atoms/InformationLinkText/InformationLinkText";
import { JpTitle } from "components/atoms/JpTitle/JpTitle";
import { LinkText } from "components/atoms/LinkText/LinkText";
import { LongArrowIcon } from "components/atoms/LongArrowIcon/LongArrowIcon";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { DiscList } from "components/molecules/DiscList/DiscList";
import { KawaichBlogTechnicalStack } from "components/molecules/KawaichBlogTechnicalStack/KawaichBlogTechnicalStack";
import {
  ProductIntroductionTemplate,
  ProductIntroductionTemplateSlotProps,
} from "components/templates/ProductIntroductionTemplate/ProductIntroductionTemplate";
import {
  ProductKawaichiBlogPageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useProduct } from "hooks/useProduct";
import { useTheme } from "hooks/useTheme";
import { ProductIntroductionSection } from "organisms/ProductIntroductionSection/ProductIntroductionSection";
import React, { useMemo } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme, Theme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { constants } from "utils/constants";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { MainLayout } from "../MainLayout/MainLayout";

type SearchRankingItemProps = {
  theme: Theme;
  searchWord: string;
  searchResultUrl: string;
  searchResultTitle: string;
  achievedDate: string;
  imageUrl: string;
};

const SearchRankingItem: React.FC<SearchRankingItemProps> = ({
  theme,
  searchWord,
  searchResultUrl,
  searchResultTitle,
  achievedDate,
  imageUrl,
}) => (
  <div>
    <p
      css={css`
        display: inline-block;
        ${mixin.text.note};
        position: relative;
        top: 8px;
      `}
    >
      <span>「{searchWord}」</span>
      <div
        css={css`
          position: relative;
          top: -8px;
        `}
      >
        <span>
          <LongArrowIcon
            borderHeight={2}
            size={20}
            cssOverride={css`
              display: inline;
              position: relative;
              top: 7px;
              margin-right: ${defaultTheme.spacing.xxs};
            `}
            direction="right"
          />
          <LinkText
            cssOverride={css`
              color: ${theme.palette.gray.text};
            `}
            item={{
              href: searchResultUrl,
              value: searchResultTitle,
            }}
          />
        </span>
        <p
          css={css`
            ${mixin.text.note};
            display: inline;
            color: ${theme.palette.gray.text};
            margin-left: ${defaultTheme.spacing.xxs};
          `}
        >
          {`(獲得日: ${achievedDate} `}
          <LinkText
            mode="anchor"
            cssOverride={css`
              color: ${theme.palette.gray.text};
            `}
            item={{
              href: imageUrl,
              value: "[画像]",
            }}
          />
          {`)`}
        </p>
      </div>
    </p>
  </div>
);

const ProductKawaichiBlogPage: React.FC = () => {
  const { theme, mode } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const {
    imageSources: { productKawaichiBlog },
  } = useImageSources();
  const { open, src, alt, handleImageCardClick, handleImageModalClose } =
    useProduct();

  const slotProps: ProductIntroductionTemplateSlotProps = useMemo(
    () => ({
      title: (
        <JpTitle
          cssOverride={css`
            margin-bottom: ${theme.spacing.xxs};
          `}
        >
          かわいちのエンジニアぶろぐ！
        </JpTitle>
      ),
      subText: "ENGINEER BLOG",
      description: (
        <BodyText>プログラミングに役立つ記事を投稿しているブログです</BodyText>
      ),
      information: (
        <InformationLinkText
          label="WEB: "
          href={constants.links.kawaichiBlog}
          color={theme.palette.text}
          showNewWindowIcon
        />
      ),
      technicalStack: {
        icons: (
          <KawaichBlogTechnicalStack
            bgColor={defaultTheme.palette.contrastText}
          />
        ),
        table: {
          data: [
            ["フロントエンド", "Next.js 13, TypeScript, SCSS(CSS Modules)"],
            ["バックエンド", "Node.js, GraphQL, microCMS, SendGrid"],
            ["インフラ", "AWS(AppSync / Lambda / DynamoDB / EventBridge 等)"],
            ["モニタリング", "CloudWatch, SNS, Chatbot"],
            ["プロビジョニング", "Amplify, Terraform"],
            [
              "主要パッケージ等",
              "Tailwind CSS, Font Awesome,cheerio(HTMLパーサ),highlight.js(コードシンタックスハイライト), React Hook Form, [GraphQL]aws-amplify, Apollo, [外部API取得]Axios",
            ],
            ["ビルド", "SSG"],
            ["コーディング規約", " ESLint & Prettier"],
            [
              "その他",
              "reCAPTCHA(v3), GoogleTagManager, GoogleAnalytics(GA4), GoogleSearchConsole, Illustrator",
            ],
          ],
        },
      },
      images: {
        product: {
          src: productKawaichiBlog.brandBanner,
          alt: "かわいちのエンジニアぶろぐ！",
        },
      },
      slot1: (
        <ProductIntroductionSection
          pb={defaultTheme.spacing.lg}
          title="実績"
          theme={theme}
          bgColor={theme.palette.background}
          slot1={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    <MedalLogo
                      width={32}
                      height={32}
                      css={css`
                        display: inline-block;
                        position: relative;
                        top: 0.35rem;
                      `}
                    />
                    「vscode 型 表示」でGoogle検索順位1位を獲得
                  </GradationDividerMediumTitle>
                  <BodyText>
                    「vscode 型 表示」の検索ワードで、
                    <LinkText
                      cssOverride={css`
                        color: ${theme.palette.gray.text};
                      `}
                      item={{
                        href: "https://kawaichiblog.com/blog/yr2wzlvn2",
                        value:
                          "【TypeScript】型を調べる方法「VSCodeのホバー表示」",
                      }}
                    />
                    の記事がGoogle検索順位1位<sup>(※)</sup>を獲得しました。
                  </BodyText>
                  <div
                    css={css`
                      margin-top: ${defaultTheme.spacing.xs};
                    `}
                  >
                    <p
                      css={css`
                        ${mixin.text.note};
                        color: ${theme.palette.gray.text};
                      `}
                    >
                      ※: 2024年5月時点
                    </p>
                  </div>
                  <div
                    css={css`
                      margin-top: ${defaultTheme.spacing.lg};
                    `}
                  >
                    <p
                      css={css`
                        ${mixin.text.description};
                        color: ${theme.palette.gray.text};
                      `}
                    >
                      そのほかGoogle検索順位1位を獲得した記事
                    </p>
                    <DiscList
                      cssOverride={css`
                        flex-direction: column;
                        align-items: flex-start;
                      `}
                      slotProps={{
                        item: {
                          style: css`
                            ${mixin.text.body};
                            margin-bottom: ${defaultTheme.spacing.xxxs};
                            @media screen and (min-width: ${defaultTheme
                                .breakpoints.md}) {
                              margin-bottom: ${defaultTheme.spacing.xxs};
                            }
                          `,
                        },
                      }}
                      items={[
                        <SearchRankingItem
                          theme={theme}
                          searchWord="chrome ショートカット 翻訳 切り替え"
                          searchResultUrl="https://kawaichiblog.com/blog/btt-translated-into-japanese"
                          searchResultTitle="【Chrome】ショートカットで日本語と英語原文の翻訳を切り替え【BetterTouchTool】"
                          achievedDate="2024年8月時点"
                          imageUrl="/assets/images/product/blog/seo2.webp"
                        />,
                        <SearchRankingItem
                          theme={theme}
                          searchWord="vscode react import 自動"
                          searchResultUrl="https://www.kawaichiblog.com/blog/ej-gewz8edl8"
                          searchResultTitle="【React×VSCode】自動でimport＆未使用importの削除とソートで爆速開発！"
                          achievedDate="2025年1月時点"
                          imageUrl="/assets/images/product/blog/seo3.webp"
                        />,
                      ]}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-left: auto; // 右端に寄せる
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productKawaichiBlog.achievements1,
                          "「VSCode 型 表示」で検索順位1位獲得"
                        )
                      }
                    >
                      <img
                        src={productKawaichiBlog.achievements1}
                        alt={"「VSCode 型 表示」で検索順位1位獲得"}
                        css={css`
                          ${mixin.boxShadow};
                          ${mixin.hoverTransitionOpacityEffect};
                          border: ${defaultTheme.borderWidth.sm}px solid;
                          border-color: ${theme.palette.gray.background};
                          border-radius: ${defaultTheme.borderRadius.base};
                          max-width: 100%;
                          min-width: 300px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot2: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="各種図面"
          theme={theme}
          bgColor={theme.palette.contrastText}
          slot1={
            <Columns1to2Wrapper
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    インフラ構成図
                  </GradationDividerMediumTitle>
                  <BodyText>
                    AWS
                    Amplifyでホスティング・デプロイしています。コメント機能は、AppSync(GraphQL)・Lambda(Node.js)・DynamoDBで構築および実装しています。
                    また、IaCであるTerraformを用いてインフラの一部(モニタリング等)をコード化しています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-left: auto; // 右端に寄せる
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productKawaichiBlog.diagram1,
                          "インフラ構成図"
                        )
                      }
                    >
                      <img
                        src={productKawaichiBlog.diagram1}
                        alt={"インフラ構成図"}
                        css={css`
                          ${mixin.boxShadow};
                          ${mixin.hoverTransitionOpacityEffect};
                          border: ${defaultTheme.borderWidth.sm}px solid;
                          border-color: ${theme.palette.gray.background};
                          border-radius: ${defaultTheme.borderRadius.base};
                          max-width: 100%;
                          min-width: 300px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-left: auto; // 右端に寄せる
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productKawaichiBlog.diagram2,
                          "ワークフロー図"
                        )
                      }
                    >
                      <img
                        src={productKawaichiBlog.diagram2}
                        alt={"ワークフロー図"}
                        css={css`
                          ${mixin.boxShadow};
                          ${mixin.hoverTransitionOpacityEffect};
                          border: ${defaultTheme.borderWidth.sm}px solid;
                          border-color: ${theme.palette.gray.background};
                          border-radius: ${defaultTheme.borderRadius.base};
                          max-width: 100%;
                          min-width: 300px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    ワークフロー図
                  </GradationDividerMediumTitle>
                  <BodyText>
                    ブランチおよびAmplify環境は開発と本番で分け、microCMS管理画面からコンテンツ更新した際のWebhook通知、リポジトリへのプッシュをトリガーにデプロイしています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot3: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="工夫した点"
          theme={theme}
          bgColor={theme.palette.background}
          slot1={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productKawaichiBlog.ingenuity1}
                      alt={"Jamstackによる静的サイト構築"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 439px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    Jamstackによる静的サイト構築
                  </GradationDividerMediumTitle>
                  <BodyText>
                    アーキテクチャとして、フロントエンドとサーバーコンテンツ(データ)を分離し、静的サイトジェネレーターを使用してWebサイトを構築するJamstackを採用しています。
                  </BodyText>
                  <BodyText>
                    <br />
                    技術選定の候補としてWordPressがありましたが、Jamstackのほうがコストが安い、高速表示できるパフォーマンスがある、また、Next.jsを使ってなにかサイトを作ってみたい、という欲求があったため、こちらを採用しました。
                  </BodyText>
                  <BodyText>
                    <br />
                    また、コンテンツ(ブログ記事など)管理システムとして、日本製のヘッドレスCMSである「microCMS」を利用しています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    <TailwindLogo
                      width={26}
                      height={26}
                      css={css`
                        vertical-align: middle;
                        margin-right: ${defaultTheme.spacing.xxs};
                        position: relative;
                        top: -2px;
                      `}
                    />
                    <span>Tailwindを導入</span>
                  </GradationDividerMediumTitle>
                  <BodyText>
                    サイトのスタイリングには「Tailwind」を導入しています。開発を始めようとした当時に、注目され流行していたCSSフレームワークだったため、「面白そうだし最新の技術を使ってみよう」というのが検討のきっかけでした。
                  </BodyText>
                  <BodyText>
                    <br />
                    色々調べていくうちに、デザインにオリジナリティを持たせつつも実装の時間が短縮できる、というメリットがあると知り、案としてイメージしていた「私のプロフィールアイコンを活かした、デザインにこだわりのあるかわいいサイトにしたい」「でも、お仕事しながらだから時間がない...」という課題を解消してくれそうだったので、導入しました。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productKawaichiBlog.ingenuity2}
                      alt={"Tailwindを導入"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 439px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot3={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    そのほか工夫した点
                  </GradationDividerMediumTitle>
                  <DiscList
                    cssOverride={css`
                      flex-direction: column;
                      align-items: flex-start;
                    `}
                    slotProps={{
                      item: {
                        style: css`
                          ${mixin.text.body};
                          margin-bottom: ${defaultTheme.spacing.xxxs};
                          @media screen and (min-width: ${defaultTheme
                              .breakpoints.md}) {
                            margin-bottom: ${defaultTheme.spacing.xxs};
                          }
                        `,
                      },
                    }}
                    items={[
                      "Illustratorを用いたロゴの作成",
                      "コメント投稿時、Webhook通知による運営者への通知・IPブラックリスト判定・NGワード判定",
                      "お問い合わせフォームの実装",
                      "reCAPTCHA(v3)によるスパム対策",
                      "バッチ処理による定期的な注目記事の更新 および Webhook通知による運営者への処理成功/失敗の通知",
                      "コンテンツ更新時、Webhook通知による自動デプロイ",
                      "SEO対策として、カテゴリー・タグ・検索結果等の一覧ページをnoindexによって検索エンジンからインデックス除外",
                      "セキュリティ対策として、FWのバージョン情報を隠蔽",
                      <>
                        <BodyText>
                          パーマリンクの最適化 (
                          <LinkText
                            cssOverride={css`
                              color: ${theme.palette.gray.text};
                            `}
                            item={{
                              href: "https://developers.google.com/search/docs/crawling-indexing/url-structure?hl=ja",
                              value:
                                "参考: Google における URL 構造のベスト プラクティス",
                            }}
                          />
                          )
                        </BodyText>
                      </>,
                      "GoogleTagManager および GoogleAnalytics(GA4)を導入 ※内部トラフィックは「Google アナリティクス オプトアウト アドオン」によって除外",
                      "OGP対応",
                    ]}
                  />
                  <BodyText
                    cssOverride={css`
                      text-align: right;
                    `}
                  >
                    など...
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      imageModal: {
        open,
        src,
        alt,
        onClose: handleImageModalClose,
      },
    }),
    [mode, theme, pc, open, src, alt]
  );

  return (
    <ProductIntroductionTemplate
      id={constants.pages.product.id}
      theme={theme}
      mediaQuery={mediaQuery}
      slotProps={slotProps}
    />
  );
};

const imageSources: ProductKawaichiBlogPageImageSourcesState = {
  productKawaichiBlog: {
    brandBanner: require("assets/images/product/kawaichi-blog.webp"),
    achievements1: require("assets/images/product/blog/seo.webp"),
    diagram1: require("assets/images/product/blog/infra.webp"),
    diagram2: require("assets/images/product/blog/workflow.webp"),
    ingenuity1: require("assets/images/product/blog/jamstack-type.webp"),
    ingenuity2: require("assets/images/product/blog/tailwind-type.webp"),
  },
};
const requireSources = Object.values(imageSources.productKawaichiBlog);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <ProductKawaichiBlogPage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as ProductKawaichiBlogPage };
