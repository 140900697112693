/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { GradationDivider } from "../GradationDivider/GradationDivider";

type Props = {
  children: React.ReactNode;
  color?: string;
  cssOverride?: SerializedStyles;
};

export const GradationDividerMediumTitle: React.FC<Props> = ({
  children,
  color = defaultTheme.palette.gray.text,
  cssOverride,
}) => (
  <div
    css={[
      cssOverride,
      css`
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
      `,
    ]}
  >
    <div
      css={css`
        ${mixin.text.subtitle};
        font-weight: 700;
        color: ${color};
      `}
    >
      {children}
    </div>
    <div
      css={css`
        margin-top: ${defaultTheme.spacing.xs};
        margin-bottom: ${defaultTheme.spacing.sm};
        width: 60px;
      `}
    >
      <GradationDivider size={defaultTheme.borderWidth.md} />
    </div>
  </div>
);
