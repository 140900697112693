/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  color?: string;
  size?: number;
  borderHeight?: number; // 線の太さ
  direction?: "left" | "right";
};

const styles = ({
  color,
  borderHeight,
  direction,
}: Pick<Props, "color" | "borderHeight" | "direction">) => {
  return css`
    ${mixin.changeThemeStroke};
    stroke: ${color};
    stroke-width: ${borderHeight}px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform: ${direction === "left" ? "rotate(180deg)" : "none"};
  `;
};

export const LongArrowIcon: React.FC<Props> = ({
  className,
  cssOverride,
  color = "#444444",
  size = 48,
  borderHeight = 2,
  direction = "right",
}) => {
  return (
    <>
      <div
        css={[styles({ color, borderHeight, direction }), cssOverride]}
        className={`${className ? className : ""}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 48 48"
          fill="none"
        >
          <polyline
            points="35 18 41 24 35 30"
            strokeWidth={borderHeight}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="7"
            y1="24"
            x2="41"
            y2="24"
            strokeWidth={borderHeight}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </>
  );
};
