import { css } from "@emotion/css";

const disableScrollStyles = css`
  overflow: hidden; // スクロールを無効化
`;

export const disableScroll = () => {
  document.body.classList.add(disableScrollStyles);
}

export const enableScroll = () => {
  document.body.classList.remove(disableScrollStyles);
}