/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { JpTitle } from "components/atoms/JpTitle/JpTitle";
import React from "react";
import { Theme, defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "../../utils/Wrapper/BaseInner/BaseInner";
import { FullWidthOuter } from "../../utils/Wrapper/FullWidthOuter/FullWidthOuter";

type Props = {
  theme: Theme;
  title: string;
  slot1: React.ReactNode;
  slot2?: React.ReactNode;
  slot3?: React.ReactNode;
  bgColor?: string;
  pt?: string;
  pb?: string;
};

export const ProductIntroductionSection: React.FC<Props> = ({
  theme,
  title,
  slot1,
  slot2,
  slot3,
  bgColor = defaultTheme.palette.background,
  pt = "0",
  pb = defaultTheme.spacing.xl,
}) => {
  return (
    <section>
      <FullWidthOuter pt={pt} pb={pb} bgColor={bgColor}>
        <BaseInner
          width="100%"
          cssOverride={css`
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing.lg};
          `}
        >
          <section>
            <FadeInOnScrollByComponent
              delay={defaultTheme.delay.short}
              direction="spot"
            >
              <div
                css={css`
                  margin-bottom: ${theme.spacing.lg};
                `}
              >
                <JpTitle>{title}</JpTitle>
              </div>
            </FadeInOnScrollByComponent>
            {slot1}
          </section>
          {slot2 && <section>{slot2}</section>}
          {slot3 && <section>{slot3}</section>}
        </BaseInner>
      </FullWidthOuter>
    </section>
  );
};
