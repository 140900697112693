/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { css } from "@storybook/theming";
import React from "react";
import { mixin } from "styles/mixin";

type LargeBodyTextProps = {
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
};

export const LargeBodyText: React.FC<LargeBodyTextProps> = ({
  children,
  cssOverride,
}) => {
  return (
    <p
      css={[
        cssOverride,
        css`
          ${mixin.text.largeBody};
        `,
      ]}
    >
      {children}
    </p>
  );
};
