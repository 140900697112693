/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { defaultTheme } from "styles/theme";

type Props = {
  children: React.ReactNode;
  width?: string;
  maxWidth?: string;
  cssOverride?: SerializedStyles;
};

export const BaseSmallInner: React.FC<Props> = ({
  children,
  width = "fit-content",
  maxWidth = defaultTheme.maxWidth.sm,
  cssOverride,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          margin: 0 auto;
          width: ${width};
          max-width: ${maxWidth};
        `,
      ]}
    >
      {children}
    </div>
  );
};
