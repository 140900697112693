import {
  CustomFitScreenWindowTheme,
  CustomHeaderTheme,
  CustomTheme,
  ThemeState,
} from "hooks/useTheme";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import {
  BASE_COLOR_FITSCREENWINDOW,
  defaultMode,
  defaultTheme,
} from "styles/theme";

const defaultCustomHeaderTheme: CustomHeaderTheme = {
  logo: {
    dark: false,
  },
  hamburgerButton: {
    color: defaultTheme.palette.text,
    openColor: defaultTheme.palette.common.white,
  },
  changeThemeButton: {
    dark: false,
    colors: {
      light: defaultTheme.palette.text,
      dark: defaultTheme.palette.text,
    },
  },
};

const defaultCustomFitScreenWindowTheme: CustomFitScreenWindowTheme = {
  color: BASE_COLOR_FITSCREENWINDOW,
};

const defaultCustomTheme: CustomTheme = {
  header: defaultCustomHeaderTheme,
  fitScreenWindow: defaultCustomFitScreenWindowTheme,
};

const defaultValues: ThemeState = {
  theme: defaultTheme,
  customTheme: defaultCustomTheme,
  mode: defaultMode,
  active: false,
};

const themeState = atom<ThemeState>({
  key: "themeState",
  default: defaultValues,
});

export const useThemeState = () => {
  const [state, setState] = useRecoilState(themeState);
  return {
    ...state,
  };
};

export const useThemeMutators = () => {
  const setState = useSetRecoilState(themeState);

  return {
    setTheme: setState,
  };
};
