/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FadeInSequentialText } from "components/atoms/FadeInSequentialText/FadeInSequentialText";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import { GradationDividerTitle } from "components/atoms/GradationDividerTitle/GradationDividerTitle";
import { SubTitle } from "components/atoms/SubTitle/SubTitle";
import {
  ImageGallery,
  ImageGalleryItem,
  ImageGallerySlotProps,
} from "components/molecules/ImageGallery/ImageGallery";
import { LabelRadiusTags } from "components/molecules/LabelRadiusTags/LabelRadiusTags";
import { DesignCardClickEventHandler } from "components/organisms/DesignCard/DesignCard";
import {
  ImageModal,
  ImageModalSlotProps,
} from "components/organisms/ImageModal/ImageModal";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { CenteredOuter } from "utils/Wrapper/CenteredOuter/CenteredOuter";

type DesignTemplateImageGallerySlotProps = {
  open: boolean;
  src: string;
  alt: string;
  title: string;
  description: string;
  items: ImageGalleryItem[];
  onImageCardClick: DesignCardClickEventHandler;
  onImageModalClose: () => void;
  imageSize?: string | number;
  imageModal?: ImageModalSlotProps & {
    buttons?: React.ReactNode;
    isPanelScrollTop?: boolean;
    bottomComponent?: React.ReactNode;
  };
} & ImageGallerySlotProps;

export type DesignTemplateSlotProps = {
  title: string;
  subTitle: React.ReactNode;
  subTitleColor?: string;
  description: React.ReactNode;
  descriptionColor?: string;
  descriptionTags: {
    label: React.ReactNode;
    labelColor?: string;
    tags: {
      value: React.ReactNode;
    }[];
    backgroundColor?: string;
    textColor?: string;
  };
  imageGallery: DesignTemplateImageGallerySlotProps;
  imageGalleryFooter?: {
    text?: React.ReactNode;
    color?: string;
  };
};

type DesignTemplateProps = {
  id: string;
  slotProps: DesignTemplateSlotProps;
  mediaQuery: MediaQuery;
};

export const DesignTemplate: React.FC<DesignTemplateProps> = ({
  id,
  slotProps,
  mediaQuery,
}) => {
  const { title, imageGallery } = slotProps;
  const { subTitle, subTitleColor = "#666666", description } = slotProps;
  const { descriptionTags } = slotProps;
  const { imageGalleryFooter } = slotProps;

  return (
    <>
      <BaseWrapper
        id={id}
        cssOverride={css`
          padding-bottom: 7rem;
        `}
      >
        <section>
          {/* ヒーロー */}
          <CenteredOuter>
            <h1>
              <GradationDividerTitle
                cssOverride={css`
                  margin-left: auto;
                  margin-right: auto;
                  margin-bottom: ${defaultTheme.spacing.title.bottom};
                `}
              >
                <FadeInSequentialText>{title}</FadeInSequentialText>
              </GradationDividerTitle>
            </h1>
            <div
              css={css`
                margin-bottom: 1.5rem;
              `}
            >
              <FadeInOnScrollByComponent
                delay={defaultTheme.delay.short}
                direction="spot"
              >
                <h2>
                  <SubTitle color={subTitleColor}>{subTitle}</SubTitle>
                </h2>
              </FadeInOnScrollByComponent>
              <FadeInOnScrollByComponent
                delay={defaultTheme.delay.medium}
                direction="bottom"
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex-wrap: wrap;
                    margin-bottom: 4.25rem;
                  `}
                >
                  <p
                    css={css`
                      ${mixin.text.body};
                      display: inline-block;
                      margin-bottom: 0.75rem;
                      text-align: left;
                    `}
                  >
                    {description}
                  </p>
                  <LabelRadiusTags
                    label={descriptionTags.label}
                    labelColor={descriptionTags.labelColor}
                    tags={descriptionTags.tags}
                    backgroundColor={descriptionTags.backgroundColor}
                    textColor={descriptionTags.textColor}
                    outlined
                  />
                </div>
              </FadeInOnScrollByComponent>
            </div>
          </CenteredOuter>
          {/* イメージカード */}
          <BaseInner mb="1.5rem">
            <ImageGallery
              items={imageGallery.items}
              onClick={imageGallery.onImageCardClick}
              slotProps={{
                designCard: imageGallery.designCard,
              }}
            />
            {/* イメージカード フッター */}
            <GradationDivider
              size={defaultTheme.borderWidth.sm}
              cssOverride={css`
                margin-top: ${defaultTheme.spacing.lg};
                margin-bottom: ${defaultTheme.spacing.sm};
              `}
            />
            {imageGalleryFooter?.text && (
              <p
                css={css`
                  ${mixin.text.description};
                  color: ${imageGalleryFooter?.color &&
                  imageGalleryFooter.color};
                `}
              >
                {imageGalleryFooter.text}
              </p>
            )}
          </BaseInner>
        </section>
      </BaseWrapper>

      {/* イメージカード モーダル */}
      <ImageModal
        src={imageGallery.src}
        alt={imageGallery.alt}
        open={imageGallery.open}
        onClose={imageGallery.onImageModalClose}
        imageSize={imageGallery.imageSize}
        panelMinWidth={mediaQuery.pc ? "100%" : "85vw"}
        panelMaxWidth={mediaQuery.pc ? "80vw" : "85vw"}
        panelMaxHeight="80vh"
        title={imageGallery.title}
        description={imageGallery.description}
        slotProps={imageGallery.imageModal}
        buttons={imageGallery.imageModal?.buttons}
        isPanelScrollTop={imageGallery.imageModal?.isPanelScrollTop}
        bottomComponent={imageGallery.imageModal?.bottomComponent}
      />
    </>
  );
};
