/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckBox } from "components/atoms/CheckBox/CheckBox";
import { FillRadiusButton } from "components/atoms/FillRadiusButton/FillRadiusButton";
import { GradationDivider } from "components/atoms/GradationDivider/GradationDivider";
import { HelperText } from "components/atoms/HelperText/HelperText";
import { Input } from "components/atoms/Input/Input";
import { Label } from "components/atoms/Label/Label";
import { LinkText } from "components/atoms/LinkText/LinkText";
import { TextArea } from "components/atoms/TextArea/TextArea";
import { useForm } from "react-hook-form";
import { defaultTheme } from "styles/theme";
import { RecaptchaWidget } from "utils/GoogleReCaptcha";
import { FormRow } from "utils/Wrapper/FormRow/FormRow";
import { constants } from "utils/constants";
import { z } from "zod";

const formNames = {
  companyName: "companyName",
  name: "name",
  email: "email",
  inquiry: "inquiry",
  privacyPolicyAgreement: "privacyPolicyAgreement",
} as const;

const REQUIRED_ERROR_MSG = "を入力してください。";

const formSchema = z.object({
  companyName: z.string().optional(),
  name: z.string().min(1, "お名前" + REQUIRED_ERROR_MSG),
  email: z
    .string()
    .email("有効なメールアドレスを入力してください。")
    .min(1, "メールアドレス" + REQUIRED_ERROR_MSG),
  inquiry: z.string().min(1, "お問い合わせ内容" + REQUIRED_ERROR_MSG),
  privacyPolicyAgreement: z
    .boolean()
    .refine((val) => val, "個人情報の利用目的に同意する必要があります。"),
});

export type ContactFormData = z.infer<typeof formSchema>;

export type ContactFormProps = {
  onSubmit: (data: ContactFormData) => void;
};

export const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {
  const { companyName, name, email, inquiry, privacyPolicyAgreement } =
    formNames;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormData>({
    resolver: zodResolver(formSchema),
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      css={css`
        width: 100%;
      `}
    >
      <FormRow maxWidth={defaultTheme.maxWidth.sm}>
        <Label htmlFor={companyName} optional>
          会社名
        </Label>
        <Input
          id={companyName}
          type="text"
          register={register(companyName)}
          errors={errors[companyName]}
        />
      </FormRow>
      <FormRow maxWidth={defaultTheme.maxWidth.sm}>
        <Label htmlFor={name} required>
          お名前（ハンドルネーム可）
        </Label>
        <Input
          id={name}
          type="text"
          autoComplete="name"
          register={register(name)}
          errors={errors[name]}
        />
        {errors.name && <HelperText>{errors.name.message}</HelperText>}
      </FormRow>
      <FormRow maxWidth={defaultTheme.maxWidth.sm}>
        <Label htmlFor={email} required>
          メールアドレス
        </Label>
        <Input
          id={email}
          type="email"
          autoComplete="email"
          register={register(email)}
          errors={errors[email]}
        />
        {errors.email && <HelperText>{errors.email.message}</HelperText>}
      </FormRow>
      <FormRow maxWidth={defaultTheme.maxWidth.sm}>
        <Label htmlFor={inquiry} required>
          お問い合わせ内容
        </Label>
        <TextArea
          id={inquiry}
          register={register(inquiry)}
          errors={errors[inquiry]}
        />
        {errors.inquiry && <HelperText>{errors.inquiry.message}</HelperText>}
      </FormRow>
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <GradationDivider
          size={defaultTheme.borderWidth.sm}
          cssOverride={css`
            width: 100%;
            margin-top: ${defaultTheme.spacing.sm};
            margin-bottom: ${defaultTheme.spacing.sm};
          `}
        />
        <FormRow maxWidth={defaultTheme.maxWidth.sm}>
          <CheckBox
            id={privacyPolicyAgreement}
            register={register(privacyPolicyAgreement)}
            errors={errors[privacyPolicyAgreement]}
            label={
              <div>
                <LinkText
                  mode="anchor"
                  cssOverride={css`
                    color: ${defaultTheme.palette.gray.text};
                  `}
                  item={{
                    href: constants.pages.privacyPolicy.url,
                    value: "個人情報の利用目的",
                  }}
                />
                について同意する
              </div>
            }
          />
          {errors.privacyPolicyAgreement && (
            <HelperText>{errors.privacyPolicyAgreement.message}</HelperText>
          )}
        </FormRow>
        <RecaptchaWidget
          cssOverride={css`
            margin-bottom: ${defaultTheme.spacing.md};
          `}
        />
        <FillRadiusButton
          type="submit"
          height={30}
          width={40}
          cssOverride={css`
            font-weight: 700;
          `}
        >
          内容を送信する
        </FillRadiusButton>
      </div>
    </form>
  );
};
