/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const ShotokuTextLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 20,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 730.17 48"
        height={size}
        width={"100%"}
      >
        <g id="front">
          <path d="M2.9,13.95h16V30.56H8.63c-.25,3.85-.9,8.75-3.44,13.09L.45,38.86A20.79,20.79,0,0,0,2.9,28.71ZM1.19,6h18.7v5.6H1.19ZM8.75,19.39V25H13.3v-5.6Zm27.82,3.4V43.65h-6.1V22.78H27c.12,12.68-3.68,17.88-6.3,20.95l-5.2-4.17c2.9-2.9,5.56-6.79,5.56-15.67V7.11c5.48-.08,11-.65,16.12-2.7l2.7,5.89A44.93,44.93,0,0,1,27,12.64v4.09H39.8v6.05Z" />
          <path d="M42.79,11.61a17.18,17.18,0,0,0,6.46-7.08L54.9,6.38c-1.8,3.68-5.56,8.75-9.7,11.41Zm13.46,5A42.05,42.05,0,0,1,52.53,23V43.57H46.47V29.9a10.8,10.8,0,0,1-1.88,1.35L43,23.68c3.64-2.54,6.71-7.08,8-9.53Zm2.62,16.61H54.12V28.06H71.71V26.51H55.27V21.84H81.45v4.66H77.81v1.55h4.62v5.11H77.81v6.71c0,2.21-.41,3.68-4.09,3.68h-6.5l-1-5.44h4.42c1,0,1.06-.25,1.06-1.31V33.17H61.61a32.22,32.22,0,0,1,4.21,4.66l-4.75,3.72a46.93,46.93,0,0,0-5-6.18ZM80.06,6V20.49H56.54V6ZM62.35,10v1.8H74.29V10Zm0,5v1.8H74.29V15Z" />
          <path d="M99.62,27.45a30,30,0,0,1-3.19,1.27V39.64c0,4-2.9,4-4,4H87l-.86-6.34h3c1.35,0,1.51-.08,1.51-1.31V30.51c-1.92.61-3.15.94-4.17,1.27l-.65-6.34c1.39-.29,2.25-.49,4.83-1.23v-7H86.12V11.45H90.7V4.78h5.73v6.67h3.19v5.81H96.42V22.5l3.07-1Zm21-2.95A86.31,86.31,0,0,1,125.43,42l-6,1.76c-.12-1.27-.25-2-.41-3.19-2,.33-9.29,1.31-13.38,1.72l-.7-4.91a16.91,16.91,0,0,1-3.31,6.38l-4.91-4.25c2.29-2.74,4-5.07,4-16.49V9.53h8.47V4.78h6.14V9.53h9.57v5.85H106.45v7.81a57.69,57.69,0,0,1-1.19,13c.25,0,1.47-.12,1.72-.16a175.82,175.82,0,0,0,3.48-19.39l6.14.82c-1.27,8.1-3.72,17.39-3.89,18.12.41,0,3.4-.37,5.11-.57a76.77,76.77,0,0,0-2.74-9Z" />
          <path d="M128.25,37.06c6.71-2.45,13.62-6.5,15.75-16.9H128.83v-6.3h15.67V4.78H151v9.08h16.32v6.3h-15.5c2.54,9.86,8.22,14.11,16.36,17.14l-3.52,6.22c-5-2-12.76-6.1-16.49-16.65-4.09,10.76-12.72,14.85-16.65,16.57Z" />
          <path d="M180.13,39.19v4.42h-6V25.81a20.78,20.78,0,0,1-2,2.13l-1.55-8.79a30.06,30.06,0,0,0,6-14.56l6,.57A46.1,46.1,0,0,1,180.13,15V38.86c3.52-4,4.87-7.9,5.15-14.4l5.77.61a44.07,44.07,0,0,1-.74,6,9.36,9.36,0,0,0,3.44,4.13V21.56H184.3V6h24.14V21.56h-8.71v4.13h9.9v5.48h-9.9v5.6c.45,0,.82,0,1.76,0h9.16l-1.31,5.93h-8.47c-1.88,0-6.42-.78-8.47-2a17,17,0,0,1-4-3.56A21.53,21.53,0,0,1,184,43.61Zm10.19-27.86V16.4h12.11V11.33Z" />
          <path d="M213.88,20h9.37V32.07c1.35,4.83,5.89,4.83,8.22,4.83h21.85l-1.43,5.93H231.31c-6.34,0-8.67-2.78-9.78-4.13a28.3,28.3,0,0,1-5.85,5.15l-2.17-6.71a16.9,16.9,0,0,0,3.85-2.5V25.77h-3.48ZM218,4.78a48.66,48.66,0,0,1,6.87,6.05l-4.21,5A36.73,36.73,0,0,0,214,9.36Zm8.26,15.63-1.51,1.35L221.12,17A29.19,29.19,0,0,0,230,4.54l5.77,1.06a35.49,35.49,0,0,1-1.68,3.85h3.6a21.68,21.68,0,0,0,2.13-4.66l6.05.94a24.88,24.88,0,0,1-2,3.72h7.81V15h-8.34v2.25h7v3.93h-7v2.25h7v3.85h-7v2.33h8.63V35H226.23Zm5.85-5.4v2.25h5.65V15Zm0,6.18v2.25h5.65V21.19Zm0,6.1v2.33h5.65V27.28Z" />
          <path d="M272.26,29,270,33.17a19.07,19.07,0,0,1-2.41-4.34V43.61h-5.73V30.35a31.68,31.68,0,0,1-4.17,7.16l-2-7.4a28.24,28.24,0,0,0,5.65-9.33h-5V15.09h5.56V11.7c-2.21.2-3.48.25-4.79.33l-.9-5.52a60.73,60.73,0,0,0,13.58-1.88l2,5.52a33.53,33.53,0,0,1-4.21.86v4.09H271v5.69H267.8a29.56,29.56,0,0,0,4.34,6.38V13h2.74a47.29,47.29,0,0,0-3.19-6.59l6-1.72A39.28,39.28,0,0,1,281.13,12l-3.8,1h7.16a54.8,54.8,0,0,0,3.76-8.39l6.34,1.68A53.8,53.8,0,0,1,291.2,13H294V29h-5.36v8c0,.25,0,.94.74.94,1.51,0,1.55,0,1.64-5.07l4.79,1.43c-.41,7.24-.74,9.37-4.3,9.37h-5c-3,0-3.72-1.59-3.72-3.8V29h-2.09c-.37,7.53-2.9,11.33-8.84,14.89l-3.64-5.2c6-2.82,6.79-6.46,7-9.7Zm5.65-10.47v5H288v-5Z" />
          <path d="M303.76,16.32l-4.91-2.09a27.13,27.13,0,0,0,2.21-9.08l5.4.45c-.16,1.19-.25,1.84-.41,2.78h2.13V4.95h5.73V8.38h7.53v5.48H313.9v2.45h8.43v5.52H313.9v2.21h7.24V37.59c0,1.59-.25,3.48-2.62,3.48h-2.95L314.11,35h1.47c.37,0,.7-.08.7-1V29.49h-2.74V43.61h-5.36V29.49h-2.45v12h-5.44V24.05h7.9V21.84H299V16.32Zm4.42,0V13.86h-3.23a14,14,0,0,1-.94,2.45ZM323.31,7h5.28V33.83h-5.28Zm7.12-2h5.85V39.43c0,3.23-2.41,4.17-3.44,4.17h-7.28l-1.23-6.3h4.83c1,0,1.27-.25,1.27-1.35Z" />
          <path d="M375.06,16.48H356.61V11.7h18.53v4h6.71V4.82h6.34V15.71h7.36V22H388.2V43.61h-6.34V22h-6.79ZM358,5.68h15.87v4.5H358ZM358,18h15.87v4.5H358Zm0,6.1h15.87v4.5H358Zm0,6.22h15.87V42.13H363.53v1.47H358Zm5.56,4.34v2.86h5V34.61Z" />
          <path d="M434.38,14.27V31.5h-5.93v1.35H438v4.83h-9.53v6h-6.18v-6h-8.1c-2.33,4.75-9.37,6.26-11.29,6.67l-2.45-4.66c1.76-.33,5-.94,6.63-2h-7.77V32.85h10.19c0-.49.08-.74.16-1.35h-6.79V15.62l-4.09-4a18.3,18.3,0,0,0,5.93-7l6.46.65c-.33.74-.41.86-.57,1.19H418v4.25a19.1,19.1,0,0,0,3.64-6.14l6.14.65c-.25.74-.33.94-.41,1.23H437.9v5h-6.18a16.58,16.58,0,0,1,1.15,2.13l-3,.65Zm-25.57,0a21.26,21.26,0,0,0-1-2.78h-.41a34.31,34.31,0,0,1-2.45,2.78Zm.12,3.35v1.51h19.43V17.63Zm0,4.34v1.55h19.43V22Zm0,4.38V27.9h19.43V26.34Zm11.78-12.07L417,11.7l.2-.21h-3.52a18.32,18.32,0,0,1,.86,2.09l-3.31.7Zm1.55,17.22h-6.79c0,.78,0,1-.08,1.35h6.87Zm4.29-17.22a19,19,0,0,0-1.23-2.78h-.57a22.12,22.12,0,0,1-2,2.78Z" />
          <path d="M476.56,9.16v6.42H464.49V32.93h14.07v6.42h-34.9V32.93h14V15.58H445.72V9.16Z" />
          <path d="M521.24,11c-3.4,23.11-16,29.21-29.7,31.21l-2.66-6c10-1.35,20.13-4.13,24.18-19.15H500.37A35.11,35.11,0,0,1,490.64,26l-4.25-4.79A28.72,28.72,0,0,0,499.68,5.52l7,.9A48.29,48.29,0,0,1,504.46,11Z" />
          <path d="M563.13,12.72c-.82,9.16-3.89,14.85-12.35,19.27l-4-5c3.31-1.39,6.14-3.07,8.14-7.4l-12.56,1.23v11.5c0,2.21.12,2.82,3.68,2.82h9.49a31.7,31.7,0,0,0,6.38-.57v6.54c-1.47.16-4.62.33-6.18.33H544.6c-8.75,0-8.84-5.44-8.84-8.43V21.43l-7.85.74-.45-6.38,8.3-.74V6.3h6.63v8.18Z" />
          <path d="M583.62,17.63c0,18.16-5.28,23.32-7.41,25.32l-5.11-5.32C573,35.79,577,31.91,577,18.32V8.1h6.59Zm11.17,15.91c7-2.33,8.51-10.88,9-14.15l6.14,2.95c-2.21,11-8.84,18.9-21.68,19.39V6.79h6.55Z" />
          <path d="M622.37,22.95a67.75,67.75,0,0,0-7.49-11.46l5.64-3.15a53,53,0,0,1,8,10.8Zm-4.3,13c8.88-2.29,20.17-5.93,24-28.43L649.45,9c-5.28,21.89-14,29.13-28,32.85Z" />
          <path d="M690.4,8.71C689.87,19.55,687.33,37.39,663,42l-2.86-6.18c18.2-3.19,21.15-12.44,22.54-20.7H657.3V8.71Z" />
          <path d="M709.55,6.17v9.74a94.07,94.07,0,0,1,19.68,8.59l-3.48,6.83a79.29,79.29,0,0,0-16.2-8.51V42.21h-7.12v-36Z" />
          <path d="M2.9,13.95h16V30.56H8.63c-.25,3.85-.9,8.75-3.44,13.09L.45,38.86A20.79,20.79,0,0,0,2.9,28.71ZM1.19,6h18.7v5.6H1.19ZM8.75,19.39V25H13.3v-5.6Zm27.82,3.4V43.65h-6.1V22.78H27c.12,12.68-3.68,17.88-6.3,20.95l-5.2-4.17c2.9-2.9,5.56-6.79,5.56-15.67V7.11c5.48-.08,11-.65,16.12-2.7l2.7,5.89A44.93,44.93,0,0,1,27,12.64v4.09H39.8v6.05Z" />
          <path d="M42.79,11.61a17.18,17.18,0,0,0,6.46-7.08L54.9,6.38c-1.8,3.68-5.56,8.75-9.7,11.41Zm13.46,5A42.05,42.05,0,0,1,52.53,23V43.57H46.47V29.9a10.8,10.8,0,0,1-1.88,1.35L43,23.68c3.64-2.54,6.71-7.08,8-9.53Zm2.62,16.61H54.12V28.06H71.71V26.51H55.27V21.84H81.45v4.66H77.81v1.55h4.62v5.11H77.81v6.71c0,2.21-.41,3.68-4.09,3.68h-6.5l-1-5.44h4.42c1,0,1.06-.25,1.06-1.31V33.17H61.61a32.22,32.22,0,0,1,4.21,4.66l-4.75,3.72a46.93,46.93,0,0,0-5-6.18ZM80.06,6V20.49H56.54V6ZM62.35,10v1.8H74.29V10Zm0,5v1.8H74.29V15Z" />
          <path d="M99.62,27.45a30,30,0,0,1-3.19,1.27V39.64c0,4-2.9,4-4,4H87l-.86-6.34h3c1.35,0,1.51-.08,1.51-1.31V30.51c-1.92.61-3.15.94-4.17,1.27l-.65-6.34c1.39-.29,2.25-.49,4.83-1.23v-7H86.12V11.45H90.7V4.78h5.73v6.67h3.19v5.81H96.42V22.5l3.07-1Zm21-2.95A86.31,86.31,0,0,1,125.43,42l-6,1.76c-.12-1.27-.25-2-.41-3.19-2,.33-9.29,1.31-13.38,1.72l-.7-4.91a16.91,16.91,0,0,1-3.31,6.38l-4.91-4.25c2.29-2.74,4-5.07,4-16.49V9.53h8.47V4.78h6.14V9.53h9.57v5.85H106.45v7.81a57.69,57.69,0,0,1-1.19,13c.25,0,1.47-.12,1.72-.16a175.82,175.82,0,0,0,3.48-19.39l6.14.82c-1.27,8.1-3.72,17.39-3.89,18.12.41,0,3.4-.37,5.11-.57a76.77,76.77,0,0,0-2.74-9Z" />
          <path d="M128.25,37.06c6.71-2.45,13.62-6.5,15.75-16.9H128.83v-6.3h15.67V4.78H151v9.08h16.32v6.3h-15.5c2.54,9.86,8.22,14.11,16.36,17.14l-3.52,6.22c-5-2-12.76-6.1-16.49-16.65-4.09,10.76-12.72,14.85-16.65,16.57Z" />
          <path d="M180.13,39.19v4.42h-6V25.81a20.78,20.78,0,0,1-2,2.13l-1.55-8.79a30.06,30.06,0,0,0,6-14.56l6,.57A46.1,46.1,0,0,1,180.13,15V38.86c3.52-4,4.87-7.9,5.15-14.4l5.77.61a44.07,44.07,0,0,1-.74,6,9.36,9.36,0,0,0,3.44,4.13V21.56H184.3V6h24.14V21.56h-8.71v4.13h9.9v5.48h-9.9v5.6c.45,0,.82,0,1.76,0h9.16l-1.31,5.93h-8.47c-1.88,0-6.42-.78-8.47-2a17,17,0,0,1-4-3.56A21.53,21.53,0,0,1,184,43.61Zm10.19-27.86V16.4h12.11V11.33Z" />
          <path d="M213.88,20h9.37V32.07c1.35,4.83,5.89,4.83,8.22,4.83h21.85l-1.43,5.93H231.31c-6.34,0-8.67-2.78-9.78-4.13a28.3,28.3,0,0,1-5.85,5.15l-2.17-6.71a16.9,16.9,0,0,0,3.85-2.5V25.77h-3.48ZM218,4.78a48.66,48.66,0,0,1,6.87,6.05l-4.21,5A36.73,36.73,0,0,0,214,9.36Zm8.26,15.63-1.51,1.35L221.12,17A29.19,29.19,0,0,0,230,4.54l5.77,1.06a35.49,35.49,0,0,1-1.68,3.85h3.6a21.68,21.68,0,0,0,2.13-4.66l6.05.94a24.88,24.88,0,0,1-2,3.72h7.81V15h-8.34v2.25h7v3.93h-7v2.25h7v3.85h-7v2.33h8.63V35H226.23Zm5.85-5.4v2.25h5.65V15Zm0,6.18v2.25h5.65V21.19Zm0,6.1v2.33h5.65V27.28Z" />
          <path d="M272.26,29,270,33.17a19.07,19.07,0,0,1-2.41-4.34V43.61h-5.73V30.35a31.68,31.68,0,0,1-4.17,7.16l-2-7.4a28.24,28.24,0,0,0,5.65-9.33h-5V15.09h5.56V11.7c-2.21.2-3.48.25-4.79.33l-.9-5.52a60.73,60.73,0,0,0,13.58-1.88l2,5.52a33.53,33.53,0,0,1-4.21.86v4.09H271v5.69H267.8a29.56,29.56,0,0,0,4.34,6.38V13h2.74a47.29,47.29,0,0,0-3.19-6.59l6-1.72A39.28,39.28,0,0,1,281.13,12l-3.8,1h7.16a54.8,54.8,0,0,0,3.76-8.39l6.34,1.68A53.8,53.8,0,0,1,291.2,13H294V29h-5.36v8c0,.25,0,.94.74.94,1.51,0,1.55,0,1.64-5.07l4.79,1.43c-.41,7.24-.74,9.37-4.3,9.37h-5c-3,0-3.72-1.59-3.72-3.8V29h-2.09c-.37,7.53-2.9,11.33-8.84,14.89l-3.64-5.2c6-2.82,6.79-6.46,7-9.7Zm5.65-10.47v5H288v-5Z" />
          <path d="M303.76,16.32l-4.91-2.09a27.13,27.13,0,0,0,2.21-9.08l5.4.45c-.16,1.19-.25,1.84-.41,2.78h2.13V4.95h5.73V8.38h7.53v5.48H313.9v2.45h8.43v5.52H313.9v2.21h7.24V37.59c0,1.59-.25,3.48-2.62,3.48h-2.95L314.11,35h1.47c.37,0,.7-.08.7-1V29.49h-2.74V43.61h-5.36V29.49h-2.45v12h-5.44V24.05h7.9V21.84H299V16.32Zm4.42,0V13.86h-3.23a14,14,0,0,1-.94,2.45ZM323.31,7h5.28V33.83h-5.28Zm7.12-2h5.85V39.43c0,3.23-2.41,4.17-3.44,4.17h-7.28l-1.23-6.3h4.83c1,0,1.27-.25,1.27-1.35Z" />
          <path d="M375.06,16.48H356.61V11.7h18.53v4h6.71V4.82h6.34V15.71h7.36V22H388.2V43.61h-6.34V22h-6.79ZM358,5.68h15.87v4.5H358ZM358,18h15.87v4.5H358Zm0,6.1h15.87v4.5H358Zm0,6.22h15.87V42.13H363.53v1.47H358Zm5.56,4.34v2.86h5V34.61Z" />
          <path d="M434.38,14.27V31.5h-5.93v1.35H438v4.83h-9.53v6h-6.18v-6h-8.1c-2.33,4.75-9.37,6.26-11.29,6.67l-2.45-4.66c1.76-.33,5-.94,6.63-2h-7.77V32.85h10.19c0-.49.08-.74.16-1.35h-6.79V15.62l-4.09-4a18.3,18.3,0,0,0,5.93-7l6.46.65c-.33.74-.41.86-.57,1.19H418v4.25a19.1,19.1,0,0,0,3.64-6.14l6.14.65c-.25.74-.33.94-.41,1.23H437.9v5h-6.18a16.58,16.58,0,0,1,1.15,2.13l-3,.65Zm-25.57,0a21.26,21.26,0,0,0-1-2.78h-.41a34.31,34.31,0,0,1-2.45,2.78Zm.12,3.35v1.51h19.43V17.63Zm0,4.34v1.55h19.43V22Zm0,4.38V27.9h19.43V26.34Zm11.78-12.07L417,11.7l.2-.21h-3.52a18.32,18.32,0,0,1,.86,2.09l-3.31.7Zm1.55,17.22h-6.79c0,.78,0,1-.08,1.35h6.87Zm4.29-17.22a19,19,0,0,0-1.23-2.78h-.57a22.12,22.12,0,0,1-2,2.78Z" />
          <path d="M476.56,9.16v6.42H464.49V32.93h14.07v6.42h-34.9V32.93h14V15.58H445.72V9.16Z" />
          <path d="M521.24,11c-3.4,23.11-16,29.21-29.7,31.21l-2.66-6c10-1.35,20.13-4.13,24.18-19.15H500.37A35.11,35.11,0,0,1,490.64,26l-4.25-4.79A28.72,28.72,0,0,0,499.68,5.52l7,.9A48.29,48.29,0,0,1,504.46,11Z" />
          <path d="M563.13,12.72c-.82,9.16-3.89,14.85-12.35,19.27l-4-5c3.31-1.39,6.14-3.07,8.14-7.4l-12.56,1.23v11.5c0,2.21.12,2.82,3.68,2.82h9.49a31.7,31.7,0,0,0,6.38-.57v6.54c-1.47.16-4.62.33-6.18.33H544.6c-8.75,0-8.84-5.44-8.84-8.43V21.43l-7.85.74-.45-6.38,8.3-.74V6.3h6.63v8.18Z" />
          <path d="M583.62,17.63c0,18.16-5.28,23.32-7.41,25.32l-5.11-5.32C573,35.79,577,31.91,577,18.32V8.1h6.59Zm11.17,15.91c7-2.33,8.51-10.88,9-14.15l6.14,2.95c-2.21,11-8.84,18.9-21.68,19.39V6.79h6.55Z" />
          <path d="M622.37,22.95a67.75,67.75,0,0,0-7.49-11.46l5.64-3.15a53,53,0,0,1,8,10.8Zm-4.3,13c8.88-2.29,20.17-5.93,24-28.43L649.45,9c-5.28,21.89-14,29.13-28,32.85Z" />
          <path d="M690.4,8.71C689.87,19.55,687.33,37.39,663,42l-2.86-6.18c18.2-3.19,21.15-12.44,22.54-20.7H657.3V8.71Z" />
          <path d="M709.55,6.17v9.74a94.07,94.07,0,0,1,19.68,8.59l-3.48,6.83a79.29,79.29,0,0,0-16.2-8.51V42.21h-7.12v-36Z" />
        </g>
      </svg>
    </div>
  );
};
