/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const BatchFileRenamerTextLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 18,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 868 79.5"
        height={size}
        width={"100%"}
      >
        <path d="M2.9,7.23H24.33c14.19,0,24.44,4.61,24.44,17.11,0,6.58-3.67,12.88-9.87,14.95v.47c7.9,1.69,13.63,7,13.63,16.45,0,13.72-11.19,20.3-26.51,20.3H2.9Zm20.21,29C33.63,36.18,38,32.23,38,25.74c0-7.24-4.89-10-14.66-10H13.8v20.4ZM24.8,67.95c10.81,0,17-3.85,17-12.31,0-7.8-6-11.28-17-11.28h-11V67.95Z" />
        <path d="M61.65,62.78C61.65,51.69,71,46,92.57,43.6c-.09-6-2.26-11.28-9.87-11.28-5.45,0-10.53,2.44-14.95,5.26l-4-7.33c5.36-3.38,12.6-6.77,20.87-6.77,12.88,0,18.8,8.18,18.8,21.9V76.5H94.54l-.85-5.92h-.38c-4.7,4-10.25,7.14-16.45,7.14C67.94,77.72,61.65,71.89,61.65,62.78Zm30.92.38V50.46C77.25,52.25,72.08,56.2,72.08,62c0,5.08,3.48,7.14,8.08,7.14S88.34,67,92.57,63.15Z" />
        <path d="M120.39,59.58V33.45h-7.52V25.37L121,24.8l1.22-14.29h9V24.8h13.44v8.65H131.2V59.67c0,6.2,2.16,9.4,7.52,9.4a15.41,15.41,0,0,0,5.55-1.22l2,8a33.06,33.06,0,0,1-10.15,1.88C124.44,77.72,120.39,70.48,120.39,59.58Z" />
        <path d="M152.26,50.65c0-17.2,11.94-27.16,25.57-27.16A22.19,22.19,0,0,1,193,29.41l-5.36,7c-2.82-2.54-5.73-4-9.21-4-8.74,0-14.95,7.33-14.95,18.23s6,18.24,14.57,18.24c4.32,0,8.18-2.07,11.28-4.7l4.51,7.14A25.6,25.6,0,0,1,177,77.72C163,77.72,152.26,67.85,152.26,50.65Z" />
        <path d="M204.52,1.59h10.81V21.42l-.47,10.25c4.51-4.32,9.68-8.18,16.73-8.18,11,0,15.89,7.33,15.89,20.49V76.5H236.67V45.29c0-8.93-2.63-12.5-8.74-12.5-4.79,0-8.08,2.44-12.6,7V76.5H204.52Z" />
        <path d="M285,7.23h41v9.21H295.89V37.59h25.57V46.7H295.89V76.5H285Z" />
        <path d="M335.93,8.64c0-3.85,2.73-6.39,6.67-6.39s6.77,2.54,6.77,6.39-2.82,6.49-6.77,6.49S335.93,12.58,335.93,8.64Zm1.32,16.17h10.81V76.5H337.25Z" />
        <path d="M364.23,64.38V1.59H375V64.94c0,2.91,1.22,3.95,2.54,3.95a7.49,7.49,0,0,0,1.79-.19l1.41,8.18a17,17,0,0,1-5.83.85C367.14,77.72,364.23,72.74,364.23,64.38Z" />
        <path d="M388.57,50.65c0-16.73,11.56-27.16,23.59-27.16,13.72,0,21.15,9.87,21.15,24.44a28.09,28.09,0,0,1-.56,5.55H399.19c.85,9.87,7,15.79,16,15.79a22,22,0,0,0,12.31-3.85l3.76,6.86a31.37,31.37,0,0,1-17.48,5.45C399.76,77.72,388.57,67.76,388.57,50.65ZM423.91,46c0-8.84-4-14-11.56-14-6.49,0-12.22,5-13.25,14Z" />
        <path d="M467.43,7.23h22.65c14.29,0,25,5.17,25,20.11,0,10.72-5.73,17.11-14.48,19.83L517.53,76.5H505.22l-15.7-27.92H478.34V76.5h-10.9Zm21.34,32.62c10.06,0,15.51-4.13,15.51-12.5s-5.45-11.28-15.51-11.28H478.34V39.84Z" />
        <path d="M524.49,50.65c0-16.73,11.56-27.16,23.59-27.16,13.72,0,21.15,9.87,21.15,24.44a28,28,0,0,1-.56,5.55H535.11c.85,9.87,7,15.79,16,15.79a22,22,0,0,0,12.31-3.85l3.76,6.86a31.36,31.36,0,0,1-17.48,5.45C535.68,77.72,524.49,67.76,524.49,50.65ZM559.83,46c0-8.84-4-14-11.56-14-6.49,0-12.22,5-13.25,14Z" />
        <path d="M581.27,24.8h8.83l.85,7.14h.28c4.79-4.61,10.06-8.46,17.11-8.46,11,0,15.88,7.33,15.88,20.49V76.5H613.41V45.29c0-8.93-2.63-12.5-8.74-12.5-4.79,0-8.08,2.44-12.6,7V76.5H581.27Z" />
        <path d="M636.91,62.78c0-11.09,9.4-16.83,30.92-19.18-.09-6-2.26-11.28-9.87-11.28-5.45,0-10.53,2.44-14.95,5.26l-4-7.33c5.36-3.38,12.6-6.77,20.87-6.77,12.88,0,18.8,8.18,18.8,21.9V76.5h-8.84L669,70.58h-.38c-4.7,4-10.25,7.14-16.45,7.14C643.21,77.72,636.91,71.89,636.91,62.78Zm30.92.38V50.46C652.52,52.25,647.35,56.2,647.35,62c0,5.08,3.48,7.14,8.08,7.14S663.61,67,667.84,63.15Z" />
        <path d="M694,24.8h8.83l.85,7.24h.28c4.42-4.7,9.59-8.55,16-8.55,7.71,0,12,3.57,14.29,9.49,5.08-5.45,10.34-9.49,16.83-9.49,10.81,0,15.88,7.33,15.88,20.49V76.5H756.1V45.29c0-8.93-2.82-12.5-8.65-12.5-3.57,0-7.33,2.35-11.66,7V76.5H725V45.29c0-8.93-2.73-12.5-8.65-12.5q-5.22,0-11.56,7V76.5H694Z" />
        <path d="M778.85,50.65c0-16.73,11.56-27.16,23.59-27.16,13.72,0,21.15,9.87,21.15,24.44a28.09,28.09,0,0,1-.56,5.55H789.47c.85,9.87,7,15.79,16,15.79a22,22,0,0,0,12.31-3.85l3.76,6.86A31.37,31.37,0,0,1,804,77.72C790,77.72,778.85,67.76,778.85,50.65ZM814.19,46c0-8.84-4-14-11.56-14-6.49,0-12.22,5-13.25,14Z" />
        <path d="M835.63,24.8h8.83l.85,9.31h.28c3.67-6.67,9.12-10.62,14.66-10.62a12.86,12.86,0,0,1,5.83,1.13l-2,9.4a15.3,15.3,0,0,0-5.17-.85c-4.23,0-9.31,2.91-12.5,11.09V76.5H835.63Z" />
      </svg>
    </div>
  );
};
