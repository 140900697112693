/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BodyText } from "components/atoms/BodyText/BodyText";
import { GradationDividerMediumTitle } from "components/atoms/GradationDividerMediumTitle/GradationDividerMediumTitle";
import { ImageFadeLoader } from "components/atoms/ImageFadeLoader/ImageFadeLoader";
import { InformationLinkText } from "components/atoms/InformationLinkText/InformationLinkText";
import { JpTitle } from "components/atoms/JpTitle/JpTitle";
import { withLoader } from "components/atoms/withLoader/withLoader";
import { ProgrammingOtasukeTechnicalStack } from "components/molecules/ProgrammingOtasukeTechnicalStack/ProgrammingOtasukeTechnicalStack";
import { Table } from "components/molecules/Table/Table";
import {
  ProductIntroductionTemplate,
  ProductIntroductionTemplateSlotProps,
} from "components/templates/ProductIntroductionTemplate/ProductIntroductionTemplate";
import {
  ProductProgrammingOtasukePageImageSourcesState,
  useImageSources,
} from "hooks/useImageSources";
import { useMediaQuery } from "hooks/useMediaQuery";
import { usePreloadImages } from "hooks/usePreloadImages";
import { useProduct } from "hooks/useProduct";
import { useTheme } from "hooks/useTheme";
import { ProductIntroductionSection } from "organisms/ProductIntroductionSection/ProductIntroductionSection";
import React, { memo, useMemo } from "react";
import { mixin } from "styles/mixin";
import { defaultTheme, Theme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { constants } from "utils/constants";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { Columns1to2Wrapper } from "utils/Wrapper/Columns1to2Wrapper/Columns1to2Wrapper";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";
import { MainLayout } from "../MainLayout/MainLayout";

type DetailProps = {
  handleImageCardClick: (src: string, alt: string) => void;
  theme: Theme;
};

const Detail01: React.FC<DetailProps> = memo(
  ({ handleImageCardClick, theme }) => {
    const detailRequireSource = [
      require("assets/images/product/programming-otasuke/main-features-1.webp"),
    ];
    const {
      preloadedSources: preloadedDetailSources,
      isPreloading: isDetailPreloading,
    } = usePreloadImages(detailRequireSource);
    const preloadedDetailRequireSource = preloadedDetailSources[0];

    return isDetailPreloading ? (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <ImageFadeLoader
          color="#AAAAAA"
          height={200}
          bgColor={defaultTheme.palette.common.white}
          cssOverride={css`
            border-radius: ${defaultTheme.borderRadius.base};
          `}
        />
      </FadeInOnScrollByComponent>
    ) : (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <button
          type="button"
          onClick={() =>
            handleImageCardClick(
              preloadedDetailRequireSource,
              "質問に答えてプログラミングのエラーを解決していく機能"
            )
          }
        >
          <div
            css={css`
              ${mixin.boxShadow};
              ${mixin.hoverTransitionOpacityEffect};
              overflow: hidden;
            `}
          >
            <img
              src={preloadedDetailRequireSource}
              alt={"質問に答えてプログラミングのエラーを解決していく機能"}
              css={css`
                border: ${defaultTheme.borderWidth.sm}px solid;
                border-color: ${theme.palette.gray.background};
                border-radius: ${defaultTheme.borderRadius.base};
                width: 100%;
                max-width: 439px;
                min-width: 320px;
              `}
            />
          </div>
        </button>
      </FadeInOnScrollByComponent>
    );
  }
);

const Detail02: React.FC<DetailProps> = memo(
  ({ handleImageCardClick, theme }) => {
    const detailRequireSource = [
      require("assets/images/product/programming-otasuke/main-features-2.webp"),
    ];
    const {
      preloadedSources: preloadedDetailSources,
      isPreloading: isDetailPreloading,
    } = usePreloadImages(detailRequireSource);
    const preloadedDetailRequireSource = preloadedDetailSources[0];

    return isDetailPreloading ? (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <ImageFadeLoader
          height={200}
          color="#AAAAAA"
          bgColor={defaultTheme.palette.common.white}
          cssOverride={css`
            width: 100%;
            max-width: 0px;
            border-radius: ${defaultTheme.borderRadius.base};
          `}
        />
      </FadeInOnScrollByComponent>
    ) : (
      <FadeInOnScrollByComponent
        delay={defaultTheme.delay.medium}
        direction="spot"
      >
        <button
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          type="button"
          onClick={() =>
            handleImageCardClick(
              preloadedDetailRequireSource,
              "進捗状況の保存・共有機能"
            )
          }
        >
          <img
            src={preloadedDetailRequireSource}
            alt={"進捗状況の保存・共有機能"}
            css={css`
              ${mixin.boxShadow};
              ${mixin.hoverTransitionOpacityEffect};
              border: ${defaultTheme.borderWidth.sm}px solid;
              border-color: ${theme.palette.gray.background};
              border-radius: ${defaultTheme.borderRadius.base};
              width: 100%;
              max-width: 439px;
              min-width: 320px;
            `}
          />
        </button>
      </FadeInOnScrollByComponent>
    );
  }
);

type ImageProps = {
  handleImageCardClick: (src: string, alt: string) => void;
  theme: Theme;
};

const AWSPortfolioInfra: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail01RequireSource = [
    require("assets/images/product/programming-otasuke/infra.webp"),
  ];
  const {
    preloadedSources: preloadedDetail01Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail01RequireSource);
  const preloadedDetailRequireSource = preloadedDetail01Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(preloadedDetailRequireSource, "インフラ構成図")
      }
    >
      <div
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          overflow: hidden;
        `}
      >
        <img
          src={preloadedDetailRequireSource}
          alt={"インフラ構成図"}
          css={css`
            border: ${defaultTheme.borderWidth.sm}px solid;
            border-color: ${theme.palette.gray.background};
            border-radius: ${defaultTheme.borderRadius.base};
            width: 100%;
            max-width: 100%;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              max-width: 520px;
            }
          `}
        />
      </div>
    </button>
  );
};

const AWSPortfolioWorkflow01: React.FC<ImageProps> = ({
  handleImageCardClick,
  theme,
}) => {
  const detail02RequireSource = [
    require("assets/images/product/programming-otasuke/workflow-front.webp"),
  ];
  const {
    preloadedSources: preloadedDetail02Sources,
    isPreloading: isDetailPreloading,
  } = usePreloadImages(detail02RequireSource);
  const preloadedDetailRequireSource = preloadedDetail02Sources[0];

  return isDetailPreloading ? (
    <ImageFadeLoader
      color="#AAAAAA"
      height={200}
      bgColor={defaultTheme.palette.common.white}
      cssOverride={css`
        border-radius: ${defaultTheme.borderRadius.base};
      `}
    />
  ) : (
    <button
      type="button"
      onClick={() =>
        handleImageCardClick(
          preloadedDetailRequireSource,
          "ワークフロー図 フロントエンド"
        )
      }
    >
      <img
        src={preloadedDetailRequireSource}
        alt={"ワークフロー図 フロントエンド"}
        css={css`
          ${mixin.boxShadow};
          ${mixin.hoverTransitionOpacityEffect};
          border: ${defaultTheme.borderWidth.sm}px solid;
          border-color: ${theme.palette.gray.background};
          border-radius: ${defaultTheme.borderRadius.base};
          width: 100%;
          max-width: 100%;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            max-width: 360px;
          }
        `}
      />
    </button>
  );
};

const ProgrammingOtasukePage: React.FC = () => {
  const { theme, mode } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  const { pc } = mediaQuery;
  const {
    imageSources: { productProgrammingOtasuke },
  } = useImageSources();
  const { open, src, alt, handleImageCardClick, handleImageModalClose } =
    useProduct();

  const slotProps: ProductIntroductionTemplateSlotProps = useMemo(
    () => ({
      title: (
        <JpTitle
          size="small"
          cssOverride={css`
            margin-bottom: ${theme.spacing.xxs};
          `}
        >
          プログラミングのエラーおたすけツール！
        </JpTitle>
      ),
      subText: "WEB TOOL",
      description: (
        <BodyText>
          質問に答えてプログラミングのエラーを解決していく、初心者Webエンジニアの方へ向けたウェブツールです
        </BodyText>
      ),
      information: (
        <InformationLinkText
          label="WEB:"
          href={constants.links.programmingOtasuke}
          color={theme.palette.text}
          showNewWindowIcon
        />
      ),
      technicalStack: {
        icons: (
          <ProgrammingOtasukeTechnicalStack
            bgColor={defaultTheme.palette.contrastText}
          />
        ),
        table: {
          data: [
            [
              "フロントエンド",
              "Nuxt.js 3, TypeScript, Vite, Feature-Sliced Design",
            ],
            [
              "インフラ",
              "AWS(S3 / CloudFront / CloudFront Functions / Route53 / ACM 等)",
            ],
            ["プロビジョニング", "AWS CDK v2(TypeScript)"],
            ["CI/CD", "GitHub Actions"],
            ["開発環境", "bolt.new, Cursor(Claude 3.5 Sonnet / gpt-4o)"],
            ["主要パッケージ等", "Tailwind CSS, Headless UI, Pinia"],
            ["品質保証", "Vitest, Vue Testing Library, Storybook"],
            ["ビルド", "SSG"],
            ["コーディング規約", " ESLint & Prettier"],
            ["その他", "Illustrator"],
          ],
        },
      },
      images: {
        product: {
          src: productProgrammingOtasuke.brandBanner,
          alt: "プログラミングのエラーおたすけツール！",
        },
      },
      slot1: (
        <ProductIntroductionSection
          title="主な機能"
          theme={theme}
          bgColor={theme.palette.background}
          slot1={
            <Columns1to2Wrapper
              leftSize={6}
              rightSize={4}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    質問に答えてプログラミングのエラーを解決していく機能
                  </GradationDividerMediumTitle>
                  <BodyText>
                    利用するユーザーは質問の内容に従って、エラー解決に向けた確認や作業を順番にしていきます。また、具体的にどのような作業をすればいいのか「ヒント」を見ることもできます。
                    <br />
                    選択肢の回答のボタンを押すと次の質問が表示され、「解決した」ボタンの場合はポップアップが表示されます。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      text-align: right;
                    }
                  `}
                >
                  <Detail01
                    handleImageCardClick={handleImageCardClick}
                    theme={theme}
                  />
                </div>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={4}
              rightSize={6}
              leftComponent={
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    @media screen and (min-width: ${defaultTheme.breakpoints
                        .md}) {
                      text-align: right;
                    }
                  `}
                >
                  <Detail02
                    handleImageCardClick={handleImageCardClick}
                    theme={theme}
                  />
                </div>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    進捗状況の保存・共有機能
                  </GradationDividerMediumTitle>
                  <BodyText>
                    質問に対しておこなった回答の進捗状況(各ボタンの選択など)を、URL発行として保存・共有することができます。
                    発行されたURLにアクセスすると、途中から回答の再開ができ、また、ほかの人へ進捗を共有することができます。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot2: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="開発背景" // TODO-9a2coadsc: 差し替える
          //title="開発背景・工夫した点" // TODO-9a2coadsc: 差し替える
          theme={theme}
          bgColor={theme.palette.contrastText}
          slot1={
            <Columns1to2Wrapper
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  {/*TODO-9a2coadsc: 差し替える*/}
                  {/*<GradationDividerMediumTitle color={theme.palette.gray.text}>
                    開発背景
                  </GradationDividerMediumTitle>*/}
                  <BodyText>
                    Web開発プログラミング初心者にありがちな悩みである「進捗が出ない」「先輩への質問と報告が辛い」という課題を解決しようと考えたことが背景です。
                  </BodyText>
                  <BodyText>
                    <br />
                    私の体験談として、未経験からエンジニアとして入社した会社ではリモート勤務が主体でした。
                    オフィスへの出社は自由でしたが、チームメンバーは多忙かつ家から場所が遠いこともあり、ほとんど出社していない状況でした。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right;
                      }
                    `}
                  >
                    <img
                      src={productProgrammingOtasuke.ingenuity1}
                      alt={"開発背景-1"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 600px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right;
                      }
                    `}
                  >
                    <img
                      src={productProgrammingOtasuke.ingenuity2}
                      alt={"開発背景-2"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 600px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <BodyText>
                    初心者のうちのリモートワークは分からないことだらけで、用語・知識をたくさんインプットしたり、目の前の実装をこなしていくことがで精一杯だったり、だんだんと作業効率が落ちている実感がありました。
                    また、疲れている状態だと初歩的なエラーにも気づくのが遅れてしまって、数時間以上も無駄にすることもありました。
                    <br />
                    特に、タスクごとに、どこまで進捗が進んだのかを把握して、それをまとめた文章にするため思考し、先輩に質問や報告をする、という一連の作業がとにかく大変でした。
                    <br />
                    <br />
                    私が経験したこの悩みは、Qiitaなどのブログ記事を見ると他の未経験エンジニアの方も多く感じていることを知り、「初心者でもプログラミングを楽しく、ラクにするツールを開発をしよう」と思い立ちました。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot3={
            <Columns1to2Wrapper
              mt={0}
              leftSize={5}
              rightSize={5}
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      text-align: center;
                      @media screen and (min-width: ${defaultTheme.breakpoints
                          .md}) {
                        text-align: right; // 右端に寄せる
                      }
                    `}
                  >
                    <img
                      src={productProgrammingOtasuke.ingenuity3}
                      alt={"開発背景-3"}
                      css={css`
                        border-radius: ${defaultTheme.borderRadius.base};
                        width: 100%;
                        max-width: 600px;
                        min-width: 320px;
                      `}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <BodyText>
                    「疲れているせいか、今日はまったく進捗が出ていないような気がする…」
                    <br />
                    エンジニアが納期に追われているときの進捗が出ない焦りのストレスは想像以上に大きいです。
                    また、プロジェクトリーダー・マネージャー側もスケジュールに対して進捗が見えない、把握と確認に対するコミュニケーションコストが負担になりがちです。
                    <br />
                    <br />
                    そのような状況で、この補助ツールを使うことで、エラー解決の手順を一定化し、進捗を可視化します。
                    <br />
                    エンジニアは進捗を安定して出せる安心感を得ることができ、マネージャー・リーダーは少ない時間コストで進捗・質問状況を把握し回答することができ、お互いWin-Winの関係を築くことがねらいです。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
          }
        />
      ),
      slot3: (
        <ProductIntroductionSection
          pt={defaultTheme.spacing.xl}
          title="各種図面"
          theme={theme}
          bgColor={theme.palette.background}
          slot1={
            <Columns1to2Wrapper
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    インフラ構成図
                  </GradationDividerMediumTitle>
                  <BodyText>
                    クラウドサービスにAWSを採用しています。 フロントエンドはAWS
                    S3 & CloudFrontでホスティングしています。
                    インフラは、IaCであるAWS CDK
                    v2(TypeScript)を用いてコード化しています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-left: auto; // 右端に寄せる
                    `}
                  >
                    <AWSPortfolioInfra
                      handleImageCardClick={handleImageCardClick}
                      theme={theme}
                    />
                  </div>
                </FadeInOnScrollByComponent>
              }
            />
          }
          slot2={
            <Columns1to2Wrapper
              reverse={!pc}
              leftSize={5}
              rightSize={5}
              leftComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.medium}
                  direction="spot"
                >
                  <div
                    css={css`
                      margin-left: auto; // 右端に寄せる
                    `}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleImageCardClick(
                          productProgrammingOtasuke.diagram2,
                          "ワークフロー図"
                        )
                      }
                    >
                      <img
                        src={productProgrammingOtasuke.diagram2}
                        alt={"ワークフロー図"}
                        css={css`
                          ${mixin.boxShadow};
                          ${mixin.hoverTransitionOpacityEffect};
                          border: ${defaultTheme.borderWidth.sm}px solid;
                          border-color: ${theme.palette.gray.background};
                          border-radius: ${defaultTheme.borderRadius.base};
                          max-width: 100%;
                          min-width: 300px;
                        `}
                      />
                    </button>
                  </div>
                </FadeInOnScrollByComponent>
              }
              rightComponent={
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.gray.text}>
                    ワークフロー図
                  </GradationDividerMediumTitle>
                  <BodyText>
                    指定したブランチのリポジトリへのプッシュをトリガーにCI/CDが実行され、ビルドされたコンテンツをS3にアップロードしています。
                  </BodyText>
                </FadeInOnScrollByComponent>
              }
            />
            // TODO: ワークフロー図を2つ以上表示する場合は差し替える
            //<div>
            //  <FadeInOnScrollByComponent
            //    delay={defaultTheme.delay.none}
            //    direction="spot"
            //  >
            //    <GradationDividerMediumTitle color={theme.palette.gray.text}>
            //      ワークフロー図
            //    </GradationDividerMediumTitle>
            //  </FadeInOnScrollByComponent>
            //  <Columns1to2to3Wrapper
            //    firstComponent={
            //      <FadeInOnScrollByComponent
            //        delay={defaultTheme.delay.none}
            //        direction="spot"
            //      >
            //        <div
            //          css={css`
            //            margin-bottom: ${defaultTheme.spacing.xs};
            //          `}
            //        >
            //          <AWSPortfolioWorkflow01
            //            handleImageCardClick={handleImageCardClick}
            //            theme={theme}
            //          />
            //        </div>
            //        <LargeBodyText
            //          cssOverride={css`
            //            font-weight: 700;
            //            color: ${theme.palette.gray.text};
            //          `}
            //        >
            //          フロントエンド
            //        </LargeBodyText>
            //        <BodyText>
            //          リポジトリへのプッシュをトリガーに、GitHubActionsのCI/CDを実行し、S3
            //          & CloudFrontへアップロードしています。
            //        </BodyText>
            //      </FadeInOnScrollByComponent>
            //    }
            //  />
            //</div>
          }
        />
      ),
      slot4: (
        <section>
          <FullWidthOuter
            pt={defaultTheme.spacing.lg}
            pb={defaultTheme.spacing.lg}
            bgColor={theme.palette.contrastText}
          >
            <BaseInner
              width="100%"
              cssOverride={css`
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing.lg};
              `}
            >
              <section>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.none}
                  direction="spot"
                >
                  <GradationDividerMediumTitle color={theme.palette.text}>
                    開発期間および主なアップデート履歴
                  </GradationDividerMediumTitle>
                </FadeInOnScrollByComponent>
                <FadeInOnScrollByComponent
                  delay={defaultTheme.delay.short}
                  direction="spot"
                >
                  <Table
                    borderColor={defaultTheme.palette.gray.button[100]}
                    headers={["年月", "内容"]}
                    data={[
                      ["〜現在", "現在も継続してアップデート中"],
                      [
                        "2024年12月",
                        "ホスティングをVercel から AWS CDK v2(S3 & CloudFront) へ変更",
                      ],
                      ["2024年10月", "Vercel にて初回リリース"],
                    ]}
                  />
                </FadeInOnScrollByComponent>
              </section>
            </BaseInner>
          </FullWidthOuter>
        </section>
      ),
      imageModal: {
        open,
        src,
        alt,
        onClose: handleImageModalClose,
      },
    }),
    [mode, theme, pc, open, src, alt]
  );

  return (
    <ProductIntroductionTemplate
      id={constants.pages.product.id}
      theme={theme}
      mediaQuery={mediaQuery}
      slotProps={slotProps}
    />
  );
};

const imageSources: ProductProgrammingOtasukePageImageSourcesState = {
  productProgrammingOtasuke: {
    brandBanner: require("assets/images/product/programming-otasuke.webp"),
    diagram2: require("assets/images/product/programming-otasuke/workflow-front.webp"),
    ingenuity1: require("assets/images/product/programming-otasuke/ingenuity1.webp"),
    ingenuity2: require("assets/images/product/programming-otasuke/ingenuity2.webp"),
    ingenuity3: require("assets/images/product/programming-otasuke/ingenuity3.webp"),
  },
};
const requireSources = Object.values(imageSources.productProgrammingOtasuke);
const LayoutPage = () => (
  <MainLayout showPageTop>
    <ProgrammingOtasukePage />
  </MainLayout>
);
const PageWithLoader = withLoader(LayoutPage, requireSources, imageSources);
export { PageWithLoader as ProgrammingOtasukePage };
