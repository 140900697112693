/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

type Props = {
  zoom?: number;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const withZoomInImage =
  (WrappedComponent: React.ComponentType<Props>) =>
  ({ zoom = 1.05, ...rest }: Props) => {
    return (
      <div
        css={css`
          overflow: hidden; // MEMO: transform: scale()ではみだした部分を非表示にする
        `}
      >
        <WrappedComponent
          css={[
            css`
              transform: scale(1);
              &:hover {
                transform: scale(${zoom});
              }
            `,
          ]}
          {...rest}
        />
      </div>
    );
  };

export const ZoomInImage = withZoomInImage((props: Props) =>
  React.createElement("img", props)
);
